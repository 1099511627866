import { useEffect, useState } from "react";
import Pagination from "../../../components/pagination/Pagination";
import Header from "./Header";
import RowsWarehouse from "./RowsWarehouse";

const TableWarehouse = ({ enableEditR, enableCreateR, warehouse, racks, currntPg, onPage, onRack, onDeleteProduct, onEditProduct, onEditRack, onEditWarehouse, onCompatible }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(1);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = racks.slice(indexOfFirstRecord, indexOfLastRecord);

  useEffect(() => {
    setCurrentPage(currntPg);
  }, [currntPg]);

  return (
    <div>
      <Header
        enableCreateR={enableCreateR}
        name={warehouse.name}
        address={warehouse.address}
        phone={warehouse.phoneNumber}
        onRack={() => onRack(true)}
        onEditWarehouse={() => onEditWarehouse(true)}
      />
      <div className="overflow-x-auto">
        <table className="w-full text-center text-ag-secondary">
          <thead>
            <tr className="bg-ag-secondary-table">
              <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-4 md:px-2 py-4">Number</th>
              <th className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-20 md:px-2 py-4">Name</th>
              <th className="w-[40%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-20 md:px-2 py-4">Products</th>
              <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-2 py-4">Quantity</th>
              <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-5 md:px-2 py-4">Edit</th>
              <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-5 md:px-2 py-4">Delete</th>
            </tr>
          </thead>
          <tbody>
            {
              currentRecords.map((element, i) => {
                const newProducts = element.productsRack.reduce((acc, currentProduct) => {
                  const existingProductIndex = acc.findIndex(
                    (prod) => prod?.productInventory?.product?._id === currentProduct?.productInventory?.product?._id
                  );

                  if (existingProductIndex > -1) {
                    acc[existingProductIndex].quantity += currentProduct.quantity;
                  } else {
                    acc.push(currentProduct);
                  }

                  return acc;
                }, []);

                const sortedProducts = newProducts.sort((a, b) => {
                  return a?.productInventory?.product?.name.localeCompare(b?.productInventory?.product?.name, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                  });
                });


                element.productsRack = sortedProducts;

                return (
                  <RowsWarehouse
                    key={i}
                    indexWarehouse={(i-recordsPerPage)+(currentPage*recordsPerPage)}
                    enableEditR={enableEditR}
                    rack={element}
                    onEditRack={onEditRack}
                    onDeleteProduct={onDeleteProduct}
                    onEditProduct={(rckElement) => { onEditProduct(rckElement); }}
                    onCompatible={onCompatible}
                  />
                );
              })
            }
          </tbody>
        </table>
        <div className="p-5 m-auto">
          <Pagination
            currentPage={currentPage}
            totalCount={racks.length}
            pageSize={recordsPerPage}
            onPageChange={page => {
              setCurrentPage(page);
              onPage(page);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TableWarehouse;