const AboutUsInfo = () => {
    return (
        <>
            <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                <div className="text-center">
                    <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">About Us</h1>
                </div>
                <div className="text-center w-full md:w-[90%] m-auto">
                    <span className="text-base text-ag-secondary">
                        Our experts with over 10 years experience will repair your window in a professional and timely manner. 
                        We take pride in our quality parts and our affordable prices. We offer a warranty on our work. We do all types 
                        of standard auto glass repair.
                    </span>
                </div>
                <div className="pt-5 text-center">
                    <ul className="text-base text-ag-secondary">
                        <li>Windshield, back, and side glass replacement.</li>
                        <li>Inspections for easy-to-fix problems.</li>
                        <li>Replace motors, switches, regulators.</li>
                        <li>Quality parts for all makes & models.</li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default AboutUsInfo;