import { IoMdCloseCircle } from "react-icons/io";

const Modal = ({ title, onClose, onResponse }) => {
    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[500px]">
                        <div className="p-2 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-end rounded text-center">
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="relative px-5 py-3 flex-auto">
                                <div className="space-y-5">
                                    <div className="text-center font-normal">
                                        <h3 className="text-xl">{ title }</h3>
                                    </div>
                                    <div className="flex justify-center items-center space-x-3">
                                        <button
                                            type="button"
                                            className="px-5 py-2 bg-ag-secondary text-white text-sm rounded-md"
                                            onClick={() => onClose(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="px-5 py-2 bg-ag-primary text-white text-sm rounded-md"
                                            onClick={() => onResponse(true)}
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

export default Modal;