import React from "react";
import { Tooltip } from "react-tooltip";

const BackGlass = ({ isSelected, isAvailable, onClick }) => {
  return (
      <svg className="cursor-pointer outline-none" onClick={onClick} data-tooltip-id="car" data-tooltip-content="Backglass/Slider">
        <path d="M198.27 370.88V362.27C184.41 363.12 171.61 363.16 160.06 362.7V370.88H198.27Z" fill={isSelected ? "#A60812" :isAvailable ? "#147F40" : "#B4B4B3"} />
        <path
          d="M264.19 352.68C240.38 358.16 218.23 361.05 198.27 362.27V370.88H160.06V362.7C117.81 361.02 92.1897 352.68 92.1897 352.68L73.3896 405.48C100.19 415.08 143.92 419.08 178.32 419.08C239.12 419.08 284.99 407.48 284.99 407.48C284.99 407.48 284.99 407.48 264.19 352.68Z"
          fill={isSelected ? "#F41A28" :isAvailable ? "#1D8F43" : "#D3D3D4"}
        />
      </svg>
  );
};

export default BackGlass;
