const RowsCompatibleVehicles = ({ compVehElem }) => {
    return (
        <tr>
            <td className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-2">
                <p className="truncate w-44 m-auto">
                    { String(compVehElem?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(compVehElem?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(compVehElem?.year).replace(/\s+/g, '').split(',').sort()[Number(String(compVehElem?.year).replace(/\s+/g, '').split(',').length-1)]}` : compVehElem?.year}
                </p>
            </td>
            <td className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-2">
                <p className="truncate w-44 m-auto">{ compVehElem.brand }</p>
            </td>
            <td className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-2">
                <p className="truncate w-44 m-auto">{ compVehElem.model }</p>
            </td>
            <td className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-2">
                <p className="truncate w-44 m-auto">{ compVehElem.type_glass }</p>
            </td>
            <td className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-2">
                <p className="truncate w-44 m-auto">{ compVehElem.type_car }</p>
            </td>
        </tr>
    );
}

export default RowsCompatibleVehicles;