import { _getParams } from "./http";

export const getReportsByOrders = (params) => _getParams(`/reports/searchOrders`, params);

export const getReportsByReturns = (params) => _getParams(`/reports/searchReturns`, params);

export const getGeneralDate = (params) => _getParams(`/reports/generalData`, params);

export const searchProduct = (params) => _getParams(`/reports/searchProduct`, params);

export const searchProductWithLimits = (params) => _getParams(`/reports/searchProductsWithFilters`, params);
