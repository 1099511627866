import { useState } from "react";
import { useEffect } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { getInventoryAddId } from "../../../services/Inventory";
import TableHistory from "./TableHistory";

const MovementHistory = ({ id, onClose }) => {
    const [movements, setMovements] = useState([]);
    const [product, setProduct] = useState(null);

    useEffect(() => {
        getInventoryAddId(id).then(res => {
            if (res.status === 200) {
                setProduct(res.data.productInventory.product);
                setMovements(res.data.productInventory.movementsHistory);
            }
        });
    }, [id]);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[750px] lg:w-[850px]">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="text-xl text-ag-secondary font-bold">
                                        Movement History
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="px-2 md:px-5 pb-0 pt-5">
                                <TableHistory
                                    product={product}
                                    movements={movements}
                                />
                            </div>
                            <div className="w-full flex justify-center">
                                <button
                                    className="w-full md:w-[40%] bg-ag-primary-light text-center text-white px-2 py-1 rounded-lg disabled:bg-zinc-200 m-auto"
                                    type="button"
                                    onClick={() => onClose(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
        </>
    );
}

export default MovementHistory;