import React from "react";
import { Tooltip } from "react-tooltip";

const BaseCar = ({ children }) => {
  return (
    <>
    <svg width="358" height="508" viewBox="0 0 358 508" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M277.41 53.51C269.68 40.12 264.46 27.07 262.37 17.03C245.39 6.25 225.25 0 203.65 0H154.73C133.68 0 114.01 5.94 97.3096 16.22C95.3396 26.36 90.0496 39.76 82.1096 53.51C70.0296 74.44 55.5796 89.65 46.4896 91.95C45.5496 97.72 45.0596 103.64 45.0596 109.67V443.34C45.0596 478.69 73.7096 507.34 109.06 507.34H249.33C284.68 507.34 313.33 478.69 313.33 443.34V109.67C313.33 103.51 312.81 97.47 311.84 91.58C302.7 88.33 288.97 73.54 277.41 53.51Z"
        fill="#E2E1E0"
      />
      <path d="M44.6597 380.95L92.9197 339.35V289.28H44.6597V380.95Z" fill="#B4B4B3" />
      <path d="M44.6597 161.25V239.28H92.9197V179.62L44.6597 161.25Z" fill="#B4B4B3" />
      <path d="M92.9297 239.28H44.6597V289.28H92.9297V239.28Z" fill="#929392" />
      <path d="M264.66 339.35L312.92 380.95V289.28H264.66V339.35Z" fill="#B4B4B3" />
      <path d="M264.66 179.62V239.28H312.92V161.25L264.66 179.62Z" fill="#B4B4B3" />
      <path d="M312.93 239.28H264.66V289.28H312.93V239.28Z" fill="#929392" />
      <path
        d="M44.6598 189.75L13.9898 207.35C13.9898 207.35 8.91979 211.48 5.18979 204.95C3.12979 201.35 1.86979 198.57 1.14979 197.28C0.769794 196.61 0.499795 195.89 0.359795 195.13C-0.290205 191.68 -1.1102 182.08 8.3898 176.68C20.1198 170.01 35.5898 162.55 35.5898 162.55C35.5898 162.55 42.2598 159.62 44.6598 162.55V189.75Z"
        fill="#DAD9D9"
      />
      <path
        d="M312.92 189.75L343.59 207.35C343.59 207.35 348.66 211.48 352.39 204.95C354.45 201.35 355.71 198.57 356.43 197.28C356.81 196.61 357.08 195.89 357.22 195.13C357.87 191.68 358.69 182.08 349.19 176.68C337.46 170.01 321.99 162.55 321.99 162.55C321.99 162.55 315.32 159.62 312.92 162.55V189.75Z"
        fill="#DAD9D9"
      />
      <path
        d="M261.97 17.03C264.06 27.07 269.28 40.12 277.01 53.51C288.57 73.54 302.3 88.33 311.43 91.59C306.23 60.26 287.75 33.41 261.97 17.03Z"
        fill="#F4DFB5"
      />
      <path
        d="M82.62 53.29C90.56 39.54 95.85 26.14 97.82 16C71.26 32.36 52.2 59.72 47 91.73C56.09 89.43 70.54 74.22 82.62 53.29Z"
        fill="#F4DFB5"
      />

      {children}
    </svg>
    <Tooltip id="car" className="!bg-orange-500"/>
    </>
  );
};

export default BaseCar;
