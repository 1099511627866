import WithoutRows from "../../../components/table/WithoutRows";
import RowsDetail from "./RowsDetails";

const TableDetail = ({ stsId, products, warehouseOptions, kitValue, insurersOptions, onProduct, onSelect, onPrice, onCompatible, onFeatures }) => {
    return (
        <div className="overflow-auto md:overflow-hidden">
            <table className="w-full text-center">
                <thead>
                    <tr className="bg-ag-secondary-table">
                        <th className="w-full text-ag-secondary-letter text-xs font-normal px-2 py-2 border border-zinc-100" colSpan={7}>
                            Variables
                        </th>
                    </tr>
                    <tr className="bg-ag-secondary-table">
                        <th className="w-[30%] text-ag-secondary-letter text-xs font-normal px-2 py-2 border border-zinc-100">Product</th>
                        <th className="w-[15%] text-ag-secondary-letter text-xs font-normal px-2 py-2 border border-zinc-100">Features</th>
                        <th className="w-[15%] text-ag-secondary-letter text-xs font-normal px-2 py-2 border border-zinc-100">Vendor</th>
                        <th className="w-[10%] text-ag-secondary-letter text-xs font-normal px-2 py-2 border border-zinc-100">Hour labor</th>
                        <th className="w-[15%] text-ag-secondary-letter text-xs font-normal px-2 py-2 border border-zinc-100">Price</th>
                        <th className="w-[15%] text-ag-secondary-letter text-xs font-normal px-2 py-2 border border-zinc-100">Kit</th>
                        <th className="w-[15%] text-ag-secondary-letter text-xs font-normal px-2 py-2 border border-zinc-100">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        products.length > 0 ? products.map((element, i) => {
                            return (
                                <RowsDetail
                                    key={i}
                                    stsId={stsId}
                                    product={element}
                                    warehouseOptions={warehouseOptions}
                                    kitValue={kitValue}
                                    insurersOptions={insurersOptions}
                                    onProduct={onProduct}
                                    onSelect={onSelect}
                                    onPrice={onPrice}
                                    onCompatible={onCompatible}
                                    onFeatures={onFeatures}
                                />
                            )
                        })
                        :
                            <WithoutRows colspan={6} />
                    }
                </tbody>
            </table>
        </div>
    );
}

export default TableDetail;