import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { validateRetail, validateWholesaler } from "../../../utils/Validators";
import { fileUpload } from "../../../services/File";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import IconPDF from "../../../assets/icons/pdf-icon.png";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

const FormUser = ({ enableAddRetail, enableAddWholesaler, userType, userData, onSave, onEdit, onClose }) => {
  const [step, setStep] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [focusClass, setFocusClass] = useState({
    names: { placeholder: "Name", status: false },
    lastNames: { placeholder: "Last Name", status: false },
    email: { placeholder: "Email", status: false },
    password: { placeholder: "Password", status: false },
    phoneNumber: { placeholder: "Phone Number", status: false },
    zipCode: { placeholder: "ZIP Code", status: false },
    workshopName: { placeholder: "Company Name", status: false },
    workshopAddress: { placeholder: "Company Address", status: false },
    invoice: { placeholder: "EIN", status: false },
    availableCredit: { placeholder: "Available Credit", status: false }
  });

  const [user, setUser] = useState({
    id: "",
    names: "",
    lastNames: "",
    email: "",
    password: "",
    phoneNumber: "",
    zipCode: "",
    workshopName: "",
    workshopAddress: "",
    invoice: "",
    invoiceFile: { file: null, name: "" },
    availableCredit: ""
  });
  const [title, setTitle] = useState("New User");
  const [disabledSave, setDisabledSave] = useState(false);

  const handleStep = (sts) => {
    setStep(sts);
    setShowPassword(false);
    setShowPassword2(false);
    setUser({...user,
      id: "",
      names: "",
      lastNames: "",
      email: "",
      password: "",
      phoneNumber: "",
      zipCode: "",
      workshopName: "",
      invoice: "",
      invoiceFile: { file: null, name: "" },
      availableCredit: ""
    });

    setFocusClass({...focusClass,
      names: { placeholder: "Name", status: false },
      lastNames: { placeholder: "Last Name", status: false },
      email: { placeholder: "Email", status: false },
      password: { placeholder: "Password", status: false },
      phoneNumber: { placeholder: "Phone Number", status: false },
      zipCode: { placeholder: "ZIP Code", status: false },
      workshopName: { placeholder: "Company Name", status: false },
      workshopAddress: { placeholder: "Company Address", status: false },
      invoice: { placeholder: "EIN", status: false },
      availableCredit: { placeholder: "Available Credit", status: false }
    });
  };

  const handleUpload = (evt) => {
    if (evt.currentTarget.files[0].type !== "application/pdf") {
      toast.warning("It is not possible to upload a file other than pdf", alertOptions);
    } else {
      const file = evt.currentTarget.files;
      if (file !== null) {
        setUser({...user, invoiceFile: { file: file[0], name: file[0].name }});
      }
    }
  };

  const handleChange = (evt) => {
    if (evt.currentTarget.name === "workshopName" || evt.currentTarget.name === "names" || evt.currentTarget.name === "lastNames") {
      const pattern = new RegExp(/^[A-Za-z\s]+$/g);
      if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
        setUser({...user, [evt.currentTarget.name]: evt.currentTarget.value});
      }
    } else if (evt.currentTarget.name === "invoice" || evt.currentTarget.name === "phoneNumber" || evt.currentTarget.name === "zipCode") {
      const pattern = new RegExp(/^[0-9\s]+$/g);
      if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
        setUser({...user, [evt.currentTarget.name]: evt.currentTarget.value});
      }
    } else {
      setUser({...user, [evt.currentTarget.name]: evt.currentTarget.value});
    }
  };

  const isFormValid = (step, user) => {
    const commonFields = [
      user.names, user.email, user.password, user.phoneNumber, user.zipCode
    ];

    if (step) {
      return commonFields.concat(user.workshopName, user.invoice).every(field => field !== "");
    }

    return commonFields.concat(user.lastNames).every(field => field !== "");
  };


  const buildSubmitModel = (step, user) => {
    const baseModel = {
      image: "",
      names: user.names,
      lastNames: user.lastNames,
      email: user.email,
      password: user.password,
      phoneNumber: user.phoneNumber,
      zipCode: user.zipCode
    };

    if (step) {
      return {
        ...baseModel,
        workshopName: user.workshopName,
        workshopAddress: user.workshopAddress,
        invoice: user.invoice,
        invoiceFile: user.invoiceFile.name,
        availableCredit: user.availableCredit
      };
    }

    return baseModel;
  };

  const handleFileUpload = async (invoiceFile) => {
    if (invoiceFile.file !== null) {
      let bodyFormData = new FormData();
      bodyFormData.append('file', invoiceFile.file);

      const response = await fileUpload(bodyFormData);
      if (response.status === 200) return response.data;
    }
    return invoiceFile.name;
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setDisabledSave(true);

    if (!isFormValid(step, user)) {
      setDisabledSave(false);
      return toast.info("Please fill in all the fields of the form", alertOptions);
    }

    const submitModel = buildSubmitModel(step, user);
    const typeModel = step ? "wsu" : "ru";
    const result = step ? validateWholesaler(submitModel) : validateRetail(submitModel);

    if (!result.status) {
      setDisabledSave(false);
      return toast.warning(result.msg, alertOptions);
    }

    if (step && submitModel.invoiceFile.file !== null) {
      submitModel.invoiceFile = await handleFileUpload(user.invoiceFile);
    }

    if (userData !== null) {
      submitModel._id = userData._id;
      onEdit(userData._id, submitModel, typeModel);
    } else {
      onSave(submitModel, typeModel);
    }
    setDisabledSave(false);
  };


  useEffect(() => {
    let stepForm = false;
    if (userType === "wholesaler" ) {
      stepForm = true;
    }
    setStep(stepForm);

    if (userData) {
      setTitle("Edit User");

      setUser({...user,
        id: userData._id ? userData._id : "",
        names: userData.names ? userData.names : "",
        lastNames: userData.lastNames ? userData.lastNames : "",
        email: userData.user.email ? userData.user.email : "",
        password: userData.password ? userData.password : "",
        phoneNumber: userData.phoneNumber ? userData.phoneNumber : "",
        zipCode: userData.zipCode ? userData.zipCode : "",
        workshopName: userData.workshopName ? userData.workshopName : "",
        workshopAddress: userData.workshopAddress ? userData.workshopAddress : "",
        invoice: userData.invoice ? userData.invoice : "",
        invoiceFile: userData.invoiceFile ? { file: null, name: userData.invoiceFile } : { file: null, name: "" },
        availableCredit: userData.availableCredit ? userData.availableCredit : ""
      });

      setFocusClass({...focusClass,
        names: { placeholder: "Name", status: userData.names !== "" ? true : false },
        lastNames: { placeholder: "Last Name", status: userData.lastNames !== "" ? true : false },
        email: { placeholder: "Email", status: userData.user.email !== "" ? true : false },
        password: { placeholder: "Password", status: userData.password !== "" ? true : false },
        phoneNumber: { placeholder: "Phone Number", status: userData.phoneNumber !== "" ? true : false },
        zipCode: { placeholder: "Zip Code", status: userData.zipCode !== "" ? true : false },
        workshopName: { placeholder: "Company Name", status: userData.workshopName !== "" ? true : false },
        workshopAddress: { placeholder: "Company Address", status: userData.workshopAddress !== "" ? true : false },
        invoice: { placeholder: "Invoice", status: userData.invoice !== "" ? true : false },
        availableCredit: { placeholder: "Available Credit", status: userData.availableCredit !== "" ? true : false }
      });
    }
  }, [userData]);

  return (
    <>
      <div className="block">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none">
          <div className="w-[320px] md:w-[700px] lg:w-[800px] h-[600px] md:h-auto">
            <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
              <div className="w-full flex items-center justify-center rounded text-center">
                <div className="w-[95%]">
                  <span className="pl-[5%] text-xl text-ag-secondary font-bold">
                    {title}
                  </span>
                </div>

                <div className="w-[5%]">
                  <button
                    className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                    onClick={() => onClose(false)}
                  >
                    <IoMdCloseCircle />
                  </button>
                </div>
              </div>

              <div className="relative px-5 pb-2 pt-5 flex-auto">
                <form>
                  {
                    !step ?
                      <div className="space-y-8">
                        <div className="w-full md:w-[60%] flex justify-center items-center m-auto ">
                          <button
                            className="w-full bg-ag-primary-light text-sm lg:text-base text-white p-2 rounded-l-xl disabled:bg-zinc-200"
                            type="button"
                            onClick={() => handleStep(false)}
                            disabled={!enableAddRetail}
                          >
                            Retail client
                          </button>

                          <button
                            className="w-full bg-ag-secondary text-sm lg:text-base text-white p-2 rounded-r-xl disabled:bg-zinc-200"
                            type="button"
                            onClick={() => handleStep(true)}
                            disabled={!enableAddWholesaler}
                          >
                            Wholesaler
                          </button>
                        </div>

                        <div className="grid gap-3 md:grid-cols-2 md:rows-3">
                          <div>
                            <input
                              className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.names.status ? "text-left" : "text-center"}`}
                              type="text"
                              name="names"
                              placeholder={focusClass.names.placeholder}
                              onChange={handleChange}
                              value={user.names}
                              disabled={!enableAddRetail}
                              onFocus={() => setFocusClass({...focusClass, names: { placeholder: "", status: true }})}
                              onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, names: { placeholder: "Name", status: false }}) : null}
                              maxLength={40}
                            />
                          </div>

                          <div>
                            <input
                              className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.email.status ? "text-left" : "text-center"}`}
                              type="text"
                              name="email"
                              placeholder={focusClass.email.placeholder}
                              onChange={handleChange}
                              value={user.email}
                              disabled={!enableAddRetail}
                              onFocus={() => setFocusClass({...focusClass, email: { placeholder: "", status: true }})}
                              onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, email: { placeholder: "Email", status: false }}) : null}
                              maxLength={40}
                            />
                          </div>

                          <div>
                            <input
                              className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.lastNames.status ? "text-left" : "text-center"}`}
                              type="text"
                              name="lastNames"
                              placeholder={focusClass.lastNames.placeholder}
                              onChange={handleChange}
                              value={user.lastNames}
                              disabled={!enableAddRetail}
                              onFocus={() => setFocusClass({...focusClass, lastNames: { placeholder: "", status: true }})}
                              onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, lastNames: { placeholder: "Last Name", status: false }}) : null}
                              maxLength={40}
                            />
                          </div>

                          <div className="flex justify-center items-center border border-gray-500 rounded-xl">
                            <input
                              title="Password must contain at least: One number, one special character, one uppercase letter, one lowercase letter, must not contain spaces, and must be at least 8 characters."
                              className={`w-full text-ag-secondary-letter p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.password.status ? "text-left" : "text-center"}`}
                              type={showPassword ? "text" : "password"}
                              name="password"
                              placeholder={focusClass.password.placeholder}
                              onChange={handleChange}
                              value={user.password}
                              disabled={!enableAddRetail}
                              onFocus={() => setFocusClass({...focusClass, password: { placeholder: "", status: true }})}
                              onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, password: { placeholder: "Password", status: false }}) : null}
                              maxLength={40}
                            />
                            { showPassword ?
                              <button
                                className="border-none p-2 rounded-xl disabled:bg-zinc-200"
                                type="button"
                                onClick={() => setShowPassword(false)}
                              >
                                <span className="text-ag-secondary-letter text-base lg:text-xl"><BsFillEyeSlashFill /></span>
                              </button>
                              :
                              <button
                                className="border-none p-2 rounded-xl disabled:bg-zinc-200"
                                type="button"
                                onClick={() => setShowPassword(true)}
                              >
                                <span className="text-ag-secondary-letter text-base lg:text-xl"><BsFillEyeFill /></span>
                              </button>
                            }
                          </div>

                          <div>
                            <input
                              className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.phoneNumber.status ? "text-left" : "text-center"}`}
                              type="text"
                              name="phoneNumber"
                              maxLength={10}
                              placeholder={focusClass.phoneNumber.placeholder}
                              onChange={handleChange}
                              value={user.phoneNumber}
                              disabled={!enableAddRetail}
                              onFocus={() => setFocusClass({...focusClass, phoneNumber: { placeholder: "", status: true }})}
                              onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, phoneNumber: { placeholder: "Phone Number", status: false }}) : null}
                            />
                          </div>

                          <div>
                            <input
                              className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.zipCode.status ? "text-left" : "text-center"}`}
                              type="text"
                              name="zipCode"
                              placeholder={focusClass.zipCode.placeholder}
                              onChange={handleChange}
                              value={user.zipCode}
                              disabled={!enableAddRetail}
                              onFocus={() => setFocusClass({...focusClass, zipCode: { placeholder: "", status: true }})}
                              onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, zipCode: { placeholder: "ZIP Code", status: false }}) : null}
                              maxLength={5}
                            />
                          </div>
                        </div>

                        <div className="w-full md:w-[60%] m-auto">
                          <button
                            className="w-full bg-ag-primary-light text-white p-2 rounded-xl disabled:bg-zinc-200"
                            type="button"
                            onClick={handleSubmit}
                            disabled={!enableAddRetail || disabledSave}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                      :
                      <div className="space-y-3">
                        <div className="w-full md:w-[60%] flex justify-center items-center pt-3 m-auto">
                          <button
                            className="w-full bg-ag-secondary text-white p-2 rounded-l-xl disabled:bg-zinc-200"
                            type="button"
                            onClick={() => handleStep(false)}
                            disabled={!enableAddRetail}
                          >
                            Retail client
                          </button>

                          <button
                            className="w-full bg-ag-primary-light text-white p-2 rounded-r-xl disabled:bg-zinc-200"
                            type="button"
                            onClick={() => handleStep(true)}
                            disabled={!enableAddWholesaler}
                          >
                            Wholesaler
                          </button>
                        </div>

                        <div className="grid gap-3 md:grid-cols-2 md:rows-3 pt-3">
                          <div>
                            <input
                              className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.workshopName.status ? "text-left" : "text-center"}`}
                              type="text"
                              name="workshopName"
                              placeholder={focusClass.workshopName.placeholder}
                              onChange={handleChange}
                              value={user.workshopName}
                              disabled={!enableAddWholesaler}
                              onFocus={() => setFocusClass({...focusClass, workshopName: { placeholder: "", status: true }})}
                              onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, workshopName: { placeholder: "Company Name", status: false }}) : null}
                              maxLength={40}
                            />
                          </div>

                          <div>
                            <input
                              className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.workshopAddress.status ? "text-left" : "text-center"}`}
                              type="text"
                              name="workshopAddress"
                              placeholder={focusClass.workshopAddress.placeholder}
                              onChange={handleChange}
                              value={user.workshopAddress}
                              disabled={!enableAddWholesaler}
                              onFocus={() => setFocusClass({...focusClass, workshopAddress: { placeholder: "", status: true }})}
                              onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, workshopAddress: { placeholder: "Company Address", status: false }}) : null}
                              maxLength={60}
                            />
                          </div>

                          <div>
                            <input
                              className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.email.status ? "text-left" : "text-center"}`}
                              type="text"
                              name="email"
                              placeholder={focusClass.email.placeholder}
                              onChange={handleChange}
                              value={user.email}
                              disabled={!enableAddWholesaler}
                              onFocus={() => setFocusClass({...focusClass, email: { placeholder: "", status: true }})}
                              onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, email: { placeholder: "Email", status: false }}) : null}
                              maxLength={40}
                            />
                          </div>

                          <div>
                            <input
                              className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.names.status ? "text-left" : "text-center"}`}
                              type="text"
                              name="names"
                              placeholder={focusClass.names.placeholder}
                              onChange={handleChange}
                              value={user.names}
                              disabled={!enableAddWholesaler}
                              onFocus={() => setFocusClass({...focusClass, names: { placeholder: "", status: true }})}
                              onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, names: { placeholder: "Name", status: false }}) : null}
                              maxLength={40}
                            />
                          </div>

                          <div>
                            <input
                              className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.names.status ? "text-left" : "text-center"}`}
                              type="text"
                              name="lastNames"
                              placeholder={focusClass.lastNames.placeholder}
                              onChange={handleChange}
                              value={user.lastNames}
                              disabled={!enableAddWholesaler}
                              onFocus={() => setFocusClass({...focusClass, lastNames: { placeholder: "", status: true }})}
                              onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, lastNames: { placeholder: "Last Name", status: false }}) : null}
                              maxLength={40}
                            />
                          </div>

                          <div className="flex justify-center items-center border border-gray-500 rounded-xl">
                            <input
                              title="Password must contain at least: One number, one special character, one uppercase letter, one lowercase letter, must not contain spaces, and must be at least 8 characters."
                              className={`w-full text-ag-secondary-letter p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.password.status ? "text-left" : "text-center"}`}
                              type={showPassword2 ? "text" : "password"}
                              name="password"
                              placeholder={focusClass.password.placeholder}
                              onChange={handleChange}
                              value={user.password}
                              disabled={!enableAddWholesaler}
                              onFocus={() => setFocusClass({...focusClass, password: { placeholder: "", status: true }})}
                              onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, password: { placeholder: "Password", status: false }}) : null}
                              maxLength={40}
                            />
                            { showPassword2 ?
                              <button
                                className="border-none p-2 rounded-xl disabled:bg-zinc-200"
                                type="button"
                                onClick={() => setShowPassword2(false)}
                              >
                                <span className="text-ag-secondary-letter text-base lg:text-xl"><BsFillEyeSlashFill /></span>
                              </button>
                              :
                              <button
                                className="border-none p-2 rounded-xl disabled:bg-zinc-200"
                                type="button"
                                onClick={() => setShowPassword2(true)}
                              >
                                <span className="text-ag-secondary-letter text-base lg:text-xl"><BsFillEyeFill /></span>
                              </button>
                            }
                          </div>

                          <div>
                            <input
                              className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.phoneNumber.status ? "text-left" : "text-center"}`}
                              type="text"
                              name="phoneNumber"
                              maxLength={10}
                              placeholder={focusClass.phoneNumber.placeholder}
                              onChange={handleChange}
                              value={user.phoneNumber}
                              disabled={!enableAddWholesaler}
                              onFocus={() => setFocusClass({...focusClass, phoneNumber: { placeholder: "", status: true }})}
                              onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, phoneNumber: { placeholder: "Phone Number", status: false }}) : null}
                            />
                          </div>

                          <div>
                            <input
                              className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.invoice.status ? "text-left" : "text-center"}`}
                              type="text"
                              name="invoice"
                              placeholder={focusClass.invoice.placeholder}
                              onChange={handleChange}
                              value={user.invoice}
                              disabled={!enableAddWholesaler}
                              onFocus={() => setFocusClass({...focusClass, invoice: { placeholder: "", status: true }})}
                              onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, invoice: { placeholder: "Invoice", status: false }}) : null}
                              maxLength={40}
                            />
                          </div>
                        </div>

                        <div className="grid gap-3 md:grid-cols-2">
                          <div>
                            <label className="relative">
                              <div className="w-full flex justify-center items-center space-x-1 text-ag-secondary-letter text-center border border-gray-500 h-[95px] rounded-xl">
                                { user.invoiceFile.name !== "" ? null :
                                  <span><img className="w-[35px] m-auto" src={IconPDF} /></span>
                                }
                                { user.invoiceFile.name !== "" ?
                                  <p className="w-64 truncate">{ user.invoiceFile.name }</p>
                                  :
                                  <span>5000 Form</span>
                                }
                              </div>
                              <input type="file" className="absolute top-[0px] opacity-0" name="invoiceFile" accept=".pdf" onChange={handleUpload} />
                            </label>
                          </div>

                          <div className="space-y-3">
                            <div>
                              <input
                                className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.zipCode.status ? "text-left" : "text-center"}`}
                                type="text"
                                name="zipCode"
                                placeholder={focusClass.zipCode.placeholder}
                                onChange={handleChange}
                                value={user.zipCode}
                                disabled={!enableAddWholesaler}
                                onFocus={() => setFocusClass({...focusClass, zipCode: { placeholder: "", status: true }})}
                                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, zipCode: { placeholder: "ZIP Code", status: false }}) : null}
                                maxLength={5}
                              />
                            </div>

                            <div>
                              <input
                                className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.availableCredit.status ? "text-left" : "text-center"}`}
                                type="number"
                                name="availableCredit"
                                placeholder={focusClass.availableCredit.placeholder}
                                onChange={handleChange}
                                value={user.availableCredit}
                                disabled={!enableAddWholesaler}
                                onFocus={() => setFocusClass({...focusClass, availableCredit: { placeholder: "", status: true }})}
                                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, availableCredit: { placeholder: "Available Credit", status: false }}) : null}
                                maxLength={12}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="w-[60%] m-auto pt-3">
                          <button
                            className="w-full bg-ag-primary-light text-white p-2 rounded-xl disabled:bg-zinc-200"
                            type="button"
                            onClick={handleSubmit}
                            disabled={!enableAddWholesaler || disabledSave}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-50 fixed inset-0 z-20 bg-black"></div>
    </>
  );
};

export default FormUser;