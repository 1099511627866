import { useState } from 'react';

function MixedPaymentModal({ isOpen, onClose, onAccept }) {
  const [selectedMethod, setSelectedMethod] = useState('');
  const [credits, setCredits] = useState(0);

  if (!isOpen) return null;

  const handleAmountChange = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      setCredits(value);
    }
  };

  return (
    <div className="fixed inset-0 z-50 bg-black/50">
      <div className="fixed left-1/2 top-1/2 w-full max-w-md -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white p-6">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-medium">Credits to use: Venmo</h2>
          <button
            onClick={onClose}
            className="rounded-full p-1.5 hover:bg-gray-100"
          >
            ✕
          </button>
        </div>

        <div className="mt-6">
          <input
            type="text"
            value={credits}
            onChange={handleAmountChange}
            className="w-full rounded-lg border border-gray-300 px-4 py-2 focus:border-gray-400 focus:outline-none"
          />
        </div>

        <div className="mt-6">
          <p className="mb-3 text-sm">Select the second payment method</p>
          <div className="flex gap-3">
            <button
              onClick={() => setSelectedMethod('CREDIT_CARD')}
              className={`flex w-full items-center justify-center gap-2 rounded-lg border px-4 py-2 ${
                selectedMethod === 'CREDIT_CARD'
                  ? 'border-emerald-500 bg-emerald-50'
                  : 'border-gray-300'
              }`}
            >
              <span className="text-lg">💳</span>
              <span>Credit card</span>
            </button>
            <button
              onClick={() => setSelectedMethod('CASH')}
              className={`flex w-full items-center justify-center gap-2 rounded-lg border px-4 py-2 ${
                selectedMethod === 'CASH'
                  ? 'border-emerald-500 bg-emerald-50'
                  : 'border-gray-300'
              }`}
            >
              <span className="text-lg">💵</span>
              <span>Cash</span>
            </button>
          </div>
        </div>

        <div className="mt-6 flex gap-3">
          <button
            onClick={onClose}
            className="w-full rounded-lg bg-red-700 px-4 py-2 text-white hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            Cancel
          </button>
          <button
            onClick={() => selectedMethod && onAccept(selectedMethod, credits)}
            disabled={!selectedMethod}
            className="w-full rounded-lg bg-gray-600 px-4 py-2 text-white hover:bg-gray-700 disabled:cursor-not-allowed disabled:opacity-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
}

export default MixedPaymentModal;

