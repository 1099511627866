import { useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { alertOptions } from "../../utils/Utilities";

const ModalNumber = ({ forDelivery, title, onClose, onBack, onResponse }) => {
    const [textInput, setTextInput] = useState("");

    const handleChange = (evt) => {
        const pattern = new RegExp(/^[0-9]+$/g);
        if (pattern.test(evt.currentTarget.value) || evt.target.value === "") {
            setTextInput(evt.target.value);
        }
    }

    const handleSubmit = () => {
        if (textInput !== "") {
            if (textInput > 0) {
                onResponse(textInput);
            } else {
                toast.info("Please enter an amount greater than 0", alertOptions);
            }
        } else {
            toast.info("Please fill in the field", alertOptions);
        }
    }

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[620px]">
                        <div className="p-3 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-end rounded text-center">
                                <div className="w-[95%]">
                                    <div className="text-center font-normal">
                                        <h3 className="text-xl pl-[5%]">{ title }</h3>
                                    </div>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="relative p-3 flex-auto">
                                <div className="space-y-5">
                                    <div>
                                        <input
                                            className="w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-md disabled:bg-zinc-200"
                                            type="text"
                                            maxLength={10}
                                            onChange={handleChange}
                                            value={textInput}
                                        />
                                    </div>
                                    <div className="flex justify-center items-center space-x-3">
                                        <button
                                            type="button"
                                            className="px-5 py-2 bg-ag-primary text-white text-sm rounded-md"
                                            onClick={() => handleSubmit()}
                                        >
                                            Accept
                                        </button>
                                        <button
                                            type="button"
                                            className="px-5 py-2 bg-ag-secondary text-white text-sm rounded-md"
                                            onClick={() => forDelivery ? onBack(false) : onClose(false)}
                                        >
                                            { forDelivery ? "Back" : "Cancel" }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-20 bg-black"></div>
        </>
    );
}

export default ModalNumber;