import { _delete, _get, _post, _put } from "./http";

export const getInterchangeable = (interId) => _get(`/interchangeableNags/${interId}`);

export const getInterchangeables = () => _get(`/interchangeableNags`);

export const searchInterchangrablesByNag = (interNag) => _get(`/interchangeableNags/seeAllCompatVehiclesbyNag/${interNag}`);

export const saveInterchangeable = (interObject) => _post(`/interchangeableNags`, interObject);

export const updateInterchangeable = (interId, interObject) => _put(`/interchangeableNags/${interId}`, interObject);

export const deleteInterchangeable = (interId) => _delete(`/interchangeableNags/${interId}`);