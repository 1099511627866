import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import { validateInsurer } from "../../../../utils/Validators";
import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";

const FormInsurer = ({ insurerInfo, onSave, onEdit, onClose }) => {
    const [focusClass, setFocusClass] = useState({
        name: { placeholder: "Name", status: false },
        discountRegular: { placeholder: "Discount Regular", status: false },
        discountHeated: { placeholder: "Discount Heated", status: false },
        hour: { placeholder: "Hour", status: false },
        kitRegular: { placeholder: "Kit Regular", status: false },
        kitHighModulus: { placeholder: "Kit High Modulus", status: false },
        dynamicCalibration: { placeholder: "Dynamic Calibration", status: false },
        staticCalibration: { placeholder: "Static Calibration", status: false },
        dualCalibration: { placeholder: "Dual Calibration", status: false },
        homeServiceCost: { placeholder: "Home Service", status: false },
        taxRate: { placeholder: "Tax Rate", status: false },
    });

    const [insurerData, setInsurerData] = useState({
        name: "",
        discountRegular: "",
        discountHeated: "",
        hour: "",
        kitRegular: "",
        kitHighModulus: "",
        dynamicCalibration: "",
        staticCalibration: "",
        dualCalibration: "",
        homeServiceCost: "",
        taxRate: ""
    });

    const handleChange = (evt) => {
        if (evt.currentTarget.name === "name") {
            const pattern = new RegExp(/^[A-Za-z\s]+$/g);
            if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
                setInsurerData({...insurerData, [evt.currentTarget.name]: evt.currentTarget.value});
            }
        } else {
            const pattern = new RegExp(/^\d*\.?\d*$/);
            if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
                setInsurerData({...insurerData, [evt.currentTarget.name]: evt.currentTarget.value});
            }
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (
            insurerData.name !== "" && insurerData.discountRegular !== "" && insurerData.discountHeated !== "" && 
            insurerData.hour !== "" && insurerData.kitRegular !== "" && insurerData.kitHighModulus !== "" && 
            insurerData.dynamicCalibration !== "" && insurerData.staticCalibration !== "" && insurerData.dualCalibration !== "" &&
            insurerData.homeServiceCost !== "" && insurerData.taxRate !== ""
        ) {
            let insurerModel = {
                name: insurerData.name,
                discountRegular: insurerData.discountRegular,
                discountHeated: insurerData.discountHeated,
                kitRegular: insurerData.kitRegular,
                kitHighModulus: insurerData.kitHighModulus,
                staticCalibration: insurerData.staticCalibration,
                dynamicCalibration: insurerData.dynamicCalibration,
                hour: insurerData.hour,
                dualCalibration: insurerData.dualCalibration,
                homeServiceCost: insurerData.homeServiceCost,
                taxRate: insurerData.taxRate
            };

            const result = validateInsurer(insurerModel);
            if (result.status) {
                if (insurerInfo !== null) {
                    insurerModel._id = insurerInfo._id;
                    onEdit(insurerInfo._id, insurerModel);
                } else {
                    onSave(insurerModel);
                }
            } else {
                toast.warning(result.msg, alertOptions);
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    useEffect(() => {
        if (insurerInfo !== null) {
            setInsurerData({...insurerData,
                name: insurerInfo.name,
                discountRegular: insurerInfo.discountRegular,
                discountHeated: insurerInfo.discountHeated,
                hour: insurerInfo.hour,
                kitRegular: insurerInfo.kitRegular,
                kitHighModulus: insurerInfo.kitHighModulus,
                dynamicCalibration: insurerInfo.dynamicCalibration,
                staticCalibration: insurerInfo.staticCalibration,
                dualCalibration: insurerInfo.dualCalibration,
                homeServiceCost: insurerInfo.homeServiceCost,
                taxRate: insurerInfo.taxRate
            });

            setFocusClass({...focusClass,
                name: { placeholder: "Name", status: insurerInfo.name !== "" ? true : false },
                discountRegular: { placeholder: "Discount Regular", status: insurerInfo.discountRegular !== "" ? true : false },
                discountHeated: { placeholder: "Discount Heated", status: insurerInfo.discountHeated !== "" ? true : false },
                hour: { placeholder: "Hour", status: insurerInfo.hour !== "" ? true : false },
                kitRegular: { placeholder: "Kit Regular", status: insurerInfo.kitRegular !== "" ? true : false },
                kitHighModulus: { placeholder: "Kit High Modulus", status: insurerInfo.kitHighModulus !== "" ? true : false },
                dynamicCalibration: { placeholder: "Dynamic Calibration", status: insurerInfo.dynamicCalibration !== "" ? true : false },
                staticCalibration: { placeholder: "Static Calibration", status: insurerInfo.staticCalibration !== "" ? true : false },
                dualCalibration: { placeholder: "Dual Calibration", status: insurerInfo.dualCalibration !== "" ? true : false },
                homeServiceCost: { placeholder: "Home Service", status: insurerInfo.homeServiceCost !== "" ? true : false },
                taxRate: { placeholder: "Tax Rate", status: insurerInfo.taxRate !== "" ? true : false }

            });
        } else {
            setInsurerData({...insurerData,
                name: "",
                discountRegular: "",
                discountHeated: "",
                hour: "",
                kitRegular: "",
                kitHighModulus: "",
                dynamicCalibration: "",
                staticCalibration: "",
                dualCalibration: "",
                homeServiceCost: "",
                taxRate: ""
            });
        }
    }, [insurerInfo]);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[700px] lg:w-[800px] h-[450px] md:h-auto">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="text-xl text-ag-secondary font-bold">
                                        { insurerInfo !== null ? "Edit Insurence" : "New Insurence" }
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="relative px-5 pb-2 pt-5 flex-auto">
                                <form onSubmit={handleSubmit}>
                                    <div className="space-y-2">
                                        <div className="w-full flex justify-center items-center">
                                            <span
                                                className="w-[50%] md:w-[19%] bg-ag-secondary text-xs text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                            >
                                                Name
                                            </span>
                                            <input
                                                className="w-[50%] md:w-[81%] text-ag-secondary-letter border border-gray-500 p-2 rounded-r-xl truncate"
                                                type="text"
                                                name="name"
                                                onChange={handleChange}
                                                value={insurerData.name}
                                                maxLength={40}
                                            />
                                        </div>
                                        <div className="grid gap-3 md:grid-cols-3">
                                            <div className="w-full flex justify-center items-center">
                                                <span
                                                    className="w-[50%] md:w-[60%] bg-ag-secondary text-xs text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                                >
                                                    Discount (Regular)
                                                </span>
                                                <input
                                                    className="w-[50%] md:w-[40%] text-ag-secondary-letter border border-gray-500 p-2 rounded-r-xl truncate"
                                                    type="text"
                                                    name="discountRegular"
                                                    onChange={handleChange}
                                                    value={insurerData.discountRegular}
                                                    maxLength={12}
                                                />
                                            </div>
                                            <div className="w-full flex justify-center items-center">
                                                <span
                                                    className="w-[50%] md:w-[60%] bg-ag-secondary text-xs text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                                >
                                                    Discount (Heated)
                                                </span>
                                                <input
                                                    className="w-[50%] md:w-[40%] text-ag-secondary-letter border border-gray-500 p-2 rounded-r-xl truncate"
                                                    type="text"
                                                    name="discountHeated"
                                                    onChange={handleChange}
                                                    value={insurerData.discountHeated}
                                                    maxLength={12}
                                                />
                                            </div>
                                            <div className="w-full flex justify-center items-center">
                                                <span
                                                    className="w-[50%] md:w-[60%] bg-ag-secondary text-xs text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                                >
                                                    Hour
                                                </span>
                                                <input
                                                    className="w-[50%] md:w-[40%] text-ag-secondary-letter border border-gray-500 p-2 rounded-r-xl truncate"
                                                    type="text"
                                                    name="hour"
                                                    onChange={handleChange}
                                                    value={insurerData.hour}
                                                    maxLength={12}
                                                />
                                            </div>
                                            <div className="w-full flex justify-center items-center">
                                                <span
                                                    className="w-[50%] md:w-[60%] bg-ag-secondary text-xs text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                                >
                                                    Kit (Regular)
                                                </span>
                                                <input
                                                    className="w-[50%] md:w-[40%] text-ag-secondary-letter border border-gray-500 p-2 rounded-r-xl truncate"
                                                    type="text"
                                                    name="kitRegular"
                                                    onChange={handleChange}
                                                    value={insurerData.kitRegular}
                                                    maxLength={12}
                                                />
                                            </div>
                                            <div className="w-full flex justify-center items-center">
                                                <span
                                                    className="w-[50%] md:w-[60%] bg-ag-secondary text-xs text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                                >
                                                    Kit (HM)
                                                </span>
                                                <input
                                                    className="w-[50%] md:w-[40%] text-ag-secondary-letter border border-gray-500 p-2 rounded-r-xl truncate"
                                                    type="text"
                                                    name="kitHighModulus"
                                                    onChange={handleChange}
                                                    value={insurerData.kitHighModulus}
                                                    maxLength={12}
                                                />
                                            </div>
                                            <div className="w-full flex justify-center items-center">
                                                <span
                                                    className="w-[50%] md:w-[60%] bg-ag-secondary text-xs text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                                >
                                                    Dynamic (Cal.)
                                                </span>
                                                <input
                                                    className="w-[50%] md:w-[40%] text-ag-secondary-letter border border-gray-500 p-2 rounded-r-xl truncate"
                                                    type="text"
                                                    name="dynamicCalibration"
                                                    onChange={handleChange}
                                                    value={insurerData.dynamicCalibration}
                                                    maxLength={12}
                                                />
                                            </div>
                                            <div className="w-full flex justify-center items-center">
                                                <span
                                                    className="w-[50%] md:w-[60%] bg-ag-secondary text-xs text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                                >
                                                    Static (Cal.)
                                                </span>
                                                <input
                                                    className="w-[50%] md:w-[40%] text-ag-secondary-letter border border-gray-500 p-2 rounded-r-xl truncate"
                                                    type="text"
                                                    name="staticCalibration"
                                                    onChange={handleChange}
                                                    value={insurerData.staticCalibration}
                                                    maxLength={12}
                                                />
                                            </div>
                                            <div className="w-full flex justify-center items-center">
                                                <span
                                                    className="w-[50%] md:w-[60%] bg-ag-secondary text-xs text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                                >
                                                    Dual (Cal.)
                                                </span>
                                                <input
                                                    className="w-[50%] md:w-[40%] text-ag-secondary-letter border border-gray-500 p-2 rounded-r-xl truncate"
                                                    type="text"
                                                    name="dualCalibration"
                                                    onChange={handleChange}
                                                    value={insurerData.dualCalibration}
                                                    maxLength={12}
                                                />
                                            </div>
                                            <div className="w-full flex justify-center items-center">
                                                <span
                                                    className="w-[50%] md:w-[60%] bg-ag-secondary text-xs text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                                >
                                                    Home Service
                                                </span>
                                                <input
                                                    className="w-[50%] md:w-[40%] text-ag-secondary-letter border border-gray-500 p-2 rounded-r-xl truncate"
                                                    type="text"
                                                    name="homeServiceCost"
                                                    onChange={handleChange}
                                                    value={insurerData.homeServiceCost}
                                                    maxLength={12}
                                                />
                                            </div>
                                            <div className="w-full flex justify-center items-center">
                                                <span
                                                    className="w-[50%] md:w-[60%] bg-ag-secondary text-xs text-white text-center border border-gray-500 py-3 rounded-l-xl"
                                                >
                                                    Tax Rate
                                                </span>
                                                <input
                                                    className="w-[50%] md:w-[40%] text-ag-secondary-letter border border-gray-500 p-2 rounded-r-xl truncate"
                                                    type="text"
                                                    name="taxRate"
                                                    onChange={handleChange}
                                                    value={insurerData.taxRate}
                                                    maxLength={12}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full md:w-[50%] pt-3 m-auto">
                                            <button
                                                className="w-full bg-ag-primary-light text-white p-2 rounded-xl"
                                                type="submit"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-20 bg-black"></div>        
        </>
    );
}

export default FormInsurer;