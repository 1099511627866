import { useEffect, useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import FeaturesByProductOrder from "../../../wholesaler/mycart/FeaturesByProductOrder";

const RowsDelivery = ({ item, onItem, onCheck, onCompatible, order }) => {
  const [values, setValues] = useState({ real: 0, note: "" });

  const handleChange = (evt) => {
    const { name, value } = evt.target;

    if (name === "real") {
      if (/^\d+$/.test(value)) { // Solo números positivos
        setValues({ ...values, real: value });
        onItem(evt, item);
      } else {
        toast.warning("Please use only whole numbers in the Real field", alertOptions);
      }
    } else {
      setValues({ ...values, note: value });
      onItem(evt, item);
    }
  };

  useEffect(() => {
    if (item?.note) setValues({ ...values, note: item.note });
  }, [item?.note]);

  useEffect(() => {
    if (item?.real) setValues({ ...values, real: item.real });
  }, [item?.real]);

  return (
    <tr>
      <td className="w-[35%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        {item?.type !== "POWER_WINDOW_REPAIR" && item?.type !== "WINDOW_TINT" ? (
          <div className="flex justify-center items-center space-x-3">
            <div className="flex flex-col">
              <div>
                {order.warehouse.name}
              </div>

              {item.compatible_vehicles.map((prodComp, i) => (
                i === 0 && (
                  <div key={i}>
                    <p className="w-40 m-auto" title={
                      `${prodComp?.nag} ` +
                      `(${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length - 1)]}` : prodComp?.year})`
                    }>
                      {prodComp?.nag} ({String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length - 1)]}` : prodComp?.year})
                    </p>
                    <p className="w-40 m-auto" title={`${prodComp?.brand} ${prodComp?.model} ${prodComp?.type_car}`}>
                      {prodComp?.brand} {prodComp?.model} {prodComp?.type_car}
                    </p>
                  </div>
                )
              ))}
            </div>

            <div className="pt-1 pl-2">
              {item.compatible_vehicles.length > 1 && (
                <p className="truncate w-40 m-auto font-semibold cursor-pointer" onClick={() => onCompatible(item)}>
                  See compatible vehicles +
                </p>
              )}
            </div>
          </div>
        ) : (
          <p>{`${item?.year} ${item?.brand} ${item?.model} ${item?.typeGlass}`}</p>
        )}
      </td>
      <td className="w-[14%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
        <FeaturesByProductOrder features={item} />
      </td>
      <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        {item?.type !== "POWER_WINDOW_REPAIR" && item?.type !== "WINDOW_TINT" ? item.quantity : 1}
      </td>
      <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        {item?.type !== "POWER_WINDOW_REPAIR" && item?.type !== "WINDOW_TINT" ? (
          <input
            className="w-full h-12 text-center border border-ag-secondary-light rounded-md"
            name="real"
            type="number"
            onChange={handleChange}
            defaultValue={item?.real}
            value={values.real}
          />
        ) : "---"}
      </td>
      <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        {item?.type !== "POWER_WINDOW_REPAIR" && item?.type !== "WINDOW_TINT" ? (
          <p className="flex items-center justify-center w-full h-12 text-center border border-ag-secondary-light rounded-md">
            {item.real ? item.quantity - item?.real : ""}
          </p>
        ) : "---"}
      </td>
      <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        {item?.type !== "POWER_WINDOW_REPAIR" && item?.type !== "WINDOW_TINT" ? (
          <input
            className="w-full h-12 text-center border border-ag-secondary-light rounded-md"
            name="note"
            type="text"
            onChange={handleChange}
            defaultValue={item?.note}
          />
        ) : "---"}
      </td>
      <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        <div className="space-y-3">
          {item?.type !== "POWER_WINDOW_REPAIR" && item?.type !== "WINDOW_TINT" ? (
            <div className={`flex justify-center items-center space-x-1 cursor-pointer ${item?.check && item?.qtyDefault === item?.real ? "text-ag-secondary-light" : "text-green-500"}`} onClick={() => onCheck(item)}>
              <span className="text-lg"><BsFillCheckCircleFill /></span>
              <span className="text-xs">Check</span>
            </div>
          ) : "---"}
        </div>
      </td>
    </tr>
  );
};

export default RowsDelivery;
