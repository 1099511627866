import { _delete, _get, _post, _put } from "./http";

export const getInventoryStocktakeId = (id) => _get(`/stocktake/${id}`);

export const getInventoryStocktake = () => _get(`/stocktake`);

export const saveInventoryStocktake = (inventoryObject) => _post(`/stocktake`, inventoryObject);

export const updateInventoryStocktakeId = (id, inventoryObject) => _put(`/stocktake/${id}`, inventoryObject);

export const deleteInventoryStocktakeId = (id) => _delete(`/stocktake/${id}`);
