import { Link } from "react-router-dom";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import SidebarSubItem from "./SidebarSubItem";

const SidebarItem = ({ to, open, show, text, icon, subItems, showSub, setShowSub }) => {
  if (!show) return null;

  return (
    <>
      {open ? (
        <>
          <div className="flex justify-between items-center hover:bg-ag-secondary-light">
            <Link to={to}>
              <h3 className="py-3 pl-10 text-white text-left text-xl">{text}</h3>
            </Link>
            {subItems && (
              <button className="py-3 px-5" onClick={() => setShowSub(!showSub)}>
                <span className="text-white text-left text-xl">
                  {showSub ? <FaAngleUp /> : <FaAngleDown />}
                </span>
              </button>
            )}
          </div>
          {subItems && showSub && (
            <div>
              {subItems.map(
                (item, index) =>
                  item.show && (
                    <SidebarSubItem key={index} to={item.to} text={item.text} />
                  )
              )}
            </div>
          )}
        </>
      ) : (
        <Link to={to}>
          <h3 className="py-3 pl-5 md:pl-6 text-white text-left text-2xl group hover:bg-blue-500">
            <span className="w-56 -mt-3 ml-12 px-8 py-[10px] md:py-3 absolute bg-blue-500 text-lg rounded-r-xl hidden group-hover:block group-hover:transition group-hover:duration-700 delay-150 group-hover:ease-in-out">
              {text}
            </span>

            {typeof icon === "string" ? <img className="w-[25px]" src={icon} /> : icon}
          </h3>
        </Link>
      )}
    </>
  );
};

export default SidebarItem;
