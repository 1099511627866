import { useContext, useEffect, useState } from "react";
import { getProductEspecificationsBrands, getProductEspecificationsModel, getProductEspecificationsYears } from "../../../services/Product";
import { searchInventoryAdd } from "../../../services/Inventory";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { IoMdSearch } from "react-icons/io";
import { PermitsContext } from "../../../services/Permits";
import ModalCompatible from "../products/ModalCompatible";

function SelectProduct({ onClose, addToCart }) {
  const [quantity, setQuantity] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const { dataSession } = useContext(PermitsContext);
  const [pagesData, setPagesData] = useState({ total: 0, records: 0 });
  const [products, setProducts] = useState([]);
  const [yearOpts, setYearOpts] = useState([]);
  const [makeOpts, setMakeOpts] = useState([]);
  const [modelOpts, setModelOpts] = useState([]);
  const [filterPagination, setFilterPagination] = useState({
    data: dataSession.userType === "ADMIN" || dataSession.userType === "ADMINISTRATOR" ? {} : { warehouse: dataSession?.allWarehouse[0]._id },
    status: false
  });
  const [filterParams, setFilterParams] = useState({
    nag: "",
    brand: "",
    model: "",
    year: ""
  });
  const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
  const [isLoading, setIsLoading] = useState(false);

  const getYears = () => {
    setMakeOpts([]);
    setModelOpts([]);
    getProductEspecificationsYears().then(res => {
      if (res.status === 200) setYearOpts(res.data);
    });
  };

  const getBrands = (yearParam) => {
    setModelOpts([]);
    getProductEspecificationsBrands(yearParam).then(res => {
      if (res.status === 200) setMakeOpts(res.data);
    });
  };

  const getModels = (yearParam, brandParam) => {
    getProductEspecificationsModel(yearParam, brandParam).then(res => {
      if (res.status === 200) setModelOpts(res.data);
    });
  };

  const handleFilters = (filtersParams) => {
    setIsLoading(true);
    let filterObject = dataSession.userType === "ADMIN" || dataSession.userType === "ADMINISTRATOR" ? {} : { warehouse: dataSession?.allWarehouse[0]._id };
    if (
      filtersParams.model === "" &&
            filtersParams.brand === "" &&
            filtersParams.year === "" &&
            filtersParams.nag === ""
    ) {
      setFilterPagination({...filterPagination, data: { warehouse: dataSession?.allWarehouse[0]._id }, status: false});
      searchInventoryForPage(1, dataSession.userType === "ADMIN" || dataSession.userType === "ADMINISTRATOR" ? {} : { warehouse: dataSession?.allWarehouse[0]._id });
    } else {
      for (const key in filtersParams) {
        if (Object.hasOwnProperty.call(filtersParams, key)) {
          if (filtersParams[key] !== "") {
            filterObject[key] = filtersParams[key];
          }
        }
      }
      setFilterPagination({...filterPagination, data: filterObject, status: true});
      console.log(filterObject);
      searchInventoryForPage(1, filterObject);
    }
  };

  const handleChange = (evt) => {
    if (evt.currentTarget.name === "year") {
      setFilterParams({...filterParams, year: evt.currentTarget.value, brand: "", model: "" });
      getBrands(evt.currentTarget.value);
    } else if (evt.currentTarget.name === "brand") {
      setFilterParams({...filterParams, brand: evt.currentTarget.value, model: "" });
      getModels(filterParams.year, evt.currentTarget.value);
    } else if (evt.currentTarget.name === "model") {
      setFilterParams({...filterParams, model: evt.currentTarget.value });
    } else {
      setFilterParams({...filterParams, [evt.currentTarget.name]: evt.currentTarget.value});
    }
  };

  const searchInventoryForPage = (pageInventory, paramsFilters) => {
    searchInventoryAdd(pageInventory, 9, paramsFilters).then(res => {
      if (res.status === 200 && res.data.productsInventory.length > 0) {
        let array_productsInvetory = res.data.productsInventory.map((x) => {
          return {
            ...x,
            year: (typeof filterParams.year) === 'string' ? filterParams.year : ''
          };
        });
        setProducts(array_productsInvetory);
        setPagesData({...pagesData, total: res.data.totalRecords/9, records: res.data.totalRecords});
      } else {
        toast.warning("Search no records found", alertOptions);
      }

      setIsLoading(false);
    }).catch((error) => {
      toast.warning(error.response.data.message, alertOptions);
      setIsLoading(false);
    });
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    if (value === '' || parseInt(value) > 0) {
      setQuantity(value);
    }
  };

  const handleAddProduct = (isBulkOrder) => {
    addToCart(isBulkOrder, selectedItem, quantity, false);
    setSelectedItem(null);
    setQuantity('1');
  };

  useEffect(() => {
    setIsLoading(true);
    getYears();
    searchInventoryForPage(1, dataSession.userType === "ADMIN" || dataSession.userType === "ADMINISTRATOR" ? {} : { warehouse: dataSession?.allWarehouse[0]._id });
  }, []);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-6xl rounded-lg bg-white p-6">
        <div className="mb-4 flex items-center justify-between">
          <div>
          </div>

          <h2 className="text-xl font-semibold">Inventory specification</h2>

          <button
            onClick={() => onClose()}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>

        <div className="px-6 pt-2 md:flex md:justify-center md:items-center space-x-0 space-y-3 md:space-x-3 md:space-y-0">
          <div>
            <h3 className="text-sm md:text-base">Please choose an inventory specification to continue</h3>
          </div>

          <div className="grid gap-3 grid-cols-2 md:grid-cols-4">
            <div>
              <input
                className="w-full text-center text-ag-secondary-letter border border-gray-500 p-1 rounded-md disabled:bg-zinc-200"
                type="text"
                name="nag"
                placeholder="Nags"
                onChange={handleChange}
              />
            </div>
            <div>
              <select
                className="w-full text-center text-ag-secondary-letter border border-gray-500 p-[6px] rounded-md disabled:bg-zinc-200"
                name="year"
                onChange={handleChange}
                value={filterParams.year}
              >
                <option value="">Year</option>
                {
                  yearOpts.map((element, index) => {
                    return <option key={index} value={element.year}>{element.year}</option>;
                  })
                }
              </select>
            </div>
            <div>
              <select
                className="w-full text-center text-ag-secondary-letter border border-gray-500 p-[6px] rounded-md disabled:bg-zinc-200"
                name="brand"
                onChange={handleChange}
                value={filterParams.brand}
                disabled={!filterParams.year}
              >
                <option value="">Make</option>
                {
                  makeOpts.map((element, index) => {
                    return <option key={index} value={element.brand}>{element.brand}</option>;
                  })
                }
              </select>
            </div>
            <div>
              <select
                className="w-full text-center text-ag-secondary-letter border border-gray-500 p-[6px] rounded-md disabled:bg-zinc-200"
                name="model"
                onChange={handleChange}
                value={filterParams.model}
                disabled={!filterParams.brand}
              >
                <option value="">Model</option>
                {
                  modelOpts.map((element, index) => {
                    return <option key={index} value={element.model}>{element.model}</option>;
                  })
                }
              </select>
            </div>
          </div>
          <div>
            <button
              className="w-full bg-ag-secondary text-center text-white p-[6px] rounded-md flex justify-center"
              type="button"
              onClick={() => handleFilters(filterParams)}
            >
              <span className="text-2xl">
                <IoMdSearch />
              </span>
            </button>
          </div>
        </div>
        <div className="max-h-[60vh] overflow-auto mt-[30px]">
          { isLoading ? (
            <div className="flex justify-center items-center h-32">
              <div className="loader border-t-4 border-blue-500 rounded-full w-12 h-12 animate-spin"></div>
              <span className="ml-4">Loading products...</span>
            </div>
          ) :
            <table className="w-full">
              <thead className="bg-gray-100">
                <tr>
                  <th className="border px-4 py-2">Warehouse</th>
                  <th className="border px-4 py-2">NAG</th>
                  <th className="border px-4 py-2">Features</th>
                  <th className="border px-4 py-2">Name</th>
                  <th className="border px-4 py-2">Compatible Vehicles</th>
                  <th className="border px-4 py-2">Barcodes</th>
                  <th className="border px-4 py-2">Sales Price</th>
                  <th className="border px-4 py-2">Stock</th>
                </tr>
              </thead>
              <tbody>
                {products.map((item) => {
                  const product = item.productInventory.product;
                  console.log(product);
                  return  (
                    <tr
                      key={`${product._id}-${item.warehouse.name}`}
                      onClick={() => setSelectedItem({ ...product, warehouse: item.warehouse.name, cost: item.productInventory.cost, productInventory: { _id: item.productInventory._id }})}
                      className={`cursor-pointer transition-colors ${
                        selectedItem && selectedItem._id === product._id && item.warehouse.name === selectedItem.warehouse ? 'bg-green-100' : 'hover:bg-gray-50'
                      }`}
                    >
                      <td className="border px-4 py-2">{item.warehouse.name}</td>
                      <td className="border px-4 py-2">{product.nags}</td>
                      <td className="border px-4 py-2">{product.features}</td>
                      <td className="border px-4 py-2">{product.name}</td>
                      <td className="border px-4 py-2">
                        {
                          product.compatible_vehicles.map((prodComp, i) => {
                            if (i <= 2) {
                              return (
                                <p className="truncate w-24 m-auto" key={i} title={
                                  `${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year} ` +
                                    `${prodComp?.brand} ` +
                                    `${prodComp?.model} ` +
                                    `${prodComp?.type_car}`
                                }>
                                  { String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year }{' '}
                                  { prodComp?.brand }{' '}
                                  { prodComp?.model }{' '}
                                  { prodComp?.type_car }
                                </p>
                              );
                            }
                          })
                        }
                        {
                          product.compatible_vehicles.length > 3 ?
                            <p className="truncate w-24 font-semibold m-auto cursor-pointer" onClick={() => setShowCompatible({status: true, element: product})}>
                            See compatible vehicles +
                            </p>
                            : null
                        }
                      </td>
                      <td className="border px-4 py-2">
                        <p>
                          {product.barcode1}
                        </p>

                        <p>
                          {product.barcode2 ? product.barcode2 : ''}
                        </p>
                      </td>
                      <td className="border px-4 py-2">${product.price.toFixed(2)}</td>
                      <td className="border px-4 py-2">{item.totalQuantity}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          }
        </div>
        <div className="mt-4 flex items-center justify-end gap-4">
          <input
            type="text"
            value={quantity}
            onChange={handleQuantityChange}
            placeholder="Quantity"
            className="w-32 rounded-md border border-gray-300 px-4 py-2"
          />
          <button
            onClick={() => handleAddProduct(true)}
            className="rounded-md bg-orange-500 px-4 py-2 text-white hover:bg-orange-600"
            disabled={!selectedItem}
          >
            Bulk order
          </button>
          <button
            onClick={() => handleAddProduct(false)}
            className={`rounded-md bg-emerald-500 ${quantity > 1 ? 'opacity-50' : 'opacity-1 hover:bg-emerald-600'} px-4 py-2 text-white `}
            disabled={!selectedItem || quantity > 1}
          >
            Add product
          </button>
        </div>
      </div>

      {
        showCompatible.status ?
          <ModalCompatible
            products={showCompatible.element}
            onClose={() => setShowCompatible({status: false, element: null})}
          />
          : null
      }
    </div>
  );
}

export default SelectProduct;
