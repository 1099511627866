import { useEffect, useState } from "react";
import WithoutRows from "../../../../components/table/WithoutRows";
import Pagination from "../../../../components/pagination/Pagination";
import RowsHistory from "./RowsHistory";

const TableHistory = ({ orders }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(3);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = orders.slice(indexOfFirstRecord, indexOfLastRecord);

    useEffect(() => {
        setCurrentPage(1);
    }, []);

    return (
        <div>
            <table className="w-full text-center text-ag-secondary">
                <thead>
                    <tr className="bg-ag-secondary-table">
                        <th className="w-[25%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-base font-normal px-2 py-3">Transactions</th>
                        <th className="w-[25%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-base font-normal px-2 py-3">Amount</th>
                        <th className="w-[25%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-base font-normal px-2 py-3">Product</th>
                        <th className="w-[25%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-base font-normal px-2 py-3">Date</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        currentRecords.length === 0 ? <WithoutRows colspan={4} /> : currentRecords.map((element, i) => {
                            return (
                                <RowsHistory key={i} order={element} />
                            )
                        })
                    }
                </tbody>
            </table>
            <div className="p-5 m-auto">
                <Pagination
                    currentPage={currentPage}
                    totalCount={orders.length}
                    pageSize={recordsPerPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
        </div>
    );
}

export default TableHistory;