import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { getAllFeaturesWC } from "../../../utils/Utilities";

const MyCartFeatures = ({ product, onClose }) => {
    const [features, setFeatures] = useState([]);

    useEffect(() => {
        if (product !== null && product?.features) {
            const featuresAux = getAllFeaturesWC();
            let distinctFeatures = [];

            for (let i = 0; i < featuresAux.length; i++) {
                const regex = new RegExp(featuresAux[i].value.toLowerCase(), 'i')
                if (
                    (product.compatible_vehicles.some(element => regex.test(element.ad_info_a.toLowerCase())) === true) ||
                    (String(product.features).toLowerCase().includes(String(featuresAux[i].value).toLowerCase()) === true)
                ) {
                    const foundFeatures = distinctFeatures.find(distFeatElem => distFeatElem === featuresAux[i].value);
                    if (!foundFeatures) distinctFeatures.push(featuresAux[i].value);
                }
            }
            if (product.compatible_vehicles.some(element => element.antena === "Y")) {
                const foundFeatures = distinctFeatures.find(feature => feature === "Antenna");
                if (!foundFeatures) distinctFeatures.push("Antenna");
            }
            if (product.compatible_vehicles.some(element => element.encapsulated === "Y")) {
                const foundFeatures = distinctFeatures.find(feature => feature === "Encapsulated");
                if (!foundFeatures) distinctFeatures.push("Encapsulated");
            }
            if (product.compatible_vehicles.some(element => element.hud === "Y")) {
                const foundFeatures = distinctFeatures.find(feature => feature === "HUD");
                if (!foundFeatures) distinctFeatures.push("HUD");
            }
            if (product.compatible_vehicles.some(element => element.slider === "Y")) {
                const foundFeatures = distinctFeatures.find(feature => feature === "Slider");
                if (!foundFeatures) distinctFeatures.push("Slider");
            }
            if (product.compatible_vehicles.some(element => element.solar === "Y")) {
                const foundFeatures = distinctFeatures.find(feature => feature === "Solar");
                if (!foundFeatures) distinctFeatures.push("Solar");
            }
            setFeatures(distinctFeatures);
        }
    }, [product]);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[700px] lg:w-[850px] h-[95%] flex justify-center items-center">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none overflow-y-auto max-h-[100%]">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="text-xl text-ag-secondary font-bold">
                                        Features of: { product?.nags ? product?.nags : product?.nag }
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="px-8 py-5">
                                <ul className="list-disc">
                                    {
                                        features.length ?
                                            features.map((featuresElem, i) => (
                                                <li className="w-full text-ag-secondary" key={i}>{ featuresElem }</li>
                                            ))
                                        :
                                            <li className="w-full text-ag-secondary italic">Features not found...</li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

export default MyCartFeatures;