import { useState } from "react";
import { MdCalendarToday } from "react-icons/md";
import ReactDatePicker from "react-datepicker";

const Filters = ({ statusOptions, typeOptions, onFilters }) => {
    const [focusClass, setFocusClass] = useState({
        status: { placeholder: "Status", status: false },
        startDate: { placeholder: "Start", status: false },
        endDate: { placeholder: "End", status: false },
        type: { placeholder: "Type", status: false }
    });

    const [options, setOptions] = useState({
        status: "",
        startDate: "",
        endDate: "",
        type: ""
    });

    const handleChange = (evt) => {
        if (evt.currentTarget.value === "default") {
            setOptions({...options, [evt.currentTarget.name]: ""});
        } else {
            setOptions({...options, [evt.currentTarget.name]: evt.currentTarget.value});
        }
     }
 
     const handleSubmit = (evt) => {
        evt.preventDefault();
        onFilters(options);
     }

    return (
        <>
            <div>
                <form onSubmit={handleSubmit}>
                    <div className="grid gap-3 lg:gap-7 md:grid-cols-5 items-center">
                        <div className="w-full flex justify-center items-center">
                            <span
                                className="w-[30%] md:w-[45%] lg:w-[37%] bg-ag-secondary text-xs text-white text-center border border-gray-500 p-[10px] rounded-l-xl"
                            >
                                Start
                            </span>
                            <div className="customDatePickerWidth">
                                <div className="flex justify-center items-center px-2 border border-gray-500 rounded-r-xl">
                                    <ReactDatePicker
                                        className={`w-full text-ag-secondary-letter py-[6px] disabled:bg-zinc-200`}
                                        placeholderText=""
                                        name="startDate"
                                        autoComplete="off"
                                        selected={options.startDate}
                                        onChange={(dateVal) => setOptions({...options, startDate: dateVal})}
                                    />
                                    <span className="text-lg text-zinc-500"><MdCalendarToday /></span>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex justify-center items-center">
                            <span
                                className="w-[30%] md:w-[45%] lg:w-[37%] bg-ag-secondary text-xs text-white text-center border border-gray-500 p-[10px] rounded-l-xl"
                            >
                                End
                            </span>
                            <div className="customDatePickerWidth">
                                <div className="flex justify-center items-center px-2 border border-gray-500 rounded-r-xl">
                                    <ReactDatePicker
                                        className={`w-full text-ag-secondary-letter py-[6px] disabled:bg-zinc-200`}
                                        placeholderText=""
                                        name="endDate"
                                        autoComplete="off"
                                        selected={options.endDate}
                                        onChange={(dateVal) => setOptions({...options, endDate: dateVal})}
                                    />
                                    <span className="text-lg text-zinc-500"><MdCalendarToday /></span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <select
                                className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.status.status ? "text-left" : "text-center"}`}
                                name="status"
                                onChange={handleChange}
                                placeholder={focusClass.status.placeholder}
                                onFocus={() => setFocusClass({...focusClass, status: { placeholder: "", status: true }})}
                                onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, status: { placeholder: "Status", status: false }}) : null}
                            >
                                <option value="default">Status</option>
                                {
                                    statusOptions.map((element, index) => {
                                        return <option key={index} value={element.value}>{element.label}</option>;
                                    })
                                }
                            </select>
                        </div>
                        <div>
                            <select
                                className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.type.status ? "text-left" : "text-center"}`}
                                name="type"
                                onChange={handleChange}
                                placeholder={focusClass.type.placeholder}
                                onFocus={() => setFocusClass({...focusClass, type: { placeholder: "", status: true }})}
                                onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, type: { placeholder: "Type", status: false }}) : null}
                            >
                                <option value="default">Type</option>
                                {
                                    typeOptions.map((element, index) => {
                                        return <option key={index} value={element.value}>{element.label}</option>;
                                    })
                                }
                            </select>
                        </div>
                        <div>
                            <button
                                className="w-full text-center bg-ag-secondary text-white text-xs lg:text-base border border-gray-500 p-[7.5px] rounded-xl disabled:bg-zinc-200"
                                type="submit"
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default Filters;