import { formatAMPM, formatDate, formatCurrency } from "../../../../utils/Utilities";
import Features from "../../../../components/features/Features";

const RowsPriceHistory = ({ priceHistory, onCompatible}) => {
  const cost = priceHistory.productInventory ? priceHistory.productInventory.cost : '--';

  return (
    <tr>
      <td className="w-[6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        <p className="truncate w-16 m-auto">{ priceHistory.nags }</p>
      </td>

      <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        <p className="w-20 m-auto">{ priceHistory.name }</p>
      </td>

      <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        {
          priceHistory.compatible_vehicles.map((prodComp, i) => {
            if (i <= 2) {
              return (
                <p className="truncate w-24 m-auto" key={i} title={
                  `${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year} ` +
                                    `${prodComp?.brand} ` +
                                    `${prodComp?.model} ` +
                                    `${prodComp?.type_car}`
                }>
                  { String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year }{' '}
                  { prodComp?.brand }{' '}
                  { prodComp?.model }{' '}
                  { prodComp?.type_car }
                </p>
              );
            }
          })
        }
        {
          priceHistory.compatible_vehicles.length > 3 ?
            <p className="truncate w-24 font-semibold m-auto cursor-pointer" onClick={() => onCompatible(priceHistory)}>
              See compatible vehicles +
            </p>
            : null
        }
      </td>

      <td className="w-[11%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        <Features element={priceHistory} color={'#7a7a7a'} textSize={'10px'} seeTitle={false} />
      </td>

      <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        <p className="truncate w-20 m-auto">{ priceHistory.description }</p>
      </td>

      <td className="w-[7%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        <p className="truncate w-16 m-auto">{ priceHistory.vendors.length > 0 ? priceHistory.vendors[0].name : "" }</p>
      </td>

      <td className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        <p className="truncate w-12 m-auto">${ priceHistory.price }</p>
      </td>
      <td className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        <p className="truncate w-12 m-auto"> -- </p>
      </td>
      <td className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        <p className="truncate w-12 m-auto">${ cost }</p>
      </td>

      <td className="w-[7%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        <p className="truncate w-16 m-auto">{ priceHistory.barcode1 }</p>
      </td>

      <td className="w-[7%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        <p className="truncate w-16 m-auto">{ priceHistory.barcode2 }</p>
      </td>

      <td className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        <p className="truncate w-12 m-auto">{ priceHistory.totalQuantity }</p>
      </td>

      <td className="w-[6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        <p className="truncate w-16 m-auto">{ formatDate(priceHistory.date) }</p>
      </td>

      <td className="w-[7%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        <p className="truncate w-16 m-auto">{ formatAMPM(priceHistory.hour) }</p>
      </td>

      <td className="w-[6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        <p className="truncate w-16 m-auto">{ priceHistory.role }</p>
      </td>

      <td className="w-[7%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3" title={priceHistory?.user}>
        <p className="truncate w-16 m-auto">{ priceHistory.user }</p>
      </td>
    </tr>
  );
};

export default RowsPriceHistory;