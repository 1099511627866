import { useEffect, useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { RiCloseCircleFill } from "react-icons/ri";
import { MdEdit } from "react-icons/md";

const RowsNags = ({ nag, productsOpts, onEdit, onDelete, onCanEdit }) => {
    const [canEdit, setCanEdit] = useState(true);
    const [nagSelected, setNagSelected] = useState("");

    const handleCanEdit = () => {
        if (canEdit) {
            setCanEdit(!canEdit);
            onCanEdit(nag, true);
        } else {
            handleEdit();
        }
    }

    const handleEdit = () => {
        onCanEdit(nag, false);
        setNagSelected(nag);
        onEdit(nagSelected);
        setCanEdit(!canEdit);
    }

    useEffect(() => {
        setNagSelected(nag);
    }, [nag]);

    return (
        <tr>
            <td className="w-[50%] p-3 border-[0.5px] border-ag-secondary-light">
                <select
                    className="w-full text-center text-ag-secondary-letter text-xs border border-gray-500 px-2 py-1 rounded-xl disabled:bg-zinc-200"
                    name="nag"
                    value={nagSelected}
                    onChange={(evt) => setNagSelected(evt.target.value)}
                    disabled={canEdit}
                >
                    {
                        productsOpts.map((element, index) => {
                            return <option key={index} value={element.nags}>{element.nags}</option>;
                        })
                    }
                </select>
            </td>
            <td className="w-[50%] p-3 border-[0.5px] border-ag-secondary-light">
                <div className="flex justify-center items-center space-x-10">
                    <div>
                        <button type="button" onClick={() => handleCanEdit()}>
                            <span className={`${ canEdit ? "text-3xl" : "text-2xl text-green-600" }`}>
                                { canEdit ? <MdEdit /> : <BsCheckCircleFill /> }
                            </span>
                        </button>
                    </div>
                    <div>
                        <button type="button" onClick={() => onDelete(nag)}>
                            <span className="text-3xl text-red-700"><RiCloseCircleFill /></span>
                        </button>
                    </div>
                </div>
            </td>
        </tr>
    );
}

export default RowsNags;