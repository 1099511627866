import { _delete, _get, _post, _put } from "./http";

export const getUser = (id) => _get(`/administrators/${id}`);

export const getUsers = () => _get(`/administrators`);

export const saveUser = (userObject) => _post(`/administrators`, userObject);

export const updateUser = (id, userObject) => _put(`/administrators/${id}`, userObject);

export const deleteUser = (id) => _delete(`/administrators/${id}`);