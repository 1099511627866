const WithoutRows = ({ colspan }) => {
    return (
        <tr className="w-full">
            <td colSpan={colspan} className="w-full text-center text-ag-secondary-letter text-xs py-4">
                Table without records...
            </td>
        </tr>
    );
}

export default WithoutRows;