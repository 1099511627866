const arrStatus = {
    canceled: { text: "Canceled", color: "bg-zinc-200" },
    delivered: { text: "Delivered", color: "bg-ag-order-delivered" },
    inprocess: { text: "In process", color: "bg-ag-order-pending" },
    paid: { text: "Paid", color: "bg-ag-order-paid" },
    pending: { text: "Pending", color: "bg-ag-order-inprocess" },
    attend: { text: "Attended", color: "bg-ag-order-inprocess" }
}

const RowsProcess = ({ item }) => {
    return (
        <tr>
            <td className="w-[17%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                {`${String(item?._id).substring(String(item?._id).length - 13).toLocaleUpperCase()}`}
            </td>
            <td className="w-[25%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <ul>
                    {
                        item?.orderType === "POWER_WINDOW_REPAIR" ? "Power Window Repair" :
                        item?.orderType === "WINDOW_TINT" ? "Window Tint" : 
                        item.productsOrder.map((product, i) => {
                            return (
                                <li key={i}>{ product.productInventory.product.nags }</li>
                            )
                        })
                    }
                </ul>
            </td>
            <td className="w-[17%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <ul>
                    {
                        item.productsOrder.map((product, i) => {
                            return (
                                <li key={i}>{ product.quantity }</li>
                            )
                        })
                    }
                </ul>
            </td>
            <td className="w-[25%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                { item.wholesalerUser ? `${item.wholesalerUser?.names} ${item.wholesalerUser?.lastNames}` : "No employee assigned..."}
            </td>
            <td className={`w-[16%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6 ${ arrStatus[item.status]?.color }`}>
                { item.status ? arrStatus[item.status]?.text : "" }
            </td>
        </tr>
    );
}

export default RowsProcess;