import { useEffect, useState } from "react";
import Pagination from "../../../../components/pagination/Pagination";
import RowsStocktake from "./RowsStocktake";

const TableStocktake = ({ inventory }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(7);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = inventory.slice(indexOfFirstRecord, indexOfLastRecord);

  useEffect(() => {
    setCurrentPage(1);
  }, [inventory]);

  return (
    <div>
      <div className="overflow-auto min-h-[38vh] lg:min-h-[47vh] 3xl:min-h-[55vh]">
        <table className="w-full text-center text-ag-secondary">
          <thead>
            <tr className="bg-ag-secondary-table">
              <th className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-2 py-4">Stocktake No.</th>
              <th className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-2 py-4">Date</th>
              <th className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-20 md:px-2 py-4">Warehouse</th>
              <th className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-14 md:px-2 py-4">Reason</th>
              <th className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-2 py-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              currentRecords.map((element, i) => {
                return (<RowsStocktake key={i} stocktake={element} />);
              })
            }
          </tbody>
        </table>
      </div>
      <div className="p-5 m-auto">
        <Pagination
          currentPage={currentPage}
          totalCount={inventory.length}
          pageSize={recordsPerPage}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>
    </div>
  );
};

export default TableStocktake;