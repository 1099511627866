import { useState } from "react";
import { Link } from "react-router-dom";

const Filters = ({ stocktakes, warehouses, onFilters }) => {
    const [focusClass, setFocusClass] = useState({
        search: { placeholder: "Search Stocktake", status: false },
        stocktake: { placeholder: "Stocktake No.", status: false },
        warehouse: { placeholder: "Warehouse", status: false }
    });
    
    const [options, setOptions] = useState({
        search: "",
        stocktake: "",
        warehouse: ""
    });

    const handleChange = (evt) => {
        if (evt.currentTarget.name === "search") {
            setOptions ({...options, [evt.currentTarget.name]: evt.currentTarget.value});
            onFilters(evt.currentTarget.value, options);
        } else {
            if (evt.currentTarget.value === "default") {
                setOptions({...options, [evt.currentTarget.name]: ""});
            } else {
                setOptions({...options, [evt.currentTarget.name]: evt.currentTarget.value});
            }
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        onFilters(options.search, options);
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="space-y-3">
                    <div className="grid gap-3 md:gap-6 md:grid-cols-4">
                        <div className="md:col-span-3">
                            <div>
                                <input
                                    className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-2 px-4 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.search.status ? "text-left" : "text-center"}`}
                                    type="text"
                                    name="search"
                                    placeholder={focusClass.search.placeholder}
                                    onChange={handleChange}
                                    onFocus={() => setFocusClass({...focusClass, search: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, search: { placeholder: "Search Stocktake", status: false }}) : null}
                                />
                            </div>
                        </div>
                        <div>
                            <Link to="/inventory/stocktake/element">
                                <button
                                    className="w-full bg-ag-primary-light text-white text-xs lg:text-base p-2 rounded-xl disabled:bg-zinc-200"
                                    type="button"
                                >
                                    New Stocktake
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="grid gap-3 lg:gap-6 md:grid-cols-4 2xl:grid-cols-8">
                        <div>
                            <select
                                className={`w-full text-xs lg:text-sm py-2 px-4 text-ag-secondary-letter border border-gray-500 rounded-xl truncate ${focusClass.stocktake.status ? "text-left" : "text-center"}`}
                                name="stocktake"
                                defaultValue="default"
                                onChange={handleChange}
                                onFocus={() => setFocusClass({...focusClass, stocktake: { placeholder: "", status: true }})}
                                onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, stocktake: { placeholder: "Stocktake No.", status: false }}) : null}
                            >
                                <option value="default">Stocktake No.</option>
                                {
                                    stocktakes.map((element, index) => {
                                        return <option key={index} value={element.value}>{element.label}</option>;
                                    })
                                }
                            </select>
                        </div>
                        <div>
                            <select
                                className={`w-full text-xs lg:text-sm py-2 px-4 text-ag-secondary-letter border border-gray-500 rounded-xl truncate ${focusClass.warehouse.status ? "text-left" : "text-center"}`}
                                name="warehouse"
                                defaultValue="default"
                                onChange={handleChange}
                                onFocus={() => setFocusClass({...focusClass, warehouse: { placeholder: "", status: true }})}
                                onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, warehouse: { placeholder: "Warehouse", status: false }}) : null}
                            >
                                <option value="default">Warehouse</option>
                                {
                                    warehouses.map((element, index) => {
                                        return <option key={index} value={element._id}>{element.name}</option>;
                                    })
                                }
                            </select>
                        </div>
                        <div>
                            <button
                                className="w-full bg-ag-secondary-light text-center text-xs lg:text-sm px-4 py-[9px] text-white rounded-xl"
                                type="submit"
                            >
                                Search
                            </button>
                        </div>
                        <div className="2xl:col-span-5"></div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Filters;