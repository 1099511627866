import { formatCurrency, formatDateEng } from "../../../../utils/Utilities";

const RowsHistory = ({ order }) => {
    return (
        <tr>
            <td className="w-[25%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal py-7 px-2">
                { order?.productsOrder.length === 1 ? "1 Transaction" : `${order?.productsOrder.length} Transactions` }
            </td>
            <td className="w-[25%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal py-7 px-2">
                { formatCurrency(order?.amount) }
            </td>
            <td className="w-[25%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal py-7 px-2">
                {
                    order?.productsOrder.map((element, i) => {
                        if (order?.productsOrder.length > 0 && order?.productsOrder.length > i+1) {
                            return (<span key={i}>{ `${element?.productInventory?.product?.nags}, ` }</span>)
                        } else {
                            return (<span key={i}>{ element?.productInventory?.product?.nags}</span>)
                        }
                    })
                }
            </td>
            <td className="w-[25%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal py-7 px-2">
                { formatDateEng(order.createdAt) }
            </td>
        </tr>
    );
}

export default RowsHistory;