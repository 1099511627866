import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { getOrdersByInventory } from "../../../services/Orders";
import { formatCurrency, formatDateEng } from "../../../utils/Utilities";

const ModalOrders = ({ idInventory, onClose }) => {
  const [orders, setOrders] = useState([]);

  const searchOrdersInventory = (id) => {
    getOrdersByInventory(id).then(res => {
      if (res.status === 200 && res.data?.orders) setOrders(res.data.orders);
    });
  };

  useEffect(() => {
    if (idInventory !== "") searchOrdersInventory(idInventory);
  }, [idInventory]);

  return (
    <>
      <div className="block">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
          <div className="w-[320px] md:w-[750px] lg:w-[850px]">
            <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
              <div className="w-full flex items-center justify-center rounded text-center">
                <div className="w-[95%]">
                  <span className="text-xl text-ag-secondary font-bold">
                    History
                  </span>
                </div>
                <div className="w-[5%]">
                  <button
                    className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                    onClick={() => onClose(false)}
                  >
                    <IoMdCloseCircle />
                  </button>
                </div>
              </div>
              <div className="px-2 md:px-5 pb-0 pt-5 max-h-[400px] overflow-auto">
                {
                  orders.length > 0 ?
                    <div className="flex justify-end items-center space-x-3 border-b border-zinc-500 pb-2">
                      <p className="text-ag-secondary-letter font-semibold">Total orders:</p>
                      <p className="text-ag-secondary-letter">{ orders.length }</p>
                    </div>
                    : null
                }
                <ul className="space-y-3">
                  { orders.length > 0 ? orders.map((elem, i) => (
                    <li key={i} className="py-3 border-b border-zinc-500">
                      <div className="flex justify-start items-center space-x-3">
                        <p className="text-sm font-semibold">Date:</p>
                        <p className="text-sm">{ formatDateEng(elem?.createdAt) }</p>
                      </div>
                      <div className="flex justify-start items-center space-x-3">
                        <p className="text-sm font-semibold">ID Order:</p>
                        <p className="text-sm">{ String(elem?._id).substring(String(elem?._id).length - 5).toLocaleUpperCase() }</p>
                      </div>
                      <div className="flex justify-start items-center space-x-3">
                        <p className="text-sm font-semibold">ID Clover:</p>
                        <p className="text-sm">{ elem?.orderIdClover }</p>
                      </div>
                      <div className="flex justify-start items-center space-x-3">
                        <p className="text-sm font-semibold">Products:</p>
                        <p className="text-sm">{ elem?.productsOrder.length }</p>
                      </div>
                      <div className="flex justify-start items-center space-x-3">
                        <p className="text-sm font-semibold">Total:</p>
                        <p className="text-sm">{ formatCurrency(elem?.total) }</p>
                      </div>
                      <div className="flex justify-start items-center space-x-3">
                        <p className="text-sm font-semibold">Status:</p>
                        <p className="text-sm">{ elem?.status }</p>
                      </div>
                    </li>
                  )) :
                    <div className="pb-3">
                      <span className="text-ag-secondary-letter italic">Product inventory without orders...</span>
                    </div>
                  }
                </ul>
              </div>
              <div className="w-full flex justify-center mt-[20px]">
                <button
                  className="w-full md:w-[40%] bg-ag-primary-light text-center text-white px-2 py-1 rounded-lg disabled:bg-zinc-200 m-auto"
                  type="button"
                  onClick={() => onClose(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
    </>
  );
};

export default ModalOrders;