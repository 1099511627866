import { useState } from "react";
import { useEffect } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { getAllAvailableProducts, getWarehouse } from "../../../services/Warehouse";
import { removeAccents } from "../../../utils/Utilities";
import ProductCard from "./ProductCard";
import { FaPlus } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";

const ProductsAvailable = ({ typeView, IdWarehouse, currentProducts, onProducts, onClose, onCompatible }) => {
  const [nameWarehouse, setNameWarehouse] = useState("");
  const [racksOptions, setRacksOptions] = useState([]);
  const [productsAvailable, setProductsAvailable] = useState([]);
  const [productsAvailableUniv, setProductsAvailableUniv] = useState([]);
  const [productsStocktake, setProductsStocktake] = useState([]);
  const [productsStocktakeUniv, setProductsStocktakeUniv] = useState([]);
  const [dataFilters, setDataFilters] = useState({
    products_available: "",
    products_rack_first: "",
    products_stocktake: "",
    products_rack_second: ""
  });
  const [allProducts, setAllProducts] = useState([]);

  const filterProducts = (products, searchValue) => {
    const palabra = new RegExp(`${removeAccents(searchValue)}.*`, "i");
    return products.filter(element => (
      palabra.test(removeAccents(element.productInventory.product?.nags)) ||
      palabra.test(removeAccents(element.productInventory.product?.name)) ||
      palabra.test(removeAccents(element.productInventory.product?.model)) ||
      palabra.test(removeAccents(element.productInventory.product?.brand)) ||
      palabra.test(removeAccents(element.productInventory.product?.year)) ||
      palabra.test(removeAccents(element.productInventory.product?.glassType)) ||
      palabra.test(removeAccents(element.productInventory.product?.type_car)) ||
      palabra.test(removeAccents(element.productInventory.product?.description)) ||
      palabra.test(element.productInventory.product?.price) ||
      palabra.test(removeAccents(element.productInventory.product?.barcode1)) ||
      palabra.test(removeAccents(element.productInventory.product?.barcode2)) ||
      palabra.test(element.productInventory.product?.totalQuantity)
    ));
  };

  const handleFilters = (evt, productsList, setProductsList, rackKey, productKey, rackDefault) => {
    const { name, value } = evt.target;

    if ((name === productKey && value === "" && dataFilters[rackKey] === rackDefault) ||
        (name === rackKey && value === rackDefault && dataFilters[productKey] === "")) {
      setProductsList(productsList);
      setDataFilters({ ...dataFilters, [productKey]: "", [rackKey]: "" });
    } else {
      let productsCurrentFound = productsList;

      if (name === productKey) {
        productsCurrentFound = filterProducts(productsList, value);
        setDataFilters({ ...dataFilters, [productKey]: value });
      } else if (dataFilters[productKey] !== "") {
        productsCurrentFound = filterProducts(productsList, dataFilters[productKey]);
      }

      if (name === rackKey && value !== rackDefault) {
        productsCurrentFound = productsCurrentFound.filter(element => element.rack._id === value);
        setDataFilters({ ...dataFilters, [rackKey]: value });
      } else if (name === rackKey && value === rackDefault) {
        setDataFilters({ ...dataFilters, [rackKey]: "" });
      } else if (dataFilters[rackKey] !== "") {
        productsCurrentFound = productsCurrentFound.filter(element => element.rack._id === dataFilters[rackKey]);
      }

      setProductsList(productsCurrentFound);
    }
  };

  const handleFiltersFirst = (evt) => {
    handleFilters(evt, allProducts, setProductsAvailable, "products_rack_first", "products_available", "default");
  };

  const handleFiltersSecond = (evt) => {
    handleFilters(evt, productsStocktakeUniv, setProductsStocktake, "products_rack_second", "products_stocktake", "default");
  };


  const addProduct = (id) => {
    const newProductsAvailable = productsAvailable.filter(element => element._id_rackAndProductRelation !== id);

    const newProductsStocktake = productsStocktakeUniv;
    const productFound = allProducts.find(element => element._id_rackAndProductRelation === id);
    if (productFound) {
      newProductsStocktake.push(productFound);
    }

    setProductsAvailable(newProductsAvailable);
    setProductsStocktake(newProductsStocktake);
    setProductsAvailableUniv(newProductsAvailable);
    setProductsStocktakeUniv(newProductsStocktake);
  };

  const addProducts = () => {
    const newProductsStocktake = [...productsStocktakeUniv];
    const newProductsAvailable = [];

    allProducts.forEach(element => {
      if (dataFilters.products_rack_first !== "") {
        if (element.rack._id === dataFilters.products_rack_first) {
          newProductsStocktake.push(element);
        } else {
          newProductsAvailable.push(element);
        }
      } else {
        newProductsStocktake.push(element);
      }
    });

    setProductsAvailable(newProductsAvailable);
    setProductsStocktake(newProductsStocktake);
    setProductsAvailableUniv([...newProductsAvailable]);
    setProductsStocktakeUniv([...newProductsStocktake]);
    setDataFilters({ ...dataFilters, products_rack_first: "" });
  };


  const removeProduct = (id) => {
    const newProductsAvailable = [...productsAvailableUniv];

    const productFound = productsStocktakeUniv.find(element => element._id_rackAndProductRelation === id);

    if (productFound && !newProductsAvailable.some(prod => prod._id_rackAndProductRelation === id)) {
      newProductsAvailable.push(productFound);
    }

    const newProductsStocktake = productsStocktakeUniv.filter(element => element._id_rackAndProductRelation !== id);

    setProductsAvailable(newProductsAvailable);
    setProductsStocktake(newProductsStocktake);
    setProductsAvailableUniv(newProductsAvailable);
    setProductsStocktakeUniv(newProductsStocktake);
  };

  const removeProducts = () => {
    let newProductsAvailable = [...productsAvailableUniv];
    const newProductsStocktake = [];

    productsStocktakeUniv.forEach(element => {
      if (dataFilters.products_rack_second !== "") {
        if (element.rack._id === dataFilters.products_rack_second) {

          if (!newProductsAvailable.some(prod => prod.productInventory._id === element.productInventory._id && prod.rack._id === element.rack._id)) {
            newProductsAvailable.push(element);
          }
        } else {
          newProductsStocktake.push(element);
        }
      } else {
        if (!newProductsAvailable.some(prod => prod.productInventory._id === element.productInventory._id && prod.rack._id === element.rack._id)) {
          newProductsAvailable.push(element);
        }
      }
    });

    setProductsAvailable(newProductsAvailable);
    setProductsStocktake(newProductsStocktake);
    setProductsAvailableUniv(newProductsAvailable);
    setProductsStocktakeUniv(newProductsStocktake);
    setDataFilters({ ...dataFilters, products_rack_second: "" });
  };



  const handleSubmit = (evt) => {
    evt.preventDefault();
    onProducts(productsStocktake);
  };

  const fetchAvailableProducts = async (warehouseId, currentProducts, typeView) => {
    const res = await getAllAvailableProducts(warehouseId);
    if (res.status === 200 && res.data.allAvailableProducts?.length > 0) {
      const allAvailableProducts = res.data.allAvailableProducts;
      if (currentProducts.length === 0) {
        return { prodsAvailable: allAvailableProducts, prodsStocktake: [] };
      }

      let prodsAvailable = [];
      let prodsStocktake = [];

      allAvailableProducts.forEach(product => {
        const prodFound = currentProducts.find(element => {
          const currentProductKey = `${element._id}${element.rack?._id || element.rack}`;
          const availableProductKey = `${product.productInventory._id}${product.rack._id}`;
          if (typeView === "transfer") {
            return currentProductKey === availableProductKey || `${element?.productInventory?._id}${element.rack._id}` === availableProductKey;
          }

          return currentProductKey === availableProductKey;
        });

        if (prodFound) {
          prodsStocktake.push(product);
        } else {
          prodsAvailable.push(product);
        }
      });

      return { prodsAvailable, prodsStocktake };
    }
    return { prodsAvailable: [], prodsStocktake: [] };
  };

  const fetchWarehouseDetails = async (warehouseId) => {
    const res = await getWarehouse(warehouseId);
    if (res.status === 200) {
      return { name: res.data.name, racks: res.data.racks };
    }
    return { name: '', racks: [] };
  };

  useEffect(() => {
    fetchAvailableProducts(IdWarehouse, currentProducts, typeView).then(({ prodsAvailable, prodsStocktake }) => {
      setProductsAvailable(prodsAvailable);
      setProductsStocktake(prodsStocktake);
      setProductsAvailableUniv(prodsAvailable);
      setProductsStocktakeUniv(prodsStocktake);
      setAllProducts(prodsAvailable);
    });
  }, [IdWarehouse, currentProducts, typeView]);

  useEffect(() => {
    fetchWarehouseDetails(IdWarehouse).then(({ name, racks }) => {
      setNameWarehouse(name);
      setRacksOptions(racks);
    });
  }, [IdWarehouse]);


  return (
    <>
      <div className="block">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
          <div className="w-[320px] md:w-[750px] lg:w-[950px] h-[550px] md:h-auto">
            <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
              <div className="w-full flex items-center justify-center rounded text-center">
                <div className="w-[95%]">
                  <span className="text-xl font-bold">
                    Products available at warehouse:
                  </span>
                  {' '}
                  <span className="text-xl text-red-700 font-bold">
                    { nameWarehouse }
                  </span>
                </div>

                <div className="w-[5%]">
                  <button
                    className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                    onClick={() => onClose(false)}
                  >
                    <IoMdCloseCircle />
                  </button>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4 flex-grow overflow-hidden mt-[20px]">
                <div className="flex flex-col">
                  <h3 className="mb-2 font-semibold text-ag-secondary">All available products</h3>
                  <div className="flex gap-2 mb-2">
                    <input
                      className="w-full text-ag-secondary-letter border border-[#6b728057] p-1 rounded-md"
                      type="text"
                      name="products_available"
                      placeholder="Filter products..."
                      onChange={handleFiltersFirst}
                    />

                    <select
                      className="w-full text-ag-secondary-letter border border-[#6b728057] p-1 rounded-md"
                      name="products_rack_first"
                      onChange={handleFiltersFirst}
                      value={dataFilters.products_rack_first}
                    >
                      <option value="default">Rack</option>
                      {
                        racksOptions.map((element, index) => {
                          return <option key={index} value={element._id}>{element.name}</option>;
                        })
                      }
                    </select>
                  </div>

                  <button
                    className="font-semibold text-blue-700 mt-[15px] mb-[10px] text-[14px] text-left"
                    type="button"
                    onClick={addProducts}
                  >
                    Add all products to { typeView } {`(${productsAvailable.length} products)`}
                  </button>

                  <div className="flex-grow h-[400px] overflow-y-scroll">
                    <div className="pr-4">
                      {productsAvailable.map(product => (
                        <ProductCard
                          key={product.id}
                          product={product}
                          onAction={addProduct}
                          actionIcon={<FaPlus />}
                          actionText="Add to Transfer"
                          onCompatible={onCompatible}
                        />
                      ))}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col">
                  <h3 className="mb-2 font-semibold text-ag-secondary">Products for transfer</h3>

                  <div className="flex gap-2 mb-2">
                    <input
                      className="w-full text-ag-secondary-letter border border-[#6b728057] p-1 rounded-md"
                      type="text"
                      name="products_stocktake"
                      placeholder="Filter products..."
                      onChange={handleFiltersSecond}
                    />

                    <select
                      className="w-full text-ag-secondary-letter border border-[#6b728057] p-1 rounded-md"
                      name="products_rack_second"
                      onChange={handleFiltersSecond}
                      value={dataFilters.products_rack_second}
                    >
                      <option value="default">Rack</option>
                      {
                        racksOptions.map((element, index) => {
                          return <option key={index} value={element._id}>{element.name}</option>;
                        })
                      }
                    </select>
                  </div>

                  <button
                    className="font-semibold text-red-700 mt-[15px] mb-[10px] text-[14px] text-left"
                    type="button"
                    onClick={removeProducts}
                  >
                    Remove all variants from { typeView } {`(${productsStocktake.length})`}
                  </button>

                  <div className="flex-grow h-[400px] overflow-y-scroll">
                    <div className="pr-4">
                      {productsStocktake.map(product => (
                        <ProductCard
                          key={product.id}
                          product={product}
                          onAction={removeProduct}
                          actionIcon={<IoCloseSharp className="text-lg"/>}
                          actionText="Remove from Transfer"
                          onCompatible={onCompatible}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end mt-[20px]">
                <div className="flex justify-end gap-2 mt-4">
                  <button className="w-full bg-red-700 text-white py-[8px] px-[10px] rounded-md" type="button" onClick={() => onClose(false)}>
                    Cancel
                  </button>

                  <button
                    className="w-full bg-green-600 text-white py-[8px] px-[30px] rounded-md"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
    </>
  );
};

export default ProductsAvailable;