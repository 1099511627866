import { useContext, useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { getWarehouses } from "../../../../services/Warehouse";
import { getWholesalerUsers } from "../../../../services/Wholesaler";
import { alertOptions, formatReverseDate, removeAccents } from "../../../../utils/Utilities";
import { toast } from "react-toastify";
import { getReturns, addInventoryReturn, updateReturn } from "../../../../services/Returns";
import { PermitsContext } from "../../../../services/Permits";
import { getRetailUsers } from "../../../../services/Retail";
import Loader from "../../../../components/loader/Loader";
import Filters from "../Filters";
import InventoryReturns from "./InventoryReturns";
import DetailReturns from "../DetailReturns";
import TableApproved from "./TableApproved";

const Approved = () => {
  const { dataSession, permits } = useContext(PermitsContext);
  const [loader, setLoader] = useState(true);
  const [showInventory, setShowInventory] = useState({ status: false, data: null });
  const [showDetail, setShowDetail] = useState({ status: false, data: null });
  const [returns, setReturns] = useState([]);
  const [returnsUniv, setReturnsUniv] = useState([]);
  const [searchWord, setSearchWord] = useState(null);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [statusOptions] = useState([
    { label: "Approved", value: "APPROVED" },
    { label: "Canceled", value: "CANCELED" },
    { label: "Finished", value: "FINISHED" }
  ]);

  const [focusClass, setFocusClass] = useState({
    search: { placeholder: "Search", status: false }
  });

  const handleFilters = (wordFilter, options) => {
    if (wordFilter === "" && options.warehouse === "" && options.status === "" && options.client === "" && options.dateStart === "" && options.dateEnd === "") {
      setReturns(returnsUniv);
    } else {
      let returnsFound = returnsUniv;

      if (wordFilter !== "") {
        let palabra = new RegExp(`${removeAccents(wordFilter)}.*`, "i");
        returnsFound = returnsFound.filter(element => {
          if (
            palabra.test(removeAccents(element.status)) ||
                        palabra.test(removeAccents(element.reason)) ||
                        palabra.test(removeAccents(element.productOrder.productInventory.product.nags)) ||
                        palabra.test(removeAccents(`${element?.wholesaler?.names} ${element?.wholesaler?.lastNames}`)) ||
                        palabra.test(removeAccents(`${element?.employee?.names} ${element?.employee?.lastNames}`)) ||
                        palabra.test(element.total) ||
                        palabra.test(removeAccents(element?.warehouse?.name))
          ) {
            return element;
          }
        });
      }

      if (options.warehouse !== "") {
        returnsFound = returnsFound.filter(element => (element.warehouse?._id === options.warehouse));
      }

      if (options.status !== "") {
        returnsFound = returnsFound.filter(element => (options.status === element.status));
      }

      if (options.client !== "") {
        returnsFound = returnsFound.filter(element => (element.wholesaler?._id === options.client));
      }

      if ((options.dateStart !== "" && options.dateEnd === "") || (options.dateStart === "" && options.dateEnd !== "")) {
        toast.info("Please enter both dates to perform the filter", alertOptions);
        returnsFound = [];
      } else if (options.dateStart !== "" && options.dateEnd !== "") {
        if (formatReverseDate(options.dateStart) > formatReverseDate(options.dateEnd)) {
          toast.info("The start date is greater than the end date", alertOptions);
          returnsFound = [];
        } else {
          returnsFound = returnsFound.filter(element => (
            (formatReverseDate(element.createdAt) >= formatReverseDate(options.dateStart)) &&
                        (formatReverseDate(element.createdAt) <= formatReverseDate(options.dateEnd))
          ));
        }
      }

      setReturns(returnsFound);
    }
  };

  const handleInventory = (inventoryModel) => {
    setLoader(true);
    addInventoryReturn(inventoryModel._id, inventoryModel).then(res => {
      if (res.status === 200) {
        getData();
        setLoader(false);
        setShowInventory({...showInventory, status: false, data: null});
        toast.success("Product updated successfully", alertOptions);
      } else {
        setLoader(false);
        toast.warning(res.response.data.message, alertOptions);
      }
    }).catch(error => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  const getData = () => {
    getReturns().then(res => {
      if (res.data && res.data.length > 0) {
        const returnsFound = res.data.map(ele => {
          if (ele.status === "APPROVED") {
            if (ele?.productReturn) return {...ele, status: "FINISHED"};
            return ele;
          }
          return ele;
        });
        let returnFilter = [];
        if (dataSession.userType === "ADMIN") {
          returnFilter = returnsFound.filter(ele => ele.status !== "INPROCESS");
        } else {
          returnFilter = returnsFound.filter(ele => {
            const foundWH = dataSession.allWarehouse.find(elem => ele?.warehouse._id === elem._id);
            if (ele.status !== "INPROCESS" && foundWH) return ele;
          });
        }

        const array_sort = returnFilter.sort(function(a,b){
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        setReturns(array_sort);
        setReturnsUniv(array_sort);
      }
      setLoader(false);
    });
  };

  const handleCheck = async (returnElement) => {
    const newReturnValues = { ...returnElement, productInWarehouse: true };
    const response = await updateReturn(newReturnValues._id, newReturnValues);

    if (response.status === 200) {
      getData();
    } else {
      toast.info("There was an error updating the return status", alertOptions);
    }
  };

  useEffect(() => {
    getData();
  }, [dataSession]);

  useEffect(() => {
    let array_clients = [];
    getWholesalerUsers().then(resWU => {
      if (resWU.status === 200 && resWU.data.length > 0) {
        const clientsFound = resWU.data.filter(element => !element.deleted);
        array_clients = array_clients.concat(clientsFound);
      }
      getRetailUsers().then(resRU => {
        if (resRU.status === 200 && resRU.data.length > 0) {
          const clientsFoundAux = resRU.data.filter(element => !element.deleted);
          array_clients = array_clients.concat(clientsFoundAux);
        }

        const sortUser = array_clients.sort(function(a, b) {
          return a?.user?.identifier - b?.user?.identifier;
        });

        setClientOptions(sortUser);
      });
    });

    getWarehouses().then(res => {
      if (res.status === 200 && res.data.length > 0) {
        const warehousesCurrent = res.data.filter(element => !element.deleted);
        setWarehouseOptions(warehousesCurrent);
      }
    });
  }, []);

  return (
    <>
      <div className="space-y-3 px-3 py-5 md:px-8 md:py-8">
        <div className="grid grid-flow-col md:grid-cols-5 px-0">
          <div className="pr-6 hidden lg:block">
            <div className="flex justify-center items-center border border-gray-500 rounded-xl">
              <input
                className={`w-full flex justify-center items-center space-x-3 text-ag-secondary-letter text-xs lg:text-sm px-2 py-2 rounded-xl disabled:bg-zinc-200 ${ focusClass.search.status ? "text-left" : "text-center" }`}
                type="text"
                name="search"
                placeholder={focusClass.search.placeholder}
                disabled={!permits['ORDERS']['RETURNS_FILTERS']}
                onChange={(evt) => setSearchWord({ currentTarget: { name: evt.target.name, value: evt.target.value } })}
                onFocus={() => setFocusClass({...focusClass, search: { placeholder: "", status: true }})}
                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, search: { placeholder: "Search", status: false }}) : null}
                maxLength={60}
              />
              <div
                className="border-none p-[10px] rounded-md disabled:bg-zinc-200"
              >
                <span className="text-ag-secondary-letter text-xl"><BiSearch /></span>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center md:col-span-5 lg:grid-cols-4 text-center">
            <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary lg:mr-[25%]">
              Returns History
            </h1>
          </div>
        </div>
        <div className="">
          <Filters
            enableFilters={permits['ORDERS']['RETURNS_FILTERS']}
            typeView="secondary"
            warehouses={warehouseOptions}
            status={statusOptions}
            clients={clientOptions}
            wordFilter={searchWord}
            onFilters={handleFilters}
          />
        </div>
        <div className="pt-3">
          <TableApproved
            enableActions={permits['ORDERS']['ACTIONS_RETURNS']}
            returns={returns}
            onInventory={(rtrnEle) => setShowInventory({...showInventory, status: true, data: rtrnEle})}
            onDetail={(returnElement) => setShowDetail({...showDetail, status: true, data: returnElement})}
            onCheck={handleCheck}
          />
        </div>
      </div>

      { loader ? <Loader /> : null }

      {
        showInventory.status ?
          <InventoryReturns
            returnElement={showInventory.data}
            onSubmit={handleInventory}
            onClose={() => setShowInventory({...showInventory, status: false, data: null})}
          />
          :  null
      }

      {
        showDetail.status ?
          <DetailReturns
            returnElement={showDetail.data}
            onClose={() => setShowDetail({...showDetail, status: false, data: null})}
            onCancel={() => {}}
            onApproved={() => {}}
          />
          : null
      }
    </>
  );
};

export default Approved;