import React from "react";

const RearDoorLeft = ({ isSelected, isAvailable, onClick }) => {
  return (
    <svg className="cursor-pointer outline-none" onClick={onClick} data-tooltip-id="car" data-tooltip-content="Rear Door (left)">
      <path
        d="M87.26 322.88V294.28H54.46V329.88L87.26 322.88Z"
        fill={isSelected ? "#F41A28" :isAvailable ? "#1D8F43" : "#D3D3D4"}
      />
    </svg>
  );
};

export default RearDoorLeft;
