import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { alertOptions } from "../../../utils/Utilities";
import { toast } from "react-toastify";
import { changePassword } from "../../../services/Auth";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { validateConfirmPassword } from "../../../utils/Validators";
import { getWholesalerUser } from "../../../services/Wholesaler";
import LogoMAY from "../../../assets/images/logo_a.png";
import Loader from "../../../components/loader/Loader";

const ChangePassword = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const [typeUser, setTypeUser] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [user, setUser] = useState({
    password: "",
    confirmPassword: ""
  });

  const handleChange = (evt) => {
    setUser({...user, [evt.target.name]: evt.target.value});
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (user.password !== "" && user.confirmPassword !== "") {
      if (user.password === user.confirmPassword) {
        if (user.password.length >= 8) {
          const result = validateConfirmPassword(user);
          if (result.status) {
            setLoader(true);
            changePassword({ user: { _id: params.user }, password: user.password, code: params.code }).then(res => {
              if (res.status === 200) {
                toast.success("Password changed successfully", alertOptions);
                navigate(`/${typeUser}`);
              } else {
                toast.warning(res.response.data.message, alertOptions);
              }
              setLoader(false);
            });
          } else {
            toast.warning(result.msg, alertOptions);
          }
        } else {
          toast.info("Password must contain at least eight characters", alertOptions);
        }
      } else {
        toast.warning("The passwords entered do not match", alertOptions);
      }
    } else {
      toast.info("Please fill in all the fields of the form", alertOptions);
    }
  };

  useEffect(() => {
    if (params.user) {
      setLoader(true);
      getWholesalerUser(params.user).then(resWU => {
        if (resWU.status === 200) {
          setTypeUser("wholesaler");
          setLoader(false);
        } else {
          setTypeUser("");
          setLoader(false);
        }
      }).catch(() => {
        setTypeUser("");
        setLoader(false);
      });
    }
  }, []);

  return (
    <>
      { loader ? <Loader /> : null }

      <div className="min-h-screen bg-black flex justify-center items-center">
        <div className="lex flex-col md:grid md:gap-5 lg:gap-20 md:grid-cols-2 w-[80%] md:w-[800px] lg:w-[1100px] space-y-10 md:space-y-0">
          <div className="flex justify-center items-center w-full">
            <img className="h-auto w-auto" src={LogoMAY} />
          </div>
          <div className="lex justify-center p-0 md:p-7 lg:p-10">
            <div className="w-full bg-white rounded-xl p-5 w-full space-y-5">
              <div className="w-full text-center pb-5">
                <h1 className="text-2xl text-ag-secondary font-bold">Recover password</h1>
              </div>
              <div className="w-full">
                <form onSubmit={handleSubmit}>
                  <div className="space-y-2">
                    <div className="text-center">
                      <p className="text-xs text-ag-secondary-letter">Enter your new password</p>
                    </div>
                    <div className="flex justify-center items-center border border-gray-500 rounded-xl">
                      <input
                        title="Password must contain at least: One number, one special character, one uppercase letter, one lowercase letter, must not contain spaces, and must be at least 8 characters."
                        className="w-full text-center py-2 pl-7 rounded-xl"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        onChange={handleChange}
                        value={user.password}
                      />
                      { showPassword ?
                        <button
                          className="border-none p-2 rounded-xl disabled:bg-zinc-200"
                          type="button"
                          onClick={() => setShowPassword(false)}
                        >
                          <span className="text-ag-secondary-letter text-base lg:text-xl"><BsFillEyeSlashFill /></span>
                        </button>
                        :
                        <button
                          className="border-none p-2 rounded-xl disabled:bg-zinc-200"
                          type="button"
                          onClick={() => setShowPassword(true)}
                        >
                          <span className="text-ag-secondary-letter text-base lg:text-xl"><BsFillEyeFill /></span>
                        </button>
                      }
                    </div>
                    <div className="flex justify-center items-center border border-gray-500 rounded-xl">
                      <input
                        title="Password must contain at least: One number, one special character, one uppercase letter, one lowercase letter, must not contain spaces, and must be at least 8 characters."
                        className="w-full text-center py-2 pl-7 rounded-xl"
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        onChange={handleChange}
                        value={user.confirmPassword}
                      />
                      { showConfirmPassword ?
                        <button
                          className="border-none p-2 rounded-xl disabled:bg-zinc-200"
                          type="button"
                          onClick={() => setShowConfirmPassword(false)}
                        >
                          <span className="text-ag-secondary-letter text-base lg:text-xl"><BsFillEyeSlashFill /></span>
                        </button>
                        :
                        <button
                          className="border-none p-2 rounded-xl disabled:bg-zinc-200"
                          type="button"
                          onClick={() => setShowConfirmPassword(true)}
                        >
                          <span className="text-ag-secondary-letter text-base lg:text-xl"><BsFillEyeFill /></span>
                        </button>
                      }
                    </div>
                    <div className="py-2 text-center">
                      <p className="text-xs text-ag-secondary-letter">
                        <Link to="/">
                          <span>Do you already have an account?</span>
                        </Link>
                      </p>
                    </div>
                    <div className="pb-7">
                      <button
                        className="w-full bg-ag-primary-light text-white p-2 rounded-xl"
                        type="submit"
                      >
                                                Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;