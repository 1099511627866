import { useEffect, useState } from "react";
import { HiPlusCircle } from "react-icons/hi";
import { toast } from "react-toastify";
import { alertOptions, removeAccents } from "../../../../utils/Utilities";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import RowsNags from "./RowsNags";

const TableNags = ({ arrNags, productsOpts, onAdd, onEdit, onDelete, onCanEdit }) => {
    const [nagSelected, setNagSelected] = useState("");
    const [showOptions, setShowOptions] = useState(false);
    const [selOptions, setSelOptions] = useState([]);

    const handleChange = (evt) => {
        setNagSelected(evt.target.value);
        if (evt.target.value === "") {
            setSelOptions(productsOpts);
        } else {
            let palabra = new RegExp(`${removeAccents(evt.target.value)}.*`, "i");
            const newNagsOpts = productsOpts.filter(elem => palabra.test(elem?.nags));
            setSelOptions(newNagsOpts);
        }
    }

    const handleSelect = (ngOpt) => {
        setNagSelected(ngOpt);
        setShowOptions(false);
    }

    const handleAdd = () => {
        setShowOptions(false);
        if (nagSelected !== "") {
            const foundNag = productsOpts.filter(elem => elem.nags === String(nagSelected).toUpperCase());
            if (foundNag.length > 0) {
                onAdd(foundNag[0].nags);
                setNagSelected("");
                setSelOptions(productsOpts);
            } else {
                toast.info("The selected nag does not exist in the inventory", alertOptions);
            }
        } else {
            toast.info("Please select a nag to add", alertOptions);
        }
    }

    const handleEdit = (i, ngSel) => {
        setShowOptions(false);
        onEdit(i, ngSel);
    }

    const handleDelete = (ngSel) => {
        setShowOptions(false);
        onDelete(ngSel);
    }

    useEffect(() => {
        setSelOptions(productsOpts);
    }, [productsOpts]);

    return (
        <>
            <div className="w-full flex justify-center items-center space-x-3 md:space-x-10">
                <div className="w-[80%] md:w-[50%] xl:w-[40%] block">
                    <div className="w-full text-center pb-1">
                        <span className="text-xs lg:text-sm text-ag-secondary">Nag</span>
                    </div>
                    <div className="w-full relative">
                        <div className="flex justify-center items-center border border-gray-500 rounded-xl">
                            <input
                                className="w-full text-center text-ag-secondary-letter text-xs p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                name="nags"
                                autoComplete="off"
                                onChange={handleChange}
                                value={nagSelected}
                                onFocus={() => setShowOptions(true)}
                            />
                            <span className="text-ag-secondary-letter cursor-pointer px-2" onClick={() => setShowOptions(!showOptions)}>
                                { showOptions ? <BsCaretUpFill /> : <BsCaretDownFill /> }
                            </span>
                        </div>
                        {
                            showOptions ?
                                <div className="w-full bg-white absolute text-center border-x border-b border-zinc-500 rounded-b-xl z-50">
                                    <ul>
                                        {
                                            selOptions.map((elemProd, i) => (
                                                <li
                                                    key={i}
                                                    className="relative px-3 py-1 text-ag-secondary-letter text-xs cursor-pointer hover:bg-zinc-600 hover:text-white hover:rounded-xl z-50"
                                                    onClick={() => handleSelect(elemProd.nags)}
                                                >
                                                    {elemProd.nags}
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            : null
                        }
                    </div>
                </div>
                <div className="w-[10%]">
                    <div className="w-full text-center pb-1">
                        <span className="text-xs lg:text-sm text-transparent">.</span>
                    </div>
                    <div className="pt-0 m-auto">
                        <button type="button" onClick={() => handleAdd()}>
                            <span className="text-green-600 text-[40px]"><HiPlusCircle /></span>
                        </button>
                    </div>
                </div>
            </div>
            {
                arrNags.length > 0 ?
                    <div className="pt-10 overflow-x-auto">
                        <table className="w-full md:w-[90%] lg:w-[70%] text-center text-ag-secondary-letter m-auto">
                            <thead>
                                <tr className="bg-ag-secondary-table">
                                    <th className="w-[50%] border-[0.5px] border-ag-secondary-light text-sm font-normal px-2 py-3">Nags</th>
                                    <th className="w-[50%] border-[0.5px] border-ag-secondary-light text-sm font-normal px-2 py-3">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    arrNags.map((element, i) => {
                                        return (
                                            <RowsNags
                                                key={i}
                                                nag={element}
                                                productsOpts={productsOpts}
                                                onEdit={(ngSel) => handleEdit(i, ngSel)}
                                                onDelete={(ngSel) => handleDelete(ngSel)}
                                                onCanEdit={onCanEdit}
                                            />
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                : null
            }
        </>
    );
}

export default TableNags;