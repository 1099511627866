import React from "react";

const FrontDoorLeft = ({ isSelected, isAvailable, onClick }) => {
  return (
    <svg className="cursor-pointer outline-none" onClick={onClick} data-tooltip-id="car" data-tooltip-content="Front Door (left)">
      <path
        d="M87.26 207.39V232.88H54.46V194.79L87.26 207.39Z"
        fill={isSelected ? "#F41A28" :isAvailable ? "#1D8F43" : "#D3D3D4"}
      />
    </svg>
  );
};

export default FrontDoorLeft;
