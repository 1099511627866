import { useContext, useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getProducts } from "../../../../services/Product";
import { getWarehouses } from "../../../../services/Warehouse";
import { getInventoryAddId } from "../../../../services/Inventory";
import { saveInventoryAdd, updateInventoryAddId } from "../../../../services/Inventory";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import { PermitsContext } from "../../../../services/Permits";
import FormInventory from "./FormInventory";
import Loader from "../../../../components/loader/Loader";
import ModalCompatible from "../../products/ModalCompatible";

const SaveInventory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { dataSession } = useContext(PermitsContext);
  const [loader, setLoader] = useState(false);
  const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
  const [inventoryData, setInventoryData] = useState(null);
  const [productsOptions, setProductsOptions] = useState([]);
  const [warehouseOptions, setWarehouseOptions] = useState([]);

  const handleSave = async (inventoryModel) => {
    try {
      setLoader(true);
      const res = await saveInventoryAdd(inventoryModel);
      setLoader(false);
      if (res.status === 200) {
        toast.success("Product added successfully", alertOptions);
        navigate("/inventory");
      } else {
        toast.warning(res.response.data.message, alertOptions);
      }
    } catch (error) {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    }
  };

  const handleEdit = async (inventoryModel) => {
    try {
      setLoader(true);
      const res = await updateInventoryAddId(inventoryModel._id, inventoryModel);
      setLoader(false);
      if (res.status === 200) {
        toast.success("Product updated successfully", alertOptions);
        navigate("/inventory");
      } else {
        toast.warning(res.response.data.message, alertOptions);
      }
    } catch (error) {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    }
  };

  const fetchInventoryData = async () => {
    if (id) {
      setLoader(true);
      const res = await getInventoryAddId(id);
      if (res.status === 200) {
        setInventoryData(res.data);
      }
      setLoader(false);
    }
  };

  const fetchWarehouses = async () => {
    try {
      if (!dataSession?.userType || !Array.isArray(dataSession.allWarehouse)) {
        setWarehouseOptions([]);
        return;
      }
      const res = await getWarehouses();
      if (res.status === 200) {
        const warehouses = res.data.filter((element) =>
          !element.deleted &&
          (dataSession.userType === "ADMIN" || dataSession.allWarehouse.some((elem) => element._id === elem._id))
        );
        setWarehouseOptions(warehouses);
      } else {
        setWarehouseOptions([]);
      }
    } catch (error) {
      console.error("Failed to fetch warehouses:", error);
      setWarehouseOptions([]);
    }
  };

  const fetchProducts = async () => {
    const res = await getProducts();
    setProductsOptions(res.status === 200 && res.data.length > 0 ? res.data : []);
  };

  useEffect(() => {
    fetchWarehouses();
  }, [dataSession]);

  useEffect(() => {
    fetchInventoryData();
    fetchWarehouses();
    fetchProducts();
  }, []);

  return (
    <>
      {loader && <Loader />}

      <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
        <div className="grid grid-cols-12 px-0 md:px-5">
          <div>
            <Link to="/inventory">
              <button className="p-1 rounded-md" type="button">
                <span className="text-ag-secondary-dark text-2xl md:text-3xl"><FiChevronLeft /></span>
              </button>
            </Link>
          </div>
          <div className="flex justify-center items-center col-span-11 text-center">
            <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
              Add/Edit Product
            </h1>
          </div>
        </div>
        <div className="py-3">
          <FormInventory
            inventoryData={inventoryData}
            warehouses={warehouseOptions}
            products={productsOptions}
            onEdit={handleEdit}
            onSave={handleSave}
            onCompatible={(prdSel) => setShowCompatible({ ...showCompatible, status: true, element: prdSel })}
            onLoader={setLoader}
            userData={dataSession}
          />
        </div>
      </div>

      {showCompatible.status && (
        <ModalCompatible
          products={showCompatible.element}
          onClose={() => setShowCompatible({ ...showCompatible, status: false, element: null })}
        />
      )}
    </>
  );
};

export default SaveInventory;
