import { IoMdLock, IoMdUnlock, IoMdImage } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { formatCommaSeparate, formatCurrency, parseTextTypeGlass } from "../../../utils/Utilities";
import { useEffect, useState } from "react";

const RowsInventoryAccessory = ({ enableEdit, enableRecord, enableOrderQuery, inventory, onHistory, onStatus, onImage, onPrice, onOrder, onCompatible, warehousesAccess, onMovementHistory }) => {
    const navigate = useNavigate();
    const [images, setImages] = useState([])
    const changeStatus = (stsProduct) => {
        const newModel = {
            warehouse: { _id: inventory.warehouse._id },
            accessoryInventory: {
                _id: inventory.accessoryInventory._id,
                status: stsProduct
            }
        };
        onStatus(newModel);
    };

    useEffect(() => {
        let images = [];
        for (let x = 0; x < inventory.accessoryInventory.accessory.images.length; x++) {
            const image = inventory.accessoryInventory.accessory.images[x];
            if (image.length > 0) {
                images.push(image)
            }
        }
        setImages(images)
    }, [inventory])

    return (
        <tr>
            <td className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs p-2">
                <div className="flex space-x-3">
                    <div className="flex justify-center items-center">
                        {inventory.accessoryInventory.status === "active" ?
                            <button type="button" onClick={() => changeStatus("dead")}>
                                <span className="text-green-600 text-3xl"><IoMdLock /></span>
                            </button>
                            :
                            <button type="button" onClick={() => changeStatus("active")}>
                                <span className="text-red-600 text-3xl"><IoMdUnlock /></span>
                            </button>
                        }
                        <button type="button" onClick={() => onImage(images)}>
                            <span className="text-3xl">
                                {
                                    images.length > 0?
                                        <div className="w-[35px] h-[35px] flex justify-center items-center border border-ag-secondary-light rounded-lg p-1">
                                            <img className="w-full h-full object-contain" src={images[0]} />
                                        </div>
                                        :
                                        <IoMdImage />
                                }
                            </span>
                        </button>
                    </div>
                    <div className="space-y-2">
                        <div className="text-left">
                            <div>
                                <p className="text-ag-secondary-letter text-xs w-56 mb-3">{inventory.accessoryInventory.accessory.nags} {inventory.accessoryInventory.accessory.name}</p>
                            </div>
                        </div>
                        <div className="text-left">
                            <span className="text-ag-secondary-letter text-[10px]">
                                <b>Vendors:</b>{" "}
                                {
                                    inventory.accessoryInventory.accessory.vendors.length === 0 ?
                                        <p>---</p>
                                    :
                                    inventory.accessoryInventory.accessory.vendors.map((item, index) => (
                                        <span key={index}>{item.name}{index < inventory.accessoryInventory.accessory.vendors.length - 1 && ', '}</span>
                                    ))
                                }
                            </span>
                        </div>
                        <div className="text-left">
                            <span className="text-ag-secondary-letter text-[10px]">
                                <b>Section:</b>{" "}
                                {
                                    inventory.racks.map((rack, i) => {
                                        if ((i + 1) === inventory.racks.length) {
                                            return `${rack.rack.name}`;
                                        }

                                        return `${rack.rack.name}, `;
                                    })
                                }
                            </span>
                        </div>
                    </div>
                </div>
            </td>
            <td className="w-[30%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs p-2">
                <p className="w-[100%] m-auto" title={inventory.accessoryInventory.accessory.description}>
                    {inventory.accessoryInventory.accessory.description}
                </p>
            </td>
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs p-2">
                <p className="w-20 truncate m-auto" title={inventory.warehouse.name}>
                    {inventory.warehouse.name}
                </p>
            </td>
            <td className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs p-2">{formatCommaSeparate(inventory.totalQuantity)}</td>
            <td className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs p-2">{formatCurrency(inventory.accessoryInventory.cost)}</td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs p-2">
                <div className="space-y-1">
                    <button
                        className="w-full bg-ag-secondary text-white text-xs rounded-full py-[6px] disabled:bg-zinc-200"
                        type="button"
                        disabled={!enableEdit}
                        // onClick={() => navigate(`/inventory-accessory/element/detail/${inventory.accessoryInventory.accessory._id}`)}
                        onClick={() => navigate(`/inventory-accessory/element/detail/${inventory.accessoryInventory._id}`)}
                    >
                        Details
                    </button>
                    <button
                        className="w-full bg-ag-secondary text-white text-xs rounded-full py-[6px] disabled:bg-zinc-200"
                        type="button"
                        //disabled={isButtonEnabled()}
                        onClick={() => navigate(`/inventory-accessory/element/${inventory.accessoryInventory._id}`)}
                    >
                        Edit
                    </button>
                    <button
                        className="w-full bg-ag-secondary text-white text-xs rounded-full py-[6px] disabled:bg-zinc-200"
                        type="button"
                        disabled={!enableRecord}
                        //onClick={() => onHistory(inventory.productInventory._id)}
                    >
                        Record
                    </button>
                    <button
                        className="w-full bg-ag-secondary text-white text-xs rounded-full py-[6px] disabled:bg-zinc-200"
                        type="button"
                        disabled={!enableOrderQuery}
                        onClick={() => onMovementHistory(inventory.accessoryInventory._id)}
                    >
                        History
                    </button>
                </div>
            </td>
        </tr>
    )
}

export default RowsInventoryAccessory