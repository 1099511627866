import React from "react";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "../../../../components/loader/Loader";
import { getInventoryAccessoryById } from "../../../../services/InventoryAccessory";

const DetailInventoryAccessory = () => {
  let { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [vendors, setVendors] = useState([])
  const [sections, setSections] = useState([])
  const [accessory, setAccessory] = useState({
    id: "",
    status: true,
    name: "",
    price: "",
    salesPrice: "",
    barcode1: "",
    barcode2: "",
    description: "",
    notes: "",
    productImages: [],
    warehouse: "",
  });

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    setLoader(true);
    const res = await getInventoryAccessoryById(id);
    if (res.status === 200) {
      setAccessory({
        name: res.data.accessoryInventory.accessory.name,
        price: res.data.accessoryInventory.accessory.price,
        salesPrice: res.data.accessoryInventory.cost,
        barcode1: res.data.accessoryInventory.accessory.barcode1,
        barcode2: res.data.accessoryInventory.accessory.barcode2,
        description: res.data.accessoryInventory.accessory.description,
        notes: res.data.accessoryInventory.accessory.notes,
        productImages: res.data.images,
        warehouse: res.data.warehouse.name
      });

      setVendors(res.data.accessoryInventory.accessory.vendors)
      setSections(res.data.racks)
      setLoader(false);
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div className="overflow-auto w-full flex-grow product-inventory">
        <div className="space-y-5 py-8 px-5 md:px-10 lg:px-16">
          <div className="col-span-4 text-center">
            <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">
              Details
            </h1>
          </div>

          <div className="pt-3 pb-10 space-y-5">
            <div className="grid gap-3 md:grid-cols-5">
              <div className="space-y-[14px]">

                <div>
                  <Link to="/inventory/tableInventoryAccessories">
                    <button
                      className="w-full bg-ag-secondary text-white text-xs lg:text-base p-3 rounded-xl"
                      type="button"
                    >
                      Back
                    </button>
                  </Link>
                </div>
              </div>

              <div className="mt-2 md:mt-0 md:order-first md:col-span-4">
                <form>
                  <div className="space-y-3">
                    <div>
                      <div className="w-full flex justify-center items-center">
                        <span className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl">
                          Accessory name
                        </span>
                        <input
                          className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                          type="text"
                          name="name"
                          value={accessory?.name}
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div>
                      <div className="w-full flex justify-center items-center">
                        <span className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl">
                          Accessory price
                        </span>
                        <input
                          className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                          type="text"
                          name="price"
                          value={accessory?.price}
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div>
                      <div className="w-full flex justify-center items-center">
                        <span className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl">
                          Sales price
                        </span>
                        <input
                          className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                          type="text"
                          name="price"
                          value={accessory?.salesPrice}
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div className="grid gap-3 lg:grid-cols-2">
                      <div>
                        <div className="w-full flex justify-center items-center">
                          <span className="w-[30%] lg:w-[45%] xl:w-[37%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl">
                            Barcode 1
                          </span>
                          <input
                            className="w-[70%] lg:w-[55%] xl:w-[63%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate disabled:bg-[#d9d9d9] "
                            type="text"
                            name="barcode1"
                            value={accessory?.barcode1}
                            disabled={true}
                          />
                        </div>
                      </div>

                      <div>
                        <div className="w-full flex justify-center items-center">
                          <span className="w-[30%] lg:w-[45%] xl:w-[37%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl">
                            Barcode 2
                          </span>
                          <input
                            className="w-[70%] lg:w-[55%] xl:w-[63%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                            type="text"
                            name="barcode2"
                            value={accessory?.barcode2}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex justify-center items-center">
                      <span className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs lg:text-base text-white text-center border border-gray-500 py-[36px] rounded-l-xl">
                        Description
                      </span>
                      <textarea
                        className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl"
                        name="description"
                        rows={3}
                        value={accessory?.description}
                        disabled={true}
                      />
                    </div>

                    <div className="w-full flex justify-center items-center">
                      <span className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs lg:text-base text-white text-center border border-gray-500 py-[36px] rounded-l-xl">
                        Notes
                      </span>
                      <textarea
                        className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl"
                        name="note"
                        rows={3}
                        value={accessory?.notes}
                        disabled={true}
                      />
                    </div>

                    <div>
                      <div className="w-full flex justify-center items-center">
                        <span className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl">
                          Vendors
                        </span>
                        <p className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate bg-[#D9D9D9] h-[50px]">
                          {
                            vendors.length === 0 ?
                              <></>
                              :
                              vendors.map((item, index) => (
                                <> {item.name}{index < vendors.length - 1 && ', '}</>
                              ))
                          }
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="w-full flex justify-center items-center">
                        <span className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl">
                          Sections
                        </span>
                        <p className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate bg-[#D9D9D9] h-[50px]">
                          {
                            sections.length === 0 ?
                              <></>
                              :
                              sections.map((item, index) => (
                                <> {item.rack.name}{index < sections.length - 1 && ', '}</>
                              ))
                          }
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="w-full flex justify-center items-center">
                        <span className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl">
                          Warehouse
                        </span>
                        <p className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate bg-[#D9D9D9] h-[50px]">
                          {
                            accessory?.warehouse
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailInventoryAccessory;
