import { formatDateWordEng } from "../../../utils/Utilities";
// import { ReactComponent as IconDocument } from "../../../assets/icons/document-icon.svg";

const RowsHistory = ({ nag, movement }) => {
    return (
        <tr>
            <td className="w-[11%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">{ nag }</td>
            <td className="w-[17%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">{ movement.rack }</td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">{ movement.type }</td>
            <td className="w-[16%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">{ movement.warehouse }</td>
            <td className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">{ movement.qtyChanged }</td>
            <td className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">{ movement.qtyInStock }</td>
            <td className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">{ formatDateWordEng(movement.createdAt) }</td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                {'-'}
                {/* <button type="button">
                    <IconDocument className="w-[25px]" />
                </button> */}
            </td>
        </tr>
    );
}

export default RowsHistory;