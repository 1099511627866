import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { getWarehouse } from "../../../../services/Warehouse";
import { alertOptions, removeAccents } from "../../../../utils/Utilities";
import { validateInventoryAdd } from "../../../../utils/Validators";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import TableInventoryAccessory from "./TableInventoryAccessory";

const FormInventoryAccessory = ({ inventoryData, warehouses, accessories, onSave, onEdit, onLoader }) => {
    const nagsRef = useRef(null);
    const namesRef = useRef(null);
    const [accessorySelected, setAccessorySelected] = useState("");
    const [showNgOptions, setShowNgOptions] = useState(false);
    const [showNameOptions, setShowNameOptions] = useState(false);
    const [selOptions, setSelOptions] = useState([]);
    const [sumStock, setSumStock] = useState(0);
    const [racksOptions, setRacksOptions] = useState([]);
    const [racksToEdit, setRacksToEdit] = useState([]);
    const [inventory, setInventory] = useState({
      status: "active",
      warehouse: "",
      product: "",
      nags: "",
      nagPrice: "",
      cost: "",
      maxStock: "",
      minStock: "",
      stock: "",
      racks: []
    });
    const [showPopup, setShowPopup] = useState(false);
  
    const handleMouseEnter = () => {
      if (inventory.racks.length) {
        setShowPopup(true);
      }
    };
  
    const handleMouseLeave = () => {
      setShowPopup(false);
    };
  
    const addRack = (rackElement) => {
      handleCloseSelect();
      if (rackElement._id === "" || (rackElement.quantity === 0 || rackElement.quantity === "")) {
        toast.warning("Please select a section and quantity to add", alertOptions);
      } else {
        let racksAux = inventory.racks;
        const foundRack = racksAux.find(rckElmnt => rckElmnt._id === rackElement._id);
        if (!foundRack) {
          racksAux.push(rackElement);
          setInventory({...inventory, racks: racksAux});
          setSumStock(Number(sumStock) + Number(rackElement.quantity));
        } else {
          toast.warning("The selected rack is already added", alertOptions);
        }
      }
    };
  
    const editRack = (rackIndex, rackElement) => {
      handleCloseSelect();
      let sumStockAux = 0;
      const racksAux = inventory.racks.map((rckElmnt, i) => {
        if (i === rackIndex) {
          sumStockAux += Number(rackElement.quantity);
          if (rckElmnt._id !== rackElement._id) {
            let updateRacksToEdit = [...racksToEdit]
            let findIndex = updateRacksToEdit.findIndex(x => x === rckElmnt._id);
            updateRacksToEdit[findIndex] = rackElement._id
            setRacksToEdit(updateRacksToEdit);
          }
          return { _id: rackElement._id, quantity: rackElement.quantity};
        }
        sumStockAux += Number(rckElmnt.quantity);
        return rckElmnt;
      });
      setSumStock(sumStockAux);
      setInventory({...inventory, racks: racksAux});
    };
  
    const deleteRack = (idRack) => {
      handleCloseSelect();
      let sumStockAux = 0;
      const racksAux = inventory.racks.filter(rackElement => {
        if (rackElement._id !== idRack) {
          sumStockAux += Number(rackElement.quantity);
          return rackElement;
        }
      });
      setSumStock(sumStockAux);
      setInventory({...inventory, racks: racksAux});
    };
  
    const handleCanEdit = (rckElem, sts) => {
      if (sts) {
        setRacksToEdit([...racksToEdit, rckElem._id]);
      } else {
        const newRacks = racksToEdit.filter(elem => elem !== rckElem._id);
        setRacksToEdit(newRacks);
      }
    };
    
    const handleSelect = (ngOpt, type) => {
      if (type === "accessory") {
        setShowNgOptions(false);
      } else {
        setShowNameOptions(false);
      }
  
      const productFound = accessories.find(productElement => productElement._id === ngOpt);
      if (productFound) {
        setAccessorySelected(productFound.name);
        setInventory({...inventory,
          accessory: type === "accessory" ? ngOpt : productFound._id,
          name: type === "accessory" ? productFound.nags : ngOpt,
          accessoryPrice: productFound.price
        });
      }
    };
  
    const handleCloseSelect = () => {
      setShowNgOptions(false);
      setShowNameOptions(false);
    };
  
    const handleDownSelect = (type) => {
      if (type === "product") {
        setShowNameOptions(!showNameOptions);
        setShowNgOptions(false);
      } else {
        setShowNgOptions(!showNgOptions);
        setShowNameOptions(false);
      }
    };
  
    const handleChangeSel = (evt, type) => {
      if (type === "accessory") {
        setAccessorySelected(evt.target.value);
      }
  
      if (evt.target.value === "") {
        const filterbyStatus = accessories.filter((item) => item.status === true).sort((a, b) => (new Date(b.updatedAt) - new Date(a.updatedAt)));
        setSelOptions(filterbyStatus);
        setInventory({...inventory, accessory: "", name: "", accessoryPrice: "" });
      } else {
        let palabra = new RegExp(`${removeAccents(evt.target.value)}.*`, "i");
        const newAccessoryOpts = accessories.filter(elem => elem.status === true && (palabra.test(elem?.name)));
        setSelOptions(newAccessoryOpts);
      }
    };
  
    const handleChange = (evt) => {
      if (evt.currentTarget.name === "warehouse") {
        setInventory({...inventory, warehouse: evt.currentTarget.value});
        getWarehouse(evt.currentTarget.value).then(res => {
          if (res.status === 200) {
            setRacksOptions(res.data.racks);
          }
        });
      } else {
        if (evt.currentTarget.name === "cost" /*|| evt.currentTarget.name === "sellerPrice" || evt.currentTarget.name === "resellerPrice"*/) {
          const pattern = new RegExp(/^\d*\.?\d*$/);
          if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
            setInventory({...inventory, [evt.currentTarget.name]: evt.currentTarget.value});
          }
        } else if (evt.currentTarget.name === "maxStock" || evt.currentTarget.name === "minStock") {
          const pattern = new RegExp(/^[0-9\s]+$/g);
          if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
            setInventory({...inventory, [evt.currentTarget.name]: evt.currentTarget.value});
          }
        } else {
          setInventory({...inventory, [evt.currentTarget.name]: evt.currentTarget.value});
        }
      }
    };
  
    const handleSubmit = (evt) => {
      evt.preventDefault();
      if (
        inventory.warehouse !== "" && inventory.accessory !== "" && inventory.cost !== "" && inventory.maxStock !== "" &&
              inventory.minStock !== ""
      ) {
        const productFound = accessories.find(prodSel => (prodSel.name === accessorySelected));
        if (!productFound) {
          toast.info("Please choose a accessory from the selector", alertOptions);
        } else {
          let rackRepeat = false;
          let rack_array = [];
          for (let i = 0; i < inventory?.racks.length; i++) {
            const foundRack = rack_array.find(element => element._id === inventory.racks[i]._id);
            if (foundRack) {
              rackRepeat = true;
              break;
            } else {
              rack_array.push(inventory.racks[i]);
            }
          }
  
          if (!rackRepeat) {
            //if (sumStock <= inventory.maxStock && sumStock >= inventory.minStock) {
              if (racksToEdit.length === 0) {
                let arrRackFormat = [];
                for (let i = 0; i < inventory.racks.length; i++) {
                  arrRackFormat.push({ _id: inventory.racks[i]._id, quantity: Number(inventory.racks[i].quantity) });
                }
                const inventoryModel = {
                  warehouse: { _id: inventory.warehouse },
                  accessory: { _id: inventory.accessory },
                  status: inventory.status,
                  maxStock: inventory.maxStock,
                  minStock: inventory.minStock,
                  cost: inventory.cost,
                  racks: arrRackFormat,
                  validateMaxMin: false
                };
                const result = validateInventoryAdd(inventoryModel);
                if (result.status) {
                  if (inventoryData && inventoryData !== null) {
                    inventoryModel._id = inventoryData.accessoryInventory._id;
                    onEdit(inventoryModel);
                  } else {
                    onSave(inventoryModel);
                  }
                } else {
                  toast.warning(result.msg, alertOptions);
                }
              } else {
                toast.info("Please finish editing the table sections", alertOptions);
              }
            //} 
            // else {
            //   toast.warning("It is not possible to add more accessories than the maximum established in stock or less accessories than the minimum in stock", alertOptions);
            // }
          } else {
            toast.warning("Please, do not duplicate the sections of the table", alertOptions);
          }
        }
      } else {
        toast.info("Please fill in all the fields of the form", alertOptions);
      }
    };
  
    const handleClickOutside = (event) => {
      if (nagsRef.current && !nagsRef.current.contains(event.target)) {
        setShowNgOptions(false);
      }
  
      if (namesRef.current && !namesRef.current.contains(event.target)) {
        setShowNameOptions(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
  
      if (inventoryData) {
        onLoader(true);
        getWarehouse(inventoryData.warehouse._id).then(resWare => {
          if (resWare.status === 200) {
            setRacksOptions(resWare.data.racks);
          }
  
          let racksAux = [];
          let stockAcum = 0;
          if (inventoryData.racks.length > 0) {
            racksAux = inventoryData.racks.map((element) => {
              stockAcum += Number(element.quantity);
              return { _id: element.rack._id, quantity: element.quantity };
            });
          }
  
          setSumStock(stockAcum);
          setAccessorySelected(inventoryData.accessoryInventory.accessory.name);
          setInventory({...inventory,
            status: inventoryData.accessoryInventory.status,
            warehouse: inventoryData.warehouse._id,
            accessory: inventoryData.accessoryInventory.accessory._id,
            name: inventoryData.accessoryInventory.accessory.name,
            cost: inventoryData.accessoryInventory.cost,
            accessoryPrice: inventoryData?.accessoryInventory?.accessory?.price,
            maxStock: inventoryData.accessoryInventory.maxStock,
            minStock: inventoryData.accessoryInventory.minStock,
            stock: stockAcum,
            racks: racksAux
          });
          onLoader(false);
        }).catch(() => onLoader(false));
      } else {
        const filterbyStatus = accessories.filter((item) => item.status === true).sort((a, b) => (new Date(b.updatedAt) - new Date(a.updatedAt)));
        setSelOptions(filterbyStatus);
      }
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [inventoryData, accessories]);
    return (
        <div>
        <form className="space-y-10" onSubmit={handleSubmit}>
          <div className="grid gap-3 md:grid-cols-2 lg:grid-cols-3">
            <div className="w-full flex justify-center items-center">
              <span
                className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
              >
                Accessory Name
              </span>
              <div className="w-[60%] relative" ref={nagsRef}>
                <div className={`w-full flex justify-center items-center ${ inventoryData !== null ? "bg-zinc-200" : "bg-white" } text-ag-secondary-letter border border-gray-500 rounded-r-xl truncate`}>
                  <input
                    className="w-full p-2 xl:p-3 text-left text-ag-secondary-letter disabled:bg-zinc-200 truncate over"
                    name="accessory"
                    autoComplete="off"
                    onChange={(evt) => handleChangeSel(evt, "accessory")}
                    value={accessorySelected}
                    onFocus={() => setShowNgOptions(true)}
                    disabled={inventoryData !== null ? true : false}
                  />
                  <span
                    className={`text-ag-secondary-letter ${ inventoryData !== null ? "" : "cursor-pointer" } px-2`}
                    onClick={() => inventoryData !== null ? null : handleDownSelect("accessory")}
                  >
                    { showNgOptions ? <BsCaretUpFill /> : <BsCaretDownFill /> }
                  </span>
                </div>
                {
                  showNgOptions ?
                    <div className="w-full bg-white absolute text-center border-x border-b border-zinc-500 rounded-b-xl">
                      <ul className="h-[200px] overflow-auto">
                        {
                          selOptions
                            .filter((elemProd) => elemProd.name.toLowerCase().includes(accessorySelected.toLowerCase()))
                            .map((elemProd, i) => (
                              <li
                                key={i}
                                className="relative px-3 py-1 text-ag-secondary-letter text-[14px] cursor-pointer hover:bg-zinc-600 hover:text-white hover:rounded-xl z-50"
                                onClick={() => handleSelect(elemProd._id, "accessory")}
                              >
                                {elemProd.name}
                              </li>
                            ))
                        }
                      </ul>
                    </div>
                    : null
                }
              </div>
            </div>
  
            <div className="w-full flex justify-center items-center">
              <span
                className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
              >
                Warehouse
              </span>
              <div
                className="relative w-[60%]"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <select
                  className="text-ag-secondary-letter border border-gray-500 px-2 xl:px-3 py-2 xl:py-[13px] rounded-r-xl disabled:bg-zinc-200 disabled:opacity-100 truncate w-full"
                  id="select-inventoryAdd-warehouse"
                  name="warehouse"
                  onChange={handleChange}
                  value={inventory.warehouse}
                  disabled={inventoryData !== null ? true : false || inventory.racks.length}
                >
                  <option value="default"></option>
                  {warehouses.map((element, index) => (
                    <option key={index} value={element._id}>{element.name}</option>
                  ))}
                </select>
                {showPopup && !inventoryData && (
                  <div className="text-ag-secondary-letter absolute left-0 top-[-60px] bg-white border border-gray-500 px-3 py-2 rounded-lg shadow-xl z-10">
                    You have to delete all the sections to change warehouse
                  </div>
                )}
              </div>
            </div>
            <div className="w-full flex justify-center items-center">
              <span
                className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
              >
                Accesory Price
              </span>
              <input
                className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl disabled:bg-zinc-200 truncate"
                type="text"
                name="nagPrice"
                value={inventory.accessoryPrice}
                disabled={true}
              />
            </div>
            <div className="w-full flex justify-center items-center">
              <span
                className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
              >
                Sales Price
              </span>
              <input
                className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                type="text"
                name="cost"
                value={inventory.cost}
                onChange={handleChange}
                maxLength={12}
              />
            </div>
            <div className="w-full flex justify-center items-center">
              <span
                className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
              >
                Maximum Stock
              </span>
              <input
                className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                type="text"
                name="maxStock"
                value={inventory.maxStock}
                onChange={handleChange}
                maxLength={12}
              />
            </div>
            <div className="w-full flex justify-center items-center">
              <span
                className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
              >
                Minimum Stock
              </span>
              <input
                className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                type="text"
                name="minStock"
                value={inventory.minStock}
                onChange={handleChange}
                maxLength={12}
              />
            </div>
            <div className="w-full flex justify-center items-center">
              <span
                className="w-[40%] bg-ag-secondary-dark text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
              >
                Stock
              </span>
              <input
                className="w-[60%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl disabled:bg-zinc-200 truncate"
                type="text"
                name="stock"
                value={inventory.stock}
                disabled={true}
                maxLength={12}
              />
            </div>
           
          </div>
          <div>
            <div className="md:flex md:justify-center md:items-center border-t border-ag-secondary py-6 space-y-3 md:space-y-0">
              <div className="w-full md:w-[66%] text-center md:text-right px-0 md:px-[10%] pb-5 md:pb-0">
                <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Organize by sections</h1>
              </div>
              <div className="w-full md:w-[34%]">
                <button
                  className="w-full bg-ag-primary text-white text-xs lg:text-base p-2 rounded-xl disabled:bg-zinc-200"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </div>
            <div className="px-5 md:px-20 py-5 md:py-10 border border-ag-secondary-light rounded-lg">
              <TableInventoryAccessory
                type="transfer"
                maxStock={inventory.maxStock}
                sumStock={sumStock}
                racksCurrent={inventory.racks}
                racks={racksOptions}
                onSave={addRack}
                onEdit={editRack}
                onDelete={deleteRack}
                onCloseSelect={handleCloseSelect}
                onCanEdit={handleCanEdit}
              />
            </div>
          </div>
        </form>
      </div>
    )
}

export default FormInventoryAccessory