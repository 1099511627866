export const TRANSFER_OPTIONS = [
  { label: "All Transfer", value: "all" },
  { label: "Canceled", value: "canceled" },
  { label: "Completed", value: "completed" },
  { label: "Draft", value: "draft" },
  { label: "In Process", value: "inprocess" }
];

export const ROLES = [
  {
    role: "ADMINISTRATORS",
    permissions: [
      { value: 'FILTER', label: 'Admin Filters' },
      { value: 'ADD', label: 'New record' },
      { value: 'ROLE', label: 'Role' },
      { value: 'EDIT', label: 'Edit' },
      { value: 'DELETE', label: 'Delete'}
    ]
  },
  {
    role: "USERS",
    permissions: [
      { value: 'FILTER', label: 'User Filters' },
      { value: 'ADD_WOLESALER', label: 'Add Wholesaler' },
      { value: 'ADD_RETAILER', label: 'Add Retailer' },
      { value: 'EDIT', label: 'Edit' },
      { value: 'DELETE', label: 'Delete' },
      { value: 'APPROVE_DISPPROVE', label: 'Approve / Disapprove' },
    ]
  },
  {
    role: "SUPPLIERS",
    permissions: [
      { value: 'FILTER', label: 'Supplier Filters' },
      { value: 'ADD', label: 'New supplier' },
      { value: 'DELETE', label: 'Delete supplier' },
      { value: 'EDIT', label: 'Edit supplier' }
    ]
  },
  {
    role: "PRODUCTS",
    permissions: [
      { value: 'FILTER', label: 'Products Filters' },
      { value: 'ADD', label: 'New product' },
      { value: 'HISTORY', label: 'Price history' },
      { value: 'EDIT', label: 'Edit' },
      { value: 'DEACTIVATE', label: 'Deactivate' }
    ]
  },
  {
    role: "INVENTORY",
    permissions: [
      { value: 'FILTER', label: 'Inventory Filters' },
      { value: 'ACTIONS', label: 'Actions'},
      { value: 'EDIT', label: 'Edit'},
      { value: 'RECORD', label: 'Record'},
      { value: 'ORDER_QUERY', label: 'History'}
    ]
  },
  {
    role: "WAREHOUSE",
    permissions: [
      { value: 'FILTER', label: 'Warehouse Filters' },
      { value: 'ADD', label: 'Create Warehouse' },
      { value: 'EDIT', label: 'Edit Warehouse' },
      { value: 'REMOVE', label: 'Delete Warehouse' },
      { value: 'CREATE_RACK', label: 'Create Rack' },
      { value: 'EDIT_RACK', label: 'Edit Rack' },
      { value: 'DELETE_RACK', label: 'Delete Rack' }
    ]
  },
  {
    role: "ORDERS",
    permissions: [
      { value: 'ORDERS_FILTERS', label: 'Orders Filters' },
      { value: 'ACTIONS_ORDERS', label: 'Actions Orders' },
      { value: 'RETURNS_FILTERS', label: 'Returns Filters' },
      { value: 'ACTIONS_RETURNS', label: 'Actions returns' },
    ]
  },
  {
    role: "SALES",
    permissions: [
      { value: 'FILTER', label: 'Sales Filters' }
    ]
  },
  {
    role: "QUOTES",
    permissions: [
      { value: 'FILTER', label: 'Quotes Filters' },
      { value: 'SEARCH', label: 'Search' },
      { value: 'CANCEL', label: 'Cancel' }
    ]
  },
  {
    role: "REPORTS",
    permissions: [
      { value: 'FILTER', label: 'Reports Filters' },
      { value: 'DOWNLOAD', label: 'Download reports' }
    ]
  }
];

export const RETURN_STATUS = {
  CANCELED: { text: "Canceled", color: "bg-ag-order-canceled" },
  APPROVED: { text: "Approved", color: "bg-ag-order-delivered" },
  INPROCESS: { text: "In process", color: "bg-ag-order-pending" },
  FINISHED_WITHOUT_CHECK: { text: "Finished (attend check)", color: 'bg-green-200'},
  FINISHED: { text: "Finished", color: "bg-blue-100" },
};

export const PRODUCTS_STATUS = [
  { label: "Active", value: "active" },
  { label: "Deactivate", value: "inactivated" }
];

export const PAYMENT_METHODS = [
  { label: 'Credit Card', value: 'CREDIT_CARD' },
  { label: 'Cash', value: 'CASH' },
  { label: 'Mixed', value: 'MIXED' },
  { label: 'Credit', value: 'CREDIT' },
];