import { useState, useEffect } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { getWindowTintCatalog, saveWindowTintCatalog, putWindowTintCatalog, deleteWindowTintCatalogById } from "../../../services/WindowTintCatalog";
import { AiFillCloseCircle, AiFillEdit } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";


const ModalShippingWindowTint = ({ onClose }) => {

    const [windowTintCatalog, setWindowTintCatalog] = useState([])
    const [newWindowTintCatalog, setNewWindowTintCatalog] = useState([])
    const [validationErrors, setValidationErrors] = useState([])
    const [validationErrorsNew, setValidationErrorsNew] = useState([])

    const handleAddRowCatalog = () => {
        let userData = JSON.parse(window.localStorage.getItem("user-data"));
        let newArray = [
            {
                name: "",
                description: "",
                warranty: "",
                price: "",
                administrator: userData.id
            }, ...newWindowTintCatalog
        ]
        setNewWindowTintCatalog(newArray)
        setValidationErrors([...validationErrors, { name: false, description: false, warranty: false }]);

    }

    // const handleInputNewRowChange = (e, column, index) => {
    //     let data = [...newWindowTintCatalog]
    //     data[index][column] = column !== 'price' ? e.target.value : Number.isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value);
    //     setNewWindowTintCatalog(data)
    // }

    const handleInputNewRowChange = (e, column, index) => {
        const value = e.target.value;
        let data = [...newWindowTintCatalog];
        const regex = /^\s*[a-zA-Z0-9]+(\s+[a-zA-Z0-9]+)*\s*$/;
        const regexNumbers = /^\d*\.?\d*$/;
        if (column !== 'price') {
            if (value === '' || regex.test(value)) {
                data[index][column] = value;
            }
        } else {
            if (value === '' || regexNumbers.test(value)) {
                // data[index][column] = Number.isNaN(parseFloat(value)) ? '' : parseFloat(value);
                if (!isNaN(value))
                    data[index][column] = value
            }
        }
        setNewWindowTintCatalog(data);
    }

    const handleSaveCatalog = (index) => {
        let array_SaveData = [...newWindowTintCatalog]
        let info = array_SaveData[index]
        let dataPost = {
            name: info.name,
            description: info.description,
            warranty: info.warranty,
            price: info.price,
            administrator: info.administrator,
        }

        const errors = { ...validationErrors[index] };
        if (!info.name) {
            errors.name = true;
        } else {
            errors.name = false;
        }
        if (!info.description) {
            errors.description = true;
        } else {
            errors.description = false;
        }
        if (!info.warranty) {
            errors.warranty = true;
        } else {
            errors.warranty = false;
        }
        // Actualizar estado de errores
        const updatedErrors = [...validationErrors];
        updatedErrors[index] = errors;
        setValidationErrors(updatedErrors);

        // const isValidDecimal = /^\d*\.?\d+$/.test(info.price) && !/^0*\.?0*$/.test(info.price);
        const isValidDecimal = /^(?!0+(?:\.0+)?$)(?:\d*\.?\d+)?$/.test(info.price);
        if (info.name === '' || info.description === '' || info.warranty === '') {
            toast.warning('Please, enter the required fields.', alertOptions);
        } else if (info.name.trim() === '' || info.description.trim() === '' || info.warranty.trim() === '') {
            toast.warning('The fields cannot contain only white spaces.', alertOptions);
        } else if (!isValidDecimal) {
            toast.warning('It is not possible to save prices with a value of 0.', alertOptions);
        } else {
            saveWindowTintCatalog(dataPost).then(res => {
                if (res.status === 200) {
                    toast.success("Record saved successfully!", alertOptions);
                    //onClose(false)
                    let dataDb = res.data
                    dataDb.edit = false
                    let newData = [...windowTintCatalog]
                    newData.push(dataDb);
                    newData = sortRows(newData)
                    setWindowTintCatalog(newData)
                    array_SaveData.splice(index, 1);
                    setNewWindowTintCatalog(array_SaveData)
                }
            }).catch((error) => {
                toast.warning(error.response.data.message, alertOptions);
            });
        }
    }

    const handleDeleteRowCatalog = (index) => {
        let data = [...newWindowTintCatalog]
        data.splice(index, 1);
        setNewWindowTintCatalog(data)
    }

    const handleEditCatalog = (id) => {
        let newData = [...windowTintCatalog]
        let index = newData.findIndex((x) => x._id === id)
        newData[index].edit = true

        setValidationErrorsNew([...validationErrorsNew, { name: false, description: false, warranty: false }]);
        setWindowTintCatalog(newData)
    }

    // const handleInputChange = (e, column, index) => {
    //     let data = [...windowTintCatalog]
    //     data[index][column] = column !== 'price' ? e.target.value : Number.isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value);
    //     setWindowTintCatalog(data)
    // }

    const handleInputChange = (e, column, index) => {
        const value = e.target.value;
        let data = [...windowTintCatalog]
        const regex = /^\s*[a-zA-Z0-9]+(\s+[a-zA-Z0-9]+)*\s*$/;
        const regexNumbers = /^\d*\.?\d*$/;
        if (column !== 'price') {
            if (value === '' || regex.test(value)) {
                data[index][column] = value;
            }
        } else {
            if (value === '' || regexNumbers.test(value)) {
                // data[index][column] = Number.isNaN(parseFloat(value)) ? '' : parseFloat(value);
                if (!isNaN(value))
                    data[index][column] = value
            }
        }
        setWindowTintCatalog(data)
    }

    const handleUpdateCatalog = (id) => {
        let newData = [...windowTintCatalog]
        let index = newData.findIndex((x) => x._id === id)
        let info = newData[index]
        let dataUpdate = {
            _id: info._id,
            name: info.name,
            description: info.description,
            warranty: info.warranty,
            price: info.price
        }

        const errors = { ...validationErrorsNew[index] };
        if (!info.name) {
            errors.name = true;
        } else {
            errors.name = false;
        }
        if (!info.description) {
            errors.description = true;
        } else {
            errors.description = false;
        }
        if (!info.warranty) {
            errors.warranty = true;
        } else {
            errors.warranty = false;
        }
        // Actualizar estado de errores
        const updatedErrors = [...validationErrors];
        updatedErrors[index] = errors;
        setValidationErrorsNew(updatedErrors);

        //const isValidDecimal = /^\d*\.?\d+$/.test(info.price) && !/^0*\.?0*$/.test(info.price);
        const isValidDecimal = /^(?!0+(?:\.0+)?$)(?:\d*\.?\d+)?$/.test(info.price);
        if (info.name === '' || info.description === '' || info.warranty === '') {
            toast.warning('Please, enter the required fields.', alertOptions);
        } else if (info.name.trim() === '' || info.description.trim() === '' || info.warranty.trim() === '') {
            toast.warning('The fields cannot contain only white spaces.', alertOptions);
        } else if (!isValidDecimal) {
            toast.warning('It is not possible to save prices with a value of 0.', alertOptions);
        } else {
            putWindowTintCatalog(id, dataUpdate).then(res => {
                if (res.status === 200) {
                    toast.success("Record updated successfully!", alertOptions);
                    //onClose(false)
                    newData[index].edit = false
                    newData = sortRows(newData)
                    setWindowTintCatalog(newData)
                }
            }).catch((error) => {
                toast.warning(error.response.data.message, alertOptions);
            });
        }
    }

    const handleDeleteCatalog = (id) => {
        let newData = [...windowTintCatalog]
        deleteWindowTintCatalogById(id).then(res => {
            if (res.status === 200) {
                toast.success("Record deleted successfully!", alertOptions);
                newData = newData.filter((x) => x._id !== id)
                setWindowTintCatalog(newData)
            }
        }).catch((error) => {
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const sortRows = (array) => {
        array.sort((a, b) => a.price - b.price);
        return array
    }

    useEffect(() => {
        load()
    }, [])

    const load = () => {
        getWindowTintCatalog().then(res => {
            if (res.status === 200) {
                let data = res.data.map((item) => {
                    return {
                        ...item,
                        edit: false
                    }
                })
                setWindowTintCatalog(data)
            }
        }).catch((error) => {
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[80%]">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="text-xl text-ag-secondary font-bold pl-[5%]">
                                        Window Tint Catalog (Retailers)
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="px-5 pb-2 pt-3">
                                <div className="pb-3">
                                    <span className="text-ag-secondary-letter text-sm">
                                        The following table is so that you can view the window tint options from the Quote Detail section when you receive a window tint type service:
                                    </span>
                                </div>
                                <div className="flex justify-end">
                                    <div className="pb-3">
                                        <button onClick={handleAddRowCatalog} type="button" className="bg-ag-secondary text-white text-xs lg:text-base py-2 px-5 rounded-xl disabled:bg-zinc-200">Add window tint</button>
                                    </div>
                                </div>
                                <div className="">
                                </div>

                                <div className="max-w-full border-solid border-[#D7D7D7] border">
                                    <table className="min-w-full border-collapse">
                                        <thead className="bg-ag-secondary-table h-[57px] text-center">
                                            <tr>
                                                <th className="text-[12px] text-ag-secondary-letter font-normal w-[20%]">Window tint name</th>
                                                <th className="text-[12px] text-ag-secondary-letter font-normal w-[20%]">Description<br />%</th>
                                                <th className="text-[12px] text-ag-secondary-letter font-normal w-[20%]">Warranty</th>
                                                <th className="text-[12px] text-ag-secondary-letter font-normal w-[20%]">Price<br />$</th>
                                                <th className="text-[12px] text-ag-secondary-letter font-normal w-[20%]">Actions</th>
                                            </tr>
                                        </thead>
                                    </table>
                                    {/* <div className="overflow-y-auto h-[350px]"> */}
                                    <div className="overflow-y-auto h-[350px] 2xl:h-[430px]">
                                        <table className="min-w-full border-collapse">

                                            <tbody>
                                                {
                                                    newWindowTintCatalog.map((wt, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="py-7 md:py-3 text-center w-[20%] relative">
                                                                    <input onChange={(e) => handleInputNewRowChange(e, "name", index)} className="w-[95%] h-[30px] border border-[#7A7A7A] rounded-[10px] text-center truncate" type="text" value={wt.name}></input>
                                                                    {
                                                                        validationErrors[index]?.name ? <span className="absolute text-[red] left-[5px] bottom-0 text-[10px]">Required field</span> : <></>
                                                                    }
                                                                    {/* {
                                                                        wt.name.length === 0 ?
                                                                            <span className="absolute text-[red] left-[5px] bottom-0 text-[10px]">required field</span>
                                                                            :
                                                                            <></>
                                                                    } */}
                                                                </td>
                                                                <td className="py-7 md:py-3 text-center w-[20%] relative">
                                                                    <input onChange={(e) => handleInputNewRowChange(e, "description", index)} className="w-[95%] h-[30px] border border-[#7A7A7A] rounded-[10px] text-center truncate" type="text" value={wt.description}></input>
                                                                    {
                                                                        validationErrors[index]?.description ? <span className="absolute text-[red] left-[5px] bottom-0 text-[10px]">Required field</span> : <></>
                                                                    }
                                                                    {/* {
                                                                        wt.description.length === 0 ?
                                                                            <span className="absolute text-[red] left-[5px] bottom-0 text-[10px]">required field</span>
                                                                            :
                                                                            <></>
                                                                    } */}
                                                                </td>
                                                                <td className="py-7 md:py-3 text-center w-[20%] relative">
                                                                    <input onChange={(e) => handleInputNewRowChange(e, "warranty", index)} className="w-[95%] h-[30px] border border-[#7A7A7A] rounded-[10px] text-center truncate" type="text" value={wt.warranty}></input>
                                                                    {
                                                                        validationErrors[index]?.warranty ? <span className="absolute text-[red] left-[5px] bottom-0 text-[10px]">Required field</span> : <></>
                                                                    }
                                                                    {/* {
                                                                        wt.warranty.length === 0 ?
                                                                            <span className="absolute text-[red] left-[5px] bottom-0 text-[10px]">required field</span>
                                                                            :
                                                                            <></>
                                                                    } */}
                                                                </td>
                                                                <td className="py-7 md:py-3 text-center w-[20%]">
                                                                    <input onChange={(e) => handleInputNewRowChange(e, "price", index)} className="w-[95%] h-[30px] border border-[#7A7A7A] rounded-[10px] text-center truncate" type="text" value={wt.price}></input>
                                                                </td>
                                                                <td className="py-2 w-[20%]">
                                                                    <div className="flex justify-around">
                                                                        <span className="text-green-600 text-2xl flex items-center justify-center cursor-pointer" onClick={() => handleSaveCatalog(index)}>
                                                                            <FaCheckCircle />
                                                                        </span>
                                                                        <span className="text-3xl text-red-700 flex items-center justify-center cursor-pointer" onClick={(e) => handleDeleteRowCatalog(index)}>
                                                                            <AiFillCloseCircle />
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                {
                                                    windowTintCatalog.length === 0 && newWindowTintCatalog.length === 0 ?
                                                        <p className="text-center">No records found</p>
                                                        :
                                                        windowTintCatalog.map((wt, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="py-3 text-center w-[20%] relative">
                                                                        <input title={wt.name} onChange={(e) => handleInputChange(e, "name", index)} className="w-[95%] h-[30px] border border-[#7A7A7A] rounded-[10px] text-center truncate" type="text" value={wt.name} disabled={!wt.edit}></input>
                                                                        {
                                                                            validationErrorsNew[index]?.name ? <span className="absolute text-[red] left-[5px] bottom-0 text-[10px]">Required field</span> : <></>
                                                                        }
                                                                        {/* {
                                                                            wt.name.length === 0 ?
                                                                                <span className="absolute text-[red] left-[5px] bottom-0 text-[10px]">Campo requerido</span>
                                                                                :
                                                                                <></>
                                                                        } */}
                                                                    </td>
                                                                    <td className="py-3 text-center w-[20%] relative">
                                                                        <input title={wt.description} onChange={(e) => handleInputChange(e, "description", index)} className="w-[95%] h-[30px] border border-[#7A7A7A] rounded-[10px] text-center truncate" type="text" value={wt.description} disabled={!wt.edit}></input>
                                                                        {
                                                                            validationErrorsNew[index]?.description ? <span className="absolute text-[red] left-[5px] bottom-0 text-[10px]">Required field</span> : <></>
                                                                        }
                                                                        {/* {
                                                                            wt.description.length === 0 ?
                                                                                <span className="absolute text-[red] left-[5px] bottom-0 text-[10px]">Campo requerido</span>
                                                                                :
                                                                                <></>
                                                                        } */}
                                                                    </td>
                                                                    <td className="py-3 text-center w-[20%] relative">
                                                                        <input title={wt.warranty} onChange={(e) => handleInputChange(e, "warranty", index)} className="w-[95%] h-[30px] border border-[#7A7A7A] rounded-[10px] text-center truncate" type="text" value={wt.warranty} disabled={!wt.edit}></input>
                                                                        {
                                                                            validationErrorsNew[index]?.warranty ? <span className="absolute text-[red] left-[5px] bottom-0 text-[10px]">Required field</span> : <></>
                                                                        }
                                                                        {/* {
                                                                            wt.warranty.length === 0 ?
                                                                                <span className="absolute text-[red] left-[5px] bottom-0 text-[10px]">Campo requerido</span>
                                                                                :
                                                                                <></>
                                                                        } */}
                                                                    </td>
                                                                    <td className="py-3 text-center w-[20%]">
                                                                        <input title={wt.price} onChange={(e) => handleInputChange(e, "price", index)} className="w-[95%] h-[30px] border border-[#7A7A7A] rounded-[10px] text-center truncate" type="text" value={wt.price !== null ? wt.price : ''} disabled={!wt.edit}></input>
                                                                    </td>
                                                                    <td className="py-2 w-[20%]">
                                                                        <div className="flex justify-around">
                                                                            {
                                                                                wt.edit === false ?
                                                                                    <span className="text-blue-600 text-3xl cursor-pointer" onClick={() => handleEditCatalog(wt._id)}>
                                                                                        <AiFillEdit />
                                                                                    </span>
                                                                                    :
                                                                                    <span className="text-green-600 text-2xl flex items-center justify-center cursor-pointer" onClick={() => handleUpdateCatalog(wt._id)}>
                                                                                        <FaCheckCircle />
                                                                                    </span>
                                                                            }
                                                                            <span className="text-3xl text-red-700 flex items-center justify-center cursor-pointer" onClick={() => handleDeleteCatalog(wt._id)}>
                                                                                <AiFillCloseCircle />
                                                                            </span>
                                                                        </div>


                                                                    </td>


                                                                </tr>
                                                            )
                                                        })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                                {/* <div className="flex justify-center pt-5">
                                    <button
                                        className="w-[45%] bg-ag-primary-light text-white text-xs lg:text-base p-2 rounded-xl disabled:bg-zinc-200"
                                        type="submit"
                                        onClick={handleSubmit}
                                    >
                                        Save
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>


            {/* <>
                <div className="block ">
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                        <div className="w-[320px] md:w-[600px]">
                            <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="w-[100%] flex justify-center px-5 pb-2 pt-3">
                                    <span className="text-xl text-ag-secondary font-bold pl-[5%]">
                                        Are you sure you want to delete this record?
                                    </span>
                                </div>
                                <div className="w-[100%] flex justify-between px-5 pb-2 pt-3">
                                    <button className="w-[45%] bg-ag-secondary text-white text-xs lg:text-base p-2 rounded-xl disabled:bg-zinc-200" type="button">Cancel</button>
                                    <button className="w-[45%] bg-ag-primary text-white text-xs lg:text-base p-2 rounded-xl disabled:bg-zinc-200" type="button">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </> */}
        </>
    )
}

export default ModalShippingWindowTint