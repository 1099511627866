import Features from "../../../../components/features/Features";
import { formatCurrency } from "../../../../utils/Utilities";

const arrSeller = {
    "Best seller": { bgColor: "bg-green-600", txtColor: "text-white" },
    "Regular sale": { bgColor: "bg-yellow-200", txtColor: "text-black" },
    "Minor sold": { bgColor: "bg-red-400", txtColor: "text-white" },
    "": { bgColor: "", txtColor: "text-ag-secondary-letter" }
}

const RowsGraphics = ({ graphic, onPrint, onCompatible }) => {
    return (
        <tr>
            <td className={`w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-3 ${ graphic?.indicator ? `${arrSeller[graphic?.indicator]?.bgColor} ${arrSeller[graphic?.indicator]?.txtColor}` : "" }`}>
                <p className={`truncate w-20 m-auto ${onPrint ? "pb-1" : ""}`}>{ graphic?.nags }</p>
            </td>
            <td className={`w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-3 ${ graphic?.indicator ? `${arrSeller[graphic?.indicator]?.bgColor} ${arrSeller[graphic?.indicator]?.txtColor}` : "" }`}>
                <p className={`w-28 m-auto ${onPrint ? "pb-1" : ""}`}>{ graphic?.name }</p>
            </td>
            <td className={`w-[18%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-3 ${ graphic?.indicator ? `${arrSeller[graphic?.indicator]?.bgColor} ${arrSeller[graphic?.indicator]?.txtColor}` : "" }`}>
                {
                    graphic.compatible_vehicles.map((prodComp, i) => {
                        if (onPrint || i <= 2) {
                            return (
                                <p className={`truncate w-64 m-auto ${onPrint ? "pb-1" : ""}`} key={i} title={
                                    `${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year} ` +
                                    `${prodComp?.brand} ` +
                                    `${prodComp?.model} ` +
                                    `${prodComp?.type_car}`
                                }>
                                    { String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year }{' '}
                                    { prodComp?.brand }{' '}
                                    { prodComp?.model }{' '}
                                    { prodComp?.type_car }
                                </p>
                            )
                        }
                    })
                }
                {
                    !onPrint && graphic.compatible_vehicles.length > 3 ?
                        <p className={`truncate w-36 m-auto font-semibold cursor-pointer ${onPrint ? "pb-1" : ""}`} onClick={() => onCompatible(graphic)}>
                            See compatible vehicles +
                        </p>
                    : null
                }
            </td>
            <td className={`w-[18%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-3 ${ graphic?.indicator ? `${arrSeller[graphic?.indicator]?.bgColor} ${arrSeller[graphic?.indicator]?.txtColor}` : "" }`}>
                <Features element={graphic} color={''} textSize={'12px'} seeTitle={false} />
            </td>
            <td className={`w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-3 ${ graphic?.indicator ? `${arrSeller[graphic?.indicator]?.bgColor} ${arrSeller[graphic?.indicator]?.txtColor}` : "" }`}>
                <p className={`truncate w-40 m-auto ${onPrint ? "pb-1" : ""}`}>{ graphic?.description }</p>
            </td>
            <td className={`w-[10%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-3 ${ graphic?.indicator ? `${arrSeller[graphic?.indicator]?.bgColor} ${arrSeller[graphic?.indicator]?.txtColor}` : "" }`}>
                <p className={`truncate w-28 m-auto ${onPrint ? "pb-1" : ""}`}>{ "-----" }</p>
            </td>
            <td className={`w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-3 ${ graphic?.indicator ? `${arrSeller[graphic?.indicator]?.bgColor} ${arrSeller[graphic?.indicator]?.txtColor}` : "" }`}>
                <p className={`truncate w-20 m-auto ${onPrint ? "pb-1" : ""}`}>{ formatCurrency(graphic?.price) }</p>
            </td>
            <td className={`w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-3 ${ graphic?.indicator ? `${arrSeller[graphic?.indicator]?.bgColor} ${arrSeller[graphic?.indicator]?.txtColor}` : "" }`}>
                <p className={`truncate w-20 m-auto ${onPrint ? "pb-1" : ""}`}>{ graphic?.qty }</p>
            </td>
        </tr>
    );
}

export default RowsGraphics;