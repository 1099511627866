import { useContext, useEffect, useState } from "react";
import { BsMegaphoneFill } from "react-icons/bs";
import { FaUserFriends } from "react-icons/fa";
import { MdMenuOpen } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { PermitsContext } from "../../services/Permits";
import Modal from "../modal/Modal";
import useScreenSize from "../../utils/useScreenSize";
import SidebarItem from "./SidebarItem";
import SBIconHead from "../../assets/icons/sidebar/sb_head_icon.svg";
import SBIconBook from "../../assets/icons/sidebar/sb_book_icon.svg";
import SBIconSquare from "../../assets/icons/sidebar/sb_square_icon.svg";
import SBIconList from "../../assets/icons/sidebar/sb_list_icon.svg";
import SBIconShope from "../../assets/icons/sidebar/sb_shope_icon.svg";
import SBIconListA from "../../assets/icons/sidebar/sb_list_a_icon.svg";
import SBIconCash from "../../assets/icons/sidebar/sb_cash_icon.svg";
import SBIconSignOut from "../../assets/icons/sidebar/sb_signout_icon.svg";
import Logo from "../../assets/images/logo_a.png";


const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const navigate = useNavigate();
  const { width } = useScreenSize();
  const {
    dataSession,
    setDataSession,
    permits,
    getCurrentPermits,
    setLogOutModal,
  } = useContext(PermitsContext);

  const [showSubUsers, setShowSubUsers] = useState(false);
  const [showSubOrders, setShowSubOrders] = useState(false);

  const sidebarItems = [
    {
      to: "/administrators",
      text: "Administrators",
      icon: SBIconHead,
      show: permits["ADMINISTRATORS"]["VIEW"],
    },
    {
      to: "/users",
      text: "Users",
      icon: <FaUserFriends />,
      show: permits["USERS"]["VIEW"],
      subItems: [
        { to: "/users/wholesaler", text: "Wholesalers", show: permits["USERS"]["ADD_WOLESALER"] },
      ],
      showSub: showSubUsers,
      setShowSub: setShowSubUsers,
    },
    {
      to: "/vendors",
      text: "Vendors",
      icon: SBIconBook,
      show: permits["SUPPLIERS"]["VIEW"],
    },
    {
      to: "/products",
      text: "Products",
      icon: SBIconSquare,
      show: permits["PRODUCTS"]["VIEW"],
    },
    {
      to: "/inventory",
      text: "Inventory",
      icon: SBIconList,
      show: permits["INVENTORY"]["VIEW"],
    },
    {
      to: "/warehouses",
      text: "Warehouses",
      icon: SBIconShope,
      show: permits["WAREHOUSE"]["VIEW"],
    },
    {
      to: "/orders",
      text: "Orders",
      icon: SBIconListA,
      show: permits["ORDERS"]["VIEW"],
      subItems: [
        { to: "/returns", text: "Returns", show: true },
        { to: "/wishList", text: "Wish list", show: true },
      ],
      showSub: showSubOrders,
      setShowSub: setShowSubOrders,
    },
    {
      to: "/quotes",
      text: "Quotes",
      show: permits["QUOTES"]["VIEW"]
    },
    {
      to: "/sales",
      text: "Sales",
      icon: SBIconCash,
      show: permits["SALES"]["VIEW"],
    },
    {
      to: "/reports",
      text: "Reports",
      icon: <BsMegaphoneFill />,
      show: permits["REPORTS"]["VIEW"],
    },
  ];

  useEffect(() => {
    const userData = window.localStorage.getItem("user-data");
    if (userData) {
      const infoUserAux = JSON.parse(userData);
      if (infoUserAux.permissions) {
        getCurrentPermits(infoUserAux.permissions, infoUserAux.userType);

        setDataSession({
          ...dataSession,
          _id: infoUserAux.idAdmin,
          _idAmin: infoUserAux._id,
          _idUser: infoUserAux.id,
          token: infoUserAux.token,
          names: infoUserAux.names,
          lastNames: infoUserAux.lastNames,
          email: infoUserAux.email,
          zipCode: infoUserAux.zipCode,
          userType: infoUserAux.userType,
          warehouse: infoUserAux.warehouse,
          allWarehouse: infoUserAux.allWarehouse,
        });
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, []);

  const handleSignOut = () => {
    setLogOutModal({
      status: true,
      title: "Are you sure you want to sign out?",
      element: "",
    });
  };

  useEffect(() => {
    if (!sidebarOpen) {
      setShowSubUsers(false);
      setShowSubOrders(false);
    }
  }, [sidebarOpen]);

  useEffect(() => {
    if (width <= 768) setSidebarOpen(false);
  }, [width]);

  return (
    <>
      <div
        className={`bg-ag-secondary-dark top-0 left-0 w-full h-full z-20 ease-in-out ${
          sidebarOpen ? "absolute md:relative translate-x-0" : "-translate-x-[0vw]"
        } overflow-y-auto`}
      >
        <div>
          <div className="flex justify-center items-center h-[90px] md:h-[100px]">
            {sidebarOpen ? (
              <div className="w-full flex justify-end">
                <img
                  className="hidden md:block h-auto w-[220px] m-auto"
                  src={Logo}
                  alt="Logo"
                />
                <button
                  className="md:hidden px-3"
                  aria-expanded={sidebarOpen}
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                  <span className="text-2xl md:text-3xl text-white">
                    <MdMenuOpen />
                  </span>
                </button>
              </div>
            ) : (
              <img />
            )}
          </div>

          {sidebarItems.map((item, index) => (
            <SidebarItem
              key={index}
              to={item.to}
              open={sidebarOpen}
              show={item.show}
              text={item.text}
              icon={item.icon}
              subItems={item.subItems}
              showSub={item.showSub}
              setShowSub={item.setShowSub}
            />
          ))}

          <button type="button" className="w-full" onClick={handleSignOut}>
            {sidebarOpen ? (
              <h3 className="py-3 pl-10 text-white text-left text-xl hover:bg-ag-secondary-light">
                Sign Out
              </h3>
            ) : (
              <h3 className="py-3 pl-5 md:pl-6 text-white text-left text-3xl md:text-2xl group hover:bg-blue-500">
                <span className="w-56 -mt-3 ml-12 px-8 py-[10.5px] md:py-[10.5px] absolute bg-blue-500 text-lg rounded-r-xl hidden group-hover:block group-hover:transition group-hover:duration-700 delay-150 group-hover:ease-in-out">
                  Sign Out
                </span>
                <img className="w-[25px]" src={SBIconSignOut} />
              </h3>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
