import { useEffect, useState } from "react";
import { formatCurrency } from "../../../utils/Utilities";

const MyCartPayment = ({ groups }) => {
    const [subtotal, setSubtotal] = useState(0);
    
    useEffect(() => {
        if (groups && groups !== null) {
            let subTotalValue = 0;
            for (let i = 0; i < groups.length; i++) {
                for (let j = 0; j < groups[i].products.length; j++) {
                    subTotalValue += (Number(groups[i].products[j].price) * Number(groups[i].products[j].quantity));
                }
            }
            setSubtotal(subTotalValue);
        }
    }, [groups]);

    return (
        <div className="w-full flex justify-center space-x-3 md:space-x-10 lg:space-x-5 xl:space-x-10 px-5 py-5 lg:py-10 border border-ag-secondary rounded-xl">
            <div>
                <p className="font-semibold">Subtotal:</p>
                <p className="font-semibold">Discount</p>
                <p className="pt-3 font-semibold">Total:</p>
            </div>
            <div>
                <p className="w-20 xl:w-40 truncate text-ag-secondary-letter">{ formatCurrency(subtotal) }</p>
                <p className="w-20 xl:w-40 truncate text-ag-secondary-letter">$0.00</p>
                <p className="w-20 xl:w-40 truncate pt-3 text-ag-secondary-letter">{ formatCurrency(subtotal) }</p>
            </div>
        </div>
    );
}

export default MyCartPayment;