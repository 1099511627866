import { _delete, _get, _post, _put } from "./http";

export const getRole = (id) => _get(`/roles/${id}`);

export const getRoles = () => _get(`/roles`);

export const saveRole = (roleObject) => _post(`/roles`, roleObject);

export const updateRole = (id, roleObject) => _put(`/roles/${id}`, roleObject);

export const deleteRole = (id) => _delete(`/roles/${id}`);
