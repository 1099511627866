import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { validateAdministrators } from "../../../utils/Validators";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

const FormAdmin = ({ adminInfo, warehouseOptions, rolOptions, onSave, onEdit, onClose }) => {
  const [warehousesOpts, setWarehousesOpts] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [focusClass, setFocusClass] = useState({
    names: { placeholder: "Name", status: false },
    lastNames: { placeholder: "Last names", status: false },
    warehouse: { placeholder: "Warehouse", status: false },
    email: { placeholder: "Email", status: false },
    phoneNumber: { placeholder: "Phone number", status: false },
    password: { placeholder: "Password", status: false },
    role: { placeholder: "Role", status: false }
  });

  const [adminData, setAdminData] = useState({
    names: "",
    lastNames: "",
    warehouse: [],
    email: "",
    phoneNumber: "",
    password: "",
    role: ""
  });

  const [showPassword, setShowPassword] = useState(false);
  const [title, setTitle] = useState("New Record");

  const handleChecked = (idWarehouse, valChecked) => {
    const newWarehouses = warehousesOpts.map(elem => {
      if (idWarehouse === "all") {
        return {...elem, checked: valChecked};
      }

      if (idWarehouse === elem?._id) {
        return {...elem, checked: valChecked};
      }

      return elem;
    });

    // Actualizar estado de "All Warehouses" según si todos están seleccionados o no
    const allChecked = newWarehouses.every(wh => wh.checked);
    setSelectAllChecked(allChecked);

    setWarehousesOpts(newWarehouses);
  };

  const handleSelectAll = (checked) => {
    const updatedWarehouses = warehousesOpts.map(wh => ({ ...wh, checked }));
    setWarehousesOpts(updatedWarehouses);
    setSelectAllChecked(checked);
  };

  const handleChange = (evt) => {
    const { name, value } = evt.currentTarget;
    const newValue = (name === "names" || name === "lastNames")
      ? /^[A-Za-z\s]+$/g.test(value) || value === "" ? value : adminData[name]
      : name === "phoneNumber"
        ? /^[0-9\s]+$/g.test(value) || value === "" ? value : adminData[name]
        : value === "default" ? "" : value;

    setAdminData({ ...adminData, [name]: newValue });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (adminData.names && adminData.lastNames && adminData.email && adminData.phoneNumber && adminData.password && adminData.role) {
      const foundWarehouses = warehousesOpts.filter(elem => elem?.checked);
      if (foundWarehouses.length > 0) {
        const adminModel = {
          names: adminData.names,
          lastNames: adminData.lastNames,
          email: adminData.email,
          password: adminData.password,
          phoneNumber: adminData.phoneNumber,
          warehouse: foundWarehouses.map(elem => ({ _id: elem._id })),
          role: { _id: adminData.role }
        };

        const result = validateAdministrators(adminModel);
        if (result.status) {
          if (adminInfo !== null) {
            adminModel._id = adminInfo._id;
            onEdit(adminInfo._id, adminModel);
          } else {
            onSave(adminModel);
          }
        } else {
          toast.warning(result.msg, alertOptions);
        }
      } else {
        toast.info("Please select at least one warehouse for the administrator", alertOptions);
      }
    } else {
      toast.info("Please fill in all the fields of the form", alertOptions);
    }
  };

  useEffect(() => {
    if (adminInfo !== null) {
      setTitle("Edit Record");

      setAdminData({...adminData,
        names: adminInfo.names,
        lastNames: adminInfo.lastNames,
        warehouse: adminInfo.warehouse._id,
        email: adminInfo.user.email,
        phoneNumber: adminInfo.phoneNumber,
        password: adminInfo.password,
        role: adminInfo.role._id
      });

      setFocusClass({...focusClass,
        names: { placeholder: "Name", status: adminInfo.names !== "" ? true : false },
        lastNames: { placeholder: "Last names", status: adminInfo.lastNames !== "" ? true : false },
        warehouse: { placeholder: "Warehouse", status: adminInfo.warehouse !== "" ? true : false },
        email: { placeholder: "Email", status: adminInfo.user.email !== "" ? true : false },
        phoneNumber: { placeholder: "Phone number", status: adminInfo.phoneNumber !== "" ? true : false },
        password: { placeholder: "Password", status: adminInfo.password !== "" ? true : false },
        role: { placeholder: "Role", status: adminInfo.role._id !== "" ? true : false }
      });
    } else {
      setAdminData({...adminData,
        names: "",
        lastNames: "",
        warehouse: "",
        email: "",
        phoneNumber: "",
        password: "",
        role: ""
      });
    }
  }, [adminInfo]);

  useEffect(() => {
    if (adminInfo !== null) {
      const newWarehousesOpts = warehouseOptions.map(element => {
        const whFound = adminInfo.warehouse.find(elem => elem._id === element._id);
        return whFound ? { ...element, checked: true } : element;
      });
      setWarehousesOpts(newWarehousesOpts);
    } else {
      setWarehousesOpts(warehouseOptions);
    }
  }, [adminInfo]);

  useEffect(() => {
    const allChecked = warehousesOpts.every(wh => wh.checked);
    setSelectAllChecked(allChecked);
  }, [warehousesOpts]);

  return (
    <>
      <div className="block">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none">
          <div className="w-[320px] md:w-[700px] lg:w-[800px]">
            <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
              <div className="w-full flex items-center justify-center rounded text-center">
                <div className="w-[95%]">
                  <span className="text-xl text-ag-secondary font-bold">
                    {title}
                  </span>
                </div>

                <div className="w-[5%]">
                  <button
                    className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                    onClick={() => onClose()}
                  >
                    <IoMdCloseCircle />
                  </button>
                </div>
              </div>

              <div className="relative px-5 pb-2 pt-5 flex-auto">
                <form onSubmit={handleSubmit}>
                  <div className="">
                    <div className="grid gap-y-2 gap-x-3 md:gap-x-6 md:grid-cols-2">
                      <div>
                        <input
                          className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.names.status ? "text-left" : "text-center"}`}
                          type="text"
                          name="names"
                          placeholder={focusClass.names.placeholder}
                          onChange={handleChange}
                          value={adminData.names}
                          onFocus={() => setFocusClass({...focusClass, names: { placeholder: "", status: true }})}
                          onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, names: { placeholder: "Name", status: false }}) : null}
                          maxLength={40}
                        />
                      </div>

                      <div>
                        <input
                          className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.lastNames.status ? "text-left" : "text-center"}`}
                          type="text"
                          name="lastNames"
                          placeholder={focusClass.lastNames.placeholder}
                          onChange={handleChange}
                          value={adminData.lastNames}
                          onFocus={() => setFocusClass({...focusClass, lastNames: { placeholder: "", status: true }})}
                          onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, lastNames: { placeholder: "Last names", status: false }}) : null}
                          maxLength={40}
                        />
                      </div>

                      <div>
                        <input
                          className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.email.status ? "text-left" : "text-center"}`}
                          type="text"
                          name="email"
                          placeholder={focusClass.email.placeholder}
                          onChange={handleChange}
                          value={adminData.email}
                          onFocus={() => setFocusClass({...focusClass, email: { placeholder: "", status: true }})}
                          onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, email: { placeholder: "Email", status: false }}) : null}
                          maxLength={40}
                        />
                      </div>

                      <div className="flex justify-center items-center border border-gray-500 rounded-xl">
                        <input
                          title="Password must contain at least: One number, one special character, one uppercase letter, one lowercase letter, must not contain spaces, and must be at least 8 characters."
                          className="p-2 text-left text-ag-secondary-light w-full rounded-xl"
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder={focusClass.password.placeholder}
                          onChange={handleChange}
                          value={adminData.password}
                          onFocus={() => setFocusClass({...focusClass, password: { placeholder: "", status: true }})}
                          onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, password: { placeholder: "Password", status: false }}) : null}
                          maxLength={40}
                        />

                        { showPassword ?
                          <button
                            className="border-none p-2 rounded-xl disabled:bg-zinc-200"
                            type="button"
                            onClick={() => setShowPassword(false)}
                          >
                            <span className="text-ag-secondary-letter text-base lg:text-xl"><BsFillEyeSlashFill /></span>
                          </button>
                          :
                          <button
                            className="border-none p-2 rounded-xl disabled:bg-zinc-200"
                            type="button"
                            onClick={() => setShowPassword(true)}
                          >
                            <span className="text-ag-secondary-letter text-base lg:text-xl"><BsFillEyeFill /></span>
                          </button>
                        }
                      </div>

                      <div>
                        <select
                          className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.role.status ? "text-left" : "text-center"}`}
                          name="role"
                          defaultValue="default"
                          onChange={handleChange}
                          value={adminData.role}
                          onFocus={() => setFocusClass({...focusClass, role: { placeholder: "", status: true }})}
                          onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, role: { placeholder: "Role", status: false }}) : null}
                        >
                          <option value="default">Role</option>
                          {
                            rolOptions.map((element, index) => {
                              return <option key={index} value={element.value}>{element.label}</option>;
                            })
                          }
                        </select>
                      </div>

                      <div>
                        <input
                          className={`w-full text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.phoneNumber.status ? "text-left" : "text-center"}`}
                          type="text"
                          name="phoneNumber"
                          placeholder={focusClass.phoneNumber.placeholder}
                          onChange={handleChange}
                          value={adminData.phoneNumber}
                          onFocus={() => setFocusClass({...focusClass, phoneNumber: { placeholder: "", status: true }})}
                          onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, phoneNumber: { placeholder: "Phone number", status: false }}) : null}
                          maxLength={10}
                        />
                      </div>
                    </div>

                    <div className="mt-3 pt-2 pl-2 h-[120px] overflow-y-auto">
                      <div className="pb-2">
                        <p className="text-ag-secondary-letter text-sm">
                          Select the warehouses to which the administrator belongs
                        </p>
                      </div>

                      <div className="pb-2">
                        <p className="text-ag-secondary-letter text-sm font-semibold">Warehouse:</p>
                      </div>

                      <div className="pt-2 pl-2">
                        <ul>
                          <li className="text-xs flex justify-start items-center space-x-2">
                            <input
                              type="checkbox"
                              checked={selectAllChecked}
                              onChange={(evt) => handleSelectAll(evt.target.checked)}
                            />
                            <span className="text-ag-secondary-letter text-xs italic">All warehouses</span>
                          </li>
                          {
                            warehousesOpts.map((elemWH, i) => (
                              <li key={i} className="text-xs flex justify-start items-center space-x-2">
                                <input
                                  type="checkbox"
                                  checked={elemWH?.checked || false}
                                  onChange={(evt) => handleChecked(elemWH?._id, evt.target.checked)}
                                />
                                <span className="text-ag-secondary-letter text-xs">{elemWH.name}</span>
                              </li>
                            ))
                          }
                        </ul>
                      </div>
                    </div>

                    <div className="w-full md:w-[50%] pt-3 m-auto">
                      <button
                        className="w-full bg-ag-primary-light text-white p-2 rounded-xl"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-50 fixed inset-0 z-20 bg-black"></div>
    </>
  );
};

export default FormAdmin;