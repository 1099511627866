import { _get, _post, _put } from "./http";

export const getReturn = (id) => _get(`/returns/${id}`);

export const getReturns = () => _get(`/returns`);

export const saveReturn = (returnsObject) => _post(`/returns`, returnsObject);

export const cancelReturn = (id, returnsObject) => _put(`/returns/${id}/canceled`, returnsObject);

export const approvedReturn = (id, returnsObject) => _put(`/returns/${id}/approved`, returnsObject);

export const addInventoryReturn = (id, returnsObject) => _put(`/returns/${id}/addInventory`, returnsObject);

export const updateReturn = (id, returnsObject) => _put(`/returns/${id}`, returnsObject);