import React, { useRef } from 'react';
import { IoMdImage } from 'react-icons/io';

const FileUpload = ({ index, handleFileChange, accept = ".png, .jpg, .jpeg" }) => {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div
     onClick={handleClick}
    >
      <span  className="text-[70px] text-ag-secondary relative">
        <IoMdImage />

        <input
          id={`productImages_${index}`}
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          name="productImages"
          accept={accept}
          onChange={(evt) => handleFileChange(evt, index)}
        />
      </span>

      <div>
        <span className="w-full text-left text-[10px] text-ag-secondary truncate">
          Upload image...
        </span>
      </div> 
    </div>
  );
};

export default FileUpload;
