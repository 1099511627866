import { IoMdCloseCircle } from "react-icons/io";
import { useState, useEffect } from "react";

const ModallRequestedBy = ({ products, onClose }) => {
    const [customers, setCustomers] = useState([]);
    const [isLoader, setIsLoader] = useState(true);

    useEffect(() => {
        setIsLoader(true)
        setCustomers(products.customers)
        setIsLoader(false)
    }, [products]);

  return (
<>
    <div className="block">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="w-[320px] md:w-[700px] lg:w-[850px] h-[500px] xl:h-auto">
                <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                    {

                        isLoader ?
                            <span className="flex items-center justify-center text-center">Loading, please wait...</span>
                            :
                            <div>
                                <div className="w-full flex items-center justify-center rounded text-center">
                                    <div className="w-[95%]">
                                        <span className="text-xl text-ag-secondary font-bold">
                                            {products?.nags ? products?.nags : products?.compatible_vehicles[0].nag} Requested by:
                                        </span>
                                    </div>
                                    <div className="w-[5%]">
                                        <button
                                            className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                            onClick={() => onClose(false)}
                                        >
                                            <IoMdCloseCircle />
                                        </button>
                                    </div>
                                </div>
                                <div className="px-8 py-5 max-h-[300px] overflow-y-auto">
                                {
                                    customers.map((item, index) => (
                                        <p className="w-full text-ag-secondary" key={index}>{item.names + ' ' + item.lastNames }</p>
                                    ))
                                }
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    </div>

    <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
</>
  )
}

export default ModallRequestedBy