import { useContext, useEffect } from "react";
import { useState } from "react";
import { IoMdCloseCircle, IoMdSearch } from "react-icons/io";
import { searchInventoryAdd } from "../../../../services/Inventory";
import { PermitsContext } from "../../../../services/Permits";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import { getProductEspecificationsBrands, getProductEspecificationsModel, getProductEspecificationsYears } from "../../../../services/Product";
import TableInventorySpecification from "./TableInventorySpecification";

const InventorySpecification = ({ onClose, onSelect, onLoader, onCompatible }) => {
  const { dataSession } = useContext(PermitsContext);
  const [page, setPage] = useState(1);
  const [pagesData, setPagesData] = useState({ total: 0, records: 0 });
  const [products, setProducts] = useState([]);
  const [yearOpts, setYearOpts] = useState([]);
  const [makeOpts, setMakeOpts] = useState([]);
  const [modelOpts, setModelOpts] = useState([]);
  const [filterPagination, setFilterPagination] = useState({
    data: dataSession.userType === "ADMIN" || dataSession.userType === "ADMINISTRATOR" ? {} : { warehouse: dataSession?.allWarehouse[0]._id },
    status: false
  });
  const [filterParams, setFilterParams] = useState({
    nag: "",
    brand: "",
    model: "",
    year: ""
  });

  const getYears = () => {
    setMakeOpts([]);
    setModelOpts([]);
    getProductEspecificationsYears().then(res => {
      if (res.status === 200) setYearOpts(res.data);
    });
  };

  const getBrands = (yearParam) => {
    setModelOpts([]);
    getProductEspecificationsBrands(yearParam).then(res => {
      if (res.status === 200) setMakeOpts(res.data);
    });
  };

  const getModels = (yearParam, brandParam) => {
    getProductEspecificationsModel(brandParam).then(res => {
      if (res.status === 200) setModelOpts(res.data);
    });
  };

  const handleFilters = (filtersParams) => {
    let filterObject = dataSession.userType === "ADMIN" || dataSession.userType === "ADMINISTRATOR" ? {} : { warehouse: dataSession?.allWarehouse[0]._id };
    if (
      filtersParams.model === "" &&
            filtersParams.brand === "" &&
            filtersParams.year === "" &&
            filtersParams.nag === ""
    ) {
      setPage(1);
      setFilterPagination({...filterPagination, data: { warehouse: dataSession?.allWarehouse[0]._id }, status: false});
      searchInventoryForPage(1, dataSession.userType === "ADMIN" || dataSession.userType === "ADMINISTRATOR" ? {} : { warehouse: dataSession?.allWarehouse[0]._id });
    } else {
      for (const key in filtersParams) {
        if (Object.hasOwnProperty.call(filtersParams, key)) {
          if (filtersParams[key] !== "") {
            filterObject[key] = filtersParams[key];
          }
        }
      }
      setPage(1);
      setFilterPagination({...filterPagination, data: filterObject, status: true});
      searchInventoryForPage(1, filterObject);
    }
  };

  const handleChange = (evt) => {
    if (evt.currentTarget.name === "year") {
      setFilterParams({...filterParams, year: evt.currentTarget.value, brand: "", model: "" });
      getBrands(evt.currentTarget.value);
    } else if (evt.currentTarget.name === "brand") {
      setFilterParams({...filterParams, brand: evt.currentTarget.value, model: "" });
      getModels(filterParams.year, evt.currentTarget.value);
    } else if (evt.currentTarget.name === "model") {
      setFilterParams({...filterParams, model: evt.currentTarget.value });
    } else {
      setFilterParams({...filterParams, [evt.currentTarget.name]: evt.currentTarget.value});
    }
  };

  const searchInventoryForPage = (pageInventory, paramsFilters) => {
    onLoader(true);
    searchInventoryAdd(pageInventory, 9, paramsFilters).then(res => {
      if (res.status === 200 && res.data.productsInventory.length > 0) {
        let array_productsInvetory = res.data.productsInventory.map((x) => {
          return {
            ...x,
            year: (typeof filterParams.year) === 'string' ? filterParams.year : ''
          };
        });
        setProducts(array_productsInvetory);
        setPage(pageInventory);
        setPagesData({...pagesData, total: res.data.totalRecords/9, records: res.data.totalRecords});
      } else {
        toast.warning("Search no records found", alertOptions);
      }
      onLoader(false);
    }).catch((error) => {
      onLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  const changePage = (pge) => {
    new Promise((resolve, reject) => {
      searchInventoryForPage(pge, filterPagination.data);
      resolve();
    }).then(() => {
      setPage(pge);
    });
  };

  useEffect(() => {
    getYears();
    searchInventoryForPage(1, dataSession.userType === "ADMIN" || dataSession.userType === "ADMINISTRATOR" ? {} : { warehouse: dataSession?.allWarehouse[0]._id });
  }, []);

  return (
    <>
      <div className="block">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none">
          <div className="w-[350px] md:w-[720px] lg:w-[950px] xl:w-[1200px] 2xl:w-[1450px] 3xl:w-[1650px]">
            <div className="px-2 md:px-5 py-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
              <div className="w-full flex items-center justify-center rounded text-center">
                <div className="w-[95%]">
                  <span className="text-xl text-ag-secondary font-bold">
                                        Inventory specification
                  </span>
                </div>
                <div className="w-[5%]">
                  <button
                    className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                    onClick={() => onClose(false)}
                  >
                    <IoMdCloseCircle />
                  </button>
                </div>
              </div>
              <div className="px-6 pt-3 md:pt-5">
                <div className="w-full">
                  <h3 className="text-sm md:text-base">Please choose an inventory specification to continue</h3>
                </div>
              </div>
              <div className="px-6 pt-2 md:flex md:justify-center md:items-center space-x-0 space-y-3 md:space-x-3 md:space-y-0">
                <div className="grid gap-3 grid-cols-2 md:grid-cols-4">
                  <div>
                    <input
                      className="w-full text-center text-ag-secondary-letter border border-gray-500 p-1 rounded-md disabled:bg-zinc-200"
                      type="text"
                      name="nag"
                      placeholder="Nags"
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <select
                      className="w-full text-center text-ag-secondary-letter border border-gray-500 p-[6px] rounded-md disabled:bg-zinc-200"
                      name="year"
                      onChange={handleChange}
                      value={filterParams.year}
                    >
                      <option value="">Year</option>
                      {
                        yearOpts.map((element, index) => {
                          return <option key={index} value={element.year}>{element.year}</option>;
                        })
                      }
                    </select>
                  </div>
                  <div>
                    <select
                      className="w-full text-center text-ag-secondary-letter border border-gray-500 p-[6px] rounded-md disabled:bg-zinc-200"
                      name="brand"
                      onChange={handleChange}
                      value={filterParams.brand}
                      disabled={!filterParams.year}
                    >
                      <option value="">Make</option>
                      {
                        makeOpts.map((element, index) => {
                          return <option key={index} value={element.brand}>{element.brand}</option>;
                        })
                      }
                    </select>
                  </div>
                  <div>
                    <select
                      className="w-full text-center text-ag-secondary-letter border border-gray-500 p-[6px] rounded-md disabled:bg-zinc-200"
                      name="model"
                      onChange={handleChange}
                      value={filterParams.model}
                      disabled={!filterParams.brand}
                    >
                      <option value="">Model</option>
                      {
                        modelOpts.map((element, index) => {
                          return <option key={index} value={element.model}>{element.model}</option>;
                        })
                      }
                    </select>
                  </div>
                </div>
                <div>
                  <button
                    className="w-full bg-ag-secondary text-center text-white p-[6px] rounded-md flex justify-center"
                    type="button"
                    onClick={() => handleFilters(filterParams)}
                  >
                    <span className="text-2xl">
                      <IoMdSearch />
                    </span>
                  </button>
                </div>
              </div>
              <div className="relative px-5 pb-1 pt-5 flex-auto">
                <TableInventorySpecification
                  arrProducts={products}
                  currntPg={page}
                  pagesData={pagesData}
                  onPage={(pg) => changePage(pg)}
                  onSelect={(productSelected) => onSelect(productSelected)}
                  onCompatible={onCompatible}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-50 fixed inset-0 z-20 bg-black"></div>
    </>
  );
};

export default InventorySpecification;