/* eslint-disable no-trailing-spaces */
import { MdDelete, MdEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import IconPDF from "../../../../assets/icons/pdf-icon.png";

const RowsUsersWholesaler = ({ enableApprove, users, onEdit, onDelete, onStatus }) => {
  return (
    <tr>
      <td className="w-[6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
        { users?.user?.identifier ? users?.user?.identifier : "-" }
      </td>
      <td className="w-[21%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
        <Link to={`/users/history/${users.id}`}>
          <p className="truncate w-48 m-auto">{ `${users.names}` }</p>
        </Link>
      </td>
      <td className="w-[21%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
        <p className="truncate w-48 m-auto">{ users.user.email }</p>
      </td>
      <td className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
        <a href={users.invoiceFile} rel="noreferrer" target="_blank">
          <img className="w-[35px] m-auto" src={IconPDF} />
        </a> 
      </td>
      <td className="w-[11%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">Wholeseller</td>
      <td className="w-[11%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">{ users.phoneNumber }</td>
      <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
        { `$${users.availableCredit}` }
      </td>
      {/* <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">$0.00</td> */}
      <td className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
        <button className="text-3xl" type="button" onClick={() => { onEdit(true); }}>
          <MdEdit />
        </button>
      </td>
      <td className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
        <button className="text-3xl" type="button" onClick={() => onDelete(true) }>
          <MdDelete />
        </button>
      </td>
      <td className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
        <label className={`${!enableApprove ? 'opacity-50' : '' } inline-flex relative items-center cursor-pointer`}>
          <input
            className="sr-only peer disabled:bg-zinc-200"
            type="checkbox"
            checked={users.approve}
            onChange={(evt) => onStatus(evt)}
            disabled={!enableApprove}
          />
          <div className="w-10 h-5 bg-ag-secondary rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-ag-primary z-[-1]"></div>
          <span className="ml-3 text-base text-ag-secondary font-bold"></span>
        </label>
      </td>
    </tr>
  );
};

export default RowsUsersWholesaler;