import { useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const Filters = ({ enableFilters, enableDownload, employeeOptions, warehouseOptions, onFilters, onParams, onReturns, onDownload }) => {
    const ref1 = useRef();
    const ref2 = useRef();

    const navigate = useNavigate();
    const [currentType, setCurrentType] = useState("order");
    const [focusClass, setFocusClass] = useState({
        search: { placeholder: "Search", status: false },
        warehouse: { placeholder: "Warehouse", status: false },
        startDate: { placeholder: "Start date", status: false },
        endDate: { placeholder: "End date", status: false },
        employee: { placeholder: "Employee", status: false }
    });

    const [options, setOptions] = useState({
        search: "",
        warehouse: "",
        startDate: "",
        endDate: "",
        employee: ""
    });

    const handleClean = (type, sts) => {
        setOptions({...options,
            search: "",
            warehouse: "",
            startDate: "",
            endDate: "",
            employee: ""
        });

        setFocusClass({...focusClass,
            search: { placeholder: "Search", status: false },
            warehouse: { placeholder: "Warehouse", status: false },
            startDate: { placeholder: "Start date", status: false },
            endDate: { placeholder: "End date", status: false },
            employee: { placeholder: "Employee", status: false }
        });

        if (type === "return") {
            onReturns({});
        } else {
            if (sts === "all") {
                onParams({});
            } else {
                onParams({ status: sts });
            }
        }
    }

    const handleType = (type, sts) => {
        setCurrentType(type);
        handleClean(type, sts);
    }

    const handleChange = (evt) => {
        if (evt.currentTarget.name === "search") {
            onFilters(evt.currentTarget.value, "filters", currentType);
        } else {
            if (evt.currentTarget.value === "default") {
                setOptions({...options, [evt.currentTarget.name]: ""});
            } else {
                setOptions({...options, [evt.currentTarget.name]: evt.currentTarget.value});
            }
        }
    }

    const handleSubmit = (evt) =>{
        evt.preventDefault();
        onFilters(options, "options", currentType);
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="space-y-3">
                    <div className="grid gap-3 md:grid-cols-6">
                        <div className="md:col-span-3">
                            <div className="flex justify-center items-center border border-gray-500 rounded-xl">
                                <input
                                    className={`w-full text-ag-secondary-letter text-xs lg:text-base border-none p-2 md:p-1 lg:p-2 rounded-xl disabled:bg-zinc-200 ${focusClass.search.status ? "text-left" : "text-center"}`}
                                    type="text"
                                    name="search"
                                    placeholder={focusClass.search.placeholder}
                                    disabled={!enableFilters}
                                    onChange={handleChange}
                                    onFocus={() => setFocusClass({...focusClass, search: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, search: { placeholder: "Search", status: false }}) : null}
                                />
                                <span className="bg-ag-primary-dark text-white text-base lg:text-xl border-none p-[10px] md:p-[8px] lg:p-[10px] rounded-xl disabled:bg-zinc-200">
                                    <BiSearch />
                                </span>
                            </div>
                        </div>
                        <div>
                            <button
                                className="w-full bg-ag-secondary text-white text-xs lg:text-base px-2 py-3 md:py-[8px] lg:py-2 rounded-xl disabled:bg-zinc-200"
                                type="button"
                                disabled={!enableFilters}
                                onClick={() => handleType("order", "delivered")}
                            >
                                Delivered
                            </button>
                        </div>
                        <div>
                            <button
                                className="w-full bg-ag-secondary text-white text-xs lg:text-base px-2 py-3 md:py-[8px] lg:py-2 rounded-xl disabled:bg-zinc-200"
                                type="button"
                                disabled={!enableFilters}
                                onClick={() => handleType("order", "canceled")}
                            >
                                Canceled
                            </button>
                        </div>
                        <div>
                            <button
                                className="w-full bg-ag-secondary text-white text-xs lg:text-base px-2 py-3 md:py-[8px] lg:py-2 rounded-xl disabled:bg-zinc-200"
                                type="button"
                                disabled={!enableFilters}
                                onClick={() => handleType("return", "all")}
                            >
                                Returns
                            </button>
                        </div>
                    </div>
                    <div className="grid gap-3 md:gap-1 lg:gap-3 md:grid-cols-9">
                        <div>
                            <select
                                className={`w-full p-2 md:p-1 text-ag-secondary-letter text-xs xl:text-sm border border-gray-500 rounded-xl truncate ${focusClass.warehouse.status ? "text-left" : "text-center"}`}
                                name="warehouse"
                                defaultValue="default"
                                value={options.warehouse}
                                onChange={handleChange}
                                disabled={!enableFilters}
                                onFocus={() => setFocusClass({...focusClass, warehouse: { placeholder: "", status: true }})}
                                onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, warehouse: { placeholder: "Warehouse", status: false }}) : null}
                            >
                                <option value="default">Warehouse</option>
                                {
                                    warehouseOptions.map((element, index) => {
                                        return <option key={index} value={element._id}>{element.name}</option>;
                                    })
                                }
                            </select>
                        </div>
                        <div className="customDatePickerWidth">
                            <ReactDatePicker
                                className={`w-full p-2 md:p-1 text-ag-secondary-letter text-xs xl:text-sm border border-gray-500 rounded-xl disabled:bg-zinc-200 ${focusClass.startDate.status ? "text-left" : "text-center"}`}
                                placeholderText="Start date"
                                name="startDate"
                                selected={options.startDate}
                                onChange={(dateVal) => setOptions({...options, startDate: dateVal})}
                                onFocus={() => setFocusClass({...focusClass, startDate: { placeholder: "", status: true }})}
                                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, startDate: { placeholder: "Start date", status: false }}) : <></> }
                                disabled={!enableFilters}
                            />
                        </div>
                        <div className="customDatePickerWidth">
                            <ReactDatePicker
                                className={`w-full p-2 md:p-1 text-ag-secondary-letter text-xs xl:text-sm border border-gray-500 rounded-xl disabled:bg-zinc-200 ${focusClass.endDate.status ? "text-left" : "text-center"}`}
                                placeholderText="End date"
                                name="endDate"
                                selected={options.endDate}
                                onChange={(dateVal) => setOptions({...options, endDate: dateVal})}
                                onFocus={() => setFocusClass({...focusClass, endDate: { placeholder: "", status: true }})}
                                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, endDate: { placeholder: "End date", status: false }}) : <></> }
                                disabled={!enableFilters}
                            />
                        </div>
                        <div>
                            <button
                                className="w-full text-center p-2 md:p-1 text-ag-secondary-letter text-xs xl:text-sm border border-gray-500 rounded-xl disabled:bg-zinc-200"
                                type="button"
                                disabled={!enableFilters}   
                                onClick={() => handleType("order", "all")}
                            >
                                All orders
                            </button>
                        </div>
                        <div>
                            <button
                                className="w-full text-center p-2 md:p-1 text-ag-secondary-letter text-xs xl:text-sm border border-gray-500 rounded-xl"
                                type="button"
                                onClick={() => navigate("/reports/graphics")}
                            >
                                Graphical view
                            </button>
                        </div>
                        <div>
                            <select
                                className={`w-full p-2 md:p-1 text-ag-secondary-letter text-xs xl:text-sm border border-gray-500 rounded-xl truncate ${focusClass.employee.status ? "text-left" : "text-center"}`}
                                name="employee"
                                defaultValue="default"
                                value={options.employee}
                                onChange={handleChange}
                                disabled={!enableFilters}
                                onFocus={() => setFocusClass({...focusClass, employee: { placeholder: "", status: true }})}
                                onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, employee: { placeholder: "Employee", status: false }}) : null}
                            >
                                <option value="default">Employee</option>
                                {
                                    employeeOptions.map((element, index) => {
                                        return (
                                            <option key={index} value={element?._id}>
                                                {`${element.names} ${element?.lastNames ? element?.lastNames : ""}`}
                                            </option>
                                        );
                                    })
                                }
                            </select>
                        </div>
                        <div>
                            <button
                                className="w-full text-center p-2 md:p-1 text-ag-secondary-letter text-xs xl:text-sm border border-gray-500 rounded-xl disabled:bg-zinc-200"
                                type="button"
                                disabled={!enableDownload}
                                onClick={() => onDownload(currentType)}
                            >
                                Download csv
                            </button>
                        </div>
                        <div>
                            <button
                                className="w-full text-center p-2 md:p-1 text-ag-secondary-letter text-xs xl:text-sm border border-gray-500 rounded-xl disabled:bg-zinc-200"
                                type="button"
                                disabled={!enableFilters}
                                onClick={() => handleClean(currentType, "all")}
                            >
                                Clean up
                            </button>
                        </div>
                        <div>
                            <button
                                className="w-full text-center p-2 md:p-1 bg-ag-secondary text-white text-xs xl:text-sm border border-gray-500 rounded-xl disabled:bg-zinc-200"
                                type="submit"
                                disabled={!enableFilters}
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Filters;