import { useEffect, useState } from "react";
import { FaClipboardCheck } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { MdCancel } from "react-icons/md";

const OrderMsg = ({ type, onCancel, onMark, onClose }) => {
    const [dataModal, setDataModal] = useState({
        title: "",
        textColor: "",
        icon: null,
        iconColor: "",
        message: ""
    });

    const handleResponse = () => {
        if (type === "cancel") {
            onCancel(true);
        } else {
            onMark(true);
        }
    }

    useEffect(() => {
        if (type === "cancel") {
            setDataModal({...dataModal,
                title: "Are you sure to cancel this order?",
                textColor: "text-red-600",
                icon: <MdCancel />,
                iconColor: "text-red-600",
                message: "This action is irreversible"
            });
        } else {
            setDataModal({...dataModal,
                title: "Are you sure to confirm this order?",
                textColor: "text-ag-secondary",
                icon: <FaClipboardCheck />,
                iconColor: "text-green-600",
                message: ""
            });
        }
    }, [type]);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[500px]">
                        <div className="px-5 pt-5 pb-3 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className={`w-[95%] text-center font-semibold ${ dataModal.textColor} `}>
                                    <h3 className="text-xl">{ dataModal.title }</h3>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="relative p-3 flex-auto">
                                <div className="space-y-3">
                                    <div className="flex justify-center pt-5">
                                        <span className={`text-[100px] ${ dataModal.iconColor }`}>{ dataModal.icon }</span>
                                    </div>
                                    <div className={`text-center text-ag-secondary ${ dataModal.message === "" ? "pb-5" : "" }`}>
                                        <span>{ dataModal.message }</span>
                                    </div>
                                    <div className="flex justify-center items-center space-x-3">
                                        <button
                                            type="button"
                                            className="w-full px-10 py-2 bg-ag-primary-light text-white text-sm rounded-xl"
                                            onClick={() => onClose(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="w-full px-10 py-2 bg-green-600 text-white text-sm rounded-xl"
                                            onClick={handleResponse}
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

export default OrderMsg;