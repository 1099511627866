import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { TRANSFER_OPTIONS } from "../../../../utils/constants";
import ReactDatePicker from "react-datepicker";
import Select from 'react-select';
import { RiEqualizerFill } from "react-icons/ri";

const INITIAL_FILTERS_STATE = {
  search: "",
  createdAt: "",
  transfer: { label: "All Transfers", value: "all" },
  warehouse: [],
};

const Filters = ({ onClose, onFilters, warehouseOptions }) => {
  const [focusClass, setFocusClass] = useState({
    search: { placeholder: "Search transfer", status: false },
    transfer: { placeholder: "All Transfers", status: false },
    warehouse: { placeholder: "Warehouse", status: false }
  });
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState(INITIAL_FILTERS_STATE);

  const handleChange = (event, name) => {
    if (name) {
      setFilters({...filters, [name]: event});
    } else {
      setFilters({...filters, [event.currentTarget.name]: event.currentTarget.value});
    }
  };

  const handleShowFilters = (e) => {
    e.preventDefault();
    setShowFilters(!showFilters);
  };

  const handleClean = (e) => {
    e.preventDefault();
    setFilters(INITIAL_FILTERS_STATE);
  };

  useEffect(() => {
    onFilters(filters);
  }, [filters]);

  return (
    <>
      <div className="space-y-3">
        <div className="grid gap-3 md:gap-6 md:grid-cols-4">
          <div className="md:col-span-3">
            <div className="flex justify-center items-center border border-gray-500 rounded-xl">
              <input
                className={`w-full text-ag-secondary-letter text-xs lg:text-base border-none px-4 py-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.search.status ? "text-left" : "text-center"}`}
                type="text"
                name="search"
                placeholder={focusClass.search.placeholder}
                onChange={handleChange}
                onFocus={() => setFocusClass({...focusClass, search: { placeholder: "", status: true }})}
                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, search: { placeholder: "Search Transfer", status: false }}) : null}
                maxLength={60}
                value={filters.search}
              />
            </div>
          </div>
          <div>
            <Link to="/inventory/transfer/element">
              <button
                className="w-full bg-ag-primary-dark text-white text-xs lg:text-base p-2 rounded-xl disabled:bg-zinc-200"
                type="button"
                onClick={() => { onClose(true); }}
              >
                Create Transfer
              </button>
            </Link>
          </div>
        </div>

        {
          showFilters
            ? <div className="grid gap-3 lg:gap-6 md:grid-cols-12">
              <div className="md:col-span-2 xl:col-span-2">
                <button
                  onClick={handleShowFilters}
                  className="h-9 w-full flex justify-center items-center space-x-3 text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-[7px] lg:p-[2px] rounded-xl disabled:bg-zinc-200"
                >
                  <span className="text-lg xl:text-2xl"><RiEqualizerFill /></span>
                  <span>Filters</span>
                </button>
              </div>

              <div className="md:col-span-2 xl:col-span-2">
                <div className="customDatePickerWidth">
                  <ReactDatePicker
                    className={`text-xs xl:text-base text-center h-9 w-full text-ag-secondary-letter text-l border border-gray-500 px-2 py-[4px] rounded-xl`}
                    placeholderText="Created At"
                    name="createdAt"
                    autoComplete="off"
                    selected={filters.createdAt}
                    onChange={(e) => handleChange(e, 'createdAt')}
                  />
                </div>
              </div>

              <div className="md:col-span-3 xl:col-span-2">
                <Select
                  classNames={{
                    control: () => 'reactSelectDefault'
                  }}
                  className="text-center text-xs xl:text-base"
                  value={filters.transfer}
                  onChange={(e) => handleChange(e, 'transfer')}
                  options={TRANSFER_OPTIONS}
                  placeholder={"All Transfers"}
                />
              </div>

              <div className="md:col-span-3 xl:col-span-3">
                <div>
                  <Select
                    classNames={{
                      control: () => 'reactSelectDefault'
                    }}
                    className="text-center text-xs xl:text-base"
                    isMulti
                    value={filters.warehouse}
                    onChange={(e) => handleChange(e, 'warehouse')}
                    options={warehouseOptions}
                    placeholder={"All Warehouse"}
                  />
                </div>
              </div>

              <div className="md:hidden xl:col-span-2"></div>

              <div className="md:col-span-2 xl:col-span-1">
                <button
                  className="text-xs xl:text-base h-9 w-full flex justify-center items-center space-x-3 text-ag-secondary-letter text-l lg:text-base border border-gray-500 p-[7px] lg:p-[2px] rounded-xl disabled:bg-zinc-200"
                  onClick={handleClean}
                >
                  Clean
                </button>
              </div>
            </div>
            : <div className="grid md:grid-cols-2">
              <div>
                <button
                  onClick={handleShowFilters}
                  className="h-9 md:w-2/12 flex justify-center items-center space-x-3 text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-[7px] lg:p-[2px] rounded-xl disabled:bg-zinc-200"
                >
                  <span>
                    <RiEqualizerFill />
                  </span>

                  <span>Filters</span>
                </button>
              </div>
            </div>
        }

      </div>
    </>
  );
};

export default Filters;