import { _delete, _get, _getParams, _post, _put } from "./http";

export const getNotificationById = (id) => _get(`/notification/${id}`);

export const getNotifications = (filterParams) => _getParams(`/notification`, filterParams);

export const getNotificationsUnreadCount = (filterParams) => _getParams(`/notification/unreadCount`, filterParams);

export const getNotificationsUnreadCountNew = () => _getParams(`/notification/unreadCount`);

export const changeReadNotification = (id, notificationObject) => _put(`/notification/${id}`, notificationObject);

export const deleteNotification = (id) => _delete(`/notification/${id}`);
