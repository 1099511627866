import React from 'react'

const FrontQuarterRight = ({onClick}) => {
  return (
    <svg className="cursor-pointer outline-none" onClick={onClick} data-tooltip-id="car" data-tooltip-content="Front Quarter (right)">
      <path d="M290.66 115.78L272.76 170.48L302.66 160.38C302.66 160.38 304.66 133.28 290.66 115.78Z" fill="#D3D3D4"/>
    </svg>
  )
}

export default FrontQuarterRight