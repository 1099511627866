import { _delete, _get, _getParamsFree, _post, _put, _getParams } from "./http";

export const getQuote = (id) => _get(`/quotes/${id}`);

export const getQuotePWRWT = (id) => _get(`/powerWindowRepair/${id}`);

export const getQuotes = (page, limit, filterParams) => _getParams(`/quotes?sort=DESC&page=${page}&limit=${limit}`, filterParams);

// export const getQuotesPWRWT = () => _get("/powerWindowRepair");

export const getQuotesPWRWT = (page, limit, filterParams) => _getParams(`/powerWindowRepair?&page=${page}&limit=${limit}&sort=DESC`, filterParams);

export const getOptionsToQuote = (id) => _get(`/quotes/${id}/options`);

export const getOptionsToQuoteByPilkington = (id) => _get(`/quotes/${id}/optionsPilkington`);

export const selectProductQuote = (id, quotesObject) => _put(`/quotes/${id}/selectProduct`, quotesObject);

export const selectProductQuotePWRWT = (id, quotesObject) => _put(`/powerWindowRepair/${id}/selectProduct`, quotesObject);

export const cancelQuote = (id, quotesObject) => _put(`/quotes/${id}/cancelQuote`, quotesObject);

export const saveQuote = (quoteObject) => _post(`/quotes`, quoteObject);

export const scheduleQuote = (idQuote, quoteObject) => _post(`/powerWindowRepairAndTint/${idQuote}/schedule`, quoteObject);

export const shippingOptionQuote = (idQuote, quoteObject) => _post(`/powerWindowRepairAndTint/${idQuote}/shipping`, quoteObject);

export const savePaymentQuote = (idQuote, quoteObject) => _post(`/powerWindowRepairAndTint/${idQuote}/savePayment`, quoteObject);

export const updateAvailability = (idQuote, quoteObject) => _put(`/quotes/${idQuote}/selectProduct/changeAvailability`, quoteObject);


// Retail endpoints

export const getQuotesByRetail = (idRetail) => _get(`/retailUser/${idRetail}/myQuotes`);

export const getHoursNotAvailable = (filterParams) => _getParamsFree(`/appointments/hoursNotAvailable`, filterParams);

export const savePaymentQuoteRetail = (idRetail, idQuote, quoteObject) => _post(`/retailUser/${idRetail}/myQuotes/${idQuote}/savePayment`, quoteObject);

export const scheduleQuoteRetail = (idRetail, idQuote, quoteObject) => _post(`/retailUser/${idRetail}/myQuotes/${idQuote}/schedule`, quoteObject);

export const shippingOptionQuoteRetail = (idRetail, idQuote, quoteObject) => _post(`/retailUser/${idRetail}/myQuotes/${idQuote}/shipping`, quoteObject);

export const paymentOptionQuoteRetail = (idRetail, idQuote, quoteObject) => _post(`/retailUser/${idRetail}/myQuotes/${idQuote}/payment`, quoteObject);

export const deleteQuoteRetail = (idRetail, idQuote) => _delete(`/retailUser/${idRetail}/myQuotes/${idQuote}/deleteQuote`);

export const cancelQuoteRetail = (idRetail, idQuote, quotesObject) => _put(`/retailUser/${idRetail}/myQuotes/${idQuote}/cancelQuote`, quotesObject);

export const requestInformation = (quoteObject) => _post(`/requestInformation`, quoteObject);