import { useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { getOrders } from "../../../../services/Orders";
import { getRetailUser } from "../../../../services/Retail";
import { getWholesalerUser } from "../../../../services/Wholesaler";
import { formatCurrency } from "../../../../utils/Utilities";
import TableHistory from "./TableHistory";
import Loader from "../../../../components/loader/Loader";

const fetchOrders = async (userId) => {
  const resOrders = await getOrders({ user: userId });
  let foundOrders = [];
  let totalAux = 0;

  if (resOrders.status === 200) {
    foundOrders = resOrders.data.result
      .filter(elem => elem.status === "delivered" && new Date(elem.createdAt).getFullYear() === new Date().getFullYear())
      .map(elem => {
        let totalTransaction = 0;
        for (let i = 0; i < elem?.productsOrder.length; i++) {
          totalTransaction += Number(elem?.productsOrder[i].price);
        }
        totalAux += Number(totalTransaction);
        return { ...elem, amount: totalTransaction };
      });
  }

  return { foundOrders, totalAux };
};

const fetchUser = async (userId, totalAux, foundOrders) => {
  try {
    const resWU = await getWholesalerUser(userId);
    if (resWU.status === 200) {
      return {
        name: `${resWU.data?.names} ${resWU.data?.lastNames}`,
        credits: resWU.data?.availableCredit,
        totalPerYear: totalAux,
        orders: foundOrders
      };
    }
    const resRU = await getRetailUser(userId);
    if (resRU.status === 200) {
      return {
        name: `${resRU.data?.names} ${resRU.data?.lastNames || ""}`,
        credits: 0,
        totalPerYear: totalAux,
        orders: foundOrders
      };
    }

  } catch {
    const resRU = await getRetailUser(userId);
    if (resRU.status === 200) {
      return {
        name: `${resRU.data?.names} ${resRU.data?.lastNames || ""}`,
        credits: 0,
        totalPerYear: totalAux,
        orders: foundOrders
      };
    }
  }

  return null;
};

const History = () => {
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({
    name: "-----",
    credits: 0,
    totalPerYear: 0,
    orders: []
  });

  const fetchData = async () => {
    setLoader(true);
    try {
      const { foundOrders, totalAux } = await fetchOrders(params.id);
      const userData = await fetchUser(params.id, totalAux, foundOrders);
      if (userData) {
        setData(userData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (params.id) {
      fetchData();
    }
  }, [params.id]);

  return (
    <>
      {loader && <Loader />}

      <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
        <div className="grid grid-cols-4 md:grid-cols-12 px-0 md:px-5">
          <div>
            <Link to="/users">
              <button className="p-1 rounded-md" type="button">
                <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
              </button>
            </Link>
          </div>
          <div className="flex justify-center items-center col-span-2 md:col-span-11 text-center">
            <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
                            Sales History
            </h1>
          </div>
          <div></div>
        </div>
        <div className="pt-8 overflow-auto">
          <div className="w-full md:flex">
            <div className="w-full md:w-[10%] flex justify-center items-center bg-ag-secondary text-center border-[0.5px] border-ag-secondary-light p-3">
              <span className="text-lg text-white">Client</span>
            </div>
            <div className="w-full md:w-[50%] flex justify-center items-center text-center border-[0.5px] border-ag-secondary-light p-3">
              <span className="text-lg text-ag-secondary font-semibold">{data.name}</span>
            </div>
            <div className="w-full md:w-[20%] bg-ag-secondary text-center">
              <div className="border-[0.5px] border-ag-secondary-light p-3">
                <span className="text-lg text-white">Available credit</span>
              </div>
              <div className="border-[0.5px] border-ag-secondary-light p-3">
                <span className="text-lg text-white">Total per year</span>
              </div>
            </div>
            <div className="w-full md:w-[20%] text-center">
              <div className="border-[0.5px] border-ag-secondary-light p-3">
                <span className="text-lg text-ag-secondary">{formatCurrency(data.credits)}</span>
              </div>
              <div className="border-[0.5px] border-ag-secondary-light p-3">
                <span className="text-lg text-ag-secondary">{formatCurrency(data.totalPerYear)}</span>
              </div>
            </div>
          </div>
          <TableHistory orders={data.orders} />
        </div>
      </div>
    </>
  );
};

export default History;
