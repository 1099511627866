import { useEffect, useState } from "react";
import { getRetailUsers } from "../../../services/Retail";
import { getWholesalerUsers } from "../../../services/Wholesaler";

const SelectClient = ({ onClose, setSelectedCustomer }) => {
  const [clientGuest, setClientGuest] = useState([]);
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadClients = async () => {
      let array_clients = [];

      try {
        const resWU = await getWholesalerUsers();
        if (resWU.status === 200 && resWU.data.length > 0) {
          const guestFound = resWU.data.filter(
            element => !element.deleted &&
              element.names === "Guest" &&
              element.lastNames === "User" &&
              element.workshopName === "Avondale Auto Glass"
          );
          setClientGuest(guestFound);

          const clientsFound = resWU.data.filter(
            element => !element.deleted &&
              element.names !== "Guest" &&
              element.lastNames !== "User" &&
              element.workshopName !== "Avondale Auto Glass"
          );
          array_clients = array_clients.concat(clientsFound);
        }

        const resRU = await getRetailUsers();
        if (resRU.status === 200 && resRU.data.length > 0) {
          const clientsFoundAux = resRU.data.filter(element => !element.deleted);
          array_clients = array_clients.concat(clientsFoundAux);
        }
        setClients(array_clients);
        setFilteredClients(array_clients); // Inicializar clientes filtrados
      } catch (error) {
        console.error("Error loading clients:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadClients();
  }, []);

  const handleChange = (term) => {
    setSearchTerm(term); // Actualiza el estado del searchTerm

    const filtered = clients.filter(customer => {
      const name = customer.names?.toLowerCase() || '';
      const lastName = customer.lastNames?.toLowerCase() || '';
      const email = customer.user?.email?.toLowerCase() || '';

      return (
        name.includes(term.toLowerCase()) ||
        lastName.includes(term.toLowerCase()) ||
        email.includes(term.toLowerCase())
      );
    });

    setFilteredClients(filtered); // Actualiza la lista filtrada
  };

  return (
    <>
      <div className="opacity-50 fixed inset-0 z-20 bg-black"></div>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="w-full max-w-2xl rounded-lg bg-white p-6">
          <div className="mb-4 flex items-center justify-between">
            <h2 className="text-xl font-semibold">Select your client</h2>
            <button
              onClick={() => onClose()}
              className="text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
          </div>

          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => handleChange(e.target.value)}
            className="mb-4 w-full rounded-md border border-gray-300 px-4 py-2"
          />

          {isLoading ? (
            <div className="flex justify-center items-center h-32">
              <div className="loader border-t-4 border-blue-500 rounded-full w-12 h-12 animate-spin"></div>
              <span className="ml-4">Loading clients...</span>
            </div>
          ) : (
            <div className="max-h-96 overflow-y-auto">
              <div className="mb-4">
                {clientGuest.length > 0 ? (
                  clientGuest.map((guest, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        setSelectedCustomer(guest);
                        onClose();
                      }}
                      className="flex cursor-pointer justify-between border-b py-3 px-3 hover:bg-gray-50"
                    >
                      <span>{guest.names} {guest.lastNames}</span>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-500">No guest users found.</p>
                )}
              </div>

              {filteredClients.length > 0 ? (
                filteredClients.map((customer, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setSelectedCustomer(customer);
                      onClose();
                    }}
                    className="flex cursor-pointer justify-between border-b py-3 px-3 hover:bg-gray-50"
                  >
                    <span>{customer.names} {customer.lastNames}</span>
                    <span className="text-gray-500">{customer.user?.email}</span>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No clients found.</p>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SelectClient;
