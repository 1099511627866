import { useRef, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { alertOptions } from "../../utils/Utilities";
import ReactSignatureCanvas from "react-signature-canvas";
import { fileUpload } from "../../services/File";

const ModalSignature = ({ title, onClose, onResponse, onBack }) => {
  const [firma, setFirma] = useState(false);
  const firmaRef = useRef();

  const handleSubmit = async () => {
    try {
      const signature = firmaRef.current.toDataURL();
      const [, contentType, base64Data] = signature.match(/^data:(.*);base64,(.*)$/);

      const binaryData = atob(base64Data);

      const arrayBuffer = new ArrayBuffer(binaryData.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }

      const blob = new Blob([arrayBuffer], { type: contentType });

      const file = new File([blob], "signature.jpg", { type: contentType });

      let bodyFormData = new FormData();
      bodyFormData.append("file", file);
      const valueUpload = await fileUpload(bodyFormData).then((res) => {
        if (res.status === 200) return res.data;
        return "";
      });

      onResponse(valueUpload)

    } catch (error) {
      console.log(error);
    }
  };

  const handleBorrarFirma = () => {
    firmaRef.current.clear();
    setFirma(false);
  };

  return (
    <>
      <div className="block">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none">
          <div className="w-[320px] md:w-[700px]">
            <div className="p-3 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
              <div className="w-full flex items-center justify-end rounded text-center">
                <div className="w-[95%]">
                  <div className="text-center font-normal">
                    <h3 className="text-xl pl-[5%]">{title}</h3>
                  </div>
                  <p className="text-xs">The user must enter their signature for the installation to proceed.</p>
                </div>
                <div className="w-[5%]">
                  <button
                    className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                    onClick={() => onClose(false)}
                  >
                    <IoMdCloseCircle />
                  </button>
                </div>
              </div>
              <div className="relative p-5 flex-auto">
                <div className="space-y-5">
                  <div className="border-2 rounded-md border-gray-400">
                    <ReactSignatureCanvas
                      ref={firmaRef}
                      penColor="black"
                      canvasProps={{ className: "w-full h-32 md:h-56" }}
                      clearOnResize={false}
                      onBegin={() => setFirma(true)}
                    />
                  </div>
                  <div className="flex justify-center items-center space-x-3">
                    {firma && (
                      <>
                        <button
                          type="button"
                          className="px-5 py-2 bg-ag-primary text-white text-sm rounded-md"
                          onClick={handleSubmit}
                        >
                          Accept
                        </button>
                        <button
                          type="button"
                          className="px-5 py-2 bg-ag-secondary text-white text-sm rounded-md"
                          onClick={handleBorrarFirma}
                        >
                          Clean
                        </button>
                      </>
                    )}
                    <button
                      type="button"
                      className="px-5 py-2 bg-ag-secondary text-white text-sm rounded-md"
                      onClick={() => onBack(false)}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-50 fixed inset-0 z-20 bg-black"></div>
    </>
  );
};

export default ModalSignature;
