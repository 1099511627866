import { useContext, useEffect, useState } from 'react';
import SelectClient from './SelectClient';
import FormUser from '../users/FormUser';
import { saveWholesalerUser } from '../../../services/Wholesaler';
import { toast } from 'react-toastify';
import { alertOptions } from '../../../utils/Utilities';
import { saveRetailUser } from '../../../services/Retail';
import SelectProduct from './SelectProduct';
import { PermitsContext } from '../../../services/Permits';
import SelectAccesory from './SelectAccesory';
import Modal from '../../../components/modal/Modal';
import { createSale } from '../../../services/Sales';
import { PAYMENT_METHODS } from '../../../utils/constants';
import { IoMdCloseCircle } from 'react-icons/io';
import { getWarehouses } from '../../../services/Warehouse';
import { RiBuilding2Fill } from 'react-icons/ri';
import { BiChevronDown } from 'react-icons/bi';
import MixedPaymentModal from './MixedPaymentModal';

const DISCOUNT_DICTIONARY = {
  "%": "PERCENTAGE",
  "$": "MONEY"
};

const Sales = () => {
  const { permits } = useContext(PermitsContext);

  const [selectedPayment, setSelectedPayment] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [showSelectModal, setShowSelectModal] = useState(false);
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [showAccessoryModal, setShowAccessoryModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [laodingModal, setLoadingModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [warehousesOptions, setWarehousesOptions] = useState([]);
  const [warehouseSelected, setWarehouseSelected] = useState(null);
  const [openWarehouses, setOpenWarehouses] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [mixedPaymentOpen, setMixedPaymentOpen] = useState(false);
  const [mixedPayment, setMixedPayment] = useState({ credits: 0, secondMethod: null, totalSecondPaymenth: 0 });
  let bulkIdCounter = 0;

  const createOrderRequest = () => {
    let orderRequest;
    const orderedProducts = products.filter((item) => item.nags);
    const orderedAccessories = products.filter((item) => !item.nags);
    const customerType = selectedCustomer.user.userType;

    const productsSale = orderedProducts.map((product) => ({
      productInventory: product.productInventory,
      year: product.year || '',
      discountType: DISCOUNT_DICTIONARY[product.discountType] || 'NONE',
      discount: product.amount || 0,
      quantity: product.bulkQuantity || product.quantity || 1,
      unitCost: product.cost,
      total:
        (product.cost - calculateDiscount(product.cost, product.discountType, product.amount)) *
        (product.bulkQuantity || product.quantity || 1),
    }));

    const accessoriesSale = orderedAccessories.map((accessory) => ({
      accessoryInventory: accessory.accessoryInventory,
      discountType: DISCOUNT_DICTIONARY[accessory.discountType] || 'NONE',
      discount: accessory.amount || 0,
      quantity: accessory.bulkQuantity || accessory.quantity || 1,
      unitCost: accessory.cost,
      total:
        (accessory.cost - calculateDiscount(accessory.cost, accessory.discountType, accessory.amount)) *
        (accessory.bulkQuantity || accessory.quantity || 1),
    }));

    const paymentMethod = {
      paymentMethodType: selectedPayment,
      storeCreditTotal: mixedPayment.credits,
      secondMethodPayment: mixedPayment.secondMethod, //secondMethodPayment || null, // Valores: CREDIT_CARD, CASH, si aplica
      totalSecondMethodPayment: mixedPayment.totalSecondPaymenth, //secondPaymentAmount || 0,
    };

    orderRequest = {
      status: 'INPROCESS',
      warehouse: { _id: warehouseSelected._id }, //{ _id: 'selectedWarehouseId' || '' },
      customerType: customerType,
      productsSale,
      accessoriesSale,
      paymentMethod: selectedPayment === 'MIXED' ? paymentMethod : { paymentMethodType: selectedPayment, storeCreditTotal: total },
      subTotal: subtotal,
      discount: discount,
      total: total,
    };

    if (customerType === 'RETAIL_USER') {
      orderRequest.retailUser = { _id: selectedCustomer._id };
    } else {
      orderRequest.wholesalerUser = { _id: selectedCustomer._id };
    }

    return orderRequest;
  };

  const handleViewTicket = () => {
    cleanView();
    setSuccessModal(false);
  };

  const cleanView = () => {
    setProducts([]);
    setSelectedCustomer('');
    setSelectedPayment(null);
    setWarehouseSelected(null);
    setMixedPayment({ credits: 0, secondMethod: null, totalSecondPaymenth: 0 });
  };

  const calculateDiscount = (cost, discountType, discountAmount) => {
    if (discountType === '%') {
      return (cost * parseFloat(discountAmount)) / 100;
    } else if (discountType === '$') {
      return parseFloat(discountAmount);
    }
    return 0;
  };

  const handleConfirmSale = async () => {
    if (!selectedCustomer) {
      toast.error('Please select a customer.', alertOptions);
      return;
    }

    if (!products.length) {
      toast.error('Please select at least one product.', alertOptions);
      return;
    }

    if (!selectedPayment) {
      toast.error('Please select a payment method.', alertOptions);
      return;
    }

    const orderData = createOrderRequest();

    try {
      setConfirmModal(false);
      setLoadingModal(true);

      const response = await createSale(orderData);

      if (response.status === 200) {
        setLoadingModal(false);
        setSuccessModal(true);
      } else {
        throw new Error('Payment confirmation failed.');
      }
    } catch (e) {
      setLoadingModal(false);
      setSuccessModal(false);
      const errorMessage = e.response?.data?.message || e.message || 'An unexpected error occurred.';
      toast.error(errorMessage, alertOptions);
    }
  };

  const handleBulkQuantityChange = (event, id) => {
    const newQuantity = parseInt(event.target.value, 10) || 0;

    setProducts((prev) =>
      prev.map((product) =>
        (product.id === id || product._id === id)
          ? { ...product, bulkQuantity: newQuantity }
          : product
      )
    );
  };

  const addUser = async (userObject, type) => {
    try {
      const saveUser = type === "wsu" ? saveWholesalerUser : saveRetailUser;
      const res = await saveUser(userObject);
      if (res.status === 200) {
        setShowNewUserModal(false);
        toast.success("User added successfully", alertOptions);
      } else {
        toast.warning(res.response.data.message, alertOptions);
      }
    } catch (error) {
      toast.warning(error.response.data.message, alertOptions);
    }
  };

  const addToCart = (isBulkOrder, selectedItem, quantity, isAccessory) => {
    const qty = isBulkOrder ? parseInt(quantity) : 1;

    if (selectedItem) {
      setProducts((prev) => [
        ...prev,
        {
          ...selectedItem,
          id: isBulkOrder ? `${selectedItem._id}-${Date.now()}-${bulkIdCounter++}` : undefined, // ID único para productos bulk
          quantity: 1,
          bulkQuantity: isBulkOrder ? qty : undefined,
          isAccessory: isAccessory || false,
        },
      ]);
    }

    setShowProductModal(false);
    setShowAccessoryModal(false);
  };

  const getDataWarehouse = () => {
    getWarehouses().then(res => {
      if (res.status === 200 && res.data.length > 0) {
        const warehousesCurrent = res.data.filter(element => !element.deleted);
        setWarehousesOptions(warehousesCurrent);
        setIsLoading(false);
      }
    });
  };

  const handlePaymentMethod = (method) => {
    if (method === 'MIXED') {
      setMixedPaymentOpen(true);
    }

    setSelectedPayment(method);
  };

  const handleDiscountTypeChange = (id, value) => {
    setProducts((prev) =>
      prev.map((product) => {
        if (product.id === id || product._id === id) {
          return { ...product, discountType: value };
        }
        return product;
      })
    );
  };

  const handleAmountChange = (id, value) => {
    setProducts(products.map(product =>
      product._id === id ? { ...product, amount: value } : product
    ));
  };

  const handleRemoveProduct = (id, isBulkOrder) => {
    setProducts((prev) =>
      prev.filter((product) =>
        isBulkOrder ? product.id !== id : product._id !== id
      )
    );
  };

  const handleAllDiscountType = (type) => {
    setProducts(products.map(product => ({ ...product, discountType: type })));
  };

  const handleAcceptMixed = (credits, selectedMethod) => {
    const { subTotal, discount, total } = calculateTotal();

    const totalSecondPaymenth = total - credits;
    setMixedPayment({ credits, secondMethod: selectedMethod, totalSecondPaymenth });
    setMixedPaymentOpen(false);
  };

  const calculateTotal = () => {
    let subtotal = 0;
    let discount = 0;

    products.forEach((product) => {
      const cost = product.cost || 0;
      const bulkQuantity = product.bulkQuantity || 1;
      const amount = parseFloat(product.amount) || 0;

      const totalCost = cost * bulkQuantity;
      subtotal += totalCost;

      if (product.discountType === '%') {
        discount += (totalCost * amount) / 100;
      } else if (product.discountType === '$') {
        discount += amount;
      }
    });

    const total = subtotal - discount;
    return { subtotal, discount, total };
  };


  const { subtotal, discount, total } = calculateTotal();

  useEffect(() => {
    getDataWarehouse();
  }, []);

  return (
    <div className="min-h-screen bg-white p-4 md:p-8">
      <h1 className="mb-8 text-2xl text-gray-600">Sales</h1>

      {
        isLoading ? (
          <div className="flex justify-center items-center h-32">
            <div className="loader border-t-4 border-blue-500 rounded-full w-12 h-12 animate-spin"></div>
            <span className="ml-4">Loading...</span>
          </div>
        )
          : (
            <div className="grid grid-cols-1 gap-8 lg:grid-cols-[1fr,400px]">
              <div className="space-y-8">
                <section>
                  <div className="relative w-[280px] space-y-1">
                    <button
                      onClick={() => setOpenWarehouses(!openWarehouses)}
                      className="flex w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background hover:bg-accent hover:text-accent-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                    >
                      <div className="flex items-center gap-2">
                        <RiBuilding2Fill className="h-4 w-4 text-muted-foreground" />
                        <span>{warehouseSelected && warehouseSelected.name}</span>
                      </div>
                      <BiChevronDown className={`h-4 w-4 transition-transform ${openWarehouses ? 'rotate-180' : ''}`} />
                    </button>

                    {openWarehouses && (
                      <div className="absolute z-50 mt-1 w-full rounded-md border bg-white bg-popover p-1 text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95">
                        <div className="px-2 py-1.5 text-sm font-medium">Available Warehouses</div>
                        {warehousesOptions.map((warehouse) => (
                          <button
                            key={warehouse.id}
                            onClick={() => {
                              setWarehouseSelected(warehouse);
                              setOpenWarehouses(false);
                            }}
                            className={`relative w-full select-none rounded-sm px-2 py-1.5 text-sm outline-none hover:bg-slate-100 hover:text-accent-foreground ${
                              warehouseSelected && warehouseSelected._id === warehouse._id ? 'bg-emerald-50' : ''
                            }`}
                          >
                            {warehouse.name}
                          </button>
                        ))}
                      </div>
                    )}

                    <p className="text-xs text-muted-foreground">
                      Select the warehouse location for the sale
                    </p>
                  </div>
                </section>
                <section>
                  <h2 className="mb-4 text-lg text-gray-600">Customer information</h2>
                  <div className="mb-4 flex gap-4">
                    <button
                      onClick={() => setShowSelectModal(true)}
                      className="rounded-md bg-emerald-500 px-4 py-2 text-white hover:bg-emerald-600"
                    >
                      Select existing customer
                    </button>
                    <button
                      onClick={() => setShowNewUserModal(true)}
                      className="rounded-md bg-emerald-500 px-4 py-2 text-white hover:bg-emerald-600"
                    >
                      Add new customer
                    </button>
                  </div>
                  <input
                    type="text"
                    value={`${selectedCustomer.names ? selectedCustomer.names : ''} ${selectedCustomer.lastNames ? selectedCustomer.lastNames : ''}`}
                    placeholder="Customer's name"
                    className="w-full rounded-md border border-gray-300 px-4 py-2"
                    readOnly
                  />
                </section>

                <section>
                  <h2 className="mb-4 text-lg text-gray-600">Product selection</h2>
                  <div className="grid gap-4 md:grid-cols-2">
                    <button
                      onClick={() => setShowProductModal(true)}
                      className="rounded-md bg-emerald-500 px-4 py-2 text-white hover:bg-emerald-600"
                    >
                      Select your product
                    </button>
                    <button
                      onClick={() => setShowAccessoryModal(true)}
                      className="rounded-md bg-emerald-500 px-4 py-2 text-white hover:bg-emerald-600"
                    >
                      Select your accessory
                    </button>
                  </div>
                </section>

                <section>
                  <h2 className="mb-4 text-lg text-gray-600">Shopping list</h2>
                  <div className="overflow-x-auto">
                    <table className="w-full min-w-[800px]">
                      <thead>
                        <tr className="border-b text-left">
                          <th className="pb-2">Products</th>
                          <th className="pb-2">Features</th>
                          <th className="pb-2">Unit cost</th>
                          <th className="pb-2">Discount type</th>
                          <th className="pb-2">Discount Amount</th>
                          <th className="pb-2">Quantity</th>
                          <th className="pb-2">Action</th>
                        </tr>
                      </thead>

                      {!products.length && (
                        <div className='mt-[20px]'>
                          No products selected.
                        </div>
                      )}

                      <tbody>
                        {products.map((product) => (
                          <tr key={product.id || product._id} className="border-b">
                            <td className="py-2">
                              <span className="rounded-md bg-gray-100 px-2 py-1 text-sm">
                                {product.nags ? `${product.nags} -` : ''}{product.name}
                              </span>
                            </td>
                            <td className="py-2">
                              <span className="rounded-md bg-gray-100 px-2 py-1 text-sm">
                                {product.features ? product.features : 'No features'}
                              </span>
                            </td>
                            <td className="py-2">
                              <span className="rounded-md bg-gray-100 px-2 py-1 text-sm">
                                ${product.cost ? product.cost.toFixed(2) : ''}
                              </span>
                            </td>
                            <td className="py-2">
                              <select
                                className="rounded-md border py-1 text-sm focus:border-emerald-500 focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                value={product.discountType}
                                onChange={(e) =>
                                  handleDiscountTypeChange(product.id || product._id, e.target.value) // Pasa el id único o el _id
                                }
                              >
                                <option value="">Select discount type</option>
                                <option value="%">%</option>
                                <option value="$">$</option>
                              </select>
                            </td>
                            <td className="py-2">
                              <input
                                type="text"
                                value={product.amount}
                                onChange={(e) => handleAmountChange(product._id, e.target.value)}
                                disabled={!product.discountType}
                                className="w-20 rounded-md border px-2 py-1 text-sm focus:border-emerald-500 focus:outline-none focus:ring-2 focus:ring-emerald-500"
                              />
                            </td>
                            <td className="py-2">
                              {product.bulkQuantity !== undefined ? (
                                <input
                                  type="number"
                                  value={product.bulkQuantity || ''}
                                  className="rounded-md bg-gray-100 px-2 py-1 text-sm w-20"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (value === '' || parseFloat(value) > 1) {
                                      handleBulkQuantityChange(e, product.id || product._id);
                                    }
                                  }}
                                />
                              ) : (
                                <span className="text-gray-500">1</span>
                              )}
                            </td>
                            <td className="py-2">
                              <button
                                onClick={() => handleRemoveProduct(product.id || product._id, Boolean(product.bulkQuantity))}
                                className="rounded-md bg-red-400 px-3 py-1 text-sm text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                              >
                                Remove
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="mt-4 flex flex-wrap gap-2">
                    <button
                      onClick={() => handleAllDiscountType('%')}
                      className="rounded-md bg-emerald-500 px-4 py-2 text-sm text-white hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                    >
                      All discount type %
                    </button>
                    <button
                      onClick={() => handleAllDiscountType('$')}
                      className="rounded-md bg-emerald-500 px-4 py-2 text-sm text-white hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                    >
                      All discount type $
                    </button>
                  </div>
                </section>

                <section>
                  <h2 className="mb-4 text-lg text-gray-600">Payment method</h2>
                  <div className="flex flex-wrap gap-4">
                    {PAYMENT_METHODS.map((method) => (
                      <button
                        key={method.value}
                        onClick={() => handlePaymentMethod(method.value)}
                        className={`rounded-full border px-4 py-1 focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                          selectedPayment === method.value
                            ? 'border-emerald-500 bg-emerald-50 text-emerald-500 focus:ring-emerald-500'
                            : 'border-gray-300 text-gray-600 focus:ring-gray-500'
                        }`}
                      >
                        {method.label}
                      </button>
                    ))}
                  </div>
                  <p className="mt-2 text-xs text-gray-500">
                    *** The payment by card will be carried out through a bank terminal
                  </p>
                </section>
              </div>

              <div className="rounded-lg border border-gray-200 p-6">
                <div className="space-y-4">
                  <div>
                    <label htmlFor="workshop-name" className="text-m font-bold text-gray-600">
                      {`${selectedCustomer.names ? selectedCustomer.names : ''} ${selectedCustomer.lastNames ? selectedCustomer.lastNames : ''}`}
                    </label>
                  </div>

                  <div>
                    <label htmlFor="available-credits" className="text-sm font-medium text-gray-600">Available credits</label>
                    <div className="mt-1 flex items-center">
                      <span id="available-credits" className="text-lg font-medium">
                        {selectedCustomer.availableCredit ? selectedCustomer.availableCredit : 0}
                      </span>
                    </div>
                  </div>

                  <div>
                    <div className="mb-2 grid grid-cols-2 gap-4">
                      <div>
                        <label className="text-sm font-medium text-gray-600">Items:</label>
                      </div>
                      <div>
                        <label className="text-sm font-medium text-gray-600">Quantity:</label>
                      </div>
                    </div>
                    <div className="space-y-1">
                      {products.map((product, index) => (
                        <div key={index} className="grid grid-cols-2 gap-4">
                          <span className="text-sm text-gray-600">{product.name}</span>
                          <span className="text-sm">{product.bulkQuantity || 1}</span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="space-y-2">
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-600">Subtotal:</span>
                      <span className="text-sm">${subtotal.toFixed(2)}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-600">Discount:</span>
                      <span className="text-sm">${discount.toFixed(2)}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-sm font-medium text-gray-600">Total:</span>
                      <span className="text-sm font-medium">${total.toFixed(2)}</span>
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <button
                      onClick={() => setConfirmModal(true)}
                      className="flex-1 rounded-md bg-emerald-500 px-4 py-2 text-white hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2">
                        Proceed to payment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
      }

      {showAccessoryModal && (
        <SelectAccesory
          onClose={() => setShowAccessoryModal(false)}
          addToCart={addToCart}
        />
      )}

      { showSelectModal && (
        <SelectClient
          onClose={() => setShowSelectModal(false)}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}

      {showNewUserModal && (
        <FormUser
          enableAddRetail={permits['USERS']['ADD_RETAILER']}
          enableAddWholesaler={permits['USERS']['ADD_WOLESALER']}
          userType="retail"
          userData={null}
          onSave={addUser}
          onClose={() => setShowNewUserModal(false)}
        />
      )}

      {showProductModal && (
        <SelectProduct
          onClose={() => setShowProductModal(false)}
          addToCart={addToCart}
        />
      )}

      { confirmModal && (
        <Modal
          title={`¿Are you sure you want to confirm the sale?`}
          onClose={() => {
            setConfirmModal(false);
          }}
          onResponse={handleConfirmSale}
        />
      )}

      { laodingModal && (
        <ModalNotification
          title='Please wait for payment confirmation'
          isClosable={false}
        />
      )}

      { successModal && (
        <ModalNotification
          title='Payment has been confirmed, you can close the window.'
          isClosable
          onClose={() => {
            setSuccessModal(false);
            cleanView();
          }}
        >
          <div className='flex'>
            <button
              className="flex-1 rounded-md bg-emerald-500 px-4 py-2 text-white hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
              onClick={handleViewTicket}
            >
              View Ticket
            </button>
          </div>
        </ModalNotification>
      )}

      <MixedPaymentModal
        isOpen={mixedPaymentOpen}
        onClose={() => setMixedPaymentOpen(false)}
        onAccept={handleAcceptMixed}
      />
    </div>
  );
};

const ModalNotification = ({ title, children, isClosable = true, onClose }) => {
  return (
    <>
      <div className="block">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="w-[320px] md:w-[500px]">
            <div className="p-2 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
              <div className="w-full flex items-center justify-end rounded text-center">
                <div className="w-[5%]">
                  {isClosable && (
                    <button
                      className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                      onClick={() => onClose(false)}
                    >
                      <IoMdCloseCircle />
                    </button>
                  )}
                </div>
              </div>

              <div className="mt-4 px-4 py-2">
                <h2 className="text-xl font-semibold text-center mb-4">{title}</h2>
                <div className="modal-content">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default Sales;