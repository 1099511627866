import ActionsColorIcon from "../assets/images/group_icon/actions_color.png";
import ActionsWOColorIcon from "../assets/images/group_icon/actions.png";
import AntennaColorIcon from "../assets/images/group_icon/antenna_color.png";
import AntennaWOColorIcon from "../assets/images/group_icon/antena.png";
import AvailableColorIcon from "../assets/images/group_icon/available_color.png";
import AvailableWOColorIcon from "../assets/images/group_icon/available.png";
import CalibrationColorIcon from "../assets/images/group_icon/calibration_color.png";
import CalibrationWOColorIcon from "../assets/images/group_icon/calibration.png";
import CameraColorIcon from "../assets/images/group_icon/camera_color.png";
import CameraWOColorIcon from "../assets/images/group_icon/camera.png";
import GlassColorIcon from "../assets/images/group_icon/glass_color.png";
import GlassWOColorIcon from "../assets/images/group_icon/glass.png";
import OtherColorIcon from "../assets/images/group_icon/other_color.png";
import OtherWOColorIcon from "../assets/images/group_icon/other.png";
import ProjectionColorIcon from "../assets/images/group_icon/projection_color.png";
import ProjectionWOColorIcon from "../assets/images/group_icon/projection.png";
import SensorColorIcon from "../assets/images/group_icon/sensor_color.png";
import SensorWOColorIcon from "../assets/images/group_icon/sensor.png";

const monthsEnglish = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const sectionsModel = [
  {
    name: "ADMINISTRATORS",
    value: false,
    options: [
      { name: "FILTER", value: false },
      { name: "ADD", value: false },
      { name: "ROLE", value: false },
      { name: "EDIT", value: false },
      { name: "DELETE", value: false }
    ]
  },
  {
    name: "USERS",
    value: false,
    options: [
      { name: "FILTER", value: false },
      { name: "ADD_WOLESALER", value: false },
      { name: "ADD_RETAILER", value: false },
      { name: "PENDING_WOLESALER", value: false },
      { name: "APPROVE_DISPPROVE", value: false }
    ]
  },
  {
    name: "SUPPLIERS",
    value: false,
    options: [
      { name: "FILTER", value: false },
      { name: "ADD", value: false },
      { name: "EDIT", value: false },
      { name: "DELETE", value: false }
    ]
  },
  {
    name: "PRODUCTS",
    value: false,
    options: [
      { name: "FILTER", value: false },
      { name: "ADD", value: false },
      { name: "HISTORY", value: false },
      { name: "EDIT", value: false },
      { name: "DEACTIVATE", value: false }
    ]
  },
  {
    name: "INVENTORY",
    value: false,
    options: [
      { name: "FILTER", value: false },
      { name: "ADD", value: false },
      { name: "RECORD", value: false },
      { name: "EDIT", value: false }
    ]
  },
  {
    name: "WAREHOUSE",
    value: false,
    options: [
      { name: "FILTER", value: false },
      { name: "ADD", value: false },
      { name: "EDIT", value: false },
      { name: "REMOVE", value: false },
      { name: "CREATE_RACK", value: false },
      { name: "SEE_ALL", value: false }
    ]
  },
  {
    name: "ORDERS",
    value: false,
    options: [
      { name: "FILTER", value: false },
      { name: "IN_PROCESS", value: false },
      { name: "ACTIONS", value: false }
    ]
  },
  {
    name: "REPORTS",
    value: false,
    options: [
      { name: "FILTER", value: false },
      { name: "DOWNLOAD", value: false }
    ]
  }
];

const newSectionsModel = [
  {
    ADMINISTRATORS: ["FILTER", "ADD", "ROLE", "EDIT", "DELETE"],
    USERS: ["FILTER", "ADD_WOLESALER", "ADD_RETAILER", "PENDING_WOLESALER", "APPROVE_DISPPROVE"],
    SUPPLIERS: ["FILTER", "ADD", "EDIT", "DELETE"],
    PRODUCTS: ["FILTER", "ADD", "HISTORY", "EDIT", "DEACTIVATE"],
    INVENTORY: ["FILTER", "ADD", "RECORD", "EDIT"],
    WAREHOUSE: ["FILTER", "ADD", "EDIT", "REMOVE", "CREATE_RACK", "SEE_ALL"],
    ORDERS: ["FILTER", "IN_PROCESS", "ACTIONS"],
    REPORTS: ["FILTER", "DOWNLOAD"]
  }
  /* {
        ADMINISTRATORS: [],
        USERS: [],
        SUPPLIERS: [],
        PRODUCTS: [],
        INVENTORY: [],
        WAREHOUSE: [],
        ORDERS: [],
        REPORTS: []
    }*/
];

const sectionsModelFront = [
  {
    ADMINISTRATORS: {
      FILTER: false,
      ADD: false,
      ROLE: false,
      EDIT: false,
      DELETE: false
    },
    USERS: {
      FILTER: false,
      ADD_WOLESALER: false,
      ADD_RETAILER: false,
      PENDING_WOLESALER: false,
      APPROVE_DISPPROVE: false
    },
    SUPPLIERS: {
      FILTER: false,
      ADD: false,
      EDIT: false,
      DELETE: false
    },
    PRODUCTS: {
      FILTER: false,
      ADD: false,
      HISTORY: false,
      EDIT: false,
      DEACTIVATE: false
    },
    INVENTORY: {
      FILTER: false,
      ADD: false,
      RECORD: false,
      EDIT: false
    },
    WAREHOUSE: {
      FILTER: false,
      ADD: false,
      EDIT: false,
      REMOVE: false,
      CREATE_RACK: false,
      SEE_ALL: false
    },
    ORDERS: {
      FILTER: false,
      IN_PROCESS: false,
      ACTIONS: false
    },
    REPORTS: {
      FILTER: false,
      DOWNLOAD: false
    }
  }
];

const alertOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

const ORIGINAL_GLASS = [
  "Logo",
  "Ford Oval",
  "Car",
  "Carlite",
  "Oem",
  "Mopar",
  "Super duty",
  "Gorilla",
  "Grille",
  "Willys logo",
];

const removeAccents = (str) => {
  let chars = {
    "á":"a", "é":"e", "í":"i", "ó":"o", "ú":"u",
    "à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u", "ñ":"n",
    "Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U",
    "À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U", "Ñ":"N"
  };
  // str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  let expr = /[áàéèíìóòúùñ]/ig;
  let res = String(str).replace(expr, (e) => chars[e]);
  res = res.toLowerCase();
  return res;
};

function getSubfijoOrdinal(date) {
  let diaFecha = date.getDate();

  if (diaFecha % 10 === 1 && diaFecha !== 11) {
    return `${diaFecha}st`;
  } else if (diaFecha % 10 === 2 && diaFecha !== 12) {
    return `${diaFecha}nd`;
  } else if (diaFecha % 10 === 3 && diaFecha !== 13) {
    return `${diaFecha}rd`;
  }
  return `${diaFecha}th`;

}

function formatAMPM(date) {
  if (date === "") return "";
  let d = new Date(date);
  d.setMinutes(d.getMinutes() + d.getTimezoneOffset());

  let hours = d.getHours();
  let minutes = d.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0'+ minutes : minutes;

  let strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

function formatYear (date) {
  if (date === "") return "";

  let d = new Date(date);
  d.setMinutes(d.getMinutes() + d.getTimezoneOffset());

  return d.getFullYear();
}

function formatDate (date) {
  if (date === "") return "";

  let d = new Date(date);
  d.setMinutes(d.getMinutes() + d.getTimezoneOffset());

  let month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [day, month, year].join('-');
}

function formatReverseDate (date) {
  if (date === "") return "";

  let d = new Date(date);
  d.setMinutes(d.getMinutes() + d.getTimezoneOffset());

  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

export const parseTextTypeGlass = (typeGlass) => {
  let changedText = typeGlass;
  switch (typeGlass) {
  case 'Back Window':
    changedText = 'Backglass';
    break;
  case 'Quarter / Left':
    changedText = 'Rear / Quarter / Left';
    break;
  case 'Quarter / Right':
    changedText = 'Rear / Quarter / Right';
    break;
  }
  return changedText;
};

export const parseTextTitleNotifications = (title) => {
  let changedTitle = title;
  switch (title) {
  case 'NEW_ORDER':
    changedTitle = 'New Order';
    break;
  case 'NEW_QUOTE':
    changedTitle = 'New Quote';
    break;
  }
  return changedTitle;
};

function compareDates (date1, date2) {
  let days1=Math.floor(date1.getTime()/(24*3600000));
  let days2=Math.floor(date2.getTime()/(24*3600000));

  if (days1>days2) {
    return 1;
  } else if (days1<days2) {
    return -1;
  }
  return 0;
}

function formatDateWordEng (date) {
  if (date === "") return "";

  const dateCurrent = new Date(date);
  return `${monthsEnglish[dateCurrent.getMonth()]} ${getSubfijoOrdinal(dateCurrent)}, ${dateCurrent.getFullYear()}`;
}

function formatDateEng (date) {
  if (date === "") return "";

  let d = new Date(date);
  d.setMinutes(d.getMinutes() + d.getTimezoneOffset());

  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [month, day, year].join('-');
}

function formatDateEngSlash (date) {
  if (date === "") return "";

  let d = new Date(date);
  d.setMinutes(d.getMinutes() + d.getTimezoneOffset());

  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [month, day, year].join('/');
}

function formatDateHourOrders (date) {
  if (date === "") return "";

  let d = new Date(date);
  // d.setMinutes(d.getMinutes() + d.getTimezoneOffset())

  let month = '' + d.getMonth();
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (day.length < 2) day = '0' + day;

  return `${monthsEnglish[month]}-${day}-${year} / ${formatAMPM(d)}`;
}

function formatHours (date) {
  if (date === "") return "";

  let d = new Date(date);
  d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
  let splitted = d.toLocaleString().split(' ')[1].split(':').splice(0, 2);
  let result = splitted.join(':');

  return result;
}

function formatCurrency (number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return formatter.format(Number(number));
}

function formatCommaSeparate (number) {
  const formatter = Number(number).toLocaleString("en-US");
  return formatter;
}

function sortStringOptions (array) {
  return array.sort(function(a, b) {
    if (a.label.toUpperCase() < b.label.toUpperCase()) return -1;
    if (a.label.toUpperCase() > b.label.toUpperCase()) return 1;
    return 0;
  });
}

function getArrayDaysOfMonth (month) {
  let date = new Date();
  let ultimoDia = new Date(date.getFullYear(), month+1, 0);

  let currentDay = 1;
  let array_number_days = [];
  for (let i = 0; i < 42; i++) {
    if (currentDay === 1) {
      if (i === new Date(`${date.getFullYear()}-${month+1}-${currentDay}`).getDay()) {
        array_number_days.push({ day: currentDay, status: false });
        currentDay++;
      } else {
        array_number_days.push({ day: 0, status: false });
      }
    } else {
      if (currentDay <= ultimoDia.getDate()) {
        array_number_days.push({ day: currentDay, status: false });
        currentDay++;
      } else {
        array_number_days.push({ day: 0, status: false });
      }
    }
  }
  return array_number_days;
}

const getHoursMinutesByDay = (day) => {
  let arrHours = [];
  for (let hi = 0; hi <= (24*day); hi++) {
    arrHours.push({
      label: String(hi).length === 1 ? `0${hi}` : `${hi}`,
      value: String(hi).length === 1 ? `0${hi}` : `${hi}`
    });
  }

  let arrMinutes = [];
  for (let mi = 0; mi < 100; mi++) {
    arrMinutes.push({
      label: String(mi).length === 1 ? `0${mi}` : `${mi}`,
      value: String(mi).length === 1 ? `0${mi}` : `${mi}`
    });
  }

  return [arrHours, arrMinutes];
};

function getNameOfDay (dateSelected) {
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const dateFormat = new Date(dateSelected);
  dateFormat.setMinutes(dateFormat.getMinutes() + dateFormat.getTimezoneOffset());
  return daysOfWeek[dateFormat.getDay()];
}

const getAllCategories = () => {
  return [
    { label: "Antenna", status: false, icont: AntennaColorIcon, iconf: AntennaWOColorIcon },
    { label: "Camera", status: false, icont: CameraColorIcon, iconf: CameraWOColorIcon },
    { label: "Calibration", status: false, icont: CalibrationColorIcon, iconf: CalibrationWOColorIcon },
    { label: "Glass", status: false, icont: GlassColorIcon, iconf: GlassWOColorIcon },
    { label: "Other", status: false, icont: OtherColorIcon, iconf: OtherWOColorIcon },
    { label: "Projection", status: false, icont: ProjectionColorIcon, iconf: ProjectionWOColorIcon },
    { label: "Sensor", status: false, icont: SensorColorIcon, iconf: SensorWOColorIcon }
  ];
};

const getAllFeatures = () => {
  return [
    // { label: "Acoustic interlayer", value: "Acoustic interlayer", category: "Glass", status: false },
    { label: "Adaptive cruise control", value: "Adaptive cruise control", category: "Calibration", status: false },
    { label: "AHBC Sensor", value: "Auto High Beam Control", category: "Calibration", status: false },
    { label: "Ant", value: "Antenna", category: "Antenna", status: false },
    { label: "Anti-theft", value: "Anti-theft", category: "Glass", status: false },
    { label: "Auto-Dim mirror", value: "Auto-Dim mirror", category: "Other", status: false },
    { label: "Colission warning system", value: "Colission warning system", category: "Sensor", status: false },
    { label: "Forward Collision Alert", value: "Forward Collision Alert", category: "Calibration", status: false },
    { label: "Condensation Sensor", value: "Condensation Sensor", category: "Sensor", status: false },
    { label: "Electrochromic Mirror", value: "Electrochromic Mirror", category: "Other", status: false },
    { label: "Heated wiper park", value: "Heated wiper park", category: "Calibration", status: false },
    { label: "High Beam Sensor", value: "High Beam Sensor", category: "Sensor", status: false },
    { label: "High Beam Assist", value: "High Beam Assist", category: "Sensor", status: false },
    { label: "Htd", value: "Htd", category: "Glass", status: false },
    { label: "HUD (Head up display)", value: "HUD (Head up display)", category: "Projection", status: false },
    { label: "Infrarred Interlayer", value: "Infrarred Interlayer", category: "Glass", status: false },
    { label: "Lane Departure Warning Systems", value: "Lane Departure Warning", category: "Calibration", status: false },
    { label: "Ldws", value: "Ldws", category: "Camera", status: false },
    { label: "Laser deletion Area", value: "Laser deletion Area", category: "Glass", status: false },
    { label: "Light Sensor", value: "Light Sensor", category: "Sensor", status: false },
    { label: "Night Vision", value: "Night Vision", category: "Glass", status: false },
    { label: "Rain Sensor", value: "Rain Sensor", category: "Sensor", status: false },
    { label: "Rain/Light Sensor", value: "Rain/Light Sensor", category: "Sensor", status: false },
    { label: "Smart beam Hedalamp system", value: "Smart beam Hedalamp system", category: "Calibration", status: false },
    { label: "3rdVisor Frit", value: "3rd Visor Frit", category: "Glass", status: false },
    { label: "Solar", value: "Solar", category: "Glass", status: false }
  ];
};

const getAllFeaturesWC = () => {
  return [
    // { label: "Acoustic interlayer", value: "Acoustic interlayer", category: "Glass", status: false },
    { label: "Adaptive cruise control", value: "Adaptive cruise control", category: "Calibration", status: false },
    { label: "AHBC Sensor", value: "Auto High Beam Control", category: "Calibration", status: false },
    { label: "Auto High Beam", value: "Auto High Beam", category: "Calibration", status: false },
    { label: "Ant", value: "Antenna", category: "Antenna", status: false },
    { label: "Anti-theft", value: "Anti-theft", category: "Glass", status: false },
    { label: "Auto-Dim mirror", value: "Auto-Dim mirror", category: "Other", status: false },
    { label: "Colission warning system", value: "Colission warning system", category: "Sensor", status: false },
    { label: "w/Forward Collision Alert", value: "w/Forward Collision Alert", category: "Calibration", status: false },
    { label: "Forward Collision Alert", value: "Forward Collision Alert", category: "Calibration", status: false },
    { label: "Forward Collision Alert W/City Crash Mitigation", value: "Forward Collision Alert W/City Crash Mitigation", category: "Calibration", status: false },
    { label: "Forward Collision Alert w/Brake Assist", value: "Forward Collision Alert w/Brake Assist", category: "Calibration", status: false },
    { label: "Forward Collision Alert W/Emergency Braking", value: "Forward Collision Alert W/Emergency Braking", category: "Calibration", status: false },
    { label: "Forward Collision Alert W/Pedestian Detection", value: "Forward Collision Alert W/Pedestian Detection", category: "Calibration", status: false },
    { label: "Forward Collision Alert W/Pedestrian Detection", value: "Forward Collision Alert W/Pedestrian Detection", category: "Calibration", status: false },
    { label: "Condensation Sensor", value: "Condensation Sensor", category: "Sensor", status: false },
    { label: "Electrochromic Mirror", value: "Electrochromic Mirror", category: "Other", status: false },
    { label: "Heated wiper park", value: "Heated wiper park", category: "Calibration", status: false },
    { label: "High Beam Sensor", value: "High Beam Sensor", category: "Sensor", status: false },
    { label: "High Beam Assist", value: "High Beam Assist", category: "Sensor", status: false },
    { label: "Htd", value: "Htd", category: "Glass", status: false },
    { label: "W/HUD", value: "W/HUD", category: "Calibration", status: false },
    { label: "Augmented Reality HUD", value: "Augmented Reality HUD", category: "Calibration", status: false },
    { label: "HUD W/Augmented Reality Navigation", value: "HUD W/Augmented Reality Navigation", category: "Calibration", status: false },
    { label: "Infrarred Interlayer", value: "Infrarred Interlayer", category: "Glass", status: false },
    { label: "Lane Departure Warning Systems", value: "Lane Departure Warning", category: "Calibration", status: false },
    { label: "LDWS", value: "LDWS", category: "Calibration", status: false },
    { label: "w/LDWS", value: "w/LDWS", category: "Calibration", status: false },
    { label: "Laser deletion Area", value: "Laser deletion Area", category: "Glass", status: false },
    { label: "Light Sensor", value: "Light Sensor", category: "Sensor", status: false },
    { label: "Night Vision", value: "Night Vision", category: "Glass", status: false },
    { label: "Rain Sensor", value: "Rain Sensor", category: "Sensor", status: false },
    { label: "Rain/Light Sensor", value: "Rain/Light Sensor", category: "Sensor", status: false },
    { label: "Smart beam Hedalamp system", value: "Smart beam Hedalamp system", category: "Calibration", status: false },
    { label: "W/Smart Entry", value: "W/Smart Entry", category: "Calibration", status: false },
    { label: "W/Smart City Brake System", value: "W/Smart City Brake System", category: "Calibration", status: false },
    { label: "Smart Cruise Control", value: "Smart Cruise Control", category: "Calibration", status: false },
    { label: "Smart City Braking", value: "Smart City Braking", category: "Calibration", status: false },
    { label: "W/Intelligent HiBeams", value: "W/Intelligent HiBeams", category: "Calibration", status: false },
    { label: "Intelligent HiBeams", value: "Intelligent HiBeams", category: "Calibration", status: false },
    { label: "Auto Head Beam", value: "Auto Head Beam", category: "Calibration", status: false },
    { label: "W/HID Headlamps", value: "W/HID Headlamps", category: "Calibration", status: false },
    { label: "Headlamp Control", value: "Headlamp Control", category: "Calibration", status: false },
    { label: "3rdVisor Frit", value: "3rd Visor Frit", category: "Glass", status: false },
    { label: "Solar", value: "Solar", category: "Glass", status: false },
    { label: "Lane Keep Assist", value: "Lane Keep Assist", category: "Calibration", status: false },
    { label: "w/Lane Keep Assist", value: "w/Lane Keep Assist", category: "Calibration", status: false },
    { label: "LKA", value: "LKA", category: "Calibration", status: false },
    { label: "Pre-collision System w/Pedistran detection", value: "Pre-collision System w/Pedistran detection", category: "Calibration", status: false },
    { label: "W/Pre-Collision System", value: "W/Pre-Collision System", category: "Calibration", status: false },
    { label: "W/Pre-Collision Braking", value: "W/Pre-Collision Braking", category: "Calibration", status: false },
    { label: "Pre-Collision System", value: "Pre-Collision System", category: "Calibration", status: false },
    { label: "Pre-Collision Assist", value: "Pre-Collision Assist", category: "Calibration", status: false },
    { label: "Low Speed Pre-Collision System", value: "Low Speed Pre-Collision System", category: "Calibration", status: false },
    { label: "Pre-Collision Assist w/Auto Emergency Braking", value: "Pre-Collision Assist w/Auto Emergency Braking", category: "Calibration", status: false },
    { label: "Pre-Collision", value: "Pre-Collision", category: "Calibration", status: false },
    { label: "Pre-Collision System w/Pedestrian Detection", value: "Pre-Collision System w/Pedestrian Detection", category: "Calibration", status: false },
    { label: "Pre-Collision Throttle Management", value: "Pre-Collision Throttle Management", category: "Calibration", status: false },
    { label: "Pre-Collision System with Pedestrian Detection", value: "Pre-Collision System with Pedestrian Detection", category: "Calibration", status: false },
    { label: "Road sign Assist", value: "Road sign Assist", category: "Calibration", status: false },
    { label: "Dynamic Radar cruise control", value: "Dynamic Radar cruise control", category: "Calibration", status: false },
    { label: "Road departure Mitigation system", value: "Road departure Mitigation system", category: "Calibration", status: false },
    { label: "Road Departure Mitigation", value: "Road Departure Mitigation", category: "Calibration", status: false },
    { label: "Colision Mitigation Braking", value: "Colision Mitigation Braking", category: "Calibration", status: false },
    { label: "Collision Mitigation Braking w/Pedestrian Detectio", value: "Collision Mitigation Braking w/Pedestrian Detectio", category: "Calibration", status: false },
    { label: "Collision Mitigation Braking System", value: "Collision Mitigation Braking System", category: "Calibration", status: false },
    { label: "Collision Mitigation Braking", value: "Collision Mitigation Braking", category: "Calibration", status: false },
    { label: "Traffic Jam Asist", value: "Traffic Jam Asist", category: "Calibration", status: false },
    { label: "Extended Traffic Jam Assist", value: "Extended Traffic Jam Assist", category: "Calibration", status: false },
    { label: "Adaptive Cruise Control w/Traffic Jam Assist", value: "Adaptive Cruise Control w/Traffic Jam Assist", category: "Calibration", status: false },
    { label: "Extended Traffic Jam Assistant", value: "Extended Traffic Jam Assistant", category: "Calibration", status: false },
    { label: "W/Adaptive Cruise Control", value: "W/Adaptive Cruise Control", category: "Calibration", status: false },
    { label: "Adaptive Cruise Control W/Lane Changing Assist", value: "Adaptive Cruise Control W/Lane Changing Assist", category: "Calibration", status: false },
    { label: "Emergency Brankin w/Pediastron detector", value: "Emergency Brankin w/Pediastron detector", category: "Calibration", status: false },
    { label: "Forward Collision w/city Colission Mitigation", value: "Forward Collision w/city Colission Mitigation", category: "Calibration", status: false },
    { label: "Active Cruise Control with stop and go", value: "Active Cruise Control with stop and go", category: "Calibration", status: false },
    { label: "Intelligent Collision Braking", value: "Intelligent Collision Braking", category: "Calibration", status: false },
    { label: "Auto Lamp Control", value: "Auto Lamp Control", category: "Calibration", status: false },
    { label: "Lane Chance Assist", value: "Lane Chance Assist", category: "Calibration", status: false },
    { label: "Lane Tracing Assist", value: "Lane Tracing Assist", category: "Calibration", status: false },
    { label: "Lane Keep Assistant", value: "Lane Keep Assistant", category: "Calibration", status: false },
    { label: "Forward collision warning", value: "Forward collision warning", category: "Calibration", status: false },
    { label: "Evasion Aid Assist", value: "Evasion Aid Assist", category: "Calibration", status: false },
    { label: "Auto Higth Bean", value: "Auto Higth Bean", category: "Calibration", status: false },
    { label: "Night Vision", value: "Night Vision", category: "Calibration", status: false },
    { label: "City Crash Mitigation", value: "City Crash Mitigation", category: "Calibration", status: false },
    { label: "Line Tracing Assist", value: "Line Tracing Assist", category: "Calibration", status: false },
    { label: "Traffic Sign Recognition", value: "Traffic Sign Recognition", category: "Calibration", status: false },
    { label: "W/Traffic Sign Recognition", value: "W/Traffic Sign Recognition", category: "Calibration", status: false },
    { label: "Lane Departure Warning Systems", value: "Lane Departure Warning Systems", category: "Calibration", status: false },
    { label: "W/Lane Departure Warning", value: "W/Lane Departure Warning", category: "Calibration", status: false },
    { label: "W/O Lane Departure Warning System", value: "W/O Lane Departure Warning System", category: "Calibration", status: false },
    { label: "W/Lane Departure Prevention", value: "W/Lane Departure Prevention", category: "Calibration", status: false },
    { label: "W/Lane Departure Camera", value: "W/Lane Departure Camera", category: "Calibration", status: false },
    { label: "Traffic Jam Assist", value: "Traffic Jam Assist", category: "Calibration", status: false },
    { label: "Humidity Sensor", value: "Humidity Sensor", category: "Calibration", status: false }
  ];
};

const checkString = (str) => {
  for (let i = 0; i < ORIGINAL_GLASS.length; i++) {
    if (str.includes(ORIGINAL_GLASS[i]) && !str.includes("Non")) {
      return true;
    }
  }
  return false;
};

const isOriginalGlass = (item) => {
  const { ad_info_a, ad_info_b } = item.item;
  if (checkString(ad_info_a) || checkString(ad_info_b)) {
    return true;
  }
  return false;

};

const formatModelYears = (yearString) => {
  const years = String(yearString).replace(/\s+/g, '').split(',').sort();
  return years.length > 1 ? `${years[0]} - ${years[years.length - 1]}` : yearString;
};

const toCamelCase = (str) => {
  const lowerCaseStr = str.toLowerCase();
  return lowerCaseStr.charAt(0).toUpperCase() + lowerCaseStr.slice(1);
};

export {
  sectionsModel,
  newSectionsModel,
  sectionsModelFront,
  alertOptions,
  removeAccents,
  formatAMPM,
  formatYear,
  formatDate,
  compareDates,
  formatReverseDate,
  formatDateWordEng,
  formatDateEngSlash,
  formatDateEng,
  formatDateHourOrders,
  formatHours,
  formatCurrency,
  formatCommaSeparate,
  sortStringOptions,
  getArrayDaysOfMonth,
  getHoursMinutesByDay,
  getNameOfDay,
  getAllCategories,
  getAllFeatures,
  getAllFeaturesWC,
  isOriginalGlass,
  formatModelYears,
  toCamelCase,
};