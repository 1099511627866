import { useEffect, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { IoMdCloseCircle } from "react-icons/io";
import { MdImageNotSupported } from "react-icons/md";

const Images = ({ images, onClose }) => {
    const [imagesData, setImagesData] = useState({
        current: 0,
        array: []
    });

    const changePage = (type) => {
        if (type === "back") {
            if (imagesData.current === 0) {
                setImagesData({...imagesData, current: imagesData.array.length-1});
            } else {
                setImagesData({...imagesData, current: imagesData.current-1});
            }
        } else {
            if (imagesData.current === imagesData.array.length-1) {
                setImagesData({...imagesData, current: 0});
            } else {
                setImagesData({...imagesData, current: imagesData.current+1});
            }
        }
    }

    useEffect(() => {
        if (images?.length > 0) {
            setImagesData({...imagesData, current: 0, array: images});
        }
    }, [images]);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[700px] lg:w-[950px]">
                        <div className="p-0 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="relative p-1 flex-auto">
                                <div className="w-full h-auto bg-black rounded-lg">
                                    <div className="w-full pt-3 pr-3">
                                        <button
                                            className="text-gray-300 float-right text-3xl leading-none outline-none focus:outline-none"
                                            onClick={() => onClose(false)}
                                        >
                                            <IoMdCloseCircle />
                                        </button>
                                    </div>
                                    { imagesData.array.length > 0 ?
                                        <div>
                                            <div className="p-3">
                                                <img
                                                    className="w-auto h-full md:h-[320px] bg-white m-auto rounded"
                                                    src={imagesData.array[imagesData.current]}
                                                />
                                            </div>
                                            <div className="flex justify-center items-center space-x-5">
                                                <div className="text-gray-300 p-3 cursor-pointer rounded-lg" onClick={() => changePage("back")}>
                                                    <span className="text-[40px]"><FiChevronLeft /></span>
                                                </div>
                                                <div className="text-gray-300 p-3 cursor-pointer rounded-lg" onClick={() => changePage("next")}>
                                                    <span className="text-[40px]"><FiChevronRight /></span>
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        <div className="md:flex md:justify-center md:items-center md:space-x-5 py-3">
                                            <div className="flex justify-center items-center pl-5">
                                                <span className="text-gray-300 text-center text-[100px]">
                                                    <MdImageNotSupported />
                                                </span>
                                            </div>
                                            <div className="text-gray-300 text-center text-2xl">
                                                <span>Item without image</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
        </>
    );
}

export default Images;