import { _delete, _get, _getParams, _post, _put } from "./http";

export const getAllInventoryAccesory = (page, limit, filterParams) => _getParams(`/accessoryInventory?page=${page}&limit=${limit}`, filterParams);

export const updateStatusInventoryAccessory = (id, inventoryObject) => _put(`/accessoryInventory/${id}/status`, inventoryObject);

export const createInventoryAccessory = (inventoryObject) => _post(`/accessoryInventory`, inventoryObject);

export const updateInvetoryAccesroyId = (id, inventoryObject) => _put(`/accessoryInventory/${id}`, inventoryObject);

export const getInventoryAccessoryById = (id) => _get(`/accessoryInventory/${id}`);

export const getMovementHistoryInventoryAccessory = (id) => _get(`/accessoryInventory/${id}/movementHistory`);
