import React, { useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../navbar/Navbar';
import Sidebar from '../sidebar/Sidebar';
import { PermitsContext } from "../../services/Permits";
import Modal from '../modal/Modal';
import Notifications from '../notifications/Notifications';

function Layout() {
  const { clearContext, logOutModal, setLogOutModal, showNotifications, setShowNotifications } = useContext(PermitsContext);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const handleModalResponse = (sts) => {
    if (sts) {
      clearContext();
      window.location.reload(true);
    }
  };

  return (
    <div>
      <div className="flex flex-row">
        <div className="w-full bg-no-repeat bg-cover fixed z-10">
          <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        </div>
      </div>
      <div className="flex h-screen overflow-hidden">
        <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        </div>
        <div className="w-full overflow-auto flex-grow mt-[100px]">
          <Outlet />
        </div>
      </div>

      <Notifications
        onClose={() => setShowNotifications(false)}
        show={showNotifications}
      />

      {logOutModal.status && (
        <Modal
          title={logOutModal.title}
          onClose={() =>
            setLogOutModal({ ...logOutModal, status: false, title: "", element: null })
          }
          onResponse={handleModalResponse}
        />
      )}
    </div>
  );
}

export default Layout;