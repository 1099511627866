import { IoMdImage } from "react-icons/io";
import { formatCurrency, formatDateEng } from "../../../utils/Utilities";
import { useNavigate } from "react-router-dom";

const arrStatus = {
    approved: { text: "Approved", bgColor: "bg-ag-order-delivered" },
    canceled: { text: "Canceled", bgColor: "bg-ag-order-canceled" },
    inprocess: { text: "In process", bgColor: "bg-zinc-300" }
}

const arrTypeQuote = {
    POWER_WINDOW_REPAIR: { text: "Power Window Repair" },
    WINDOW_TINT: { text: "Window Tint" }
}

const paymentQuote = {
    PAY_ON_MY_WON: "Pay on my own",
    CAR_INSURANCE: "Pay with Insurance"
}

const RowsQuotesPWRWT = ({ enableSearch, enableCancel, quote, onImage, onDetail, onCancel, onAddProduct }) => {
    const navigate = useNavigate();
    
    return (
        <tr>
            <td className="w-[25%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 pt-4 pb-2">
                <div className="flex space-x-3">
                    <div className="flex justify-center items-center">
                        <button type="button" onClick={() => {}}>
                            <span className="text-4xl">
                                <IoMdImage />
                            </span>
                        </button>
                    </div>
                    {
                        <div className="pb-1">
                            <div className="text-left">
                                <div className="text-ag-secondary-letter text-xs pb-1">
                                    <span>Quote by { arrTypeQuote[quote?.type].text }</span>
                                </div>
                                <div className="text-ag-secondary-letter text-xs">
                                    <span className="font-semibold">Year: </span>
                                    <span>{ quote?.year }</span>
                                </div>
                                <div className="text-ag-secondary-letter text-xs">
                                    <span className="font-semibold">Make: </span>
                                    <span>{ quote?.brand }</span>
                                </div>
                                <div className="text-ag-secondary-letter text-xs">
                                    <span className="font-semibold">Model: </span>
                                    <span>{ quote?.model }</span>
                                </div>
                                <div className="text-ag-secondary-letter text-xs">
                                    <span className="font-semibold">Style: </span>
                                    <span>{ quote?.style }</span>
                                </div>
                                {
                                    quote?.vin ?
                                        <div className="flex justify-start items-center space-x-1 text-ag-secondary-letter text-xs">
                                            <p className="font-semibold">VIN: </p>
                                            <p className="w-32 truncate" title={quote?.vin}>{ quote?.vin }</p>
                                        </div>
                                    : null
                                }
                            </div>
                        </div>
                    }
                </div>
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-28 m-auto">{quote?.year ? quote?.year : '-----'}</p>
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-28 m-auto">{ quote?.retailUser ? `${ quote?.retailUser?.names } ${quote?.retailUser?.lastNames ? quote?.retailUser?.lastNames : ""}` : 'External user' }</p>
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-28 m-auto">{ paymentQuote[quote?.paymentMethod] }</p>
            </td>
            <td className="w-[9%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-24 m-auto">{ arrTypeQuote[quote?.type].text }</p>
            </td>
            <td className="w-[9%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-24 m-auto">{ quote?.selectProduct ? quote?.selectProduct?.vendor : "-----" }</p>
            </td>
            <td className={`w-[9%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6 ${ arrStatus[quote?.status]?.bgColor }`}>
                { quote?.status ? arrStatus[quote?.status]?.text : "" }
            </td>
            <td className="w-[9%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                { quote?.selectProduct?.total ? formatCurrency(quote?.selectProduct?.total) : "-----" }
            </td>
            <td className="w-[9%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                { formatDateEng(quote?.createdAt) }
            </td>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                <div className="space-y-1">
                    <div>
                        <button
                            className={`w-full ${ quote?.status === "inprocess" ? "bg-ag-secondary" : "bg-green-700" } text-white p-2 rounded-full disabled:bg-zinc-200`}
                            type="button"
                            disabled={!enableSearch}
                            onClick={() => quote?.status === "inprocess" && quote?.price ? navigate(`/quotes/${quote?._id}/shipping`) : onDetail(quote)}
                        >
                            { quote?.status === "inprocess" ? quote?.price ? "Schedule" : "Search" : "View Details" }
                        </button>
                    </div>
                    {
                        quote?.status !== "canceled" && quote?.status !== "approved" ?
                            <div>
                                <button
                                    className="w-full bg-red-600 text-white p-2 rounded-full disabled:bg-zinc-200"
                                    type="button"
                                    disabled={!enableCancel}
                                    onClick={() => onCancel(quote)}
                                >
                                    Cancel
                                </button>
                            </div>
                        : null
                    }
                    {
                        quote?.selectProduct?.productAvailable === false && quote?.status === "approved" ?
                            <div>
                                <button
                                    className="w-full bg-blue-900 text-white p-2 rounded-full disabled:bg-zinc-200"
                                    type="button"
                                    disabled={!enableCancel}
                                    onClick={() => onAddProduct(quote)}
                                >
                                    Add products
                                </button>
                            </div>
                        : null
                    }
                </div>
            </td>
        </tr>
    );
}

export default RowsQuotesPWRWT;