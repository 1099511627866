import { _delete, _get, _post, _put } from "./http";

export const getInsurer = (id) => _get(`/insurers/${id}`);

export const getInsurers = () => _get(`/insurers`);

export const saveInsurer = (insurerObject) => _post(`/insurers`, insurerObject);

export const updateInsurer = (id, insurerObject) => _put(`/insurers/${id}`, insurerObject);

export const deleteInsurer = (id) => _delete(`/insurers/${id}`);
