import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { validateWarehouse } from "../../../utils/Validators";

const FormWarehouse = ({ enableEdit, enableRemove, warehouseInfo, onSave, onEdit, onDelete, onClose }) => {
  const [warehouse, setWarehouse] = useState({
    _id: "",
    name: "",
    phoneNumber: "",
    street: "",
    locality: "",
    state: "",
    postalCode: ""
  });

  const handleChange = (evt) => {
    if (evt.currentTarget.name === "name") {
      const pattern = new RegExp(/^[A-Za-z\s]+$/g);
      if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
        setWarehouse({...warehouse, [evt.currentTarget.name]: evt.currentTarget.value});
      }
    } else if (evt.currentTarget.name === "phoneNumber" || evt.currentTarget.name === "postalCode") {
      const pattern = new RegExp(/^[0-9\s]+$/g);
      if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
        setWarehouse({...warehouse, [evt.currentTarget.name]: evt.currentTarget.value});
      }
    } else {
      setWarehouse({...warehouse, [evt.currentTarget.name]: evt.currentTarget.value});
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (
      warehouse.name !== "" && warehouse.phoneNumber !== "" && warehouse.postalCode !== "" && warehouse.state !== "" &&
            warehouse.locality !== "" && warehouse.state !== ""
    ) {

      let warehouseModel = {
        name: warehouse.name,
        phoneNumber: warehouse.phoneNumber,
        address: {
          postalCode: warehouse.postalCode,
          state: warehouse.state,
          locality: warehouse.locality,
          street: warehouse.street,
        }
      };

      const result = validateWarehouse(warehouseModel);
      if (result.status) {
        if (warehouseInfo !== null) {
          warehouseModel._id = warehouse._id;
          onEdit(warehouseInfo._id, warehouseModel);
        } else {
          onSave(warehouseModel);
        }
      } else {
        toast.warning(result.msg, alertOptions);
      }
    } else {
      toast.info("Please fill in all the fields of the form", alertOptions);
    }
  };

  useEffect(() => {
    if (warehouseInfo !== null) {
      setWarehouse({...warehouse,
        _id: warehouseInfo._id,
        name: warehouseInfo.name,
        phoneNumber: warehouseInfo.phoneNumber,
        postalCode: warehouseInfo.address.postalCode,
        state: warehouseInfo.address.state,
        locality: warehouseInfo.address.locality,
        street: warehouseInfo.address.street
      });
    }
  }, []);

  return (
    <>
      <div className="block">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none mb-0 md:mb-5 xl:mb-0">
          <div className="w-[320px] md:w-[700px] lg:w-[800px] h-[90%] md:h-auto">
            <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
              <div className="w-full flex items-center justify-center rounded text-center">
                <div className="w-[95%]">
                  <span className="text-xl text-ag-secondary font-bold md:ml-[5%]">
                    { warehouseInfo !== null ? "Edit Warehouse" : "Create Warehouse" }
                  </span>
                </div>
                <div className="w-[5%]">
                  <button
                    className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                    onClick={() => onClose(false)}
                  >
                    <IoMdCloseCircle />
                  </button>
                </div>
              </div>
              <div className="relative px-2 md:px-5 pb-2 pt-5 flex-auto">
                <form onSubmit={handleSubmit}>
                  <div className="space-y-3">
                    <div className="grid gap-3 md:grid-cols-2">
                      <div className="w-full flex justify-center items-center">
                        <span
                          className="w-[38%] md:w-[33%] lg:w-[32%] bg-ag-secondary text-xs lg:text-sm text-white text-center border border-gray-500 py-3 rounded-l-xl"
                        >
                          Name
                        </span>
                        <input
                          className="w-[62%] md:w-[67%] lg:w-[68%] text-xs lg:text-sm text-ag-secondary-light border border-gray-500 p-3 rounded-r-xl disabled:bg-zinc-200"
                          type="text"
                          name="name"
                          onChange={handleChange}
                          disabled={!enableEdit}
                          value={warehouse.name}
                          maxLength={40}
                        />
                      </div>
                      <div className="w-full flex justify-center items-center">
                        <span
                          className="w-[38%] md:w-[33%] lg:w-[30%] bg-ag-secondary text-xs lg:text-sm text-white text-center border border-gray-500 py-3 rounded-l-xl"
                        >
                          Phone
                        </span>
                        <input
                          className="w-[62%] md:w-[67%] lg:w-[70%] text-xs lg:text-sm text-ag-secondary-light border border-gray-500 p-3 rounded-r-xl disabled:bg-zinc-200"
                          type="text"
                          name="phoneNumber"
                          onChange={handleChange}
                          disabled={!enableEdit}
                          value={warehouse.phoneNumber}
                          maxLength={10}
                        />
                      </div>
                      <div className="w-full flex justify-center items-center">
                        <span
                          className="w-[38%] md:w-[33%] lg:w-[30%] bg-ag-secondary text-xs lg:text-sm text-white text-center border border-gray-500 py-3 rounded-l-xl"
                        >
                          Street
                        </span>
                        <input
                          className="w-[62%] md:w-[67%] lg:w-[70%] text-ag-secondary-light text-xs lg:text-sm border border-gray-500 p-3 rounded-r-xl disabled:bg-zinc-200"
                          type="text"
                          name="street"
                          onChange={handleChange}
                          disabled={!enableEdit}
                          value={warehouse.street}
                          maxLength={40}
                        />
                      </div>
                      <div className="w-full flex justify-center items-center">
                        <span
                          className="w-[38%] md:w-[33%] lg:w-[30%] bg-ag-secondary text-xs md:text-sm text-white text-center border border-gray-500 py-3 rounded-l-xl"
                        >
                          Locality
                        </span>
                        <input
                          className="w-[62%] md:w-[67%] lg:w-[70%] text-xs md:text-sm text-ag-secondary-light border border-gray-500 p-3 rounded-r-xl disabled:bg-zinc-200"
                          type="text"
                          name="locality"
                          onChange={handleChange}
                          disabled={!enableEdit}
                          value={warehouse.locality}
                          maxLength={40}
                        />
                      </div>
                      <div className="w-full flex justify-center items-center">
                        <span
                          className="w-[38%] md:w-[33%] lg:w-[30%] bg-ag-secondary text-xs lg:text-sm text-white text-center border border-gray-500 py-3 rounded-l-xl"
                        >
                          State
                        </span>
                        <input
                          className="w-[62%] md:w-[67%] lg:w-[70%] text-xs lg:text-sm text-ag-secondary-light border border-gray-500 p-3 rounded-r-xl disabled:bg-zinc-200"
                          type="text"
                          name="state"
                          onChange={handleChange}
                          disabled={!enableEdit}
                          value={warehouse.state}
                          maxLength={40}
                        />
                      </div>
                      <div className="w-full flex justify-center items-center">
                        <span
                          className="w-[38%] md:w-[33%] lg:w-[30%] bg-ag-secondary text-xs lg:text-sm text-white text-center border border-gray-500 py-3 rounded-l-xl"
                        >
                          Postal code
                        </span>
                        <input
                          className="w-[62%] md:w-[67%] lg:w-[70%] text-xs lg:text-sm text-ag-secondary-light border border-gray-500 p-3 rounded-r-xl disabled:bg-zinc-200"
                          type="text"
                          name="postalCode"
                          onChange={handleChange}
                          disabled={!enableEdit}
                          value={warehouse.postalCode}
                          maxLength={5}
                        />
                      </div>
                    </div>
                    <div className="flex justify-center items-center space-x-8 w-full md:w-[60%] m-auto">
                      {
                        warehouseInfo !== null ?
                          <button
                            className="w-full bg-ag-primary-light text-white p-2 rounded-xl disabled:bg-zinc-200"
                            type="button"
                            disabled={!enableRemove}
                            onClick={() => onDelete()}
                          >
                            Delete
                          </button>
                          :
                          <button
                            className="w-full bg-ag-primary-light text-white p-2 rounded-xl"
                            type="button"
                            onClick={() => onClose(false)}
                          >
                            Cancel
                          </button>
                      }
                      <button
                        className="w-full bg-green-600 text-white p-2 rounded-xl disabled:bg-zinc-200"
                        type="submit"
                        disabled={!enableEdit}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-50 fixed inset-0 z-20 bg-black"></div>
    </>
  );
};

export default FormWarehouse;