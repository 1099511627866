import { useContext, useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import { getOrders } from "../../../../services/Orders";
import { PermitsContext } from "../../../../services/Permits";
import Loader from "../../../../components/loader/Loader";
import TableProcess from "./TableProcess";

const ProcessOrders = () => {
  const { dataSession } = useContext(PermitsContext);
  const [loader, setLoader] = useState(true);
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [pagesData, setPagesData] = useState({ total: 0, records: 0 });

  const getData = (pageInventory, paramsFilters) => {
    setLoader(true);
    getOrders(pageInventory, 50, paramsFilters).then(resGet => {
      if (resGet.data?.result && resGet.data?.result.length > 0) {
        let ordersWH = [];
        if (dataSession.userType === "ADMIN") {
          ordersWH = resGet.data?.result.filter(elem =>
            (elem.status !== "delivered" && elem.status !== "canceled" && elem?.orderType !== "QUOTES")
          );
        } else {
          ordersWH = resGet.data?.result.filter(element => {
            const foundWH = dataSession.allWarehouse.find(elem => element?.warehouse?._id === elem._id);
            if (foundWH && (element.status !== "delivered" && element.status !== "canceled" && element?.orderType !== "QUOTES")) return element;
          });
        }

        const array_sort = ordersWH.sort((a, b) => (new Date(b.createdAt) - new Date(a.createdAt)));
        setPage(pageInventory);
        setPagesData({...pagesData, total: resGet.data.count/50, records: resGet.data.count});
        setOrders(array_sort);
      }
      setLoader(false);
    });
  };

  const changePage = (pge) => {
    new Promise((resolve, reject) => {
      getData(pge, { sort: "DESC" });
      resolve();
    }).then(() => {
      setPage(pge);
    });
  };

  useEffect(() => {
    if (dataSession._id !== null) {
      getData(1, { sort: "DESC" });
    }
  }, [dataSession._id]);

  return (
    <>
      { loader && <Loader /> }

      <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
        <div className="grid grid-cols-12 px-0 md:px-5">
          <div>
            <Link to="/orders">
              <button className="p-1 rounded-md" type="button">
                <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
              </button>
            </Link>
          </div>
          <div className="flex justify-center items-center col-span-11 text-center">
            <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
                            Orders in care
            </h1>
          </div>
        </div>
        <div className="pt-3 overflow-x-auto">
          <TableProcess
            orders={orders}
            currntPg={page}
            pagesData={pagesData}
            onPage={(pg) => changePage(pg)}
          />
        </div>
      </div>
    </>
  );
};

export default ProcessOrders;