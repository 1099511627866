import { MdLocalShipping } from "react-icons/md";
import MyCartElement from "./MyCartElement";

const MyCartList = ({ groups, onQuantity, onRemoveProduct, onUpdate, onCompatible, onFeatures }) => {
    return (
        <div className="w-full space-y-3">
            { groups.map((group, i) => (
                <div key={i} className="w-full space-y-3">
                    <div className="bg-zinc-300 flex justify-start items-center space-x-3 px-5 md:px-10 py-4 border border-black shadow-lg">
                        <span>{ group.nameWarehouse }</span>
                        <span><MdLocalShipping /></span>
                    </div>
                    { group.products.map((product, j) => (
                        <div key={j}>
                            <MyCartElement
                                product={product}
                                onQuantity={(idProd, qty) => onQuantity(group.idWarehouse, idProd, qty)}
                                onRemoveProduct={onRemoveProduct}
                                onUpdate={onUpdate}
                                onCompatible={onCompatible}
                                onFeatures={onFeatures}
                            />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default MyCartList;