import { formatDate } from "../../../../utils/Utilities";

const RowsHistoryTransfer = ({ movement }) => {
    return (
        <tr>
            <td className="w-[70%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">
                {`${movement.movement} ${movement.quantity} unit of ${movement.nags}. By ${movement.UpdateByEmail}`}
            </td>
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">{ movement.UpdateByName }</td>
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">{ formatDate(movement.createdAt) }</td>
        </tr>
    );
}

export default RowsHistoryTransfer;