import { _delete, _get, _post, _put } from "./http";

export const getWindowTintCatalog = () => _get(`/windowTintCatalog`);

export const getWindowTintCatalogById = (id) => _get(`/windowTintCatalog/${id}`);

export const saveWindowTintCatalog = (object) => _post(`/windowTintCatalog`, object);

export const putWindowTintCatalog = (id, object) => _put(`/windowTintCatalog/${id}`, object);

export const deleteWindowTintCatalogById = (id) => _delete(`/windowTintCatalog/${id}`);

//SEND QUOTE
export const sendQuotePowerWindowRepair = (id, object) => _post(`/powerWindowRepair/${id}/sendQuote`, object);