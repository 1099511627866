import { IoMdCloseCircle } from "react-icons/io";
import { useState, useEffect } from "react";
import { parseTextTypeGlass , alertOptions} from "../../../utils/Utilities";
import { getCompatVehiclesWithInterchangesByNag } from "../../../services/Product";
import { toast } from "react-toastify";


const ModalCompatible = ({ products, onClose }) => {
    const [compatibleVehicles, setCompatibleVehicles] = useState([]);
    const [isLoader, setIsLoader] = useState(true);


    useEffect(() => {
        setIsLoader(true)
        if (products) {
            let nag = products?.nags ? products?.nags : products?.compatible_vehicles[0].nag
            getCompatVehiclesWithInterchangesByNag(nag).then(res => {
                if (res.status === 200 && res.data.length > 0) {
                    setCompatibleVehicles(res.data);
                    setIsLoader(false)
                } else {
                    setCompatibleVehicles([]);
                    setIsLoader(false)
                }
            })
        } else {
            toast.warning("Records not found.", alertOptions);
        }
    }, [products]);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[700px] lg:w-[850px] h-[500px] xl:h-auto">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            {

                                isLoader ?
                                    <span className="flex items-center justify-center text-center">Loading, please wait...</span>
                                    :
                                    <div>
                                        <div className="w-full flex items-center justify-center rounded text-center">
                                            <div className="w-[95%]">
                                                <span className="text-xl text-ag-secondary font-bold">
                                                    Compatible Vehicles of: {products?.nags ? products?.nags : products?.compatible_vehicles[0].nag}
                                                </span>
                                            </div>
                                            <div className="w-[5%]">
                                                <button
                                                    className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                                    onClick={() => onClose(false)}
                                                >
                                                    <IoMdCloseCircle />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="px-8 py-5 max-h-[300px] overflow-y-auto">
                                            <ul className="list-disc">
                                                {
                                                    compatibleVehicles?.map((prodComp, i) => (
                                                        <li className="w-full text-ag-secondary" key={i}>
                                                            {String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length - 1)]}` : prodComp?.year}{' '}
                                                            {prodComp?.brand}{' '}
                                                            {prodComp?.model}{' '}
                                                            {prodComp?.type_car}{' '}
                                                            {parseTextTypeGlass(prodComp?.type_glass)}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

export default ModalCompatible;