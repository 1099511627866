import RowsProductEspecification from "./RowsProductEspecification";
import Pagination from "../../../../components/pagination/Pagination";
import { useEffect, useState } from "react";

const TableProductEspecification = ({ arrProducts, onSelect, onCompatible }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = arrProducts.slice(indexOfFirstRecord, indexOfLastRecord);

    useEffect(() => {
        setCurrentPage(1);
    }, [arrProducts]);

    return (
        <div>
            <div className="overflow-auto h-[280px] md:h-[280px]">
                <table className="w-full text-center text-ag-secondary">
                    <thead>
                        <tr className="bg-gray-300">
                            <th className="w-[6%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-4 md:py-3">Nag</th>
                            <th className="w-[94%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-4 md:py-3">Compatible vehicles</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentRecords.map((element, i) => {
                                return (
                                    <RowsProductEspecification
                                        key={i}
                                        product={element}
                                        onSelect={() => onSelect(element)}
                                        onCompatible={onCompatible}
                                    />
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="p-5 m-auto">
                <Pagination
                    currentPage={currentPage}
                    totalCount={arrProducts.length}
                    pageSize={recordsPerPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
        </div>
    );
}

export default TableProductEspecification;