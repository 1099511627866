import { useContext, useEffect, useState } from "react";
import { PermitsContext } from "../../../services/Permits";
import { saveAddress, deleteAddress, getAddresses, updateAddress } from "../../../services/WholesalerAddress";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { Link } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import Loader from "../../../components/loader/Loader";
import NavbarClients from "../../../components/navbar/NavbarClients";
import SidebarClients from "../../../components/sidebar/SidebarClients";
import AdressesList from "../../fragments/adresses/AdressesList";
import AddressesForm from "../../fragments/adresses/AdressesForm";
import Modal from "../../../components/modal/Modal";

const Adresses = () => {
  const { dataSession } = useContext(PermitsContext);
  const [loader, setLoader] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [showForm, setShowForm] = useState({ status: false, element: null});
  const [showModal, setShowModal] = useState({ status: false, type: "", title: "", element: null });
  const [adresses, setAdresses] = useState([]);

  const getData = () => {
    getAddresses(dataSession._id).then(res => {
      if (res.status === 200) setAdresses(res.data);
      setLoader(false);
    }).catch(() => setLoader(false));
  };

  const handleSelected = (idAddress, sts) => {
    setLoader(true);
    if (sts) {
      const addressToFalse = adresses.find(element => element.selected);
      if (addressToFalse) {
        updateAddress(dataSession._id, addressToFalse._id, {...addressToFalse, selected: false}).then(() => {});
      }
    }

    const addressFound = adresses.find(element => element._id === idAddress);
    if (addressFound) handleEditAddress({...addressFound, selected: sts});
  };

  const handleAddAddress = (addressModel) => {
    saveAddress(dataSession._id, addressModel).then(res => {
      if (res.status === 200) {
        setShowForm({...showForm, status: false, element: null});
        toast.success("Address added successfully", alertOptions);
        getData();
      } else {
        toast.warning(res.response.data.message, alertOptions);
      }
      setLoader(false);
    }).catch(error => {
      toast.warning(error.response.data.message, alertOptions);
      setLoader(false);
    });
  };

  const handleEditAddress = (addressModel) => {
    updateAddress(dataSession._id, addressModel._id, addressModel).then(res => {
      if (res.status === 200) {
        setShowForm({...showForm, status: false, element: null});
        toast.success("Address updated successfully", alertOptions);
        getData();
      } else {
        toast.warning(res.response.data.message, alertOptions);
      }
      setLoader(false);
    }).catch(error => {
      toast.warning(error.response.data.message, alertOptions);
      setLoader(false);
    });
  };

  const handleDelete = () => {
    setLoader(true);
    deleteAddress(dataSession._id, showModal.element).then(res => {
      if (res.status === 200) {
        getData();
        setShowModal({...showModal, status: false, type: "", title: "", element: null});
        toast.success("Address deleted successfully", alertOptions);
      } else {
        setLoader(false);
        toast.warning(res.response.data.message, alertOptions);
      }
    }).catch(error => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  useEffect(() => {
    setLoader(true);
    getData();
  }, [dataSession]);

  return (
    <>
      { loader ? <Loader /> : null }

      {
        showForm.status ?
          <AddressesForm
            addressData={showForm.element}
            onClose={() => setShowForm({...showForm, status: false, element: null})}
            onLoader={(val) => setLoader(val)}
            onAdd={handleAddAddress}
            onEdit={handleEditAddress}
          />
          : null
      }

      { showModal.status ?
        <Modal
          title={showModal.title}
          onClose={() => setShowModal({...showModal, status: false, type: "", title: "", element: null})}
          onResponse={handleDelete}
        />
        :
        null
      }

      <div>
        <div className="flex flex-row">
          <div className="w-full bg-no-repeat bg-cover fixed z-10">
            <NavbarClients type="wholesaler" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          </div>
        </div>
        <div className="flex h-screen overflow-hidden">
          <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
            <SidebarClients type="wholesaler" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          </div>
          <div className="w-full overflow-auto flex-grow mt-[100px]">
            <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
              <div className="grid grid-cols-12 px-0 md:px-5">
                <div>
                  <Link to="/wholesaler/profile">
                    <button className="p-1 rounded-md" type="button">
                      <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
                    </button>
                  </Link>
                </div>
                <div className="flex justify-center items-center col-span-11 text-center">
                  <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary mr-[8%]">My Addresses</h1>
                </div>
              </div>
              <div className="pt-10 md:pt-20">
                <AdressesList
                  adresses={adresses}
                  onShow={() => setShowForm({...showForm, status: true, element: null})}
                  onEdit={(addressModel) => setShowForm({...showForm, status: true, element: addressModel})}
                  onDelete={(idAddress) => setShowModal({...showModal,
                    status: true,
                    type: "delete",
                    title: "Are you sure you want to delete the address?",
                    element: idAddress
                  })}
                  onSelected={handleSelected}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Adresses;