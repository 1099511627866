import React from 'react'

const MiddleDoorLeft = ({ isSelected, isAvailable, onClick }) => {
  return (
    <svg className="cursor-pointer outline-none" onClick={onClick} data-tooltip-id="car" data-tooltip-content="Middle Door (left)">
      <path d="M87.26 242.08H54.46V285.08H87.26V242.08Z" fill={isSelected ? "#F41A28" :isAvailable ? "#1D8F43" : "#D3D3D4"}/>
    </svg>
  )
}

export default MiddleDoorLeft