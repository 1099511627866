import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { formatCurrency, getAllFeaturesWC, isOriginalGlass } from "../../../utils/Utilities";
import { getProductEspecification } from "../../../services/Product";

const ModalCalculate = ({ item, onClose, onSelect }) => {
    const [values, setValues] = useState({
        kit: 0,
        labor: 0,
        list: 0,
        discount: 0,
        net: 0,
        services: 0,
        subtotal: 0,
        tax: 0,
        total: 0
    });

    useEffect(() => {
        if (item !== null) {
            getProductEspecification(item?.item?._id).then(res => {
                if (res.status === 200) {
                    const featuresFilters = getAllFeaturesWC().filter(element => element.category === "Calibration");
                    const prodAux = res.data;
                    let newFeaturesAux = [];
                    for (let i = 0; i < featuresFilters.length; i++) {
                        if (
                            String(prodAux.ad_info_a).toLowerCase().includes(String(featuresFilters[i].value).toLowerCase()) === true ||
                            ((prodAux.hud === "Y" && featuresFilters[i].value === "HUD (Head up display)"))
                        ) {
                            newFeaturesAux.push(featuresFilters[i]);
                        }
                    }

                    let calibrationValue = 0;
                    let discountValue = 0;
                    if (newFeaturesAux.length > 0) calibrationValue = item?.insurer?.dualCalibration;

                    const serviceCF90 = item?.quote?.ceramicFull90 ? 250 : 0;
                    const serviceCF70 = item?.quote?.ceramicFull70 ? 200 : 0;
                    const serviceWatT = item?.quote?.waterTreatment ? 50 : 0;
                    const serviceWSVS = item?.quote?.windshieldVisorStrip ? 50 : 0;
                    const sumServices = Number(serviceCF70) + Number(serviceCF90) + Number(serviceWatT) + Number(serviceWSVS) + Number(calibrationValue);

                    const listValue = item?.item?.price && item?.item.price !== "" ? Number(item?.item?.price) : Number(item?.price);
                    
                    if (!isOriginalGlass(item)) {
                        discountValue = item?.item?.heated === "Y" ? item?.insurer?.discountHeated : item?.insurer?.discountRegular;
                    }

                    const laborValue = Number(item?.item?.labor_time) === 0 ? 450 : Number(item?.item?.labor_time) * Number(item?.insurer?.hour);
                    const netValue = listValue - (listValue * (discountValue/100));
                    const subTotalValue = netValue + Number(item?.insurer?.kitRegular) + laborValue + sumServices;
                    const taxValue = Number(subTotalValue * (item?.insurer?.taxRate/100));
                    const totalValue = subTotalValue + taxValue;
                    
                    setValues({...values,
                        kit: item?.insurer?.kitRegular,
                        labor: laborValue,
                        list: listValue,
                        discount: discountValue,
                        net: netValue,
                        services: sumServices,
                        subtotal: subTotalValue,
                        tax: taxValue,
                        total: totalValue
                    });
                }
            });
        }
    }, [item]);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[700px] lg:w-[800px]">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="text-xl text-ag-secondary font-bold pl-[5%]">
                                        Total Detail
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="px-5 md:px-10 pb-2 pt-3">
                                <span className="text-ag-secondary-letter text-sm">
                                    Below is the detail of the calculation of the price of the quote:
                                </span>
                            </div>
                            <div className="w-full md:w-[80%] md:flex md:justify-between md:items-center m-auto px-5 pb-2 pt-5 space-y-3 md:space-y-0">
                                <ul className="space-y-1">
                                    <li className="flex justify-center items-center">
                                        <div className="bg-zinc-200 w-28 px-5 py-2 text-center rounded-xl">
                                            <span className="text-ag-secondary-letter text-xs font-semibold">Kit</span>
                                        </div>
                                        <div className="bg-zinc-100 w-28 px-5 py-2 text-center rounded-xl">
                                            <span className="text-xs">{ formatCurrency(values.kit) }</span>
                                        </div>
                                    </li>
                                    <li className="flex justify-center items-center">
                                        <div className="bg-zinc-200 w-28 px-5 py-2 text-center rounded-xl">
                                            <span className="text-ag-secondary-letter text-xs font-semibold">Labor</span>
                                        </div>
                                        <div className="bg-zinc-100 w-28 px-5 py-2 text-center rounded-xl">
                                            <span className="text-xs">{ formatCurrency(values.labor) }</span>
                                        </div>
                                    </li>
                                    <li className="flex justify-center items-center">
                                        <div className="bg-zinc-200 w-28 px-5 py-2 text-center rounded-xl">
                                            <span className="text-ag-secondary-letter text-xs font-semibold">List</span>
                                        </div>
                                        <div className="bg-zinc-100 w-28 px-5 py-2 text-center rounded-xl">
                                            <span className="text-xs">{ formatCurrency(values.list) }</span>
                                        </div>
                                    </li>
                                    <li className="flex justify-center items-center">
                                        <div className="bg-zinc-200 w-28 px-5 py-2 text-center rounded-xl">
                                            <span className="text-ag-secondary-letter text-xs font-semibold">Discount</span>
                                        </div>
                                        <div className="bg-zinc-100 w-28 px-5 py-2 text-center rounded-xl">
                                            <span className="text-xs">{ values.discount }%</span>
                                        </div>
                                    </li>
                                    <li className="flex justify-center items-center">
                                        <div className="bg-zinc-200 w-28 px-5 py-2 text-center rounded-xl">
                                            <span className="text-ag-secondary-letter text-xs font-semibold">Net</span>
                                        </div>
                                        <div className="bg-zinc-100 w-28 px-5 py-2 text-center rounded-xl">
                                            <span className="text-xs">{ formatCurrency(values.net) }</span>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="space-y-1">
                                    <li className="flex justify-center items-center">
                                        <div className="bg-zinc-200 w-28 px-5 py-2 text-center rounded-xl">
                                            <span className="text-ag-secondary-letter text-xs font-semibold">Services</span>
                                        </div>
                                        <div className="bg-zinc-100 w-28 px-5 py-2 text-center rounded-xl">
                                            <span className="text-xs">{ formatCurrency(values.services) }</span>
                                        </div>
                                    </li>
                                    <li className="flex justify-center items-center">
                                        <div className="bg-zinc-200 w-28 px-5 py-2 text-center rounded-xl">
                                            <span className="text-ag-secondary-letter text-xs font-semibold">Subtotal</span>
                                        </div>
                                        <div className="bg-zinc-100 w-28 px-5 py-2 text-center rounded-xl">
                                            <span className="text-xs">{ formatCurrency(values.subtotal) }</span>
                                        </div>
                                    </li>
                                    <li className="flex justify-center items-center">
                                        <div className="bg-zinc-200 w-28 px-5 py-2 text-center rounded-xl">
                                            <span className="text-ag-secondary-letter text-xs font-semibold">Tax</span>
                                        </div>
                                        <div className="bg-zinc-100 w-28 px-5 py-2 text-center rounded-xl">
                                            <span className="text-xs">{ formatCurrency(values.tax) }</span>
                                        </div>
                                    </li>
                                    <li className="flex justify-center items-center">
                                        <div className="bg-zinc-200 w-28 px-5 py-2 text-center rounded-xl">
                                            <span className="text-ag-secondary-letter text-xs font-semibold">Total</span>
                                        </div>
                                        <div className="bg-zinc-100 w-28 px-5 py-2 text-center rounded-xl">
                                            <span className="text-xs">{ formatCurrency(values.total) }</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="flex justify-end items-center space-x-5">
                                <button
                                    type="button"
                                    className="px-4 py-3 bg-red-600 text-white text-xs rounded-xl"
                                    onClick={() => onClose(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="px-4 py-3 bg-green-600 text-white text-xs rounded-xl"
                                    onClick={() => onSelect(item, values.total, values.tax)}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
        </>
    );
}

export default ModalCalculate;