import { MdDelete, MdEdit } from "react-icons/md";

const RowsVendors = ({ enableEdit, enableDelete, indexVendor, vendors, onEdit, onDelete }) => {
    return (
        <tr>
            <td className="w-[6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">{ indexVendor+1 }</td>
            <td className="w-[16%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-40 m-auto">{ vendors.name }</p>
            </td>
            <td className="w-[16%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-40 m-auto">{ vendors.email }</p>
            </td>
            <td className="w-[11%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-44 m-auto">{ vendors.address.street }{' '}{ vendors.address.locality }{', '}{ vendors.address.state }</p>
            </td>
            <td className="w-[11%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">{ vendors.phone }</td>
            <td className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <button 
                    className="text-3xl disabled:bg-zinc-200"
                    type="button"
                    onClick={() => { onEdit(true) }}
                    disabled={!enableEdit}
                >
                    <MdEdit />
                </button>
            </td>
            <td className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <button
                    className="text-3xl disabled:bg-zinc-200"
                    type="button"
                    onClick={() => onDelete(true) }
                    disabled={!enableDelete}
                >
                    <MdDelete />
                </button>
            </td>
        </tr>
    );
}

export default RowsVendors;