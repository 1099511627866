import { useEffect, useState } from "react";
import { IoMdImage } from "react-icons/io";
import { formatCurrency, getHoursMinutesByDay } from "../../../utils/Utilities";
import { BsInfoCircleFill } from "react-icons/bs";
import IconPlinkintong from "../../../assets/icons/logo_plinkintong_green.png";
import FeaturesByProductOrder from "../../wholesaler/mycart/FeaturesByProductOrder";

const RowsDetail = ({ stsId, product, warehouseOptions, kitValue, insurersOptions, onProduct, onSelect, onPrice, onCompatible, onFeatures }) => {
    const [hoursOptions, setHoursOptions] = useState([]);
    const [minutesOptions, setMinutesOptions] = useState([]);
    const [serviceTime, setServiceTime] = useState({ hours: "00", minutes: "00" });
    const [productData, setProductData] = useState({ price: "", kit: "" });

    const handleChange = (evt) => {
        if (evt.currentTarget.name === "price" && !isNaN(evt.currentTarget.value)) {
            setProductData({...productData, price: evt.currentTarget.value});
            onProduct(evt, product);
        } else if (evt.currentTarget.name === "hours" || evt.currentTarget.name === "minutes") {
            setServiceTime({...serviceTime, [evt.currentTarget.name]: evt.currentTarget.value});
            onProduct({
                target: { name: "time", value: 
                    `${evt.currentTarget.name === "hours" ? evt.currentTarget.value : serviceTime.hours}`+
                    `.${evt.currentTarget.name === "minutes" ? evt.currentTarget.value : serviceTime.minutes}`
                }}, product
            );
        } else if (evt.currentTarget.name === "vendor") {
            onProduct(evt, product)
        }
    }

    useEffect(() => {
        const resultTimeOptions = getHoursMinutesByDay(1);
        setHoursOptions(resultTimeOptions[0]);
        setMinutesOptions(resultTimeOptions[1]);
    }, []);

    useEffect(() => {
        if (kitValue !== "") {
            const foundInsurance = insurersOptions.find(element => element._id === kitValue);
            if (foundInsurance) setProductData({...productData, kit: foundInsurance});
        }
    }, [kitValue]);

    useEffect(() => {
        if (product?.labor_time || product?.time) {
            const hoursInt = Number(String(product?.labor_time ? product?.labor_time : product?.time).substring(0, 2));
            const hoursValue = hoursInt !== "" ? String(hoursInt).length === 1 ? `0${hoursInt}` : `${hoursInt}` : "00";
            
            const getDecimalVal = product?.labor_time ? product?.labor_time : product?.time.toString().indexOf(".");
            const decimalPart = product?.labor_time ? product?.labor_time : product?.time.toString().substring(getDecimalVal+1);
            const minutesValue = decimalPart !== "" ? String(decimalPart).length === 1 ? `${decimalPart}0` : `${decimalPart}` : "00";

            setServiceTime({...serviceTime, hours: hoursValue, minutes: minutesValue });
        }
        if (product?.price) setProductData({...productData, price: product?.price});
    }, []);

    return (
        <tr>
            <td className="w-[30%] text-ag-secondary-letter text-[10px] font-normal px-1 py-1">
                <div className="flex space-x-3">
                    <div className="flex justify-center items-center">
                        <span className="text-3xl">
                            <IoMdImage />
                        </span>
                    </div>
                    <div className="space-y-3">
                        <div className="text-left">
                            <div>
                                <p className="text-ag-secondary-letter text-[10px]">
                                    { product?.nag ? product?.nag : product?.productSpecification?.nag }
                                </p>
                            </div>
                            <div>
                                {
                                    product.compatible_vehicles.map((prodComp, i) => {
                                        if (i < 1) {
                                            return (
                                                <p className="truncate text-ag-secondary-letter text-[10px]" key={i} title={
                                                    `${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year} ` +
                                                    `${prodComp?.brand} ` +
                                                    `${prodComp?.model} ` +
                                                    `${prodComp?.type_car}`
                                                }>
                                                    { String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year }{' '}
                                                    { prodComp?.brand }{' '}
                                                    { prodComp?.model }{' '}
                                                    { prodComp?.type_car }
                                                </p>
                                            )
                                        }
                                    })
                                }

                                <p className="text-ag-secondary-letter text-[10px] font-semibold cursor-pointer" onClick={() => onCompatible(product)}>
                                    See compatible vehicles +
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
            <td className="w-[14%] text-ag-secondary-letter text-[10px] font-normal px-2">
                <FeaturesByProductOrder features={product} />
            </td>
            <td className="w-[15%] text-ag-secondary-letter text-[10px] font-normal px-1 py-6">
                <input
                    type="text"
                    name="vendor"
                    className="w-full text-center border border-ag-secondary-light px-2 py-2 rounded-xl disabled:bg-zinc-200"
                    onChange={handleChange}
                    defaultValue={product?.vendor}
                    disabled={product?.onlyShow}
                />
            </td>
            <td className="w-[10%] text-ag-secondary-letter text-[10px] font-normal px-1 py-6">
                <div className={`flex items-center w-full text-center border border-ag-secondary-light px-2 py-2 rounded-xl ${product?.onlyShow ? "bg-zinc-200" : ""}`}>
                    <select
                        name="hours"
                        className="px-1 appearance-none"
                        onChange={handleChange}
                        value={serviceTime.hours}
                        disabled={product?.onlyShow}
                    >
                        {
                            hoursOptions.map((element, index) => {
                                return <option key={index} value={element.value}>{element.label}</option>;
                            })
                        }
                    </select>
                    <label>.</label>
                    <select
                        name="minutes"
                        className="px-1 appearance-none"
                        onChange={handleChange}
                        value={serviceTime.minutes}
                        disabled={product?.onlyShow}
                    >
                        {
                            minutesOptions.map((element, index) => {
                                return <option key={index} value={element.value}>{element.label}</option>;
                            })
                        }
                    </select>
                </div>
            </td>
            <td className="w-[15%] text-ag-secondary-letter text-[10px] font-normal px-1 py-6">
                <input
                    id={`price-quote-${product?.productInventory?._id}`}
                    type="text"
                    name="price"
                    className="w-full text-center border border-ag-secondary-light p-2 rounded-xl disabled:bg-zinc-200"
                    onChange={handleChange}
                    value={product?.onlyShow ? formatCurrency(productData.price) : productData.price}
                    defaultValue={product?.price}
                    disabled={product?.onlyShow}
                />
            </td>
            <td className="w-[15%] text-ag-secondary-letter text-[10px] font-normal px-1 py-6">
                <input
                    type="text"
                    name="kit"
                    className="w-full text-center border border-ag-secondary-light px-2 py-2 rounded-xl disabled:bg-zinc-200"
                    onChange={handleChange}
                    value={product?.onlyShow ? formatCurrency(product?.insurer?.kitRegular) : productData.kit?.kitRegular}
                    defaultValue={formatCurrency(product?.insurer?.kitRegular)}
                    disabled={true}
                />
            </td>
            <td className="w-[15%] text-ag-secondary-letter text-[10px] font-normal p-1 space-y-1">
                {
                    !product?.onlyShow ?
                        <button
                            className={`w-full text-white p-2 rounded-full ${ stsId === product?._id ? "bg-ag-secondary" : "bg-[#199B5D]" }`}
                            type="button"
                            onClick={() => onSelect(product, productData.kit === "" ? product?.insurer : productData.kit)}
                        >
                            Select product
                        </button>
                    :
                        <span>{'-----'}</span>
                }
                {
                    !product?.onlyShow ?
                        <button
                            className="w-full bg-[#199B5D] text-white p-2 rounded-full"
                            type="button"
                            onClick={() => onPrice(product.nag)}
                        >
                            <img className="w-20 m-auto" src={IconPlinkintong} />
                        </button>
                    :
                        null
                }
            </td>
        </tr>
    );
}

export default RowsDetail;