import { BsFillCheckCircleFill } from "react-icons/bs";
import { formatCurrency, formatDateHourOrders } from "../../../../utils/Utilities";
import { RETURN_STATUS } from "../../../../utils/constants";

const RowsApproved = ({ enableActions, returnElement, onDetail, onInventory, onCheck }) => {

  let statusColor = RETURN_STATUS[returnElement.status]?.color;
  let statusLabel = RETURN_STATUS[returnElement.status]?.text;
  let isCheckAvailable = false;

  if (returnElement.status === "FINISHED" && returnElement?.productReturn && !returnElement.productInWarehouse) {
    statusColor = RETURN_STATUS.FINISHED_WITHOUT_CHECK.color;
    statusLabel = RETURN_STATUS.FINISHED_WITHOUT_CHECK.text;
    isCheckAvailable = true;
  } else if (returnElement.productInWarehouse) {
    statusColor = RETURN_STATUS.FINISHED.color;
    statusLabel = RETURN_STATUS.FINISHED.text;
    isCheckAvailable = false;
  }

  return (
    <tr>
      <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
        <ul>
          <li>{ formatDateHourOrders(returnElement.createdAt) }</li>
          <li>{`ID: ${String(returnElement?._id).substring(String(returnElement?._id).length - 13).toLocaleUpperCase()}`}</li>
        </ul>
      </td>
      <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
        <p className="truncate w-32 m-auto">{ returnElement.wholesaler.names }</p>
      </td>
      <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
        <p className="w-32 m-auto">{ returnElement.productOrder.productInventory.product.nags }</p>

        <p className="w-32 m-auto">{ returnElement.productOrder.productInventory.product.name }</p>

        <p className="w-32 m-auto">{ returnElement.productOrder.productInventory.product.features }</p>
      </td>
      <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
        <p className="truncate w-32 m-auto">{ returnElement.warehouse.name }</p>
      </td>
      <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
        <p className="truncate w-32 m-auto">{ returnElement.reason }</p>
      </td>
      <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
        <p className="truncate w-32 m-auto">{ formatCurrency(returnElement?.total) }</p>
      </td>
      <td className={`w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6 ${statusColor}`}>
        {statusLabel}
      </td>
      <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
        <p className="truncate w-32 m-auto">{ returnElement.employee ? `${returnElement.employee?.names} ${returnElement.employee?.lastNames}` : "-----" }</p>
      </td>
      <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
        <p className="font-semibold text-green-600">Add:</p>

        <p>{ returnElement.productReturn ? returnElement.productReturn?.addQuantity : "-----" }</p>
      </td>
      <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        {
          returnElement.status === "APPROVED" ?
            <button className="bg-ag-secondary text-white px-6 py-3 rounded-xl disabled:bg-zinc-200" disabled={!enableActions} type="button" onClick={() => onInventory(returnElement)}>
              Add / Remove
            </button>
            : null
        }
        {
          returnElement.status === "FINISHED" || returnElement.status === "CANCELED" ?
            <button className="bg-ag-secondary text-white px-6 py-3 rounded-xl disabled:bg-zinc-200" disabled={!enableActions} type="button" onClick={() => onDetail(returnElement)}>
              View Details
            </button>
            : null
        }

        {
          isCheckAvailable ?
            (
              <div
                className={`mt-[10px] flex justify-center items-center space-x-1 text-green-500 cursor-pointer`}
                onClick={() => onCheck(returnElement)}
              >
                <span className="text-lg"><BsFillCheckCircleFill /></span>
                <span className="text-xs">Check</span>
              </div>
            )
            : null
        }
      </td>
    </tr>
  );
};

export default RowsApproved;