import { useEffect, useState } from "react";
import { formatCurrency, formatDateEng } from "../../../utils/Utilities";

const RowsOrders = ({ report }) => {
  const [productsData, setProductsData] = useState({ quantity: "", product: "" });
  const [features, setFeatures] = useState("--");

  useEffect(() => {
    let quantityAux = 0;
    let productsAux = "";

    for (let i = 0; i < report.productsOrder.length; i++) {
      const element = report.productsOrder[i];
      quantityAux += Number(element.quantity);

      if (i === 0) productsAux += `${element?.productInventory?.product?.nags}`;
      else productsAux += `, ${element?.productInventory?.product?.nags}`;
    }

    setProductsData({...productsAux, quantity: quantityAux, product: productsAux});

    if (report?.productsOrder?.[0]?.productInventory?.product?.features) {
      setFeatures(report.productsOrder[0].productInventory.product.features);
    } else {
      setFeatures("--");
    }
  }, [report]);

  const groupClass = `group hover:bg-gray-100 transition-colors duration-200`;
  const cellClass = "px-6 py-4 whitespace-nowrap text-sm text-gray-500 group-hover:text-gray-900 transition-colors duration-200";

  console.log('employee: ',report.employee);

  return (
    <tbody className={groupClass}>
      <tr>
        <td className={cellClass}>
          <p className="truncate w-20 m-auto">{ formatDateEng(report?.createdAt) }</p>
        </td>
        <td className={cellClass}>
          <p className="truncate w-44 m-auto">{ productsData.product }</p>
        </td>
        <td className={cellClass}>
          <p className="truncate w-20 m-auto">{ productsData.quantity }</p>
        </td>
        <td className={cellClass}>
          <p className="truncate w-44 m-auto">{ report?.warehouse?.name }</p>
        </td>
        <td className={cellClass}>
          <p className="truncate w-44 m-auto">
            {
              report?.wholesalerUser ? `${report?.wholesalerUser?.names} ${report?.wholesalerUser?.lastNames ? report?.wholesalerUser?.lastNames : ""}` :
                report?.retailUser ? `${report?.retailUser?.names} ${report?.retailUser?.lastNames ? report?.retailUser?.lastNames : ""}` :
                  "-----"
            }
          </p>
        </td>
        <td className={cellClass}>
          <p className="truncate w-20 m-auto">{ report?.sale ? `SN-${String(report?.sale?._id).substring(String(report?.sale?._id).length - 5).toLocaleUpperCase()}` : "-----" }</p>
        </td>
        <td className={cellClass}>
          <p className="truncate w-20 m-auto">{ `ON-${String(report?._id).substring(String(report?._id).length - 5).toLocaleUpperCase()}` }</p>
        </td>
        <td className={cellClass}>
          <p className="truncate w-20 m-auto">{ formatCurrency(report?.total) }</p>
        </td>
        <td className={cellClass}>
          <p className="truncate w-44 m-auto">{ report?.employee ? `${report.employee.names} ${report.employee.lastNames}` : "-----" }</p>
        </td>
      </tr>

      <tr>
        <td colSpan={9} className={`px-6 py-2 text-xs text-gray-400 group-hover:text-gray-600 break-words transition-colors duration-200`}>
          <span className="font-medium">Features:</span> {features}
        </td>
      </tr>
    </tbody>
  );
};

export default RowsOrders;