import { useState } from "react";
import NavbarClients from "../../../../../components/navbar/NavbarClients";
import SidebarClients from "../../../../../components/sidebar/SidebarClients";
import PopUpDiscount from "../../../../../components/discounts/PopUpDiscount";
import iconBack from "../../../../../assets/icons/icon_back.svg";
import { Link } from "react-router-dom";

import ProductListCard from "../ProductListCard";


const DiscountList = () => {
    const [sidebarOpen, setSidebarOpen] = useState(true);

    return (
        <>
            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <NavbarClients type="wholesaler" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <SidebarClients type="wholesaler" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[65px] md:mt-[100px]">
                        {/* <PopUpDiscount /> */}
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">

                            <div className="flex justify-between items-center text-center">
                                <Link to={'/wholesaler/home'}><img className="" src={iconBack} /></Link>
                                <h1 className="w-full text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:pl-14">Products with 20% Black Friday discount</h1>

                                <div className="flex justify-center items-center">
                                    buttons
                                </div>
                            </div>


                            {/* <ProductListCard
                                key={1}
                                product={"Product"}
                                isParamsSearch={""}
                                onCart={""}
                                onPurcharse={""}
                                onAddWishList={""}
                                onCompatible={""}
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DiscountList