import { _delete, _get, _getParams, _post, _put } from "./http";

export const getInventoryAddId = (id) => _get(`/inventaryAdd/${id}`);

export const getInventoryAdd = (page) => _get(`/inventaryAdd?page=${page}&limit=3`);

export const getInventoryAddWithRacks = (id) => _get(`/inventaryAdd/getByIdwithRacks/${id}`);

export const searchInventoryAdd = (page, limit, filterParams) => _getParams(`/inventaryAdd/getAllWithFilters?page=${page}&limit=${limit}`, filterParams);

export const saveInventoryAdd = (inventoryObject) => _post(`/inventaryAdd`, inventoryObject);

export const updateInventoryAddId = (id, inventoryObject) => _put(`/inventaryAdd/${id}`, inventoryObject);

export const updateInventoryAddStatus = (id, inventoryObject) => _put(`/inventaryAdd/${id}/status`, inventoryObject);

export const deleteInventoryAddId = (id) => _delete(`/inventaryAdd/${id}`);
