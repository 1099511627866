import { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import Features from "../../../components/features/Features";

const RowsProducts = ({ enableEditR, product, available, productSelected, onEdit, onDelete, onSelect, onCompatible }) => {
  const [isEnable, setIsEnable] = useState(false);

  const handleCheck = (evt) => {
    onSelect(product);
    setIsEnable(!evt.target.checked);
  };

  useEffect(() => {
    if (productSelected !== null && productSelected.productInventory._id === product.productInventory._id) {
      setIsEnable(true);
    } else {
      setIsEnable(false);
    }
  }, [productSelected, product.productInventory._id]);

  return (
    <tr>
      {
        available ?
          <>
            <th className={`w-[75%] border-[0.5px] border-ag-secondary-light ${product?.isSelected || productSelected !== null ? "text-green-500" : "text-ag-secondary-letter"} text-xs font-normal px-2 py-3`}>
              {
                product.productInventory.product.compatible_vehicles.map((prodComp, i) => {
                  if (i < 1) {
                    return (
                      <p className="truncate w-[280px] md:w-[380px] xl:w-[480px] m-auto" key={i} title={
                        `${prodComp?.nag} ` +
                                                `${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year} ` +
                                                `${prodComp?.brand} ` +
                                                `${prodComp?.model} ` +
                                                `${prodComp?.type_car}`
                      }>
                        { prodComp?.nag }{' - '}
                        { String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year }{' '}
                        { prodComp?.brand }{' '}
                        { prodComp?.model }{' '}
                        { prodComp?.type_car }
                      </p>
                    );
                  }
                })
              }
              {
                <div className="flex justify-center">
                  <Features element={product.productInventory.product} color={'#7a7a7a'} textSize={'12px'} seeTitle={true} />
                </div>
              }
              {
                product.productInventory.product.compatible_vehicles.length > 1 ?
                  <p className="truncate w-[280px] md:w-[380px] xl:w-[480px] m-auto font-semibold cursor-pointer" onClick={() => onCompatible(product.productInventory.product)}>
                                        See compatible vehicles +
                  </p>
                  : null
              }
            </th>
            <th className={`w-[15%] border-[0.5px] border-ag-secondary-light ${product?.isSelected || productSelected !== null ? "text-green-500" : "text-ag-secondary-letter"} text-xs font-normal px-2 py-3`}>
              {product?.quantity < 0 ? 0 : product?.quantity}
            </th>
            <th className={`w-[10%] border-[0.5px] border-ag-secondary-light ${product?.isSelected || productSelected !== null ? "text-green-500" : "text-ag-secondary-letter"} text-xs font-normal px-2 py-3`}>
              <input
                className={`w-4 h-4 ${ product?.isSelected || productSelected !== null ? "accent-green-600" : "accent-ag-primary" } ${ productSelected !== null ? "" : "cursor-pointer"}`}
                type="radio"
                name="product_available_wh"
                onChange={(evt) => handleCheck(evt)}
                disabled={productSelected !== null ? !isEnable : false}
                defaultChecked={productSelected?.productInventory?._id === product?.productInventory?._id ? true : false}
              />
            </th>
          </>
          :
          <>
            <th className="w-[65%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-2">
              {
                product.productInventory.product.compatible_vehicles.map((prodComp, i) => {
                  if (i < 1) {
                    return (
                      <p className="truncate w-[180px] md:w-[300px] xl:w-[380px] m-auto" key={i} title={
                        `${prodComp?.nag} ` +
                                                `${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year} ` +
                                                `${prodComp?.brand} ` +
                                                `${prodComp?.model} ` +
                                                `${prodComp?.type_car}`
                      }>
                        { prodComp?.nag }{' - '}
                        { String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year }{' '}
                        { prodComp?.brand }{' '}
                        { prodComp?.model }{' '}
                        { prodComp?.type_car }
                      </p>
                    );
                  }
                })
              }
              {
                product.productInventory.product.compatible_vehicles.length > 1 ?
                  <p className="truncate w-[180px] md:w-[300px] xl:w-[380px] m-auto font-semibold cursor-pointer" onClick={() => onCompatible(product.productInventory.product)}>
                                        See compatible vehicles +
                  </p>
                  : null
              }
              <div className="flex justify-center">
                <Features element={product.productInventory.product} color={'#7a7a7a'} textSize={'12px'} seeTitle={true} />
              </div>
            </th>
            <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
              {product?.quantity < 0 ? 0 : product?.quantity}
            </th>
            <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-2">
              <button
                className="text-2xl disabled:bg-zinc-200"
                type="button"
                disabled={!enableEditR}
                onClick={() => onEdit(product)}
              >
                <MdEdit />
              </button>
            </th>
            <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-2">
              <button
                className="text-2xl disabled:bg-zinc-200"
                type="button"
                disabled={!enableEditR}
                onClick={() => onDelete(product)}
              >
                <MdDelete />
              </button>
            </th>
          </>
      }
    </tr>
  );
};

export default RowsProducts;