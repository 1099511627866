import { useState, useEffect } from 'react';
import { toCamelCase } from '../../../../utils/Utilities';
import Switch from 'react-switch';

function CardPermissions({ option, handleToggleChange, permissionsInProgress }) {
  const [savedPermissions, setSavedPermissions] = useState({});
  const [isRoleActive, setIsRoleActive] = useState(false);

  const handleRoleToggle = (checked, role) => {
    if (checked) {
      const restoredPermissions = savedPermissions[role] || option.permissions.map(p => p.value);

      const permissionsWithView = [...restoredPermissions, "VIEW"];

      permissionsWithView.forEach(permission => {
        handleToggleChange(true, `${role}-${permission}`);
      });
    } else {
      const currentPermissions = option.permissions
        .filter(p => permissionsInProgress[`${role}-${p.value}`])
        .map(p => p.value);

      setSavedPermissions(prev => ({ ...prev, [role]: currentPermissions.length ? [...currentPermissions, "VIEW"] : [] }));

      option.permissions.forEach(permission => {
        handleToggleChange(false, `${role}-${permission.value}`);
      });

      handleToggleChange(false, `${role}-VIEW`);
    }

    handleToggleChange(checked, role);
    setIsRoleActive(checked);
  };


  useEffect(() => {
    const hasActivePermissions = option.permissions.some(permission => permissionsInProgress[`${option.role}-${permission.value}`]);
    setIsRoleActive(hasActivePermissions);
  }, [permissionsInProgress, option.role, option.permissions]);


  return (
    <div className="border rounded-md p-4 mb-[20px] text-ag-secondary">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <label className="inline-flex relative items-center cursor-pointer">
            <Switch
              id={option.role}
              onChange={(checked, e, id) => { handleRoleToggle(checked, id); }}
              checked={isRoleActive}
              uncheckedIcon
              checkedIcon={false}
            />
            <span className="ml-3 text-[18px] text-ag-secondary font-bold">
              {option.role === 'ORDERS' ? 'Orders, Returns and Wish List' : toCamelCase(option.role)}
            </span>
          </label>
        </div>
      </div>

      <div className='ml-[15px]'>
        <p className="mt-[15px] text-sm text-muted-foreground">{toCamelCase(option.role)} permissions</p>

        <div className="flex flex-wrap mt-2">
          {option.permissions.map((permission) => {
            const checkboxKey = `${option.role}-${permission.value}`;

            return (
              <div key={permission.value} className='flex items-center gap-[5px] mr-[20px]'>
                <input
                  type="checkbox"
                  id={checkboxKey}
                  checked={permissionsInProgress[checkboxKey] || false}
                  disabled={!isRoleActive}
                  className="w-4 h-4 rounded accent-[#6e950070]"
                  onChange={(e) => {
                    const { id, checked } = e.target;
                    handleToggleChange(checked, id);
                  }}
                />
                <label className="text-base text-ag-secondary">
                  {permission.label}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CardPermissions;
