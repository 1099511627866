import { _delete, _get, _getParams, _post, _put } from "./http";

export const getUser = (id) => _get(`/users/${id}`);

export const getUsersMayMinPurcharse = (filterParams) => _getParams(`/users/purchases`, filterParams);

export const getUsers = () => _get(`/users`);

export const saveUser = (userObject) => _post(`/users`, userObject);

export const updateUser = (id, userObject) => _put(`/users/${id}`, userObject);

export const deleteUser = (id) => _delete(`/users/${id}`);

export const getAllUsers = (filterParams) => _getParams(`/users/allClients`, filterParams);
