import React from 'react'
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getProductEspecificationsBrands, getProductEspecificationsModel, getProductEspecificationsYears, getProductEspecificationVendors } from "../../../services/Product";



const FiltersPWRWT = ({ enableFilters, onFilters, years, makes, models, vendors, paymentMethodOpts, typeOpts, statusOpts }) => {
    
       const navigate = useNavigate();
       const [focusClass, setFocusClass] = useState({
        year: { placeholder: "Year", status: false },
        brand: { placeholder: "Make", status: false },
        model: { placeholder: "Model", status: false },
        vendors: { placeholder: "Vendors", status: false },
        paymentMethod: { placeholder: "Payment Method", status: false },
        type: { placeholder: "Type", status: false },
        status: { placeholder: "Status", status: false }
    });

    const [yearsOptions, setYearsOptions] = useState([]);
    const [makeOptions, setMakeOptions] = useState([]);
    const [modelOptions, setModelOptions] = useState([]);
    const [setVendorOptions] = useState([]);
       
    const [options, setOptions] = useState({
        year: "",
        brand: "",
        model: "",
        vendors: "",
        paymentMethod: "",
        type: "",
        status: ""
    });

    const getYears = () => {
        getProductEspecificationsYears().then((res) => {
            if (res.status === 200) setYearsOptions(res.data);
        });
    };

    const getBrands = () => {
        getProductEspecificationsBrands().then((res) => {
            if (res.status === 200) setMakeOptions(res.data);
        });
    };

    const getModels = (brandParam) => {
        getProductEspecificationsModel(brandParam).then((res) => {
            if (res.status === 200) setModelOptions(res.data);
        });
    };

    const getVendors = () => {
        getProductEspecificationVendors().then((res) => {
            if (res.status === 200) setVendorOptions(res.data);
        });
    };

    const handleChangeOpts = (evt) => {
        const { name, value } = evt.target;

        if (name === "year") {
            setOptions({ year: value, brand: "", model: "", vendor: options.vendor, paymentMethod: options.paymentMethod, type: options.type, status: options.status });
            setMakeOptions([]); 
            setModelOptions([]); 
            if (value) {
                getBrands(value);
            }
            setFocusClass({
                ...focusClass,
                brand: { placeholder: "Make", status: false },
                model: { placeholder: "Model", status: false }
            });
        } else if (name === "brand") {
            setOptions({ ...options, brand: value, model: "" });
            setModelOptions([]);
            if (value) {
                getModels(value);
            }
            setFocusClass({
                ...focusClass,
                model: { placeholder: "Model", status: false }
            });
        } else {
            setOptions({ ...options, [name]: value });
        }
    };

    const handleChange = (evt) => {
        const { name, value } = evt.currentTarget;
        if (value === "default") {
            setOptions({ ...options, [name]: "" });
        } else {
            setOptions({ ...options, [name]: value });
        }
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        onFilters(options);
    };

    useEffect(() => {
        getYears();
        getBrands();
        getModels();
        getVendors();
    }, []);

    return (
        <>
            <div>
                <form onSubmit={handleSubmit}>
                    <div className="grid gap-x-3 lg:gap-x-7 gap-y-2 md:grid-cols-5">
                        <div className="md:col-span-4 grid gap-x-3 lg:gap-x-7 gap-y-2 md:grid-cols-3">
                            <div >
                                {/* <select
                                    className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.year.status ? "text-left" : "text-center"}`}
                                    name="year"
                                    onChange={handleChange}
                                    placeholder={focusClass.year.placeholder}
                                    disabled={!enableFilters}
                                    onFocus={() => setFocusClass({ ...focusClass, year: { placeholder: "", status: true } })}
                                    onBlur={(evt) => evt.target.value === "default" ? setFocusClass({ ...focusClass, year: { placeholder: "Year", status: false } }) : null}
                                >
                                    <option value="default">Year</option>
                                    {
                                        years.map((element, index) => {
                                            return <option key={index} value={element.value}>{element.label}</option>;
                                        })
                                    }
                                </select> */}
                                <select
                                    className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-[9.5px] px-4 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.year.status ? "text-left" : "text-center"}`}
                                    name="year"
                                    value={options.year}
                                    onChange={handleChangeOpts}
                                    disabled={!enableFilters}
                                    onFocus={() => setFocusClass({ ...focusClass, year: { placeholder: "", status: true } })}
                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({ ...focusClass, year: { placeholder: "Year", status: false } }) : null}
                                >
                                    <option value="">Year</option>
                                    {
                                        yearsOptions.map((element, index) => {
                                            return <option key={index} value={element.year}>{element.year}</option>;
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                {/* <select
                                    className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.brand.status ? "text-left" : "text-center"}`}
                                    name="brand"
                                    onChange={handleChange}
                                    placeholder={focusClass.brand.placeholder}
                                    disabled={!enableFilters}
                                    onFocus={() => setFocusClass({ ...focusClass, brand: { placeholder: "", status: true } })}
                                    onBlur={(evt) => evt.target.value === "default" ? setFocusClass({ ...focusClass, brand: { placeholder: "Make", status: false } }) : null}
                                >
                                    <option value="default">Make</option>
                                    {
                                        makes.map((element, index) => {
                                            return <option key={index} value={element.value}>{element.label}</option>;
                                        })
                                    }
                                </select> */}
                                <select
                                    className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-[9.5px] px-4 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.brand.status ? "text-left" : "text-center"}`}
                                    name="brand"
                                    value={options.brand}
                                    onChange={handleChangeOpts}
                                    disabled={!enableFilters || !options.year}
                                    onFocus={() => setFocusClass({ ...focusClass, brand: { placeholder: "", status: true } })}
                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({ ...focusClass, brand: { placeholder: "Make", status: false } }) : null}
                                >
                                    <option value="">Make</option>
                                    {
                                        makeOptions.map((element, index) => {
                                            return <option key={index} value={element.brand}>{element.brand}</option>;
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                {/* <select
                                    className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.model.status ? "text-left" : "text-center"}`}
                                    name="model"
                                    onChange={handleChange}
                                    placeholder={focusClass.model.placeholder}
                                    disabled={!enableFilters}
                                    onFocus={() => setFocusClass({ ...focusClass, model: { placeholder: "", status: true } })}
                                    onBlur={(evt) => evt.target.value === "default" ? setFocusClass({ ...focusClass, model: { placeholder: "Model", status: false } }) : null}
                                >
                                    <option value="default">Model</option>
                                    {
                                        models.map((element, index) => {
                                            return <option key={index} value={element.value}>{element.label}</option>;
                                        })
                                    }
                                </select> */}
                                <select
                                    className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-[9.5px] px-4 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.model.status ? "text-left" : "text-center"}`}
                                    name="model"
                                    value={options.model}
                                    onChange={handleChangeOpts}
                                    disabled={!enableFilters || !options.brand}
                                    onFocus={() => setFocusClass({ ...focusClass, model: { placeholder: "", status: true } })}
                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({ ...focusClass, model: { placeholder: "Model", status: false } }) : null}
                                >
                                    <option value="">Model</option>
                                    {
                                        modelOptions.map((element, index) => {
                                            return <option key={index} value={element.model}>{element.model}</option>;
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div>
                            {/* <button
                                className="w-full text-center bg-ag-primary-light text-white text-xs lg:text-base border border-ag-primary-light p-[7.5px] rounded-xl disabled:bg-zinc-200"
                                type="button"
                                onClick={() => navigate("/appointments")}
                            >
                                Appointments
                            </button> */}
                            <button
                                className="flex items-center justify-center w-full flex text-center bg-ag-primary-light text-white text-xs lg:text-base border border-ag-primary-light p-[7.5px] rounded-xl disabled:bg-zinc-200"
                                type="button"
                                onClick={() => navigate("/quotes")}
                            >
                                Quotes
                            </button>
                        </div>
                        <div className="md:col-span-4 grid gap-x-3 lg:gap-x-7 gap-y-2 md:grid-cols-3">
                            <div>
                                <select
                                    className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.paymentMethod.status ? "text-left" : "text-center"}`}
                                    name="paymentMethod"
                                    onChange={handleChange}
                                    placeholder={focusClass.paymentMethod.placeholder}
                                    disabled={!enableFilters}
                                    onFocus={() => setFocusClass({ ...focusClass, paymentMethod: { placeholder: "", status: true } })}
                                    onBlur={(evt) => evt.target.value === "default" ? setFocusClass({ ...focusClass, paymentMethod: { placeholder: "Payment Method", status: false } }) : null}
                                >
                                    <option value="default">Payment Method</option>
                                    {
                                        paymentMethodOpts.map((element, index) => {
                                            return <option key={index} value={element.value}>{element.label}</option>;
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                <select
                                    className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.type.status ? "text-left" : "text-center"}`}
                                    name="type"
                                    onChange={handleChange}
                                    placeholder={focusClass.type.placeholder}
                                    disabled={!enableFilters}
                                    onFocus={() => setFocusClass({ ...focusClass, type: { placeholder: "", status: true } })}
                                    onBlur={(evt) => evt.target.value === "default" ? setFocusClass({ ...focusClass, type: { placeholder: "Type", status: false } }) : null}
                                >
                                    <option value="default">Type</option>
                                    {
                                        typeOpts.map((element, index) => {
                                            return <option key={index} value={element.value}>{element.label}</option>;
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                <select
                                    className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.status.status ? "text-left" : "text-center"}`}
                                    name="status"
                                    onChange={handleChange}
                                    placeholder={focusClass.status.placeholder}
                                    disabled={!enableFilters}
                                    onFocus={() => setFocusClass({ ...focusClass, status: { placeholder: "", status: true } })}
                                    onBlur={(evt) => evt.target.value === "default" ? setFocusClass({ ...focusClass, status: { placeholder: "Status", status: false } }) : null}
                                >
                                    <option value="default">Status</option>
                                    {
                                        statusOpts.map((element, index) => {
                                            return <option key={index} value={element.value}>{element.label}</option>;
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div>
                            {/* <button
                                className="w-full text-center bg-ag-secondary text-white text-xs lg:text-base border border-gray-500 p-[7.5px] rounded-xl disabled:bg-zinc-200"
                                type="submit"
                                disabled={!enableFilters}
                            >
                                Filter
                            </button> */}
                            <button
                                className="w-full text-center bg-ag-primary-light text-white text-xs lg:text-base border border-ag-primary-light p-[7.5px] rounded-xl disabled:bg-zinc-200"
                                type="button"
                                onClick={() => navigate("/appointments")}
                            >
                                Appointments
                            </button>
                        </div>
                    </div>
                    <div className="grid gap-x-3 lg:gap-x-7 gap-y-2 md:grid-cols-5 pt-2">
                        <div className="col-span-4">
                        </div>
                        <div className="md:col-span-1 col-span-5 flex items-end justify-end">
                            <button
                                className="w-full text-center bg-ag-secondary text-white text-xs lg:text-base border border-gray-500 p-[7.5px] rounded-xl disabled:bg-zinc-200"
                                type="submit"
                                disabled={!enableFilters}
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default FiltersPWRWT