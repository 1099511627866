const Header = ({ enableCreateR, name, address, phone, onRack, onEditWarehouse })  => {
    return (
        <div className="border-r border-t border-l border-[0.5px] border-ag-secondary-light space-y-2 p-2">
            <div>
                <h1 className="text-[#000E90] text-base lg:text-lg font-semibold cursor-pointer" onClick={() => onEditWarehouse(true)}>
                    { name }
                </h1>
            </div>
            <div className="flex justify-start items-start space-x-3 text-ag-secondary text-xs lg:text-sm">
                <span className="font-bold">Address:</span>
                <span>
                    { address.street },{' '}{ address.locality },{' '}
                    { address.state } { address.postalCode }
                </span>
            </div>
            <div className="flex justify-start items-center space-x-3 text-ag-secondary text-xs lg:text-sm">
                <span className="font-bold">Phone:</span>
                <span>{ phone }</span>
            </div>
            <hr />
            <div className="flex justify-end p-3">
                <button className="w-full md:w-[26%] lg:w-[28%] bg-ag-primary-light text-white text-xs lg:text-base p-2 rounded-xl disabled:bg-zinc-200" type="button" onClick={() => onRack(true)} disabled={!enableCreateR}>
                    Create Rack
                </button>
            </div>
        </div>
    );
}

export default Header;