import axios from "axios";

export const login = async (user) => {
  return await axios.post(`${process.env.REACT_APP_API_URL}/users/login`, user).then((res) => {
    return { status: res.status, data: res.data };
  }).catch((err)=>{
    return err;
  });
};

export const resetPassword = async (user) => {
  return await axios.post(`${process.env.REACT_APP_API_URL}/users/passwordResetRequest`, user).then((res) => {
    return { status: res.status, data: res.data };
  }).catch((err)=>{
    return err;
  });
};

export const changePassword = async (user) => {
  return await axios.post(`${process.env.REACT_APP_API_URL}/users/passwordReset`, user).then((res) => {
    return { status: res.status, data: res.data };
  }).catch((err)=>{
    return err;
  });
};