import { formatCurrency, formatDateEng } from "../../../utils/Utilities";

const RowsReturns = ({ report }) => {
  const groupClass = `group hover:bg-gray-100 transition-colors duration-200`;
  const cellClass = "px-6 py-4 whitespace-nowrap text-sm text-gray-500 group-hover:text-gray-900 transition-colors duration-200";

  return (
    <tr className={groupClass}>
      <td className={cellClass}>
        <p className="truncate w-20 m-auto">{ formatDateEng(report?.createdAt) }</p>
      </td>
      <td className={cellClass}>
        <p className="truncate w-44 m-auto">{ report?.productOrder?.productInventory?.product?.nags }</p>
      </td>
      <td className={cellClass}>
        <p className="truncate w-20 m-auto">{ report?.quantity }</p>
      </td>
      <td className={cellClass}>
        <p className="truncate w-44 m-auto">{ report?.warehouse?.name }</p>
      </td>
      <td className={cellClass}>
        <p className="truncate w-44 m-auto">{ report?.wholesaler ? report?.wholesaler?.names : "-----"  }</p>
      </td>
      <td className={cellClass}>
        <p className="truncate w-20 m-auto">{ report?.sale ? `SN-${String(report?.sale?._id).substring(String(report?.sale?._id).length - 5).toLocaleUpperCase()}` : "-----" }</p>
      </td>
      <td className={cellClass}>
        <p className="truncate w-20 m-auto">{ `ON-${String(report?._id).substring(String(report?._id).length - 5).toLocaleUpperCase()}` }</p>
      </td>
      <td className={cellClass}>
        <p className="truncate w-20 m-auto">{ formatCurrency(report?.total) }</p>
      </td>
      <td className={cellClass}>
        <p className="truncate w-44 m-auto">{ report?.employee ? `${report.employee.names} ${report.employee.lastNames}` : "-----" }</p>
      </td>
    </tr>
  );
};

export default RowsReturns;