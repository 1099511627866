import { useEffect } from "react";
import { useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { BiSearch } from "react-icons/bi";
import { RiEqualizerFill } from "react-icons/ri";
import { Link } from "react-router-dom";

const Filters = ({ enableFilters, typeView, warehouses, status, clients, wordFilter, onFilters }) => {
    const ref1 = useRef();
    const ref2 = useRef();

    const [showFilters, setShowFilters] = useState(true);

    const [focusClass, setFocusClass] = useState({
        search: { placeholder: "Search", status: false },
        warehouse: { placeholder: "", status: false },
        status: { placeholder: "", status: false },
        client: { placeholder: "", status: false }
    });

    const [options, setOptions] = useState({
        search: "",
        warehouse: "",
        status: "",
        client: "",
        dateStart: "",
        dateEnd: ""
    });

    const handleChange = (evt) => {
        if (evt.currentTarget.name === "search") {
            setOptions ({...options, [evt.currentTarget.name]: evt.currentTarget.value});
            onFilters(evt.currentTarget.value, options);
        } else {
            if (evt.currentTarget.value === "default") {
                setOptions({...options, [evt.currentTarget.name]: ""});
            } else {
                setOptions({...options, [evt.currentTarget.name]: evt.currentTarget.value});
            }
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        onFilters(options.search, options);
    }

    useEffect(() => {
        if (wordFilter && wordFilter !== null) handleChange(wordFilter);
    }, [wordFilter]);

    return (
        <>
            <form onSubmit={handleSubmit}>
                { showFilters ?
                    <div className="grid gap-3 lg:gap-7 lg:grid-cols-5">
                        <div className="space-y-3 md:space-y-0 block md:flex lg:block md:justify-start space-x-0 md:space-x-3 lg:space-x-0">
                            <div className="block lg:hidden">
                                <div className="flex justify-center items-center border border-gray-500 rounded-xl">
                                    <input
                                        className={`w-full flex justify-center items-center space-x-3 text-ag-secondary-letter text-xs lg:text-sm py-2 rounded-xl disabled:bg-zinc-200 truncate ${ focusClass.search.status ? "text-left" : "text-center" }`}
                                        type="text"
                                        name="search"
                                        placeholder={focusClass.search.placeholder}
                                        onChange={handleChange}
                                        disabled={!enableFilters}
                                        onFocus={() => setFocusClass({...focusClass, search: { placeholder: "", status: true }})}
                                        onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, search: { placeholder: "Search", status: false }}) : null}
                                        maxLength={60}
                                    />
                                    <div 
                                        className="border-none p-[10px] rounded-md disabled:bg-zinc-200"
                                    >
                                        <span className="text-ag-secondary-letter text-xl"><BiSearch /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:pb-2">
                                <button
                                    className="w-full flex justify-center items-center space-x-3 text-ag-secondary-letter text-xs lg:text-sm border border-gray-500 px-2 md:px-6 lg:px-2 py-2 rounded-xl disabled:bg-zinc-200"
                                    type="button"
                                    onClick={() => setShowFilters(!showFilters)}
                                    disabled={!enableFilters}
                                >
                                    <span className="text-lg lg:text-2xl"><RiEqualizerFill /></span>
                                    <span>Filters</span>
                                </button>
                            </div>
                            <div>
                                <Link to={ typeView === "primary" ? "/returns/approved" : "/returns"}>
                                    <button
                                        className="w-full bg-ag-primary-light text-white text-xs lg:text-sm px-2 md:px-6 lg:px-2 py-[10px] rounded-xl disabled:bg-zinc-200"
                                        type="button"
                                    >
                                        { typeView === "primary" ? "Return History" : "Return" }
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="lg:col-span-4 space-y-3">
                            <div className="border border-[0.5px] border-ag-secondary-light p-3 rounded-xl">
                                <div className="grid md:grid-cols-3 gap-x-10 gap-y-3">
                                    <div>
                                        <div>
                                            <select
                                                className="w-full text-ag-secondary-letter text-sm border border-gray-500 py-1 px-4 rounded-xl disabled:bg-zinc-200 text-center truncate"
                                                name="warehouse"
                                                onChange={handleChange}
                                                disabled={!enableFilters}
                                            >
                                                <option className="bg-zinc-200 font-semibold italic" value="default">Warehouse</option>
                                                {
                                                    warehouses.map((element, index) => {
                                                        return <option key={index} value={element._id}>{element.name}</option>;
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <select
                                                className="w-full text-ag-secondary-letter text-sm border border-gray-500 py-1 px-4 rounded-xl disabled:bg-zinc-200 text-center truncate"
                                                name="status"
                                                onChange={handleChange}
                                                disabled={!enableFilters}
                                            >
                                                <option className="bg-zinc-200 font-semibold italic" value="default">Status</option>
                                                {
                                                    status.map((element, index) => {
                                                        return <option key={index} value={element.value}>{element.label}</option>;
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <select
                                                className="w-full text-ag-secondary-letter border border-gray-500 text-sm py-1 px-4 rounded-xl disabled:bg-zinc-200 text-center truncate"
                                                name="client"
                                                onChange={handleChange}
                                                disabled={!enableFilters}
                                            >
                                                <option className="bg-zinc-200 font-semibold italic" value="default">Client</option>
                                                {
                                                    clients.map((element, index) => {
                                                        return (
                                                            <option key={index} value={element._id}>
                                                                {/* { String(element._id).substring(String(element._id).length - 4).toUpperCase() } */}
                                                                { element?.user?.identifier }
                                                                {' - '}{ element?.names}{' '}{element?.lastNames}
                                                            </option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="w-full flex justify-center items-center">
                                        <span
                                            className="w-[30%] md:w-[45%] lg:w-[37%] bg-ag-secondary text-xs text-white text-center border border-gray-500 py-[6px] rounded-l-xl"
                                        >
                                            Start
                                        </span>
                                        <div className="customDatePickerWidth">
                                            <ReactDatePicker
                                                className={`w-full text-ag-secondary-letter border border-gray-500 px-2 py-[2px] rounded-r-xl disabled:bg-zinc-200`}
                                                placeholderText=""
                                                name="dateStart"
                                                selected={options.dateStart}
                                                onChange={(dateVal) => setOptions({...options, dateStart: dateVal})}
                                                disabled={!enableFilters}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full flex justify-center items-center">
                                        <span
                                            className="w-[30%] md:w-[45%] lg:w-[37%] bg-ag-secondary text-xs text-white text-center border border-gray-500 py-[6px] rounded-l-xl"
                                        >
                                            End
                                        </span>
                                        <div className="customDatePickerWidth">
                                            <ReactDatePicker
                                                className={`w-full text-ag-secondary-letter border border-gray-500 px-2 py-[2px] rounded-r-xl disabled:bg-zinc-200`}
                                                placeholderText=""
                                                name="dateEnd"
                                                selected={options.dateEnd}
                                                onChange={(dateVal) => setOptions({...options, dateEnd: dateVal})}
                                                disabled={!enableFilters}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            className="w-full bg-ag-primary-light text-xs text-white p-[6px] rounded-xl disabled:bg-zinc-200"
                                            type="submit"
                                            disabled={!enableFilters}
                                        >
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    <div className="md:flex md:justify-start md:items-center md:pb-3 space-y-3 md:space-y-0">
                        <div className="block lg:hidden">
                            <div className="flex justify-center items-center border border-gray-500 rounded-xl">
                                <input
                                    className={`w-full flex justify-center items-center space-x-3 text-ag-secondary-letter text-xs lg:text-base px-2 py-3 rounded-xl disabled:bg-zinc-200 truncate ${ focusClass.search.status ? "text-left" : "text-center" }`}
                                    type="text"
                                    name="search"
                                    placeholder={focusClass.search.placeholder}
                                    onChange={handleChange}
                                    disabled={!enableFilters}
                                    onFocus={() => setFocusClass({...focusClass, search: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, search: { placeholder: "Search", status: false }}) : null}
                                    maxLength={60}
                                />
                                <div 
                                    className="border-none p-[10px] rounded-md disabled:bg-zinc-200"
                                >
                                    <span className="text-ag-secondary-letter text-lg lg:text-xl"><BiSearch /></span>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-[20%] xl:w-[16%] md:px-3 lg:px-0 pr-0 lg:pr-5">
                            <button
                                className="w-full flex justify-center items-center space-x-1 text-ag-secondary-letter text-xs lg:text-sm border border-gray-500 px-2 py-2 rounded-xl disabled:bg-zinc-200"
                                type="button"
                                onClick={() => setShowFilters(!showFilters)}
                                disabled={!enableFilters}
                            >
                                <span className="text-xl lg:text-2xl"><RiEqualizerFill /></span>
                                <span>Filters</span>
                            </button>
                        </div>
                        <div className="w-full md:w-[22%] xl:w-[16%]">
                            <Link to={typeView === "primary" ? "/returns/approved" : "/returns"}>
                                <button
                                    className="w-full bg-ag-primary-light text-white text-xs lg:text-sm px-2 py-[10px] rounded-xl disabled:bg-zinc-200"
                                    type="button"
                                >
                                    { typeView === "primary" ? "Return History" : "Return" }
                                </button>
                            </Link>
                        </div>
                    </div>
                }
            </form>
        </>
    );
}

export default Filters;