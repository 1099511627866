import { useEffect, useState } from "react";
import { getAllInventoryAccesory } from "../../../services/InventoryAccessory";

function SelectAccesory({ onClose, addToCart }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [quantity, setQuantity] = useState('1');
  const [accessories, setAccessories] = useState([]);

  const handleQuantityChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    if (value === '' || parseInt(value) > 0) {
      setQuantity(value);
    }
  };

  const handleAddProduct = (isBulkOrder) => {
    addToCart(isBulkOrder, selectedItem, quantity, true);
    setSelectedItem(null);
    setQuantity('1');
  };

  useEffect(() => {
    const getData = async () => {
      const response = await getAllInventoryAccesory();

      if (response.status === 200) {
        setAccessories(response.data.records);
      }
    };

    getData();
  }, []);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-4xl rounded-lg bg-white p-6">
        <div className="mb-4 flex items-center justify-between">
          <div>
          </div>

          <h2 className="text-xl font-semibold">Inventory specification</h2>

          <button
            onClick={() => onClose()}
            className="text-gray-500 hover:text-gray-700"
          >
                ✕
          </button>
        </div>
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="mb-4 w-full rounded-md border border-gray-300 px-4 py-2"
        />
        <div className="max-h-[60vh] overflow-auto">
          <table className="w-full">
            <thead className="bg-gray-100">
              <tr>
                <th className="border px-4 py-2">Warehouse</th>
                <th className="border px-4 py-2">Accessory name</th>
                <th className="border px-4 py-2">Barcodes</th>
                <th className="border px-4 py-2">Sales Price</th>
                <th className="border px-4 py-2">Stock</th>
              </tr>
            </thead>
            <tbody>
              {accessories.length && accessories.map((item) => {
                const accessory = item.accessoryInventory.accessory;

                return (
                  <tr
                    key={accessory._id}
                    onClick={() => setSelectedItem({...accessory, cost: item.accessoryInventory.cost ,accessoryInventory: { _id: item.accessoryInventory._id }})}
                    className={`cursor-pointer transition-colors ${
                      selectedItem && selectedItem._id === accessory._id ? 'bg-green-100' : 'hover:bg-gray-50'
                    }`}
                  >
                    <td className="border px-4 py-2">{item.warehouse.name}</td>
                    <td className="border px-4 py-2">{accessory.name}</td>
                    <td className="border px-4 py-2">{accessory.barcode1}</td>
                    <td className="border px-4 py-2">${item.accessoryInventory.cost.toFixed(2)}</td>
                    <td className="border px-4 py-2">{item.totalQuantity}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="mt-4 flex items-center justify-end gap-4">
          <input
            type="text"
            value={quantity}
            onChange={handleQuantityChange}
            placeholder="Quantity"
            className="w-32 rounded-md border border-gray-300 px-4 py-2"
          />
          <button
            onClick={() => handleAddProduct(true)}
            className="rounded-md bg-orange-500 px-4 py-2 text-white hover:bg-orange-600"
            disabled={!selectedItem}
          >
            Bulk order
          </button>
          <button
            onClick={() => handleAddProduct(false)}
            className={`rounded-md bg-emerald-500 ${quantity > 1 ? 'opacity-50' : 'opacity-1 hover:bg-emerald-600'} px-4 py-2 text-white `}
            disabled={!selectedItem || quantity > 1}
          >
            Add product
          </button>
        </div>
      </div>
    </div>
  );
}

export default SelectAccesory;