import React from "react";

const FrontVentLeft = ({ isSelected, isAvailable, onClick }) => {
  return (
    <svg className="cursor-pointer outline-none" onClick={onClick} data-tooltip-id="car" data-tooltip-content="Front Vent (left)">
      <path
        d="M87.26 183.08V201.48L54.46 188.28V170.48L87.26 183.08Z"
        fill={isSelected ? "#F41A28" :isAvailable ? "#1D8F43" : "#D3D3D4"}
      />
      ;
    </svg>
  );
};

export default FrontVentLeft;
