import { BsFillBellFill } from "react-icons/bs";
import { MdMenuOpen, MdRequestQuote } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { PermitsContext } from "../../services/Permits";
import { IoMdTime } from "react-icons/io";
import Logo from "../../assets/images/logo_a.png";

const Navbar = ({ sidebarOpen, setSidebarOpen }) => {
  const navigate = useNavigate();
  const { dataSession, permits, numNotif, getNumberOfNotifications, setShowNotifications } = useContext(PermitsContext);

  useEffect(() => {
    if (permits['QUOTES']['VIEW']) {
      getNumberOfNotifications();
      const interval = setInterval(() => getNumberOfNotifications(), 900000);
      return () => clearInterval(interval);
    }
  }, [dataSession, permits, getNumberOfNotifications]);

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleProfileClick = () => {
    navigate("/profile");
  };

  const handleQuotesClick = () => {
    navigate("/quotes");
  };

  const handleNotificationsClick = () => {
    setShowNotifications(true);
  };

  const renderNotificationIcon = () => {
    const notificationDisplay = numNotif > 99 ? "99+" : numNotif;

    return (
      numNotif !== "" && numNotif > 0 ? (
        <div className="absolute top-[-6px] right-[-14px] mr-0 bg-red-500 rounded-xl">
          <span className="flex justify-center items-center px-[6px] py-[2px] text-white text-[9px]">
            {notificationDisplay}
          </span>
        </div>
      ) : (
        <div className="absolute top-[-6px] right-[-14px] mr-0 bg-zinc-500 rounded-full">
          <span className="flex justify-center items-center px-[3px] py-[3px] text-white text-[18px]">
            <IoMdTime />
          </span>
        </div>
      )
    );
  };

  const renderSidebarButton = () => (
    <button aria-expanded={sidebarOpen} onClick={handleSidebarToggle}>
      <span className="text-2xl md:text-3xl text-white">
        <MdMenuOpen />
      </span>
    </button>
  );

  const renderLogo = () => (
    <div className="pl-5">
      <img className="h-auto w-[130px] md:w-[220px] m-auto" src={Logo} alt="Logo" />
    </div>
  );

  return (
    <div className="relative">
      <div className="bg-ag-secondary-light">
        <div className={`mx-auto px-[25px] ${!sidebarOpen ? "py-[6px]" : "py-[32px]"}`}>
          <div className="flex justify-between items-center">
            {!sidebarOpen ? (
              <div className="flex justify-between items-center">
                {renderSidebarButton()}
                {renderLogo()}
              </div>
            ) : (
              <div className="pl-[270px]">{renderSidebarButton()}</div>
            )}
            <div className="flex items-center justify-end space-x-3 md:space-x-7 pr-0 md:pr-10">
              <button type="button" onClick={handleProfileClick}>
                <span className="text-2xl md:text-3xl text-white">
                  <FaUserCircle />
                </span>
              </button>
              {permits['QUOTES']['VIEW'] && (
                <button type="button" onClick={handleQuotesClick}>
                  <span className="text-2xl md:text-3xl text-white">
                    <MdRequestQuote />
                  </span>
                </button>
              )}
              {permits['QUOTES']['VIEW'] && (
                <button className="relative" type="button" onClick={handleNotificationsClick}>
                  <span className="text-2xl md:text-3xl text-white">
                    <BsFillBellFill />
                  </span>
                  {renderNotificationIcon()}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
