import { _delete, _get, _post, _put } from "./http";

export const getAllAccessories = () => _get('/accessories');

export const getAccessoriesByID = (id) => _get(`/accessories/${id}`);

export const updateAccesory = (id, data) => _put(`/accessories/${id}`, data);

export const createAccesory = (data) => _post('/accessories', data);

export const deleteAccessory = (id) => _delete(`/accessories/${id}`);