import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { PermitsContext } from "../../../../services/Permits";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import { paymentOptionCartWholesaler, savePaymentCartWholesaler } from "../../../../services/Purcharse";
import { emptyProductCart } from "../../../../services/Shopping";
import { getWholesalerUser } from "../../../../services/Wholesaler";
import Loader from "../../../../components/loader/Loader";
import NavbarClients from "../../../../components/navbar/NavbarClients";
import SidebarClients from "../../../../components/sidebar/SidebarClients";
import NewPaymentMethod from "../../../fragments/NewPaymentMethod";
import MyCartInformation from "../MyCartInformation";
import PaymentForm from "./PaymentForm";
import PaymentConfirm from "./PaymentConfirm";

const MyCartPayment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dataSession } = useContext(PermitsContext);
  const [loader, setLoader] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isRefreshCreditCard, setIsRefreshCreditCard] = useState(false);
  const [carts, setCarts] = useState(null);

  const handlePayment = (typePayment) => {
    const newCarts = carts.map(element => {
      if (typePayment === "CREDIT_CARD") return {...element, paymentMethod: typePayment, creditCard: null};
      return {...element, paymentMethod: typePayment};
    });
    setCarts(newCarts);
  };

  const handleCreditCard = (dataCard) => {
    const newCarts = carts.map(element => {
      return {...element, paymentMethod: "CREDIT_CARD", creditCard: {
        _id: dataCard._id,
        number: dataCard.cardNumber,
        name: dataCard.nameofOwner
      }};
    });
    setCarts(newCarts);
  };

  const handleContinue = async () => {
    setShowToast(false);
    if (
      carts[0].paymentMethod && (carts[0].paymentMethod === "CASH" || (carts[0].paymentMethod === "CREDIT_CARD" && carts[0].creditCard) ||
            (carts[0].paymentMethod === "CREDIT"))
    ) {
      setLoader(true);
      let totalToPay = 0;
      const creditsRes = await getWholesalerUser(dataSession._id);
      const totalCredits = creditsRes.status === 200 ? creditsRes.data?.availableCredit : 0;
      for (let j = 0; j < carts.length; j++) {
        for (let k = 0; k < carts[j].products.length; k++) {
          totalToPay += Number(carts[j].products[k].price);
        }
      }

      if ((carts[0].paymentMethod === "CASH" || carts[0].paymentMethod === "CREDIT_CARD") || (carts[0].paymentMethod === "CREDIT" && totalCredits >= totalToPay)) {
        let cartsSended = 0;
        for (let i = 0; i < carts.length; i++) {
          const body = { paymentMethod: carts[i].paymentMethod };

          let total = 0;
          const newProducts = carts[i].products.map(elemNP => {
            total += (Number(elemNP?.quantity) * Number(elemNP?.price));
            return {
              productInventory: { _id: elemNP?.productInventory?._id },
              quantity: elemNP?.quantity,
              price: elemNP?.price,
              year: elemNP?.year,
              discount: 0
            };
          });

          if (carts[i].paymentMethod === "CREDIT_CARD") {
            body.creditCard = { _id: carts[i].creditCard._id };
          } else if (carts[i].paymentMethod === "CREDIT") {
            body.paymentMethod = "CASH";
            body.storeCredit = true;
            body.storeCreditTotal = Number(total) + Number(carts[i].costShipping);
          }

          await paymentOptionCartWholesaler(dataSession._id, body).then(async res => {
            if (res.status === 200) {
              const bodyCart = {
                shippingMethod: carts[i].shippingMethod,
                warehouse: carts[i].fromWarehouse,
                costShipping: carts[i].costShipping,
                paymentMethod: carts[i].paymentMethod,
                total: Number(total) + Number(carts[i].costShipping),
                purchasedProducts: newProducts,
                po: carts[i].po
              };

              // Shipping method
              if (carts[i].shippingMethod === "SHIP") {
                bodyCart.userAddress = carts[i].userAddress;
              } else {
                bodyCart.warehouseAddress = carts[i].warehouseAddress;
                bodyCart.fromWarehouse = carts[i].fromWarehouse;
              }

              // Payment
              if (carts[i].paymentMethod === "CREDIT_CARD") {
                bodyCart.creditCard = { _id: carts[i].creditCard._id };
              } else if (carts[i].paymentMethod === "CREDIT") {
                bodyCart.paymentMethod = "CASH";
                bodyCart.storeCredit = true;
                bodyCart.storeCreditTotal = Number(total) + Number(carts[i].costShipping);
              }
              await savePaymentCartWholesaler(dataSession._id, bodyCart).then(resCart => {
                if (resCart.status === 200) cartsSended += 1;
              }).catch(() => {});
            } else {
              setLoader(false);
            }
          });
        }

        setLoader(false);
        if (cartsSended === carts.length) {
          await emptyProductCart(dataSession._id);
          setShowToast(true);
        } else {
          toast.error("There was a problem assigning the payment method. Please try again in a few minutes", alertOptions);
        }
      } else {
        setLoader(false);
        toast.warning("Available credits are not enough to cover the purchase", alertOptions);
      }
    } else {
      toast.info("Please fill in all the required fields of the payment method", alertOptions);
    }
  };

  const handleResult = () => {
    setShowModal(false);
    setLoader(false);
    setIsRefreshCreditCard(true);
    toast.success("Payment method saved successfully", alertOptions);
  };

  useEffect(() => {
    setLoader(true);
    setCarts(location.state);
    setLoader(false);
  }, []);

  return (
    <>
      { loader ? <Loader /> : null }

      {
        showModal ?
          <NewPaymentMethod
            idUser={dataSession._id}
            typeUser="wholesaler"
            onClose={() => setShowModal(false)}
            onLoader={(sts) => setLoader(sts)}
            onResult={() => handleResult()}
          />
          : null
      }

      {
        showToast ?
          <PaymentConfirm
            carts={carts}
            onClose={() => navigate(`/wholesaler/home`)}
            onAccept={() => navigate(`/wholesaler/orders`)}
          />
          : null
      }

      <div>
        <div className="flex flex-row">
          <div className="w-full bg-no-repeat bg-cover fixed z-10">
            <NavbarClients type="wholesaler" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          </div>
        </div>

        <div className="flex h-screen overflow-hidden">
          <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
            <SidebarClients type="wholesaler" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          </div>

          <div className="w-full overflow-auto flex-grow mt-[100px]">
            <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
              <div className="text-center">
                <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">
                  Payment method
                </h1>
              </div>

              <div className="pt-3 lg:flex lg:justify-center lg:items-start lg:space-x-10 space-y-5 lg:space-y-0">
                <div className="w-full lg:w-[65%]">
                  <PaymentForm
                    idWholesaler={dataSession._id}
                    onModal={() => setShowModal(true)}
                    onPayment={handlePayment}
                    onCreditData={handleCreditCard}
                    onRefreshCreditCard={isRefreshCreditCard}
                    onResponseRefreshCreditCard={(sts) => setIsRefreshCreditCard(sts)}
                  />
                </div>

                <div className="w-full lg:w-[35%]">
                  <MyCartInformation cart={carts} onContinue={() => handleContinue()} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyCartPayment;