import { useEffect, useState } from "react";
import { IoMdCloseCircle, IoMdImage } from "react-icons/io";
import { selectProductQuote } from "../../../services/Quotes";
import { searchProductEspecification } from "../../../services/Product";
import { toast } from "react-toastify";
import { alertOptions, formatCurrency, formatDateEng, isOriginalGlass } from "../../../utils/Utilities";
import TableDetail from "./TableDetail";
import ModalCalculate from "./ModalCalculate";
import { PatternFormat } from "react-number-format";

const QuoteDetail = ({ quote, productManual, warehouseOptions, insurersOptions, onClose, onSearch, onRefresh, onLoader, onPrice, onCompatible, onFeatures, onImage }) => {
    const [products, setProducts] = useState([]);
    const [currentInsurence, setCurrentInsurence] = useState("");
    const [statusQuote, setStatusQuote] = useState({ status: false, current: "" });
    const [showCalculate, setShowCalculate] = useState({ status: false, data: null });

    const handleProduct = (evt, item) => {
        const newProduct = products.map(element => {
            if (`${item?.productInventory?._id}${item?.productInventory?.product?._id}` === `${element?.productInventory?._id}${element?.productInventory?.product?._id}`) {
                return { ...element, [evt.target.name]: evt.target.value };
            }
            return element;
        });
        setProducts(newProduct);
    };

    const handleCalculate = (item, insurerSelect) => {
        if (item.price && item.price !== "" && item.vendor && item.vendor !== "" && currentInsurence !== "") {
            if (!isNaN(item.price) && item.price > 0) {
                if (item.time !== "00:00") {
                    setShowCalculate({ ...showCalculate, status: true, data: { item: item, insurer: insurerSelect, quote: quote } });
                } else {
                    toast.warning("Please enter a working time greater than 0", alertOptions);
                }
            } else {
                toast.warning("Please check that the price field is a numeric value", alertOptions);
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    };

    const handleSelect = (item, price, tax) => {
        setShowCalculate({ ...showCalculate, status: false, data: null });
        onLoader(true);
        let discountValue = 0;

        const serviceCF90 = item?.quote?.ceramicFull90 ? 250 : 0;
        const serviceCF70 = item?.quote?.ceramicFull70 ? 200 : 0;
        const serviceWatT = item?.quote?.waterTreatment ? 50 : 0;
        const serviceWSVS = item?.quote?.windshieldVisorStrip ? 50 : 0;
        let totalAdditionalServices = Number(serviceCF90) + Number(serviceCF70) + Number(serviceWatT) + Number(serviceWSVS);

        if (!isOriginalGlass(item)) {
            discountValue = item?.item?.heated === "Y" ? item?.insurer?.discountHeated : item?.insurer?.discountRegular;
        }
        const laborValue = Number(item?.item?.labor_time) === 0 ? 450 : Number(item?.item?.labor_time) * Number(item?.insurer?.hour);
        const netValue = Number(item?.item?.price) - (Number(item?.item?.price) * (discountValue / 100));
        const subTotalValue = netValue + Number(item?.insurer?.kitRegular) + laborValue + totalAdditionalServices;

        const body = {
            vendor: item?.item?.vendor ? item?.item?.vendor : "",
            time: item?.item?.time ? item?.item?.time : "00:00",
            additionalServices: totalAdditionalServices,
            price: Number(item?.item?.price),
            total: subTotalValue + Number(tax),
            tax: tax,
            nag: item?.item?.nag
        };

        selectProductQuote(quote._id, body).then(res => {
            if (res.status === 200) {
                onLoader(false);
                setStatusQuote({ status: true, current: item?.item?._id });
                toast.success("Product selected successfully", alertOptions);
            } else {
                onLoader(false);
                toast.warning("There was a problem selecting the product", alertOptions);
            }
        }).catch(error => {
            onLoader(false);
            toast.error(error.response?.data?.message, alertOptions);
        });
    };

    const handleSend = () => {
        if (statusQuote.status) {
            onRefresh(true);
        } else {
            toast.warning("Please select the product before sending the quote", alertOptions);
        }
    };

    useEffect(() => {
        onLoader(true);

        let insurerSelect = null;
        if (insurersOptions.length > 0) {
            insurerSelect = insurersOptions.find(element => element.name === "Default Insurer");
            if (insurerSelect) setCurrentInsurence(insurerSelect._id);
        }

        if (quote.status === "approved") {
            setStatusQuote({ status: true, current: quote?.selectProduct?._id });
            let productData = quote.selectProduct.nag;
            searchProductEspecification({ nag: productData }).then(res => {
                if (res.status === 200) {
                    let newProductModel = quote.selectProduct;
                    newProductModel.productSpecification = res.data[0];
                    newProductModel.compatible_vehicles = res.data;
                    newProductModel.onlyShow = true;
                    setProducts([newProductModel]);
                }
            });
            onLoader(false);
        } else {
            if (productManual) {
                searchProductEspecification({ nag: productManual.nag }).then(res => {
                    if (res.status === 200) {
                        let productManualAux = { ...productManual };
                        productManualAux.price = productManual?.price;
                        productManualAux.vendor = "Autoglass";
                        productManualAux.time = productManual?.labor_time;
                        productManualAux.insurer = insurerSelect;
                        productManualAux.compatible_vehicles = res.data;
                        setProducts([productManualAux]);
                        onLoader(false);
                    } else {
                        let productManualAux = { ...productManual };
                        productManualAux.price = productManual?.price;
                        productManualAux.vendor = "Autoglass";
                        productManualAux.time = productManual?.labor_time;
                        productManualAux.insurer = insurerSelect;
                        productManualAux.compatible_vehicles = [];
                        setProducts([productManualAux]);
                        onLoader(false);
                    }
                }).catch(() => {
                    let productManualAux = { ...productManual };
                    productManualAux.price = productManual?.price;
                    productManualAux.vendor = "Autoglass";
                    productManualAux.time = productManual?.labor_time;
                    productManualAux.insurer = insurerSelect;
                    productManualAux.compatible_vehicles = [];
                    setProducts([productManualAux]);
                    onLoader(false);
                });
            } else {
                onLoader(false);
            }
        }
    }, []);

    return (
        <>
            {
                showCalculate.status ?
                    <ModalCalculate
                        item={showCalculate.data}
                        onClose={() => setShowCalculate({ ...showCalculate, status: false, data: null })}
                        onSelect={handleSelect}
                    />
                    : null
            }

            <div className="block">
                <div className="fixed inset-0 z-30 flex items-center justify-center">
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-20 outline-none focus:outline-none">
                        <div className="h-[95%] w-[90%] overflow-auto md:w-[700px] lg:w-[800px]">
                            <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="w-full flex items-center justify-center rounded text-center">
                                    <div className="w-[95%]">
                                        <span className="text-xl text-ag-secondary font-bold pl-[5%]">
                                            {quote?.searchType === "photos" ? <p>Quote Details</p> : <p>Quote Details</p>}
                                        </span>
                                    </div>
                                    <div className="w-[5%]">
                                        <button
                                            className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                            onClick={() => onClose(false)}
                                        >
                                            <IoMdCloseCircle />
                                        </button>
                                    </div>
                                </div>
                                <div className="relative px-5 pb-2 pt-2 flex-auto">
                                    <div className="flex flex-col w-full m-auto md:grid md:grid-cols-3">
                                        {
                                            quote.productInventoryAllInfo ?
                                                <div className="w-full">
                                                    <div className="text-left">
                                                        <div>
                                                            <span className="text-ag-secondary-letter text-xs">
                                                                {quote?.productInventoryAllInfo?.productInventory.product.nags}{" "}
                                                                {quote?.productInventoryAllInfo?.productInventory.product.name}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="text-ag-secondary-letter text-xs">
                                                                {"("}{String(quote?.productInventoryAllInfo?.productInventory.product.year).split(",").length > 1 ? `${String(quote?.productInventoryAllInfo?.productInventory.product.year).split(",").sort()[0].replace(" ", "")} - ${String(quote?.productInventoryAllInfo?.productInventory.product.year).split(",").sort()[Number(String(quote?.productInventoryAllInfo?.productInventory.product.year).split(",").length - 1)].replace(" ", "")}` : quote?.productInventoryAllInfo?.productInventory.product.year}{") "}
                                                                {quote?.productInventoryAllInfo?.productInventory.product.brand}{" "}
                                                                {quote?.productInventoryAllInfo?.productInventory.product.model}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="w-full">
                                                    <div className="text-left">
                                                        {
                                                            quote?.searchType === "photos" ?
                                                                <>
                                                                    <div className="text-ag-secondary-letter text-xs">
                                                                        <span>Photo Quote</span>
                                                                    </div>
                                                                    <div>
                                                                        <div className="flex justify-start items-center space-x-1 pt-2">
                                                                            <span className="text-[#000] text-xs font-bold">Retail Customer Information:</span>
                                                                        </div>
                                                                        <div className="flex justify-start items-center space-x-1">
                                                                            <span className="text-ag-secondary-letter text-xs font-semibold">Name:</span>
                                                                            <span className="text-ag-secondary-letter text-xs">{quote?.retailUser?.names + " " + quote?.retailUser?.lastNames}</span>
                                                                        </div>
                                                                        <div className="flex justify-start items-center space-x-1">
                                                                            <span className="text-ag-secondary-letter text-xs font-semibold">Mail:</span>
                                                                            <a className="text-xs text-blue-500 italic underline cursor-pointer" href={`mailto: ${quote?.retailUser?.user?.email}`}>{quote?.retailUser?.user?.email}</a>
                                                                        </div>
                                                                        <div className="flex justify-start items-center space-x-1">
                                                                            <span className="text-ag-secondary-letter text-xs font-semibold">Phone:</span>
                                                                            <PatternFormat
                                                                                value={quote?.retailUser?.phoneNumber}
                                                                                format="(###)###-####"
                                                                                displayType="text"
                                                                                className="text-ag-secondary-letter text-xs"
                                                                            />
                                                                            {/* <span className="text-ag-secondary-letter text-xs">{quote?.retailUser?.phoneNumber}</span> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-ag-secondary-letter text-xs pt-2">
                                                                        <span className="italic">
                                                                            Glass characteristics not available because the quote selected is
                                                                            based on photos
                                                                        </span>
                                                                    </div>

                                                                    {
                                                                        quote?.status === "canceled" ?
                                                                            <div className="text-ag-secondary-letter text-xs pt-2 z-50">
                                                                                <span className="font-semibold">Reason for cancellation: </span>
                                                                                <p className="w-[210px] truncate" title={quote?.reasonCancel}>
                                                                                    {
                                                                                        quote?.reasonCancel ?
                                                                                            String(quote?.reasonCancel).length > 49 ? `${String(quote?.reasonCancel).substring(0, 49)}...` : quote?.reasonCancel
                                                                                            : "Canceled by customer"
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    <div>
                                                                        <div className="flex justify-start items-center space-x-1 pt-2">
                                                                            <span className="text-[#000] text-xs font-bold">Retail Customer Information:</span>
                                                                        </div>
                                                                        <div className="flex justify-start items-center space-x-1">
                                                                            <span className="text-ag-secondary-letter text-xs font-semibold">Name:</span>
                                                                            <span className="text-ag-secondary-letter text-xs">{quote?.retailUser?.names + " " + quote?.retailUser?.lastNames}</span>
                                                                        </div>
                                                                        <div className="flex justify-start items-center space-x-1">
                                                                            <span className="text-ag-secondary-letter text-xs font-semibold">Mail:</span>
                                                                            <a className="text-xs text-blue-500 italic underline cursor-pointer" href={`mailto: ${quote?.retailUser?.user?.email}`}>{quote?.retailUser?.user?.email}</a>
                                                                        </div>
                                                                        <div className="flex justify-start items-center space-x-1">
                                                                            <span className="text-ag-secondary-letter text-xs font-semibold">Phone:</span>
                                                                            <PatternFormat
                                                                                value={quote?.retailUser?.phoneNumber}
                                                                                format="(###)###-####"
                                                                                displayType="text"
                                                                                className="text-ag-secondary-letter text-xs"
                                                                            />
                                                                            {/* <span className="text-ag-secondary-letter text-xs">{quote?.retailUser?.phoneNumber}</span> */}
                                                                        </div>
                                                                    </div>

                                                                    <div className="flex justify-start items-center space-x-1 pt-2">
                                                                        <span className="text-[#000] text-xs font-bold">Vehicle Information:</span>
                                                                    </div>
                                                                    <div>
                                                                        {/* <span className="text-ag-secondary-letter text-xs">
                                                                            {"("}{String(quote?.year).split(",").length > 1 ? `${String(quote?.year).split(",").sort()[0].replace(" ", "")} - ${String(quote?.year).split(",").sort()[Number(String(quote?.year).split(",").length - 1)].replace(" ", "")}` : quote?.year}{") "}
                                                                            {quote?.brand}{" "}
                                                                            {quote?.model}
                                                                        </span> */}
                                                                        <div className="flex justify-start items-center space-x-1">
                                                                            <span className="text-ag-secondary-letter text-xs font-semibold">Year:</span>
                                                                            <span className="text-ag-secondary-letter text-xs">{"("}{String(quote?.year).split(",").length > 1 ? `${String(quote?.year).split(",").sort()[0].replace(" ", "")} - ${String(quote?.year).split(",").sort()[Number(String(quote?.year).split(",").length - 1)].replace(" ", "")}` : quote?.year}{") "}</span>
                                                                        </div>
                                                                        <div className="flex justify-start items-center space-x-1">
                                                                            <span className="text-ag-secondary-letter text-xs font-semibold">Make:</span>
                                                                            <span className="text-ag-secondary-letter text-xs">{quote?.brand}{" "}</span>
                                                                        </div>
                                                                        <div className="flex justify-start items-center space-x-1">
                                                                            <span className="text-ag-secondary-letter text-xs font-semibold">Model:</span>
                                                                            <span className="text-ag-secondary-letter text-xs">{quote?.model}</span>
                                                                        </div>
                                                                        <div className="flex justify-start items-center space-x-1">
                                                                            <span className="text-ag-secondary-letter text-xs font-semibold">Style:</span>
                                                                            <span className="text-ag-secondary-letter text-xs">{quote?.style}</span>
                                                                        </div>
                                                                        {/* <div>
                                                                        <span className="text-ag-secondary-letter text-xs">
                                                                            {quote?.vin}{" "}
                                                                            {quote?.style}{" "}
                                                                            {parseTextTypeGlass(quote?.typeGlass)}
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        <span className="text-ag-secondary-letter text-xs">
                                                                            {quote?.typeService === "REPLACEMENT" ? "(Replacement)" : "(Repair)"}
                                                                        </span>
                                                                    </div> */}
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                        }
                                        <div className="w-full">
                                            <span className="text-ag-secondary-letter text-[100px] text-center m-auto">
                                                {
                                                    quote?.status === "approved" ?
                                                        <div className="w-[100px] m-auto">
                                                            <IoMdImage />
                                                        </div>
                                                        : quote?.productInventoryAllInfo?.productInventory.product?.productImages[0] || quote?.photos[0] ?
                                                            <div className="w-[100px] h-[100px] flex justify-center items-center border border-ag-secondary-light rounded-lg p-1 m-auto cursor-pointer" onClick={() => onImage(quote?.photos ? quote?.photos : quote?.productInventoryAllInfo?.productInventory.product?.productImages)}>
                                                                <img className="w-full h-full object-contain" src={quote?.productInventoryAllInfo?.productInventory.product.productImages[0] || quote?.photos[0]} />
                                                            </div>
                                                            :
                                                            <div className="w-[100px] m-auto">
                                                                <IoMdImage />
                                                            </div>
                                                }
                                            </span>
                                        </div>
                                        <div className="w-full space-y-1 pl-2 py-2 border border-ag-secondary-light rounded-xl">
                                            <div className="text-center">
                                                <p className="text-ag-secondary-letter text-base font-semibold pb-1">Payment Method</p>
                                            </div>
                                            {
                                                quote?.paymentMethod === "CAR_INSURANCE" ?
                                                    <>
                                                        <div>
                                                            <p className="text-ag-secondary-letter text-xs font-semibold">
                                                                Insurance Information:
                                                            </p>
                                                        </div>
                                                        <div className="flex justify-start items-center space-x-1">
                                                            <p className="text-ag-secondary-letter text-xs font-semibold">Policy Number:</p>
                                                            <p className="text-ag-secondary-letter text-xs truncate w-28" title={quote?.paymentMethod === "CAR_INSURANCE" ? quote?.insurance?.numberOfInsurance : ""}>
                                                                {quote?.paymentMethod === "CAR_INSURANCE" ? quote?.insurance?.numberOfInsurance : "---"}
                                                            </p>
                                                        </div>
                                                        <div className="flex justify-start items-center space-x-1">
                                                            <p className="text-ag-secondary-letter text-xs font-semibold">Insurance Company:</p>
                                                            <p className="text-ag-secondary-letter text-xs truncate w-28" title={quote?.paymentMethod === "CAR_INSURANCE" ? quote?.insurance?.companyInsurance : ""}>
                                                                {quote?.paymentMethod === "CAR_INSURANCE" ? quote?.insurance?.companyInsurance : "---"}
                                                            </p>
                                                        </div>
                                                        <div className="flex justify-start items-center space-x-1">
                                                            <p className="text-ag-secondary-letter text-xs font-semibold">Date of lost:</p>
                                                            <p className="text-ag-secondary-letter text-xs truncate w-28">
                                                                {quote?.paymentMethod === "CAR_INSURANCE" ? formatDateEng(quote?.insurance?.dateInsurance) : "---"}
                                                            </p>
                                                        </div>
                                                    </>
                                                    :
                                                    <div>
                                                        <p className="text-center text-ag-secondary-letter text-xs font-semibold">Pay on my own</p>
                                                    </div>
                                            }
                                        </div>
                                    </div>

                                    <div className="flex flex-col space-y-4 mt-5 mb-5">
                                        <div className="w-full space-y-1 p-2 border border-ag-secondary-light rounded-xl">
                                            <div className={`${quote?.description && quote?.description.length > 680 ? "overflow-y-scroll" : "overflow-y-hidden"} max-h-24`}>
                                                <p className="pr-2 text-ag-secondary-letter text-xs whitespace-normal break-all" title={quote?.description}>
                                                    <span className="font-semibold mr-5">Description:</span> {quote?.description}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="w-full space-y-1 p-2 border border-ag-secondary-light rounded-xl">
                                            <div className={`${quote?.comments && quote?.comments.length > 680 ? "overflow-y-scroll" : "overflow-y-hidden"} max-h-24`}>
                                                <p className="pr-2 text-ag-secondary-letter text-xs whitespace-normal break-all" title={quote?.comments && quote?.comments !== "" ? quote?.comments : "---"}>
                                                    <span className="font-semibold mr-5">Comments:</span> {quote?.comments && quote?.comments !== "" ? quote?.comments : "---"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="w-full text-right">
                                        <p className="text-xs font-semibold italic">Retail info:</p>
                                        <a className="text-xs text-blue-500 italic underline cursor-pointer" href={`mailto: ${quote?.retailUser?.user?.email}`}>{quote?.retailUser?.user?.email}</a>
                                    </div> */}
                                    {
                                        statusQuote.status || quote?.status === "canceled" ? null :
                                            <div className="w-full md:flex md:justify-center md:items-center md:space-x-5 space-y-2 md:space-y-0">
                                                <div className="w-full md:w-[40%] md:flex md:justify-center md:items-center md:space-x-5 space-y-2 md:space-y-0">
                                                    <div className="w-full">
                                                        <button
                                                            className="w-full px-4 py-2 border border-ag-secondary-light rounded-xl"
                                                            type="button"
                                                            onClick={() => onSearch(true)}
                                                        >
                                                            Manual Search
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="w-full md:w-[60%] md:flex md:justify-center md:items-center md:space-x-5 space-y-2 md:space-y-0">
                                                    {/* <div>
                                                    <span className="text-base">Insurance</span>
                                                </div>
                                                <select
                                                    name="insurance"
                                                    className="w-full p-2 border border-ag-secondary-light rounded-xl"
                                                    value={currentInsurence}
                                                    onChange={(evt) => setCurrentInsurence(evt.target.value)}
                                                >
                                                    <option value=""></option>
                                                    {
                                                        insurersOptions.map((element, index) => {
                                                            return <option key={index} value={element._id}>{element.name}</option>;
                                                        })
                                                    }
                                                </select> */}
                                                </div>
                                            </div>
                                    }
                                    <div className={`${products.length === 0 ? "pt-5" : "pt-2"}`}>
                                        <TableDetail
                                            stsId={statusQuote.current}
                                            products={products}
                                            warehouseOptions={warehouseOptions}
                                            kitValue={currentInsurence}
                                            insurersOptions={insurersOptions}
                                            onProduct={handleProduct}
                                            onSelect={handleCalculate}
                                            onPrice={onPrice}
                                            onCompatible={onCompatible}
                                            onFeatures={onFeatures}
                                        />
                                    </div>
                                    {
                                        quote?.status === "approved" ?
                                            <div className="pt-5">
                                                <div className="w-full bg-ag-secondary-table flex justify-between items-center">
                                                    <div className="w-auto md:w-[50%] xl:w-[60%] text-center">
                                                        <p className="text-ag-secondary-letter text-xs font-normal px-2 py-2 border border-zinc-100">
                                                            Extra services
                                                        </p>
                                                    </div>
                                                    <div className="w-full md:w-[50%] xl:w-[40%] text-center">
                                                        <p className="text-ag-secondary-letter text-xs font-normal px-2 py-2 border border-zinc-100">
                                                            Price
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between items-start py-2">
                                                    <div className="pt-2 space-y-1">
                                                        <div>
                                                            {
                                                                quote?.ceramicFull90 ?
                                                                    <p className="text-xs text-ag-secondary-letter">
                                                                        Ceramic Full Windshield Tint 90% Heat Rejection $250
                                                                    </p>
                                                                    : null
                                                            }
                                                            {
                                                                quote?.ceramicFull70 ?
                                                                    <p className="text-xs text-ag-secondary-letter">
                                                                        Ceramic Full Windshield Tint 70% Heat Rejection $200
                                                                    </p>
                                                                    : null
                                                            }
                                                            {
                                                                quote?.waterTreatment ?
                                                                    <p className="text-xs text-ag-secondary-letter">
                                                                        Windshield Water Treatment $50
                                                                    </p>
                                                                    : null
                                                            }
                                                            {
                                                                quote?.windshieldVisorStrip ?
                                                                    <p className="text-xs text-ag-secondary-letter">
                                                                        Windshield Visor Strip $50
                                                                    </p>
                                                                    : null
                                                            }
                                                            {
                                                                Number(Number(quote?.ceramicFull90 ? 250 : 0) + Number(quote?.ceramicFull70 ? 200 : 0) + Number(quote?.waterTreatment ? 50 : 0) + Number(quote?.windshieldVisorStrip ? 50 : 0)) < Number(quote?.selectProduct?.additionalServices) ?
                                                                    <p className="text-xs text-ag-secondary-letter">
                                                                        Calibration $200
                                                                    </p>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="w-full md:w-[50%] xl:w-[40%] space-y-1">
                                                        <div className="flex justify-center items-center space-x-3 px-4 py-1 border border-ag-secondary rounded-full">
                                                            <span className="text-[12px] font-semibold">Subtotal:</span>
                                                            <span className="text-[12px]">
                                                                {formatCurrency(quote?.selectProduct?.total - quote?.selectProduct?.tax)}
                                                            </span>
                                                        </div>
                                                        <div className="flex justify-center items-center space-x-3 px-4 py-1 border border-ag-secondary rounded-full">
                                                            <span className="text-[12px] font-semibold">Tax:</span>
                                                            <span className="text-[12px]">
                                                                {formatCurrency(quote?.selectProduct?.tax)}
                                                            </span>
                                                        </div>
                                                        <div className="flex justify-center items-center space-x-3 px-4 py-1 border border-ag-secondary rounded-full">
                                                            <span className="text-[12px] font-semibold">Total:</span>
                                                            <span className="text-[12px]">
                                                                {formatCurrency(quote?.selectProduct?.total)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    }
                                    <div className="flex justify-end items-center space-x-5">
                                        <button
                                            type="button"
                                            className="px-4 py-3 bg-red-600 text-white text-xs rounded-xl"
                                            onClick={() => onClose(false)}
                                        >
                                            Cancel
                                        </button>
                                        {
                                            quote?.status === "inprocess" ?
                                                <button
                                                    type="button"
                                                    className="px-4 py-3 bg-green-600 text-white text-xs rounded-xl"
                                                    onClick={() => handleSend()}
                                                >
                                                    Send Quote
                                                </button>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-50 fixed inset-0 z-10 bg-black"></div>
        </>
    );
};

export default QuoteDetail;