import { useEffect, useState } from "react";

const Filters = ({ isFilters, onFilters, onShowForm }) => {
    const [focusClass, setFocusClass] = useState({
        search: { placeholder: "Search", status: false },
        interchange: { placeholder: "Interchange", status: false },
        nags: { placeholder: "Nags", status: false }
    });

    const [options, setOptions] = useState({
        search: "",
        interchange: "",
        nags: ""
    });

    const cleanFilters = () => {
        onFilters({...options, search: "", interchange: "", nags: ""}, true);
        setOptions({...options, search: "", interchange: "", nags: ""});
        setFocusClass({...focusClass,
            search: { placeholder: "Search", status: false },
            interchange: { placeholder: "Interchange", status: false },
            nags: { placeholder: "Nags", status: false }
        });
    }

    const handleChange = (evt) => {
        setOptions({...options, [evt.target.name]: evt.target.value});
        onFilters({...options, [evt.target.name]: evt.target.value}, true);
    }

    useEffect(() => {
        if (isFilters) onFilters(options, false);
    }, [isFilters]);
    
    return (
        <>
            <div>
                <form>
                    <div className="md:col-span-3 space-y-3">
                        <div>
                            <input
                                className={`w-full text-ag-secondary-letter text-sm lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.search.status ? "text-left" : "text-center"}`}
                                type="text"
                                name="search"
                                placeholder={focusClass.search.placeholder}
                                value={options.search}
                                onChange={handleChange}
                                onFocus={() => setFocusClass({...focusClass, search: { placeholder: "", status: true }})}
                                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, search: { placeholder: "Search", status: false }}) : null}
                                maxLength={60}
                            />
                        </div>
                        <div className="grid gap-3 xl:gap-6 md:grid-cols-4">
                            <div>
                                <input
                                    className={`w-full text-ag-secondary-letter text-sm lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.interchange.status ? "text-left" : "text-center"}`}
                                    type="text"
                                    name="interchange"
                                    placeholder={focusClass.interchange.placeholder}
                                    value={options.interchange}
                                    onChange={handleChange}
                                    onFocus={() => setFocusClass({...focusClass, interchange: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, interchange: { placeholder: "Interchange", status: false }}) : null}
                                    maxLength={60}
                                />
                            </div>
                            <div>
                                <input
                                    className={`w-full text-ag-secondary-letter text-sm lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.nags.status ? "text-left" : "text-center"}`}
                                    type="text"
                                    name="nags"
                                    placeholder={focusClass.nags.placeholder}
                                    value={options.nags}
                                    onChange={handleChange}
                                    onFocus={() => setFocusClass({...focusClass, nags: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, nags: { placeholder: "Nags", status: false }}) : null}
                                    maxLength={60}
                                />
                            </div>
                            <div>
                                <button
                                    className="w-full bg-ag-secondary text-center text-white text-sm lg:text-base border border-gray-500 p-[7.5px] rounded-xl disabled:bg-zinc-200"
                                    type="button"
                                    onClick={() => cleanFilters()}
                                >
                                    Clean
                                </button>
                            </div>
                            <div>
                                <button
                                    className="w-full bg-ag-primary-light text-center text-white text-sm lg:text-base border border-ag-primary-light p-[7.5px] rounded-xl disabled:bg-zinc-200"
                                    type="button"
                                    onClick={() => onShowForm(true)}
                                >
                                    New Interchange
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default Filters;