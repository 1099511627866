import { BiSearch } from "react-icons/bi";
import { RiEqualizerFill } from "react-icons/ri";

const Filters = ({ onHandleChange }) => {
    const handleChange = (evt) => {
        onHandleChange(evt);
    }

    return (
        <div className="py-2 grid gap-3 md:grid-cols-6">
            <div className="md:col-span-3 flex items-center">
                <h1 className="text-xs lg:text-base">ORDERED</h1>
            </div>
            <div className="md:col-span-3">
                <div className="flex justify-center items-center border border-gray-500 rounded-xl">
                    <input
                        className="w-full text-center text-ag-secondary-letter text-xs lg:text-base border-none p-2 rounded-xl disabled:bg-zinc-200"
                        type="text"
                        name="search"
                        placeholder="Search products in transfer"
                        onChange={handleChange}
                    />
                    <button 
                        className="bg-ag-primary-dark border-none p-[10px] rounded-xl disabled:bg-zinc-200"
                        type="button"
                    >
                        <span className="text-white text-base lg:text-xl"><BiSearch /></span>
                    </button>
                </div>
            </div>
            {/* <div>
                <button
                    className="w-full flex justify-center items-center space-x-3 text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200"
                    type="submit"
                >
                    <span className="text-lg lg:text-2xl"><RiEqualizerFill /></span>
                    <span>Filters</span>
                </button>
            </div> */}
        </div>
    );
}

export default Filters;