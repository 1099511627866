import { Link, useParams } from 'react-router-dom';
import { getProduct } from "../../../../services/Product";
import { useContext, useEffect, useState } from "react";
import { PermitsContext } from "../../../../services/Permits";
import Loader from "../../../../components/loader/Loader";
import { getCompatiblesVehicles } from "../../../../services/Product";
import TableCompatibleVehicles from "../../products/save_product/TableCompatibleVehicles";
import TableInterchanges from "../../products/save_product/TableInterchanges";
import { searchInterchangrablesByNag } from "../../../../services/Interchangeable";
import { getVendors } from "../../../../services/Vendor";
import { formatDate } from "../../../../utils/Utilities";
import PricePlinkington from "../../../fragments/PricePlinkington";



const DetailProductInventory = () => {
  const { permits, dataSession } = useContext(PermitsContext);
  const [compatibleVehicles, setCompatibleVehicles] = useState([]);
  const [interchanges, setInterchanges] = useState([]);
  const [vendorsStr, setVendorsStr] = useState("");
  let { id } = useParams();
  const [showPrice, setShowPrice] = useState(false);
  const [dataUpdate, setDataUpdate] = useState({ by: "", lastUpdate: "" });
  const [loader, setLoader] = useState(false);

  const [product, setProduct] = useState({
    id: "",
    status: true,
    name: "",
    nags: "",
    price: "",
    serviceTime: "",
    barcode1: "",
    barcode2: "",
    totalQuantity: "",
    by: "",
    vendors: [],
    features: "",
    description: "",
    note: "",
    productImages: []
  });


  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    setLoader(true);
    const res = await getProduct(id);
    if (res.status === 200) {
      setProduct({
        name:res.data.name,
        nags:res.data.nags,
        price:res.data.price,
        barcode1:res.data.barcode1,
        barcode2:res.data.barcode2,
        description:res.data.description,
        note:res.data.note,
        features:res.data.features,
        vendors: [],
        serviceTime:res.data.serviceTime,
        productImages:res.data.productImages,

        totalQuantity: res.data.totalQuantity,
        by: res.data.by,

      });

      const responseCompatibleVehicles = await getCompatiblesVehicles(res.data.nags);
      setCompatibleVehicles(responseCompatibleVehicles.data);

      getInterchanges(res.data.nags);

      const resVen = await getVendors();
      if (resVen.status === 200 && resVen.data.length > 0) {
        getOptions(resVen.data,res.data.vendors);
      }

      setDataUpdate({
        ...dataUpdate,
        by:res.data.by ? `${res.data.by.names} ${res.data.by.lastNames}` : `${dataSession.names} ${dataSession.lastNames}`,
        lastUpdate: formatDate(res.data.lastUpdate)
      });

      setLoader(false);
    }
  };

  const getInterchanges = (nagProduct) => {
    searchInterchangrablesByNag(nagProduct).then(res => {
      if (res.status === 200) {
        setInterchanges(res.data);
      } else {
        setInterchanges([]);
      }
    }).catch(() => setInterchanges([]));
  };

  const getOptions = (array_vendors, vendors_current) => {
    let vendors = [];
    for (let i = 0; i < array_vendors.length; i++) {
      const vendorFound = vendors.find(element => element._id === array_vendors[i]._id);
      const vendorCurrentFound = vendors_current.find(element => element._id === array_vendors[i]._id);

      if (!vendorFound) {
        vendors.push({
          label: array_vendors[i].name,
          value: array_vendors[i]._id,
          selected: vendorCurrentFound ? true : false
        });
      }
    }
    setVendorsStr(vendors.filter(elem => elem?.selected).map(elem => elem.label));
  };

  // const handlePrice = () => {
  //     if (product.nags !== "") {
  //         setShowPrice(true)
  //     } else {
  //         toast.warning("Please select a nag first", alertOptions);
  //     }
  // }

  return (
    <>
      {loader && <Loader />}
      {
        showPrice ?
          <PricePlinkington
            nagSearch={product.nags}
            onClose={() => setShowPrice(false)}
            onLoader={(val) => setLoader(val)}
          />
          : null
      }
      <div className="overflow-auto w-full flex-grow product-inventory">
        <div className="space-y-5 py-8 px-5 md:px-10 lg:px-16">
          <div className="col-span-4 text-center">
            <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Details</h1>
          </div>

          <div className="pt-3 pb-10 space-y-5">
            <div className="grid gap-3 md:grid-cols-5">
              <div className="space-y-[14px]">
                <div>
                  <Link to="/inventory">
                    <button className="w-full bg-ag-secondary text-white text-xs lg:text-base p-3 rounded-xl" type="button">
                                            Back
                    </button>
                  </Link>
                </div>
              </div>


              <div className="mt-2 md:mt-0 md:order-first md:col-span-4">
                <form>
                  <div className="space-y-3">
                    <div>
                      <div className="w-full flex justify-center items-center">
                        <span
                          className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                        >
                          Product name
                        </span>
                        <input
                          className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                          type="text"
                          name="name"
                          value={product?.name}
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div>

                      <div className="w-full flex justify-center items-center">
                        <span
                          className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                        >
                          Nags
                        </span>
                        <input
                          className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                          type="text"
                          name="nags"
                          value={product?.nags}
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div>
                      <div className="w-full flex justify-center items-center">
                        <span
                          className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                        >
                          Nags Price
                        </span>
                        <input
                          className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                          type="text"
                          name="price"
                          value={product?.price}
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div className="grid gap-3 lg:grid-cols-2">
                      <div>
                        <div className="w-full flex justify-center items-center">
                          <span
                            className="w-[30%] lg:w-[45%] xl:w-[37%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                          >
                            Barcode 1
                          </span>
                          <input
                            className="w-[70%] lg:w-[55%] xl:w-[63%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate disabled:bg-zinc-200"
                            type="text"
                            name="barcode1"
                            value={product?.barcode1}
                            disabled={true}
                          />
                        </div>
                      </div>

                      <div>
                        <div className="w-full flex justify-center items-center">
                          <span
                            className="w-[30%] lg:w-[45%] xl:w-[37%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                          >
                            Barcode 2
                          </span>
                          <input
                            className="w-[70%] lg:w-[55%] xl:w-[63%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                            type="text"
                            name="barcode2"
                            value={product?.barcode2}
                            disabled={true}
                          />
                        </div>
                      </div>

                    </div>
                    <div className="w-full flex justify-center items-center">
                      <span
                        className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs lg:text-base text-white text-center border border-gray-500 py-[36px] rounded-l-xl"
                      >
                        Description
                      </span>
                      <textarea
                        className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl"
                        name="description"
                        rows={3}
                        value={product?.description}
                        disabled={true}
                      />
                    </div>

                    <div className="w-full flex justify-center items-center">
                      <span
                        className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs lg:text-base text-white text-center border border-gray-500 py-[36px] rounded-l-xl"
                      >
                        Notes
                      </span>
                      <textarea
                        className="w-[70%] lg:w-[78%] xl:w-[82%] font-bold bg-[#d7b583] text-white border border-gray-500 p-2 xl:p-3 rounded-r-xl"
                        name="note"
                        rows={3}
                        value={product?.note}
                        readOnly={true}
                        aria-disabled="true"
                        style={{
                          pointerEvents: 'none',
                          backgroundColor: '#d5655b',
                          opacity: 1,
                        }}
                      />
                    </div>

                    <div>
                      <div className="w-full flex justify-center items-center">
                        <span
                          className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-[36px] rounded-l-xl"
                        >
                          Features
                        </span>
                        <textarea
                          className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl"
                          name="features"
                          rows={3}
                          value={product?.features}
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div>
                      <div className="w-full flex justify-center items-center">
                        <span
                          className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                        >
                          Vendors
                        </span>
                        <input
                          className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                          type="text"
                          name="vendors"
                          value={vendorsStr}
                          disabled={true}
                        />
                      </div>
                    </div>


                    <div>
                      <div className="w-full flex justify-center items-center">
                        <span
                          className="w-[30%] lg:w-[22%] xl:w-[18%] bg-ag-secondary text-xs xl:text-base text-white text-center border border-gray-500 py-3 rounded-l-xl"
                        >
                          Hour labor
                        </span>
                        <input
                          className="w-[70%] lg:w-[78%] xl:w-[82%] text-ag-secondary-letter border border-gray-500 p-2 xl:p-3 rounded-r-xl truncate"
                          type="text"
                          name="hourLabor"
                          value={product?.serviceTime}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="md:flex md:justify-between md:items-center p-5 md:p-3 space-y-2 md:space-y-0">
                    <div className="text-ag-secondary">
                      <span className="text-sm font-bold">Last update: </span>
                      <span className="text-sm">{dataUpdate.lastUpdate}</span>
                    </div>
                    <div className="text-ag-secondary">
                      <span className="text-sm font-bold">By: </span>
                      <span className="text-sm">{dataUpdate.by}</span>
                    </div>
                  </div>
                </form>

              </div>
            </div>

            {
              compatibleVehicles.length > 0 ?
                <div className="pt-5 border-t border-ag-secondary">
                  <div className="w-full text-center">
                    <p className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Compatible vehicles</p>
                  </div>
                  <div className="pt-8">
                    <TableCompatibleVehicles arrCompVeh={compatibleVehicles} />
                  </div>
                </div>
                : null
            }

            {
              interchanges.length > 0 ?
                <div className="py-5 border-t border-ag-secondary">
                  <div className="w-full text-center">
                    <p className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Compatible vehicles by interchanges</p>
                  </div>
                  <div className="pt-8">
                    <TableInterchanges arrInter={interchanges} />
                  </div>
                </div>
                : null
            }
          </div>
        </div>
      </div>
    </>


  );
};

export default DetailProductInventory;