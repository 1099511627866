import { MdEdit } from "react-icons/md";
import Features from "../../../../components/features/Features";

const RowsInterchangeable = ({ interchange, onNags, onCompatible, onEdit, onStatus }) => {
    return (
        <tr>
            <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                { interchange?.index }
            </td>
            <td className="w-[30%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                <div className="flex justify-start">
                    <div>
                        {
                        interchange.interchangeableNags.map((nagElem, i) => {
                            if (i <= 3) {
                                return (
                                    <p className="truncate w-36 m-auto" key={i}>{ nagElem }</p>
                                )
                                }
                            })
                        }
                        {
                            interchange.interchangeableNags.length > 4 ?
                                <p className="truncate w-36 font-semibold m-auto cursor-pointer" onClick={() => onNags(interchange.interchangeableNags, interchange?.index)}>
                                    See nags interchange +
                                </p>
                            : null
                        }
                    </div>
                    <div>
                        {
                            <Features element={interchange} color={''} textSize={'10px'} seeTitle={true} />
                        }
                    </div>
                </div>
      </td>
      <td className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        {
          interchange.compatible_vehicles.map((prodComp, i) => {
            if (i <= 2) {
              return (
                <p className="truncate w-36 m-auto" key={i} title={
                  `${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year} ` +
                                    `${prodComp?.brand} ` +
                                    `${prodComp?.model} ` +
                                    `${prodComp?.type_car}`
                }>
                  { String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year }{' '}
                  { prodComp?.brand }{' '}
                  { prodComp?.model }{' '}
                  { prodComp?.type_car }
                </p>
              )
            }
          })
        }
        {
          interchange.compatible_vehicles.length > 3 ?
            <p className="truncate w-36 font-semibold m-auto cursor-pointer" onClick={() => onCompatible(interchange)}>
                See compatible vehicles +
            </p>
            : null
        }
            </td>
            <td className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                { interchange.description !== "" ? interchange.description : "-----------" }
            </td>
            <td className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
                <div className="flex justify-center items-center space-x-10">
                    <div>
                        <button type="button" onClick={() => onEdit(interchange)}>
                            <span className="text-3xl text-ag-secondary"><MdEdit /></span>
                        </button>
                    </div>
                    <div>
                        <label className="inline-flex relative items-center cursor-pointer">
                            <input
                                className="sr-only peer disabled:bg-zinc-200"
                                type="checkbox"
                                checked={interchange.active === "active" || interchange.active === "true" || interchange.active === true ? true : false}
                                onChange={(evt) => onStatus({...interchange, active: evt.target.checked}, false)}
                            />
                            <div className="w-10 h-5 bg-ag-secondary rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-ag-active z-[-1]"></div>
                            <span className="ml-3 text-base text-ag-secondary font-bold"></span>
                        </label>
                    </div>
                </div>
            </td>
        </tr>
    );
}

export default RowsInterchangeable;