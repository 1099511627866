import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { getWarehouse } from "../../../../../services/Warehouse";
import { alertOptions } from "../../../../../utils/Utilities";
import TableInventory from "../../save_product/TableInventory";

const OrganizeByRacks = ({ type, data, warehouse, onOrganize, onClose, onLoader, onCompatible }) => {
    const [sumStock, setSumStock] = useState(0);
    const [racksOptions, setRacksOptions] = useState([]);
    const [racksToEdit, setRacksToEdit] = useState([]);
    const [inventory, setInventory] = useState({
        id: "",
        warehouse: "",
        product: "",
        nags: "",
        stock: "",
        compatible_vehicles: [],
        racks: []
    });

    const handleCanEdit = (rckElem, sts) => {
        if (sts) {
            const newRacks = racksToEdit.filter(elem => elem === rckElem?._id || elem === rckElem?.rack?._id);
            if (!newRacks || newRacks.length === 0) setRacksToEdit([...racksToEdit, rckElem?._id ? rckElem?._id : rckElem?.rack._id]);
        } else {
            const newRacks = racksToEdit.filter(elem => elem !== rckElem?._id && elem !== rckElem?.rack?._id);
            setRacksToEdit(newRacks);
        }
    }

    const addRack = (rackElement) => {
        if (rackElement._id !== "" && rackElement.quantity !== "") {
            if ((type === "transfer" && rackElement.quantity >= 0) || (type !== "transfer" && rackElement.quantity > 0)) {
                let racksAux = inventory.racks;
                const foundRack = racksAux.find(rckElmnt => rckElmnt._id === rackElement._id);
                if (!foundRack) {
                    racksAux.quantity = Number(racksAux.quantity);
                    racksAux.push(rackElement);
                    setInventory({...inventory, racks: racksAux});
                    setSumStock(Number(sumStock) + Number(rackElement.quantity));
                } else {
                    toast.warning("The selected rack is already added", alertOptions);
                }
            } else {
                if (type === "transfer") {
                    toast.warning("The amount to enter the rack must be greater than or equal to 0", alertOptions);
                } else {
                    toast.warning("The amount to enter the rack must be greater than 0", alertOptions);
                }
            }
        } else {
            toast.info("Please add rack and quantity", alertOptions);
        }
    }

    const editRack = (rackIndex, rackElement) => {
        let sumStockAux = 0;
        let newIdsToEdit = [];
        const racksAux = inventory.racks.map((rckElmnt, i) => {
            if (i === rackIndex) {
                const foundRack = inventory.racks.filter(rckElmntAux => (rckElmntAux?._id === rackElement?._id || rckElmntAux?.rack?._id === rackElement?._id));
                if (foundRack.length === 0) {
                    sumStockAux += Number(rackElement.quantity);
                    newIdsToEdit.push(rackElement._id);
                    const nameRack = racksOptions.find(elem => elem?._id === rackElement?._id);
                    return {
                        _id: rackElement._id,
                        quantity: Number(rackElement.quantity),
                        rack: rackElement?.rack ? rackElement?.rack : "",
                        name: rackElement?.name ? rackElement?.name : nameRack ? nameRack.name : ""
                    };
                } else {
                    sumStockAux += Number(rackElement.quantity);
                    newIdsToEdit.push(rckElmnt._id);
                    return {
                        ...rckElmnt,
                        quantity: Number(rackElement.quantity)
                    }
                }
            }
            sumStockAux += Number(rckElmnt.quantity);
            newIdsToEdit.push(rckElmnt._id);
            return rckElmnt;
        });
        setSumStock(sumStockAux);
        setInventory({...inventory, racks: racksAux});
        setRacksToEdit(newIdsToEdit);
    }

    const deleteRack = (idRack) => {
        let sumStockAux = 0;
        const racksAuxEdit = racksToEdit.filter(elem => elem !== idRack);
        const racksAux = inventory.racks.filter(rackElement => {
            if (rackElement._id !== idRack) {
                sumStockAux += Number(rackElement.quantity);
                return rackElement;
            }
        });
        setSumStock(sumStockAux);
        setInventory({...inventory, racks: racksAux});
        setRacksToEdit(racksAuxEdit);
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (inventory.racks.length > 0) {
            if (sumStock <= inventory.stock) {
                if (racksToEdit.length === 0) {
                    let currentStock = 0;
                    const newRacksAux = inventory.racks.map(rackElement => {
                        currentStock += Number(rackElement.quantity);
                        return {
                            rack: { _id: rackElement._id},
                            quantity: Number(rackElement.quantity),
                            name: rackElement.name
                        }
                    });
                    let newInventoryModel = inventory;
                    newInventoryModel.racks = newRacksAux;
                    onOrganize(newInventoryModel, currentStock);
                } else {
                    toast.info("Please finish editing the table racks", alertOptions);
                }
            } else {
                toast.warning("You cannot add more products than the established maximum in stock", alertOptions);
            }
        } else {
            toast.warning("Please add at least one rack", alertOptions);
        }
    }

    useEffect(() => {
        onLoader(true);
        getWarehouse(warehouse).then(res => {
            if (res.status === 200) {
                if (type === "transfer") {
                    setRacksOptions(res.data.racks);
                } else {
                    const availableRacks = res.data.racks.map(eleRck => {
                        const existProduct = eleRck.productsRack.find(eleRckAux =>
                            (eleRckAux?.productInventory?._id === (data?.data ? data?.data?.productInventory._id : data?.productInventory._id) && eleRckAux?.quantity > 0)
                        );
    
                        if (existProduct) return {...eleRck, quantityAvailable: existProduct?.quantity};
                        return eleRck;
                    }).filter(eleFilt => eleFilt.quantityAvailable);
                    setRacksOptions(availableRacks);
                }

                let currentRacks = [];
                if (data?.racks && data?.racks.length > 0) currentRacks = data?.racks.map(elem => ({...elem, _id: elem?.rack?._id}));
                setInventory({...inventory,
                    id: data._id,
                    warehouse: res.data.name,
                    product: data?.data ? data.data.productInventory.product.name : data.productInventory.product.name,
                    nags: data?.data ? data.data.productInventory.product.nags : data.productInventory.product.nags,
                    compatible_vehicles: data?.data ? data.data.productInventory.product.compatible_vehicles : data.compatible_vehicles,
                    stock: data.quantity,
                    racks: currentRacks
                });
            }
            onLoader(false);
        }).catch(() => onLoader(false));
    }, [data]);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[700px] lg:w-[800px] h-[550px] md:h-auto">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="pl-[5%] text-xl text-ag-secondary font-bold">
                                        { type === "order" ? "Choose Racks Products" : "Organize by racks" }
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="relative px-2 md:px-5 pb-2 pt-5 flex-auto h-auto overflow-y-auto">
                                <form className="space-y-5" onSubmit={handleSubmit}>
                                    <div className="grid gap-x-3 gap-y-2 md:grid-cols-2">
                                        <div className="w-full flex justify-center items-center">
                                            <span
                                                className="w-[30%] md:w-[40%] bg-ag-secondary-dark text-xs md:text-sm text-white text-center border border-gray-500 py-2 rounded-l-xl"
                                            >
                                                Nags
                                            </span>
                                            <input
                                                className="w-[70%] md:w-[60%] text-ag-secondary-letter border border-gray-500 px-2 py-1 md:py-[6px] rounded-r-xl disabled:bg-zinc-200 truncate"
                                                type="text"
                                                name="nags"
                                                value={inventory.nags}
                                                disabled={true}
                                                title={inventory.nags}
                                            />
                                        </div>
                                        <div className="w-full flex justify-center items-center">
                                            <span
                                                className="w-[30%] md:w-[40%] bg-ag-secondary-dark text-xs md:text-sm text-white text-center border border-gray-500 py-2 rounded-l-xl"
                                            >
                                                Product
                                            </span>
                                            <input
                                                className="w-[70%] md:w-[60%] text-ag-secondary-letter border border-gray-500 px-2 py-1 md:py-[6px] rounded-r-xl disabled:bg-zinc-200 truncate"
                                                type="text"
                                                name="product"
                                                value={inventory.product}
                                                disabled={true}
                                                title={inventory.product}
                                            />
                                        </div>
                                        <div className="w-full flex justify-center items-center">
                                            <span
                                                className="w-[30%] md:w-[40%] bg-ag-secondary-dark text-xs md:text-sm text-white text-center border border-gray-500 py-2 rounded-l-xl"
                                            >
                                                Warehouse
                                            </span>
                                            <input
                                                className="w-[70%] md:w-[60%] text-ag-secondary-letter border border-gray-500 px-2 py-1 md:p-[6px] rounded-r-xl disabled:bg-zinc-200 truncate"
                                                type="text"
                                                name="warehouse"
                                                value={inventory.warehouse}
                                                disabled={true}
                                                title={inventory.warehouse}
                                            />
                                        </div>
                                        <div className="w-full flex justify-center items-center">
                                            <button className="w-full bg-ag-secondary-dark text-white text-xs md:text-sm border border-gray-500 p-2 rounded-xl truncate" type="button" onClick={() => onCompatible(inventory)}>
                                                See compatible vehicles
                                            </button>
                                        </div>
                                        <div className="w-full flex justify-center items-center">
                                            <span
                                                className="w-[30%] md:w-[40%] bg-ag-secondary-dark text-xs md:text-sm text-white text-center border border-gray-500 py-2 rounded-l-xl"
                                            >
                                                { type === "order" ? "To Dispatch" : "Stock" }
                                            </span>
                                            <input
                                                className="w-[70%] md:w-[60%] text-ag-secondary-letter border border-gray-500 px-2 py-1 md:p-[6px] rounded-r-xl disabled:bg-zinc-200 truncate"
                                                type="text"
                                                name="stock"
                                                value={inventory.stock}
                                                disabled={true}
                                                title={inventory.stock}
                                            />
                                        </div>
                                    </div>
                                    <div className="py-2 border-t border-ag-secondary-light">
                                        <TableInventory
                                            maxStock={inventory.stock}
                                            sumStock={sumStock}
                                            type={type}
                                            racksCurrent={inventory.racks}
                                            racks={racksOptions}
                                            onSave={addRack}
                                            onEdit={editRack}
                                            onDelete={deleteRack}
                                            onCloseSelect={() => {}}
                                            onCanEdit={handleCanEdit}
                                        />
                                    </div>
                                    <div className="w-full flex justify-center items-center space-x-5">
                                        <div className="w-[50%] md:w-[30%] lg:w-[25%]">
                                            <button className="w-full bg-ag-primary-light text-white p-2 rounded-xl" type="button" onClick={() => onClose(false)}>
                                                Cancel
                                            </button>
                                        </div>
                                        <div className="w-[50%] md:w-[30%] lg:w-[25%]">
                                            <button className="w-full bg-green-600 text-white p-2 rounded-xl" type="submit">
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-20 bg-black"></div>
        </>
    );
}

export default OrganizeByRacks;