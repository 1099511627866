import { MdDelete, MdEdit } from "react-icons/md";
import { formatCurrency } from "../../../../utils/Utilities";

const RowsInsurers = ({ insurer, onEdit, onDelete }) => {
    return (
        <tr>
            <td className="w-[28%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-64 m-auto">{ insurer.name }</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-14 m-auto">{ insurer.discountRegular }% - {insurer.discountHeated}%</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-14 m-auto">{ insurer.hour }</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-14 m-auto">{ insurer.kitRegular } - { insurer.kitHighModulus }</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-14 m-auto">{ insurer.dynamicCalibration }</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-14 m-auto">{ insurer.staticCalibration }</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-14 m-auto">{ insurer.dualCalibration }</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-14 m-auto">{ insurer.homeServiceCost }</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <p className="truncate w-14 m-auto">{ insurer.taxRate }%</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
                <div className="flex justify-center items-center space-x-3">
                    <button
                        className="text-3xl text-green-600 disabled:bg-zinc-200"
                        type="button"
                        onClick={() => onEdit(insurer)}
                    >
                        <MdEdit />
                    </button>
                    <button
                        className="text-3xl text-red-600 disabled:bg-zinc-200"
                        type="button"
                        onClick={() => onDelete(insurer._id)}
                    >
                        <MdDelete />
                    </button>
                </div>
            </td>
        </tr>
    );
}

export default RowsInsurers;