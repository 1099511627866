import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { alertOptions, formatCurrency } from "../../utils/Utilities";
import { getPilkingtonPrice } from "../../services/Plinkington";
import { toast } from "react-toastify";
import IconPlinkington from "../../assets/icons/logo_plinkintong.svg";

const PricePlinkington = ({ nagSearch, onLoader, onClose }) => {
    const [price, setPrice] = useState(0);

    const getData = () => {
        onLoader(true);
        getPilkingtonPrice(nagSearch).then(res => {
            if (res.status === 200 && res.data?.NetPrice) {
                setPrice(Number(res.data.NetPrice));
                onLoader(false);
            } else {
                toast.warning(res.response.data.message, alertOptions);
                onLoader(false);
            }
        }).catch(error => {
            onLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    useEffect(() => {
        if (nagSearch !== "") getData();
    }, [nagSearch]);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[550px] h-[550px] md:h-auto">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="text-lg text-ag-secondary-letter font-semibold">
                                        Prices of:
                                    </span>
                                    {' '}
                                    <span className="text-lg text-ag-secondary-letter">
                                        { nagSearch }
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="relative px-2 md:px-20 pb-2 pt-5 flex-auto">
                                <div className="">
                                    <div className="bg-ag-secondary-table p-3">
                                        <img className="w-32 m-auto" src={IconPlinkington} />
                                    </div>
                                    <div className="border border-ag-secondary-table p-10 text-center text-ag-secondary-letter">
                                        <span className="text-base">{ formatCurrency(price) }</span>
                                    </div>
                                </div>
                                <div className="w-full pt-5">
                                    <button className="w-full bg-ag-primary-light text-white p-2 rounded-xl" type="button" onClick={() => onClose(false)}>
                                        OK
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-20 bg-black"></div>
        </>
    );
}

export default PricePlinkington;