import { useEffect, useState } from "react";
import Pagination from "../../../components/pagination/Pagination";
import RowsReturns from "./RowsReturns";
import WithoutRows from "../../../components/table/WithoutRows";

const TableReturns = ({ enableActions, returns, onDetail, onInventory }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(4);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = returns.slice(indexOfFirstRecord, indexOfLastRecord);

    useEffect(() => {
        setCurrentPage(1);
    }, [returns]);

    return (
        <div>
            <div className="overflow-auto min-h-[38vh] lg:min-h-[44vh] 3xl:min-h-[55vh]">
                <table className="w-full text-center text-ag-secondary">
                    <thead>
                        <tr className="bg-ag-secondary-table">
                            <th className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-14 xl:px-2 py-4">Date</th>
                            <th className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-14 xl:px-2 py-4">Customer</th>
                            <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-14 xl:px-2 py-4">Items</th>
                            <th className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-14 xl:px-2 py-4">Warehouse</th>
                            <th className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-14 xl:px-2 py-4">Reason</th>
                            <th className="w-[6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-14 xl:px-2 py-4">Total</th>
                            <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-14 xl:px-2 py-4">Status</th>
                            <th className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-14 xl:px-2 py-4">Employee</th>
                            <th className="w-[14%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-14 xl:px-2 py-4">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            returns.length > 0 ? currentRecords.map((element, i) => {
                                return (
                                    <RowsReturns
                                        key={i}
                                        enableActions={enableActions}
                                        returnElement={element}
                                        onDetail={onDetail}
                                        onInventory={onInventory}
                                    />
                                )
                            })
                            :
                                <WithoutRows colspan={9} />
                        }
                    </tbody>
                </table>
            </div>
            <div className="p-5 m-auto">
                <Pagination
                    currentPage={currentPage}
                    totalCount={returns.length}
                    pageSize={recordsPerPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
        </div>
    );
}

export default TableReturns;