import React from 'react'

const Roof = ({onClick}) => {
  return (
    <svg className="cursor-pointer outline-none" onClick={onClick} data-tooltip-id="car" data-tooltip-content="Roof/Partition">
      <path d="M247.89 260.48H110.49C104.8 260.48 100.19 255.87 100.19 250.18V192.58C100.19 186.89 104.8 182.28 110.49 182.28H247.89C253.58 182.28 258.19 186.89 258.19 192.58V250.18C258.19 255.87 253.58 260.48 247.89 260.48Z" fill="#D3D3D4"/>
    </svg>
  )
}

export default Roof