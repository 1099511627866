import { useEffect, useState } from "react";
import RowsInsurers from "./RowsInsurers";
import Pagination from "../../../../components/pagination/Pagination";
import WithoutRows from "../../../../components/table/WithoutRows";

const TableInsurers = ({ insurers, onEdit, onDelete }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(7);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = insurers.slice(indexOfFirstRecord, indexOfLastRecord);

    useEffect(() => {
        setCurrentPage(1);
    }, [insurers]);

    return (
        <div>
            <div className="overflow-auto min-h-[40vh] lg:min-h-[46vh] 3xl:min-h-[57vh]">
                <table className="w-full text-center text-ag-secondary-letter">
                    <thead>
                        <tr className="bg-ag-secondary-table">
                            <th className="w-[28%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-28 lg:px-2 py-4">Name</th>
                            <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-10 lg:px-2 py-4">Discount (R - H)</th>
                            <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-10 lg:px-2 py-4">Hour</th>
                            <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-10 lg:px-2 py-4">Kit (R - HM)</th>
                            <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-10 lg:px-2 py-4">Dynamic Cal.</th>
                            <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-10 lg:px-2 py-4">Static Cal.</th>
                            <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-10 lg:px-2 py-4">Dual Cal.</th>
                            <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-10 lg:px-2 py-4">Home serv.</th>
                            <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-10 lg:px-2 py-4">Tax rate</th>
                            <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-10 lg:px-2 py-4">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentRecords.length === 0 ? <WithoutRows colspan={4} /> : currentRecords.map((element, i) => {
                                return (
                                    <RowsInsurers
                                        key={i}
                                        insurer={element}
                                        onEdit={onEdit}
                                        onDelete={onDelete}
                                    />
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="p-5 m-auto">
                <Pagination
                    currentPage={currentPage}
                    totalCount={insurers.length}
                    pageSize={recordsPerPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
        </div>
    );
}

export default TableInsurers;