import { _delete, _get, _getParams, _post } from "./http";

export const getWishList = (id) => _get(`/wishList/${id}`);

export const getAllWishList = () => _get(`/wishList`);

export const getMyWishList = (filterParams) => _getParams(`/wishList/myWishList`, filterParams);

export const saveWishList = (wishObject) => _post(`/wishList`, wishObject);

export const attendWishList = (wishObject) => _post(`/wishList/attend`, wishObject);

export const deleteWishList = (id) => _delete(`/wishList/${id}`);