import { _delete, _get, _post, _put } from "./http";

export const getAppointment = (idRetail, idAppointment) => _get(`/retailUser/${idRetail}/myAppointments/${idAppointment}`);

export const getAppointmentAdmin = (idAppointment) => _get(`/appointments/${idAppointment}`);

export const getAppointments = (idRetail) => _get(`/retailUser/${idRetail}/myAppointments`);

export const getAppointmentsAdmin = () => _get(`/appointments`);

export const attendAppointmentAdmin = (idAppointment, appointmentObject) => _put(`/appointments/${idAppointment}/attend`, appointmentObject);

export const cancelAppointment = (idRetail, idAppointment, appointmentObject) => _put(`/retailUser/${idRetail}/myAppointments/${idAppointment}/cancel`, appointmentObject);

export const deleteAppointment = (idRetail, idAppointment) => _delete(`/retailUser/${idRetail}/myAppointments/${idAppointment}/delete`);
