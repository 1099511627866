import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { usePagination, DOTS } from './usePagination';

const Pagination = props => {
    const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize } = props;
    const paginationRange = usePagination({ currentPage, totalCount, siblingCount, pageSize });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }
    
    const onNext = () => {
        if (currentPage < paginationRange[paginationRange.length-1]) onPageChange(currentPage + 1);
    };
    
    const onPrevious = () => {
        if (currentPage > 1) onPageChange(currentPage - 1);
    };

    return (
        <>
            <ul className="flex justify-center items-center space-x-5">
                <li>
                    <button className="text-2xl text-ag-secondary font-bold" onClick={onPrevious} type="button">
                        <FiChevronLeft />
                    </button>
                </li>
                
                { paginationRange.map((pageNumber, i) => {
                    if (pageNumber === DOTS) {
                        return <li key={i} className="pagination-item dots">&#8230;</li>;
                    }

                    return (
                        <li key={i} className= {`${currentPage == pageNumber ? 'page-active' : ''}`}>
                            <button className="text-base text-ag-secondary" onClick={() => onPageChange(pageNumber)} type="button">
                                { pageNumber }
                            </button>
                        </li>
                    );
                })}
                <li>
                    <button className="text-2xl text-ag-secondary font-bold" onClick={onNext} type="button">
                        <FiChevronRight />
                    </button>
                </li>
            </ul>
        </>
    )
}

export default Pagination;