import { Link } from "react-router-dom";

const SidebarSubItem = ({ to, text }) => (
  <Link to={to}>
    <div className="hover:bg-ag-secondary-light bg-ag-secondary">
      <h3 className="py-3 pl-16 pr-5 text-white text-left text-lg ">
        {text}
      </h3>
    </div>
  </Link>
);

export default SidebarSubItem;
