import RowsHistoryTransfer from "./RowsHistoryTransfer";

const TableHistoryTransfer = ({ movements }) => {
    return (
        <div className="overflow-auto">
            <table className="w-full text-left text-ag-secondary">
                <thead>
                    <tr className="bg-gray-300">
                        <th className="w-[70%] bg-black text-white border-[0.5px] border-white text-xs font-normal px-32 md:px-2 py-2">Event</th>
                        <th className="w-[15%] bg-black text-white border-[0.5px] border-white text-xs font-normal px-20 md:px-2 py-2">Updated By</th>
                        <th className="w-[15%] bg-black text-white border-[0.5px] border-white text-xs font-normal px-10 md:px-2 py-2">Date</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        movements.map((element, i) => {
                            return (
                                <RowsHistoryTransfer
                                    key={i}
                                    movement={element}
                                />
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default TableHistoryTransfer;