import { useEffect, useState } from "react";
import Pagination from "../../../../components/pagination/Pagination";
import RowsUsersWholesaler from "./RowsUsersWholesaler";

const TableUserWholesaler = ({ enableApprove, arrUsers, onEdit, onDelete, onStatus }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(7);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = arrUsers.slice(indexOfFirstRecord, indexOfLastRecord);

  useEffect(() => {
    setCurrentPage(1);
  }, [arrUsers]);

  return (
    <div>
      <div className="overflow-auto min-h-[55vh] 2xl:min-h-[51vh]">
        <table className="w-full text-center text-ag-secondary">
          <thead>
            <tr className="bg-ag-secondary-table">
              <th className="w-[6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-3 lg:px-2 py-3">User Number</th>
              <th className="w-[21%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-20 lg:px-2 py-3">Name</th>
              <th className="w-[21%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-20 lg:px-2 py-3">Email</th>
              <th className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">Docs</th>
              <th className="w-[11%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-14 lg:px-2 py-3">Type</th>
              <th className="w-[11%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-14 lg:px-2 py-3">Phone</th>
              <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-4 lg:px-2 py-3">Credit</th>
              {/* <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">Total year</th> */}
              <th className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">Edit</th>
              <th className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">Delete</th>
              <th className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">Approve / Disapprove</th>
            </tr>
          </thead>
          <tbody>
            {
              currentRecords.map((element, i) => {
                return (
                  <RowsUsersWholesaler
                    key={i}
                    indexUser={((i)-recordsPerPage)+(currentPage*recordsPerPage)}
                    enableApprove={enableApprove}
                    users={element}
                    onDelete={() => { onDelete(element); }}
                    onEdit={() => { onEdit(element); }}
                    onStatus={(evtSts) => { onStatus(element, evtSts.target.checked); }}
                  />
                );
              })
            }
          </tbody>
        </table>
      </div>
      <div className="p-5 m-auto">
        <Pagination
          currentPage={currentPage}
          totalCount={arrUsers.length}
          pageSize={recordsPerPage}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>
    </div>
  );
};

export default TableUserWholesaler;