import { useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { getInventoryTransferId } from "../../../../../services/Transfer";
import { getWarehouses } from "../../../../../services/Warehouse";
import { formatReverseDate, removeAccents } from "../../../../../utils/Utilities";
import { getInventoryAddWithRacks } from "../../../../../services/Inventory";
import Filters from "./Filters";
import FormTransfer from "./FormTransfer";
import TableReceivedProducts from "./TableReceivedProducts";
import TableHistoryTransfer from "../TableHistoryTransfer";
import ModalCompatible from "../../../products/ModalCompatible";

const ShowTransfer = () => {
  const params = useParams();

  const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [warehouseFormOptions, setWarehouseFormOptions] = useState([]);
  const [statusOptions] = useState([
    { label: "Canceled", value: "canceled" },
    { label: "Completed", value: "completed" },
    { label: "Draft", value: "draft" },
    { label: "In Process", value: "inprocess" },
  ]);
  const [productsReceived, setProductsReceived] = useState([]);
  const [productsReceivedUniv, setProductsReceivedUniv] = useState([]);
  const [transfer, setTransfer] = useState({
    _id: "",
    status: "",
    fromWarehouse: "",
    toWarehouse: "",
    date: "",
    expectedBy: "",
    products: [],
    totalExpectedQuantity: "",
    totalRealQuantity: "",
    totalDiscrepancy: "",
    subTotal: "",
    total: "",
    message: "",
    updateBy: "",
    movements: []
  });

  const handleFilters = (evt) => {
    if (evt.target.value === "") {
      setProductsReceived(productsReceivedUniv);
    } else {
      let palabra = new RegExp(`${removeAccents(evt.target.value)}.*`, "i");
      const productsFound = productsReceivedUniv.filter(element => (
        palabra.test(removeAccents(element.data.productInventory.product?.nags)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.name)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.model)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.brand)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.year)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.glassType)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.type_car)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.description)) ||
                palabra.test(element.data.productInventory.product?.price) ||
                palabra.test(removeAccents(element.data.productInventory.product?.barcode1)) ||
                palabra.test(removeAccents(element.data.productInventory.product?.barcode2)) ||
                palabra.test(element.data.productInventory.product?.totalQuantity) ||
                palabra.test(removeAccents(element?.note)) ||
                palabra.test(element?.quantity) ||
                palabra.test(element?.totalCost)
      ));
      setProductsReceived(productsFound);
    }
  };

  const getData = async (idTransfer) => {
    await getInventoryTransferId(idTransfer).then(async res => {
      if (res.status === 200) {
        let newProducts = res.data.productsReceived && res.data.productsReceived.length > 0 ? res.data.productsReceived : res.data.productsTransfer;
        for (let i = 0; i < newProducts.length; i++) {
          const resQtyStock = await getInventoryAddWithRacks(newProducts[i].productInventory._id);
          newProducts[i].data = { productInventory: newProducts[i].productInventory };
          newProducts[i].data.quantity = resQtyStock.status === 200 ? resQtyStock.data.totalQuantity : 0;
        }

        setProductsReceived(newProducts);
        setProductsReceivedUniv(newProducts);
        setTransfer({...transfer,
          _id: res.data._id,
          status: res.data.status,
          fromWarehouse: res.data.fromWarehouse ? res.data.fromWarehouse._id : "",
          date: formatReverseDate(res.data.date),
          expectedBy: formatReverseDate(res.data.expectedBy),
          fees: res.data.fees,
          toWarehouse: res.data.toWarehouse ? res.data.toWarehouse._id : "",
          freight: res.data.freight,
          productsInventory: [],
          totalExpectedQuantity: res.data.totalQuantity,
          totalRealQuantity: res.data.totalRealQuantity,
          totalDiscrepancy: res.data.totalDiscrepancy,
          totalQuantity: res.data.totalQuantity,
          subTotal: res.data.subTotal,
          total: res.data.total,
          message: res.data.message,
          cratedBy: res.data.cratedBy,
          movements: res.data.movementsTransfer
        });
      }
    });
  };

  useEffect(() => {
    getWarehouses().then(res => {
      if (res.status === 200 && res.data.length > 0) {
        setWarehouseOptions(res.data);
        setWarehouseFormOptions(res.data);
      } else {
        setWarehouseOptions([]);
        setWarehouseFormOptions([]);
      }
    });

    if (params && params.id) getData(params.id);
  }, []);

  return (
    <>
      <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
        <div className="grid grid-cols-12 px-0 md:px-5">
          <div>
            <Link to="/inventory/transfer">
              <button className="p-1 rounded-xl" type="button">
                <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
              </button>
            </Link>
          </div>
          <div className="flex justify-center items-center col-span-11 text-center">
            <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
                            TRANSFER #TF-{String(params.id).substring(String(params.id).length - 5).toLocaleUpperCase()}
            </h1>
          </div>
        </div>
        <div className="px-0 md:px-5">
          <FormTransfer
            headerTransferData={transfer}
            statusOptions={statusOptions}
            warehousesOptions={warehouseOptions}
            warehouseFromOptions={warehouseFormOptions}
            onHandleChange={() => {}}
            onlyRead={true}
          />
        </div>
        <div className="px-0 md:px-5">
          <Filters onHandleChange={handleFilters} />
        </div>
        <div className="px-0 md:px-5 overflow-x-auto">
          <TableReceivedProducts
            products={productsReceived}
            totalExpectedQuantity={transfer.totalExpectedQuantity}
            totalRealQuantity={transfer.totalRealQuantity}
            totalDiscrepancy={transfer.totalDiscrepancy}
            subTotal={transfer.subTotal}
            total={transfer.total}
            onProduct={() => {}}
            onOrganize={() => {}}
            onDelete={() => {}}
            onlyRead={true}
            onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
          />
        </div>
        <div className="px-0 md:px-5">
          <div className="py-3 space-y-5 pb-5 border-b-2 border-dashed border-ag-secondary-light">
            <div className="w-full">
              <h1 className="text-xs lg:text-base">SHIPPING WAREHOUSE MESSAGE</h1>
            </div>
            <div className="w-full">
              <textarea
                className="w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-100"
                rows="6"
                placeholder="Insert message to Warehouse..."
                name="message"
                value={transfer.message ? transfer.message : "-----"}
                disabled={true}
              >
              </textarea>
            </div>
          </div>
        </div>
        <div className="px-0 md:px-5">
          <div className="py-3 space-y-5 pb-5 border-b-2 border-dashed border-ag-secondary-light">
            <div className="w-full">
              <h1 className="text-xs lg:text-base">HISTORY</h1>
            </div>
            <div className="w-full">
              <TableHistoryTransfer movements={transfer.movements} />
            </div>
          </div>
        </div>
        <div className="px-0 md:px-5 flex justify-between items-center">
          <Link className="w-full" to="/inventory/transfer">
            <button
              className="w-[40%] md:w-[20%] p-2 bg-ag-secondary-dark text-white rounded-xl"
              type="button"
            >
                            Close
            </button>
          </Link>
        </div>
      </div>

      {
        showCompatible.status ?
          <ModalCompatible
            products={showCompatible.element}
            onClose={() => setShowCompatible({...showCompatible, status: false, element: null})}
          />
          : null
      }

    </>
  );
};

export default ShowTransfer;