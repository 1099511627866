import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getRoles, saveRole, updateRole } from "../../../../services/Role";
import { getUsers, updateUser } from "../../../../services/Administrator";
import { FiChevronLeft } from "react-icons/fi";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import { PermitsContext } from "../../../../services/Permits";
import Loader from "../../../../components/loader/Loader";
import { ROLES } from "../../../../utils/constants";
import CardPermissions from "./CardPermissions";

const FormRoles = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { resetPermits } = useContext(PermitsContext);
  const [loader, setLoader] = useState(false);
  const [isModifiable, setIsModifiable] = useState(false);
  const [roleDefault, setRoleDefault] = useState("");
  const [nameOptions, setNameOptions] = useState([]);
  const [permissionsInProgress, setPermissionsInProgress] = useState({});

  const [focusClass, setFocusClass] = useState({
    name: { placeholder: "Write the role name here", status: false }
  });

  const [role, setRole] = useState({
    name: "",
    users: []
  });

  const handleChange = (evt) => {
    setRole({...role, [evt.currentTarget.name]: evt.currentTarget.value});
  };

  const handleChangeEmployees = (evt) => {
    let newArrUsers = role.users;
    const newOptions = nameOptions.map((element) => {
      if (element.value === evt.currentTarget.value) {
        const foundUser = newArrUsers.find(userElement => (userElement.no === element.user._id));
        if (!foundUser) {
          newArrUsers.push({ no: element.user._id, name: element.label, user: element.user });
        } else {
          newArrUsers = newArrUsers.filter(userElement => (userElement.no !== element.user._id));
        }

        return  {
          no: element.no,
          value: element.value,
          label: element.label,
          selected: !element.selected,
          isDefault: element.isDefault,
          user: element.user
        };
      }
      return element;
    });
    setNameOptions(newOptions);
    setRole({...role, users: newArrUsers});
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (role.name !== "") {
      const formattedPermissions = formatPermissions(permissionsInProgress);
      if (Object.entries(formattedPermissions).length > 0) {
        setLoader(true);
        const newRoleModel = {
          name: role.name,
          permissions: [formattedPermissions],
        };
        if (params && params.id) {
          newRoleModel._id = params.id;
          updateRole(params.id, newRoleModel).then((res) => {
            if (res.status === 200) {
              for (let i = 0; i < role.users.length; i++) {
                let userModel = {
                  _id: role.users[i].user._id ? role.users[i].user._id : "",
                  names: role.users[i].user.names ? role.users[i].user.names : "",
                  lastNames: role.users[i].user.lastNames ? role.users[i].user.lastNames : "",
                  email: role.users[i].user.user.email ? role.users[i].user.user.email : "",
                  phoneNumber: role.users[i].user.phoneNumber ? role.users[i].user.phoneNumber : "",
                  warehouse: role.users[i].user.warehouse ? role.users[i].user.warehouse : "",
                  role: {
                    _id: params.id
                  }
                };

                updateUser(role.users[i].user._id, userModel).then(resUpd => {});
              }

              // Los usuarios que se deseleccionaron, se les asigna el rol predeterminado
              const usersRoleDefault = nameOptions.filter(urElement => (!urElement.selected && urElement.isDefault));
              if (usersRoleDefault && usersRoleDefault.length > 0) {
                for (let iurd = 0; iurd < usersRoleDefault.length; iurd++) {
                  let userModel = {
                    _id: usersRoleDefault[iurd].user._id ? usersRoleDefault[iurd].user._id : "",
                    names: usersRoleDefault[iurd].user.names ? usersRoleDefault[iurd].user.names : "",
                    lastNames: usersRoleDefault[iurd].user.lastNames ? usersRoleDefault[iurd].user.lastNames : "",
                    email: usersRoleDefault[iurd].user.user.email ? usersRoleDefault[iurd].user.user.email : "",
                    phoneNumber: usersRoleDefault[iurd].user.phoneNumber ? usersRoleDefault[iurd].user.phoneNumber : "",
                    warehouse: usersRoleDefault[iurd].user.warehouse ? usersRoleDefault[iurd].user.warehouse : "",
                    role: {
                      _id: roleDefault
                    }
                  };
                  updateUser(userModel._id, userModel).then(resUpd => {});
                }
              }

              resetPermits();
              setLoader(false);
              toast.success("Role updated successfully", alertOptions);
              navigate("/administrators/roles");
            } else {
              setLoader(false);
              toast.warning(res.response.data.message, alertOptions);
            }
          }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
          });
        } else {
          saveRole(newRoleModel).then(res => {
            if (res.status === 200) {
              for (let i = 0; i < role.users.length; i++) {
                let userModel = {
                  _id: role.users[i].user._id ? role.users[i].user._id : "",
                  names: role.users[i].user.names ? role.users[i].user.names : "",
                  lastNames: role.users[i].user.lastNames ? role.users[i].user.lastNames : "",
                  email: role.users[i].user.email ? role.users[i].user.email : "",
                  phoneNumber: role.users[i].user.phoneNumber ? role.users[i].user.phoneNumber : "",
                  warehouse: role.users[i].user.warehouse ? role.users[i].user.warehouse : "",
                  role: {
                    _id: res.data._id
                  }
                };
                updateUser(role.users[i].user._id, userModel).then(resUpd => {});
              }

              resetPermits();
              setLoader(false);
              toast.success("Role added successfully", alertOptions);
              navigate("/administrators/roles");
            } else {
              setLoader(false);
              toast.warning(res.response.data.message, alertOptions);
            }
          }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
          });
        }
      } else {
        toast.warning("You cannot save a role without any permissions assigned", alertOptions);
      }
    } else {
      toast.info("Please fill in all the fields of the form", alertOptions);
    }
  };

  const getOptions = (roleName, array_users) => {
    let names = [];
    let usersCurrent = [];
    for (let i = 0; i < array_users.length; i++) {
      const nameFound = names.find(element => element._id === array_users[i]._id);
      let userAssigned = false;
      if (params && params.id && array_users[i].role?._id === params.id) userAssigned = true;

      if (!nameFound) {
        names.push({
          no: array_users[i]._id,
          user: array_users[i],
          label: `${array_users[i].names} ${array_users[i].lastNames}`,
          value: `${array_users[i].names} ${array_users[i].lastNames}`,
          selected: userAssigned,
          isDefault: userAssigned
        });

        if (userAssigned) {
          usersCurrent.push({
            no: array_users[i]._id,
            name: `${array_users[i].names} ${array_users[i].lastNames}`,
            user: array_users[i]
          });
        }
      }
    }
    setRole({...role, name: roleName, users: usersCurrent});
    setFocusClass({...focusClass, name: { placeholder: "Write the role name here", status: roleName !== "" ? true : false }});
    setNameOptions(names);
  };

  function formatPermissions(data) {
    const formatted = {};

    Object.keys(data).forEach(key => {
      const [role, permission] = key.split('-');

      if (!formatted[role]) {
        formatted[role] = [];
      }

      if (permission && data[key] === true) {
        formatted[role].push(permission);
      }
    });

    return formatted;
  }

  const handleToggleChange = (checked, id) => {
    setPermissionsInProgress(prevState => ({
      ...prevState,
      [id]: checked,
    }));
  };

  useEffect(() => {
    setLoader(true);
    getRoles().then(res => {
      let initialPermissions = {};

      if (res.status === 200 && res.data.length) {
        if (params && params.id) {
          // Se almacena el ID del rol predeterminado
          const roleDefaultFound = res.data.find(roleElement => roleElement?.name === "DEFAULT ROLE");
          if (roleDefaultFound) setRoleDefault(roleDefaultFound._id);

          // Se obtiene la informacion del rol actual
          const roleCurrentFound = res.data.find(roleElement => roleElement?._id === params.id);
          if (roleCurrentFound) {
            if (roleCurrentFound?.name === "DEFAULT ROLE") setIsModifiable(true);

            const dataPermits = JSON.parse(roleCurrentFound.permissions);

            for (const key in dataPermits[0]) {
              if (Object.hasOwnProperty.call(dataPermits[0], key)) {
                document.getElementById(key).checked = true;

                for (let i = 0; i < dataPermits[0][key].length; i++) {
                  const permitFound = document.getElementById(`${key}-${dataPermits[0][key][i]}`);
                  if (permitFound) permitFound.checked = true;
                }
              }
            }

            for (const key in dataPermits[0]) {
              if (Object.hasOwnProperty.call(dataPermits[0], key)) {
                initialPermissions[key] = true;

                dataPermits[0][key].forEach(permit => {
                  initialPermissions[`${key}-${permit}`] = true;
                });
              }
            }

            setPermissionsInProgress(initialPermissions);

            getUsers().then(resUsers => {
              const usersFound = resUsers.data.filter(element => !element.deleted);
              getOptions(roleCurrentFound?.name, usersFound);
            });
          }
          setLoader(false);
        } else {
          getUsers().then(resUsers => {
            setLoader(false);
            const usersFound = resUsers.data.filter(element => !element.deleted);
            getOptions("", usersFound);
          });
        }
      }
    }).catch(() => {
      setLoader(false);
    });
  }, []);

  return (
    <>
      { loader && <Loader /> }

      <div className="space-y-5 py-8 px-5 md:px-16">
        <div>
          <Link to="/administrators/roles">
            <button className="p-1 rounded-xl" type="button">
              <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
            </button>
          </Link>
        </div>

        <div className="flex justify-center" >
          <div className="md:w-8/12">
            <div className="flex-col md:flex-row flex align-center justify-between items-center gap-[15px]">
              <div className="text-center">
                <h1 className="text-[26px] md:text-xl lg:text-[26px] text-ag-secondary font-bold">
                  New Role / Edit Role
                </h1>
              </div>

              <div className="flex gap-[10px]">
                <div>
                  <Link to="/administrators/roles">
                    <button
                      className="w-full bg-ag-secondary-light text-white rounded-xl py-[10px] px-[25px]"
                      type="button"
                    >
                      Cancel
                    </button>
                  </Link>
                </div>

                <div>
                  <button
                    className="w-full bg-ag-primary-light text-white rounded-xl py-[10px] px-[25px]"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>

            <div className="pt-3 space-y-5">
              <div className="filters-shadow md:flex md:justify-center md:items-center md:space-x-10 space-y-5 md:space-y-0 p-3 rounded-lg">
                <div className="w-full space-y-2">
                  <div>
                    <h1 className="text-base text-ag-secondary font-bold">Role name*</h1>
                  </div>

                  <div>
                    <input
                      className={`w-full text-ag-secondary-letter border border-[#6b72805c] p-2 truncate rounded-xl disabled:bg-zinc-200 ${focusClass.name.status ? "text-left" : "text-center"}`}
                      name="name"
                      type="text"
                      placeholder={focusClass.name.placeholder}
                      onChange={handleChange}
                      value={role.name}
                      disabled={isModifiable}
                      onFocus={() => setFocusClass({...focusClass, name: { placeholder: "", status: true }})}
                      onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, name: { placeholder: "Write the role name here", status: false }}) : null}
                      maxLength={50}
                    />
                  </div>
                </div>

                <div className="w-full space-y-2">
                  <div>
                    <h1 className="text-base text-ag-secondary font-bold">Select your employees</h1>
                  </div>

                  <div>
                    <select
                      className="w-full text-ag-secondary-letter border border-[#6b72805c] p-2 rounded-xl"
                      name="users"
                      value="default"
                      onChange={handleChangeEmployees}
                    >
                      <option value="default">Select</option>
                      {
                        nameOptions.map((optionName, k) => {
                          return (
                            <option className="flex space-x-2" key={k} value={optionName.label}
                            >
                              { optionName.label }{ optionName.selected ? ' (✓)' : '' }
                            </option>
                          );
                        })
                      }
                    </select>
                  </div>
                </div>
              </div>

              <div>
                <div className="text-left mb-[20px]">
                  <h1 className="text-[22px] md:text-xl lg:text-[22px] font-medium text-ag-secondary">Sections</h1>
                </div>

                {
                  ROLES.map((option, i) => {
                    return (
                      <CardPermissions
                        key={i}
                        option={option}
                        handleToggleChange={handleToggleChange}
                        permissionsInProgress={permissionsInProgress}
                      />
                    );
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormRoles;