import { _delete, _get, _getFree, _postFree, _put } from "./http";

export const getWholesalerUser = (id) => _getFree(`/wholesalerUser/${id}`);

export const getWholesalerUsers = () => _get(`/wholesalerUser`);

export const saveWholesalerUser = (userObject) => _postFree(`/wholesalerUser`, userObject);

export const updateWholesalerUser = (id, userObject) => _put(`/wholesalerUser/${id}`, userObject);

export const deleteWholesalerUser = (id) => _delete(`/wholesalerUser/${id}`);
