import { IoMdCloseCircle } from "react-icons/io";
import { formatCurrency } from "../../../utils/Utilities";
import Features from "../../../components/features/Features";

const OrderDetail = ({ products, onClose, onCompatible }) => {
  return (
    <>
      <div className="block">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
          <div className="w-[320px] md:w-[600px]">
            <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
              <div className="w-full flex items-center justify-center rounded text-center">
                <div className="w-[95%]">
                  <span className="text-xl text-ag-secondary font-bold pl-[5%]">
                    Order detail
                  </span>
                </div>

                <div className="w-[5%]">
                  <button
                    className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                    onClick={() => onClose(false)}
                  >
                    <IoMdCloseCircle />
                  </button>
                </div>
              </div>

              <div className="px-2 md:px-5 pb-0 pt-5 max-h-[400px] overflow-auto">
                <ul className="space-y-3">
                  { products.map((elem, i) => (
                    <li key={i} className="py-3 border-b border-zinc-500">
                      <p className="text-sm font-semibold">
                        { elem?.productInventory?.product?.nags }
                      </p>
                      <div className="flex items-start space-x-2">
                        {/* <span className="text-base flex-grow ">{elem?.productInventory?.product?.features}</span> */}
                        <Features element={elem?.productInventory?.product} color={'#000'} textSize={'10px'} seeTitle={true} />
                      </div>
                      {
                        elem?.productInventory?.product?.compatible_vehicles.map((prodComp, i) => {
                          if (i < 1) {
                            return (
                              <p
                                className="text-sm"
                                key={i}
                                title={`${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year} ` +
                                `${prodComp?.brand} ` +
                                `${prodComp?.model} ` +
                                `${prodComp?.type_car}` +
                                `(${prodComp?.type_glass})`
                                }
                              >
                                { String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year }{' '}
                                { prodComp?.brand }{' '}
                                { prodComp?.model }{' '}
                                { prodComp?.type_car }
                                {' ('}{ prodComp?.type_glass }{')'}
                              </p>
                            );
                          }
                        })
                      }

                      {
                        elem?.productInventory?.product?.compatible_vehicles.length > 1 ?
                          <p className="text-sm font-semibold cursor-pointer" onClick={() => onCompatible(elem?.productInventory?.product)}>
                            See compatible vehicles +
                          </p>
                          : null
                      }

                      <div className="flex justify-between items-center space-x-3 pt-3">
                        <p className="text-sm"><b>Quantity:</b> { elem?.quantity }</p>

                        <p className="text-sm"><b>Price:</b> { formatCurrency(elem?.price) }</p>

                        <p className="text-sm"><b>Total:</b> { formatCurrency(Number(elem?.price)*Number(elem?.quantity)) }</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
    </>
  );
};

export default OrderDetail;