import { useState, useEffect } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { getCostdeliveryFee } from "../../../services/ChangeDeliveryFee";
import CurrencyInput from 'react-currency-input-field';


const ModalShippingRetail = ({ onClose, onChange }) => {
    const [valueDelivery, setValueDelivery] = useState("");

    // const handleChange = (evt) => {
    //     if (!isNaN(evt.target.value)) setValueDelivery(evt.target.value);
    // }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (valueDelivery !== "" && valueDelivery > 0) {
            onChange(valueDelivery);
        } else {
            toast.info("Please enter a valid number", alertOptions);
        }
    }

    const getCost = () => {
        getCostdeliveryFee('RETAIL_USER').then(res => {
            if (res.status === 200) {
                setValueDelivery(res?.data?.cost)
            }
        }).catch((error) => {
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    useEffect(() => {
        getCost()
    }, [])

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[600px]">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center pb-4">
                                <div className="w-[95%]">
                                    <span className="text-xl text-ag-secondary font-bold pl-[5%]">
                                        Change Delivery Fee For Retail
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="w-[100%] flex justify-center px-5 pb-2 pt-3">
                                <div className="w-[70%]">
                                    <form onSubmit={handleSubmit}>
                                        <div className="pb-5">
                                            <span className="text-ag-secondary-letter text-sm">
                                                This delivery fee is only for retail users when they choose the shipping option: Mobile Service.
                                            </span>
                                        </div>
                                        <div className="pb-6">
                                            {/* <input
                                                className="w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-2 px-4 rounded-xl disabled:bg-zinc-200 truncate text-center"
                                                type="text"
                                                value={valueDelivery}
                                                onChange={handleChange}
                                                maxLength={6}
                                            /> */}
                                              <CurrencyInput
                                                className="w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-2 px-4 rounded-xl disabled:bg-zinc-200 truncate text-center"
                                                prefix="$"
                                                value={valueDelivery}
                                                onValueChange={(value) => setValueDelivery(value)}
                                                allowDecimals={true}
                                                decimalSeparator="." 
                                                decimalsLimit={4}
                                                maxLength={7}
                                                allowNegativeValue={false}
                                            />
                                        </div>
                                        <div className="flex justify-center">
                                            <button
                                                className="w-[90%] bg-ag-primary-light text-white text-xs lg:text-base p-2 rounded-xl disabled:bg-zinc-200"
                                                type="submit"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
        </>
    )
}

export default ModalShippingRetail