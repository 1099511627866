import { useEffect, useState } from "react";
import Pagination from "../../../components/pagination/Pagination";
import RowsProducts from "./RowsProducts";

const TableProduct = ({ enableEdit, enableDeactivate, products, currntPg, totalCount, onPage, onStatus, onCompatible, updatePagination, onResponseUpdatePagination }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);

    useEffect(() => {
        setCurrentPage(currntPg);
    }, [currntPg]);

    return (
        <div>
            <div className="overflow-x-auto min-h-[38vh] lg:min-h-[47vh] 2xl:min-h-[55vh]">
                <table className="w-full table-auto text-center text-ag-secondary">
                    <thead>
                        <tr className="bg-ag-secondary-table">
                            <th className="w-[6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 md:px-8 lg:px-2 py-3 py-4">Nags</th>
                            <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 md:px-14 lg:px-2 py-3 py-4">Name</th>
                            <th className="w-[18%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 md:px-6 lg:px-2 py-3 py-4">Compatible Vehicles</th>
                            <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 md:px-16 lg:px-2 py-3 py-4">Description</th>
                            <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 md:px-16 lg:px-2 py-3 py-4">Notes</th>
                            <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 md:px-10 lg:px-2 py-3 py-4">Vendors</th>
                            <th className="w-[6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 md:px-8 lg:px-2 py-3 py-4">Nags Price</th>
                            <th className="w-[6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 md:px-8 lg:px-2 py-3 py-4">Barcode 1</th>
                            <th className="w-[6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 md:px-8 lg:px-2 py-3 py-4">Barcode 2</th>
                            <th className="w-[4%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 md:px-4 lg:px-2 py-3 py-4">Actions</th>
                            <th className="w-[6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-4">Deactivate</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            products.map((element, i) => {
                                return (
                                    <RowsProducts
                                        key={i}
                                        enableEdit={enableEdit}
                                        enableDeactivate={enableDeactivate}
                                        products={element}
                                        onStatus={(evtSts) => onStatus(element, evtSts.target.checked)}
                                        onCompatible={onCompatible}
                                    />
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="p-5 m-auto">
                <Pagination
                    currentPage={currentPage}
                    totalCount={totalCount}
                    pageSize={recordsPerPage}
                    onPageChange={page => onPage(page)}
                />
            </div>
        </div>
    );
}

export default TableProduct;