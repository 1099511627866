import React from "react";

const RearVentRight = ({ isSelected, isAvailable, onClick }) => {
  return (
    <svg className="cursor-pointer outline-none" onClick={onClick} data-tooltip-id="car" data-tooltip-content="Rear Vent (right)">
      <path d="M270.32 338.42V328.02L303.12 337.35V365.35L270.32 338.42Z" fill={isSelected ? "#F41A28" :isAvailable ? "#1D8F43" : "#D3D3D4"} />;
    </svg>
  )
};

export default RearVentRight;
