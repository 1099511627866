import { useEffect, useState } from "react";
import Pagination from "../../../../components/pagination/Pagination";
import RowsDetails from "./RowsDetails";

const TableDetails = ({ items, status, typeClient, onReturn, onCompatible, onFeatures, order }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = items.slice(indexOfFirstRecord, indexOfLastRecord);

  useEffect(() => {
    setCurrentPage(1);
  }, [items]);

  return (
    <div>
      <table className="w-full text-center text-ag-secondary">
        <thead>
          <tr className="bg-ag-secondary-table">
            <th className="w-[40%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-[100px] md:px-2 py-4">Description</th>
            <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-8 md:px-2 py-4">Features</th>
            <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-8 md:px-2 py-4">Quantity</th>
            <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-8 md:px-2 py-4">Price</th>
            <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-8 md:px-2 py-4">Discount</th>
            <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-8 md:px-2 py-4">Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            currentRecords.map((element, i) => {
              return (
                <RowsDetails
                  key={i}
                  item={element}
                  status={status}
                  typeClient={typeClient}
                  onReturn={onReturn}
                  onCompatible={onCompatible}
                  onFeatures={onFeatures}
                  order={order}
                />
              );
            })
          }
        </tbody>
      </table>
      <div className="p-5 m-auto">
        <Pagination
          currentPage={currentPage}
          totalCount={items.length}
          pageSize={recordsPerPage}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>
    </div>
  );
};

export default TableDetails;