import { _delete, _get, _post, _put } from "./http";

export const getInventoryTransferId = (id) => _get(`/transfer/${id}`);

export const getInventoryTransfer = () => _get(`/transfer`);

export const saveInventoryTransfer = (inventoryObject) => _post(`/transfer`, inventoryObject);

export const updateInventoryTransferId = (id, inventoryObject) => _put(`/transfer/${id}`, inventoryObject);

export const completeInventoryTransferId = (id, inventoryObject) => _put(`/transfer/${id}/complete`, inventoryObject);

export const canceledInventoryTransfer = (id, inventoryObject) => _put(`/transfer/${id}/cancel`, inventoryObject);

export const deleteInventoryTransferId = (id) => _delete(`/transfer/${id}`);