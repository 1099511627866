import React from 'react'

const RearQuarterRight = ({ isSelected, isAvailable, onClick }) => {
  return (
    <svg className="cursor-pointer outline-none" onClick={onClick} data-tooltip-id="car" data-tooltip-content="Rear Quarter (right)">
      <path d="M272.12 352.68L292.12 402.01C292.12 402.01 301.45 396.41 300.65 377.34C289.19 367.62 272.12 352.68 272.12 352.68Z" fill={isSelected ? "#F41A28" :isAvailable ? "#1D8F43" : "#D3D3D4"}/>
    </svg>
  )
}

export default RearQuarterRight