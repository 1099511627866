import RowsProcess from "./RowsProcess";
import WithoutRows from "../../../../components/table/WithoutRows";

const TableProcess = ({ orders }) => {
    return (
        <div>
            <table className="w-full text-center text-ag-secondary">
                <thead>
                    <tr className="bg-ag-secondary-table">
                        <th className="w-[17%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-2 py-4">Order</th>
                        <th className="w-[25%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-12 md:px-2 py-4">Items</th>
                        <th className="w-[17%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-2 py-4">Quantity</th>
                        <th className="w-[25%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-14 md:px-2 py-4">Client</th>
                        <th className="w-[16%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-12 md:px-2 py-4">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        orders.length === 0 ? <WithoutRows colspan={5} /> : orders.map((element, i) => {
                            return (
                                <RowsProcess key={i} item={element} />
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default TableProcess;