import React, { useEffect, useState } from "react";
import { RiEqualizerFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import OrangeDropdown from '../../../components/OrangeDropdown';

const FiltersInventoryAccessory = ({ enableFilters, enableActions, enableWarehouse, warehousesOptions, statusOptions, onFilters, setShowFilters, showFilters }) => {
    const navigate = useNavigate();

    const actionsOptions = [
        { label: 'Transfer', path: '#' },
        { label: 'Stocktake', path: '#' },
        { label: 'Add', path: '/inventory-accessory/element' },
    ];

    const [focusClass, setFocusClass] = useState({
        search: { placeholder: "Search", status: false },
        warehouse: { placeholder: "Warehouse", status: false },
        status: { placeholder: "Type", status: false }
    });

    const [options, setOptions] = useState({
        search: "",
        warehouse: "",
        status: ""
    });

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setOptions({...options, [name]: value});
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        const { search, nag, ...otherOptions } = options;
        const searchQuery = {
            search: search.trim(),
            ...otherOptions
        };
        onFilters(searchQuery);
    }

    return (
        <form onSubmit={handleSubmit}>
            { showFilters ? (
                <div className="grid gap-3 lg:gap-7 md:grid-cols-5">
                    <div className="col-span-5">
                        <input
                            className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-2 px-4 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.search.status ? "text-left" : "text-center"}`}
                            type="text"
                            name="search"
                            placeholder={focusClass.search.placeholder}
                            onChange={handleChange}
                            disabled={!enableFilters}
                            onFocus={() => setFocusClass({...focusClass, search: { placeholder: "", status: true }})}
                            onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, search: { placeholder: "Search", status: false }}) : null}
                            maxLength={40}
                            value={options.search}
                        />
                    </div>

                    <div className="col-span-5 md:col-span-1 space-y-3 md:pr-3 lg:pr-8 md:border-r md:border-ag-secondary">
                        <div>
                            <button
                                className="w-full flex justify-center items-center space-x-3 text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-2 rounded-xl"
                                type="button"
                                disabled={!enableFilters}
                                onClick={() => setShowFilters(!showFilters)}
                            >
                                <span className="text-base lg:text-2xl">
                                    <RiEqualizerFill />
                                </span>

                                <span>Filters</span>
                            </button>
                        </div>

                        <OrangeDropdown options={actionsOptions} enableActions={enableActions} enableFilters={enableFilters} />
                    </div>

                    <div className="col-span-5 md:col-span-4 space-y-3">
                        <div className="grid gap-3 lg:gap-7 md:grid-cols-5">
                            <div>
                                <select
                                    className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-[9.5px] px-4 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.warehouse.status ? "text-left" : "text-center"}`}
                                    name="warehouse"
                                    value={options.warehouse}
                                    onChange={handleChange}
                                    disabled={!enableFilters}
                                    onFocus={() => setFocusClass({...focusClass, warehouse: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, warehouse: { placeholder: "Warehouse", status: false }}) : null}
                                >
                                    <option value="">Warehouse</option>
                                    {warehousesOptions.map((element, index) => (
                                        <option key={index} value={element._id}>{element.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="md:col-span-2">
                                <select
                                    className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-[9.5px] px-4 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.status.status ? "text-left" : "text-center"}`}
                                    name="status"
                                    defaultValue="default"
                                    onChange={handleChange}
                                    disabled={!enableFilters}
                                    onFocus={() => setFocusClass({...focusClass, status: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, status: { placeholder: "Status", status: false }}) : null}
                                >
                                    <option value="default">Status</option>
                                    {statusOptions.map((element, index) => (
                                        <option key={index} value={element.value}>{element.label}</option>
                                    ))}
                                </select>
                            </div>

                            <div>
                                <button
                                    className="w-full bg-ag-secondary text-white text-xs lg:text-base p-2 rounded-xl disabled:bg-zinc-200"
                                    type="submit"
                                    disabled={!enableFilters}
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="grid gap-3 lg:gap-7 md:grid-cols-5 md:pb-3">
                    <div>
                        <button
                            className="w-full flex justify-center items-center space-x-3 text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200"
                            type="button"
                            disabled={!enableFilters}
                            onClick={() => setShowFilters(!showFilters)}
                        >
                            <span className="text-base lg:text-2xl">
                                <RiEqualizerFill />
                            </span>

                            <span>Filters</span>
                        </button>
                    </div>

                    <OrangeDropdown options={actionsOptions} enableActions={enableActions} enableFilters={enableFilters} />

                    <div className="md:col-span-3"></div>
                </div>
            )}
        </form>
    );
}

export default FiltersInventoryAccessory;