import { useContext, useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { getWarehouse } from "../../../../services/Warehouse";
import { alertOptions } from "../../../../utils/Utilities";
import { PermitsContext } from "../../../../services/Permits";

const InventoryReturns = ({ returnElement, onSubmit, onClose }) => {
  const { dataSession } = useContext(PermitsContext);
  const [rackInfo, setRackInfo] = useState({ rack: "", quantity: "" });
  const [rackOptions, setRackOptions] = useState([]);
  const [rackProducts, setRackProducts] = useState([]);
  const [productQuantity, setProductQuantity] = useState({
    note: "",
    quantityAvailable: 0,
    removeQuantity: 0,
    addQuantity: 0,
    addRackQuantity: []
  });

  const handleAddRack = () => {
    if (productQuantity.quantityAvailable > 0) {
      if (rackInfo.quantity !== "" && rackInfo.rack !== "") {
        const foundRack = rackProducts.find(element => element.rack._id === rackInfo.rack._id);
        if (!foundRack) {
          setRackProducts([...rackProducts, { rack: rackInfo.rack, quantity: rackInfo.quantity }]);
          setProductQuantity({...productQuantity,
            quantityAvailable: productQuantity.quantityAvailable - rackInfo.quantity,
            addQuantity: productQuantity.addQuantity + rackInfo.quantity
          });
          setRackInfo({...rackInfo, rack: "", quantity: ""});
        } else {
          toast.warning("The entered rack is already in the list", alertOptions);
        }
      } else {
        toast.info("Please fill in all the fields of the form", alertOptions);
      }
    } else {
      toast.warning("The amount of maximum products assigned has already been found", alertOptions);
    }
  };

  const handleRemoveRack = (rck) => {
    let valueToRemove = 0;
    const newRacks = rackProducts.filter(element => {
      if (element.rack !== rck.rack) {
        return element;
      }

      valueToRemove += element.quantity;
    });
    setRackProducts(newRacks);
    setProductQuantity({...productQuantity,
      quantityAvailable: productQuantity.quantityAvailable + valueToRemove,
      addQuantity: productQuantity.addQuantity - valueToRemove
    });
  };

  const handleRackProduct = (evt) => {
    if (evt.target.name === "quantity" && !isNaN(evt.target.value)) {
      if (productQuantity.quantityAvailable >= Number(evt.target.value)) {
        setRackInfo({...rackInfo, quantity: Number(evt.target.value)});
      } else {
        toast.warning(`The available range of products is 0 - ${returnElement.quantity}`, alertOptions);
      }
    } else {
      setRackInfo({...rackInfo, rack: { _id: evt.target.value }});
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (productQuantity.quantityAvailable === 0) {
      const body = {
        _id: returnElement._id,
        warehouse: { _id: returnElement.warehouse._id },
        employee: { _id: dataSession._id },
        productInventory: { _id: returnElement.productOrder.productInventory._id },
        note: productQuantity.note,
        removeQuantity: productQuantity.removeQuantity,
        addQuantity: productQuantity.addQuantity,
        addRackQuantity: rackProducts,
        validateMaxMin: false,
      };
      onSubmit(body);
    } else {
      toast.warning("Please assign all available products", alertOptions);
    }
  };

  useEffect(() => {
    if (returnElement !== null) {
      setProductQuantity({...productQuantity, quantityAvailable: Number(returnElement.quantity)});
      getWarehouse(returnElement.warehouse._id).then((res) => {
        if (res.status === 200) setRackOptions(res.data.racks);
      });
    }
  }, []);

  return (
    <>
      <div className="block">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-20 outline-none focus:outline-none">
          <div className="w-[320px] md:w-[700px] lg:w-[800px]">
            <div className="p-3 md:p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
              <div className="w-full flex items-center justify-center rounded text-center">
                <div className="w-[95%]">
                  <span className="text-xl text-ag-secondary font-bold">
                    Inventory products
                  </span>
                </div>

                <div className="w-[5%]">
                  <button
                    className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                    onClick={() => onClose(true)}
                  >
                    <IoMdCloseCircle />
                  </button>
                </div>
              </div>

              <div className="relative px-5 pb-2 pt-5 flex-auto">
                <form className="space-y-5" onSubmit={handleSubmit}>
                  <div className="flex justify-start items-center space-x-3 text-ag-secondary-letter">
                    <span className="font-semibold">Products available for return:</span>
                    <span>{ productQuantity.quantityAvailable }</span>
                  </div>

                  <div className="border-b border-ag-secondary-light pb-3">
                    <div className="grid gap-3 md:grid-cols-3 px-5 md:px-16 pt-0">
                      <div className="text-ag-secondary-letter">
                        <span className="text-xs xl:text-sm">Products to add</span>
                      </div>

                      <div className="text-center">
                        <span className="text-green-600 text-lg">
                          { productQuantity.addQuantity }
                        </span>
                      </div>
                    </div>

                    <div className="grid gap-3 md:grid-cols-3 px-5 md:px-16 pt-3 pb-1">
                      <div>
                        <select
                          className="w-full text-ag-secondary-letter text-xs xl:text-sm border border-gray-500 py-[5px] px-5 rounded-xl disabled:bg-zinc-200 text-center"
                          name="rack"
                          onChange={handleRackProduct}
                          value={rackInfo.rack?._id ? rackInfo.rack?._id : "default"}
                        >
                          <option value="default">Rack</option>
                          {
                            rackOptions.map((element, index) => {
                              return <option key={index} value={element._id}>{element.name}</option>;
                            })
                          }
                        </select>
                      </div>

                      <div>
                        <input
                          className="w-full text-center text-ag-secondary-letter text-xs xl:text-sm border border-ag-secondary-letter px-5 py-1 rounded-xl"
                          type="text"
                          name="quantity"
                          placeholder="Quantity"
                          onChange={handleRackProduct}
                          value={rackInfo.quantity}
                        />
                      </div>

                      <div className="flex justify-center items-center space-x-3">
                        <button
                          className="w-full bg-ag-secondary text-white text-xs xl:text-sm px-3 py-1 rounded-xl"
                          type="button"
                          onClick={handleAddRack}
                        >
                                                    Add rack
                        </button>
                      </div>
                    </div>

                    <div className={rackProducts.length > 0 ? "pt-3" : "pt-0"}>
                      {
                        rackProducts.map((rackProduct, i) => (
                          <div className="grid gap-3 md:grid-cols-3 px-5 md:px-16 py-1" key={i}>
                            <div>
                              <select
                                className="w-full text-ag-secondary-letter text-xs xl:text-sm border border-gray-500 py-[5px] px-5 rounded-xl disabled:bg-zinc-200 text-center"
                                name="rack"
                                disabled={true}
                                value={rackProduct.rack._id}
                              >
                                <option value="default">Rack</option>
                                {
                                  rackOptions.map((element, index) => {
                                    return <option key={index} value={element._id}>{element.name}</option>;
                                  })
                                }
                              </select>
                            </div>

                            <div>
                              <input
                                className="w-full text-center text-ag-secondary-letter text-xs xl:text-sm border border-ag-secondary-letter px-5 py-1 rounded-xl disabled:bg-zinc-200"
                                type="text"
                                placeholder="Quantity"
                                disabled={true}
                                value={rackProduct.quantity}
                              />
                            </div>

                            <div className="flex justify-center items-center space-x-3">
                              <span className="text-red-600 text-3xl cursor-pointer" onClick={() => handleRemoveRack(rackProduct, i)}>
                                <IoMdCloseCircle />
                              </span>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>

                  <div className="px-5 md:px-16">
                    <textarea
                      className="w-full text-ag-secondary-letter text-xs xl:text-sm p-3 border border-ag-secondary-light rounded-xl"
                      rows="2"
                      placeholder="Note..."
                      value={productQuantity.note}
                      onChange={(e) => setProductQuantity({...productQuantity, note: e.target.value})}
                    ></textarea>
                  </div>

                  <div className="flex justify-center items-center px-5 md:px-16 space-x-5">
                    <button
                      type="button"
                      className="w-full px-4 py-2 bg-ag-primary-light text-white text-xs xl:text-sm rounded-xl"
                      onClick={() => onClose(true)}
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      className="w-full px-4 py-2 bg-green-600 text-white text-xs xl:text-sm rounded-xl"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-50 fixed inset-0 z-10 bg-black"></div>
    </>
  );
};

export default InventoryReturns;