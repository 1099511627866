import { IoMdCloseCircle } from "react-icons/io";

const ModalNags = ({ id, nags, onClose }) => {
    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                    <div className="w-[320px] h-[500px] xl:h-auto">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="text-xl text-ag-secondary font-bold">
                                        Nag interchange - { id }
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="px-8 py-5">
                                <ul className="list-disc">
                                    {
                                        nags.map((nagElement, i) => (
                                            <li className="w-full text-ag-secondary" key={i}>
                                                { nagElement }
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
        </>
    );
}

export default ModalNags;