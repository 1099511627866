import React from 'react'

const FrontQuarterLeft = ({onClick}) => {
  return (
    <svg className="cursor-pointer outline-none" onClick={onClick} data-tooltip-id="car" data-tooltip-content="Front Quarter (left)">
      <path d="M67.9596 115.78L85.8596 170.48L55.9596 160.38C55.9596 160.38 53.9596 133.28 67.9596 115.78Z" fill="#D3D3D4"></path>
    </svg>
  )
}

export default FrontQuarterLeft