import React from 'react'

const FrontVentRight = ({ isSelected, isAvailable, onClick }) => {
  return (
    <svg className="cursor-pointer outline-none" onClick={onClick} data-tooltip-id="car" data-tooltip-content="Front Vent (right)">
      <path d="M270.32 183.08V201.48L303.12 188.28V170.48L270.32 183.08Z" fill={isSelected ? "#F41A28" :isAvailable ? "#1D8F43" : "#D3D3D4"}/>
    </svg>
  )
}

export default FrontVentRight