import React from "react";

const Windshield = ({isSelected, isAvailable, onClick}) => {
  return (
    <svg className="cursor-pointer outline-none" onClick={onClick} data-tooltip-id="car" data-tooltip-content="Windshield">
      <path
        d="M198.9 160.73C198.9 158.49 197.09 156.68 194.85 156.68H164.05C161.81 156.68 160 158.49 160 160.73V167.33C160 167.79 160.09 168.23 160.24 168.64C171.79 168.32 184.65 168.3 198.62 168.8C198.8 168.34 198.91 167.85 198.91 167.32V160.73H198.9Z"
        fill={isSelected ? "#A60812" :isAvailable ? "#147F40" : "#B4B4B3"}
      />
      <path
        d="M282.65 111.08C282.65 111.08 246.65 88.6799 179.45 87.8799C144.65 89.8799 127.05 87.4799 76.25 111.08L97.45 174.28C97.45 174.28 121.33 169.73 160.24 168.64C160.1 168.23 160 167.79 160 167.33V160.73C160 158.49 161.81 156.68 164.05 156.68H194.85C197.09 156.68 198.9 158.49 198.9 160.73V167.33C198.9 167.85 198.79 168.35 198.61 168.81C217.71 169.49 238.85 171.14 261.45 174.28C271.05 143.88 282.65 111.08 282.65 111.08Z"
        fill={isSelected ? "#F41A28" :isAvailable ? "#1D8F43" : "#D3D3D4"}
      />
    </svg>
  );
};

export default Windshield;
