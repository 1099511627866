import { Link } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import { useEffect, useState } from "react";
import { getInsurers, saveInsurer, deleteInsurer, updateInsurer } from "../../../../services/Insurer";
import { toast } from "react-toastify";
import { alertOptions, removeAccents } from "../../../../utils/Utilities";
import Loader from "../../../../components/loader/Loader";
import Filters from "./Filters";
import TableInsurers from "./TableInsurers";
import FormInsurer from "./FormInsurer";
import Modal from "../../../../components/modal/Modal";

const Insurers = () => {
  const [loader, setLoader] = useState(true);
  const [nameOptions, setNameOptions] = useState([]);
  const [showForm, setShowForm] = useState({ status: false, data: null });
  const [showModal, setShowModal] = useState({ status: false, data: "" });
  const [insurers, setInsurers] = useState([]);
  const [insurersUniv, setInsurersUniv] = useState([]);

  const handleFilters = (value, option) => {
    if (option === "filters") {
      let palabra = new RegExp(`${removeAccents(value)}.*`, "i");
      const insurensFound = insurersUniv.filter(element => (
        palabra.test(removeAccents(element.name)) ||
                palabra.test(element.nagPrice) ||
                palabra.test(element.hour) ||
                palabra.test(element.kit) ||
                palabra.test(element.dynamicCalibration) ||
                palabra.test(element.staticCalibration) ||
                palabra.test(element.dualCalibration)
      ));
      setInsurers(insurensFound);
    } else {
      let arrFound = insurersUniv;
      if (value.name === "") {
        setInsurers(insurersUniv);
      } else {
        for (const key in value) {
          if (Object.hasOwnProperty.call(value, key) && value[key] !== "") {
            arrFound = arrFound.filter(element => element.name === value[key]);
          }
        }
        setInsurers(arrFound);
      }
    }
  };

  const getOptions = (array_insurers) => {
    let names = [];
    for (let i = 0; i < array_insurers.length; i++) {
      const nameFound = names.find(element => element.value === array_insurers[i].name);

      if (!nameFound) {
        names.push({ label: array_insurers[i].name, value: array_insurers[i].name });
      }
    }
    setNameOptions(names);
  };

  const getData = () => {
    getInsurers().then(res => {
      if (res.status === 200) {
        const insurersCurrent = res.data.filter(element => !element.deleted);

        // Sort by name
        const array_sort = insurersCurrent.sort(function(a, b) {
          if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
          if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
          return 0;
        });

        getOptions(array_sort);
        setInsurers(array_sort);
        setInsurersUniv(array_sort);
      }
      setLoader(false);
    });
  };

  const addInsurer = (insurerModel) => {
    setLoader(true);
    saveInsurer(insurerModel).then(res => {
      if (res.status === 200) {
        getData();
        setShowForm({...showForm, status: false, data: null});
        toast.success("Insurence added successfully", alertOptions);
      } else {
        toast.warning(res.response.data.message, alertOptions);
      }
      setLoader(false);
    }).catch(error => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  const editInsurer = (id, insurerModel) => {
    setLoader(true);
    updateInsurer(id, insurerModel).then((res) => {
      if (res.status === 200) {
        getData();
        setShowForm({...showForm, status: false, data: null});
        toast.success("Insurence updated successfully", alertOptions);
      } else {
        toast.warning(res.response.data.message, alertOptions);
      }
      setLoader(false);
    }).catch(error => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  const removeInsurer = (sts) => {
    setLoader(true);
    if (sts) {
      deleteInsurer(showModal.data).then((res) => {
        if (res.status === 200) {
          getData();
          toast.success("Insurence deleted successfully", alertOptions);
        } else {
          toast.warning(res.response.data.message, alertOptions);
        }
      }).catch(error => {
        toast.warning(error.response.data.message, alertOptions);
      });
    }
    setShowModal({...showModal, status: false, element: null});
    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    getData();
  }, []);

  return (
    <>
      { loader && <Loader /> }

      <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
        <div className="grid grid-cols-12 px-0 md:px-5">
          <div>
            <Link to="/quotes">
              <button className="p-1 rounded-md" type="button">
                <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
              </button>
            </Link>
          </div>
          <div className="flex justify-center items-center col-span-11 text-center">
            <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
              {/* Insurers */}
                            Insurance rate %
            </h1>
          </div>
        </div>
        <div className="pt-3">
          <Filters
            nameOptions={nameOptions}
            onShowForm={() => setShowForm({...showForm, status: true, data: null})}
            onFilters={handleFilters}
          />
        </div>
        <div className="pt-5">
          <TableInsurers
            insurers={insurers}
            onEdit={(insurerModel) => setShowForm({...showForm, status: true, data: insurerModel})}
            onDelete={(idInsurer) => setShowModal({...showModal, status: true, data: idInsurer})}
          />
        </div>
      </div>

      {
        showForm.status ?
          <FormInsurer
            insurerInfo={showForm.data}
            onSave={addInsurer}
            onEdit={editInsurer}
            onClose={() => setShowForm({...showForm, status: false, data: null })}
          />
          : null
      }

      {
        showModal.status ?
          <Modal
            title="¿Are you sure you want to delete the insurence?"
            onResponse={removeInsurer}
            onClose={() => setShowModal({...showModal, status: false, data: ""})}
          />
          : null
      }
    </>
  );
};

export default Insurers;