import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { validateLogin } from "../../../utils/Validators";
import { getWholesalerUsers } from "../../../services/Wholesaler";
import { login } from "../../../services/Auth";
import { PermitsContext } from "../../../services/Permits";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import LogoMAY from "../../../assets/images/logo_a.png";
import Loader from "../../../components/loader/Loader";

const Login = () => {
  const navigate = useNavigate();
  const { dataSession, setDataSession } = useContext(PermitsContext);
  const [loader, setLoader] = useState(false);
  const [renderLogin, setRenderLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [user, setUser] = useState({
    email: "",
    password: ""
  });

  const handleChange = (evt) => {
    setUser({...user, [evt.currentTarget.name]: evt.currentTarget.value});
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (user.email && user.password) {
      const result = validateLogin(user);
      if (result.status) {
        setLoader(true);
        login(user).then(res => {
          if (res.status === 200) {
            localStorage.setItem("user-data", JSON.stringify({ token: res.data.token }));

            if (res.data.userType === "WHOLESALER_USER") {
              // CHECAR CON BACK PARA QUE EL ENDPOINT "login" CONTENGA EL DETALLE DEL USUARIO
              getWholesalerUsers().then(resUser => {
                if (resUser.status === 200 && resUser.data.length > 0) {
                  const userFound = resUser.data.find(adminElement => adminElement.user.id === res.data.id);
                  if (userFound && userFound.approve) {
                    // Se asigna el token en el localStorage
                    localStorage.setItem("type-data", "wholesaler");
                    localStorage.setItem("user-data", JSON.stringify({
                      token: res.data.token,
                      id: res.data.id,
                      idAdmin: userFound._id,
                      names: userFound.names,
                      lastNames: userFound.lastNames,
                      zipCode: userFound.zipCode,
                      email: userFound.user.email,
                      userType: "WHOLESALER_USER"
                    }));

                    // Se almacena la información del usuario en una variable global
                    setDataSession({...dataSession,
                      _id: userFound._id,
                      _idUser: userFound.user.id,
                      token: res.data.token,
                      names: userFound.names,
                      lastNames: userFound.lastNames,
                      zipCode: userFound.zipCode,
                      email: userFound.user.email,
                      userType: "WHOLESALER_USER"
                    });

                    // Se reedirige al Home
                    setLoader(false);
                    navigate("/wholesaler/home");
                  } else if (userFound && !userFound.approve) {
                    setLoader(false);
                    toast.warning("The entered user is not approved by an administrator", alertOptions);
                  }
                }
              });
            } else {
              setLoader(false);
              toast.warning("The account entered is not of type wholesaler", alertOptions);
            }
          } else if (res.response.status === 400) {
            setLoader(false);
            toast.warning("The user is not registered", alertOptions);
          } else if (res.response.status === 401) {
            setLoader(false);
            toast.warning("The credentials entered are incorrect", alertOptions);
          } else {
            setLoader(false);
            toast.warning("An error occurred while trying to log in", alertOptions);
          }
        });
      } else {
        toast.warning(result.msg, alertOptions);
      }
    } else {
      toast.info("Please fill in all the fields of the form", alertOptions);
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem("user-data") && window.localStorage.getItem("user-data") !== null && JSON.parse(window.localStorage.getItem("user-data")).userType === "WHOLESALER_USER") {
      navigate("/wholesaler/home");
    } else {
      setRenderLogin(true);
    }
  }, []);

  return (
    <>
      { loader ? <Loader /> : null }

      {
        renderLogin ?
          <div className="min-h-screen bg-black flex justify-center items-center">
            <div className="flex flex-col md:grid md:gap-5 lg:gap-20 md:grid-cols-2 w-[80%] md:w-[800px] lg:w-[1100px] space-y-10 md:space-y-0">
              <div className="flex justify-center items-center w-full">
                <img className="h-auto w-auto" src={LogoMAY} />
              </div>
              <div className="flex justify-center p-0 md:p-7 lg:p-10">
                <div className="w-full bg-white rounded-xl p-5 w-full space-y-5">
                  <div className="w-full text-center">
                    <h1 className="text-2xl text-ag-secondary font-bold">Login</h1>
                  </div>
                  <div className="w-full">
                    <form onSubmit={handleSubmit} className="space-y-5">
                      <div className="space-y-2">
                        <div>
                          <input
                            className="w-full text-center border border-gray-500 p-2 rounded-xl"
                            type="text"
                            name="email"
                            placeholder="Email"
                            onChange={handleChange}
                            value={user.email}
                          />
                        </div>
                        <div className="flex justify-center items-center border border-gray-500 rounded-xl">
                          <input
                            className="w-full text-center pl-10 py-2 rounded-xl"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                            onChange={handleChange}
                            value={user.password}
                          />
                          { showPassword ?
                            <button
                              className="border-none p-2 rounded-xl disabled:bg-zinc-200"
                              type="button"
                              onClick={() => setShowPassword(false)}
                            >
                              <span className="text-ag-secondary-letter text-base lg:text-xl"><BsFillEyeSlashFill /></span>
                            </button>
                            :
                            <button
                              className="border-none p-2 rounded-xl disabled:bg-zinc-200"
                              type="button"
                              onClick={() => setShowPassword(true)}
                            >
                              <span className="text-ag-secondary-letter text-base lg:text-xl"><BsFillEyeFill /></span>
                            </button>
                          }
                        </div>
                      </div>
                      <div className="text-center underline">
                        <Link to="/recoverPassword">
                          <p className="text-xs text-ag-secondary-letter">Recover Password</p>
                        </Link>
                      </div>
                      <div>
                        <button className="w-full bg-ag-primary-light text-white p-2 rounded-xl" type="submit">Enter</button>
                      </div>
                      <div className="text-center">
                        <p className="text-xs text-ag-secondary-letter">
                                                    You do not have an account?
                          {' '}
                          <Link to="/wholesaler/register">
                            <span className="underline">Sign up</span>
                          </Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null
      }
    </>
  );
};

export default Login;