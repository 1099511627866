import React from 'react'

const RearVentLeft = ({ isSelected, isAvailable, onClick }) => {
  return (
    <svg className="cursor-pointer outline-none" onClick={onClick} data-tooltip-id="car" data-tooltip-content="Rear Vent (left)">
      <path d="M87.26 338.42V328.02L54.46 337.35V365.35L87.26 338.42Z" fill={isSelected ? "#F41A28" :isAvailable ? "#1D8F43" : "#D3D3D4"}/>
    </svg>
  )
}

export default RearVentLeft