import React from 'react'
import { useEffect, useState } from "react";
import Pagination from "../../../components/pagination/Pagination";
import RowsQuotes from "./RowsQuotes";
import RowsQuotesPWRWT from "./RowsQuotePWRWT";

const TableQuotesPWRWT = ({ enableSearch, enableCancel, quotes, currntPg, pagesData, onPage, onImage, onDetail, onCancel, onCompatible, onFeatures, onAddProduct }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(20);

    useEffect(() => {
        setCurrentPage(currntPg);
    }, [currntPg]);

    // useEffect(() => {
    //     setCurrentPage(1);
    // }, [quotes]);
    return (
        <div>
            <div className="overflow-auto min-h-[40vh] lg:min-h-[46vh] 3xl:min-h-[57vh]">
                <table className="w-full text-center text-ag-secondary-letter">
                    <thead>
                        <tr className="bg-ag-secondary-table">
                            <th className="w-[25%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-28 lg:px-2 py-4">Products</th>
                            <th className="w-[5%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-10 lg:px-2 py-4">Year chosen by client</th>
                            <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-10 lg:px-2 py-4">Costumer</th>
                            <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-5 lg:px-2 py-4">Payment Method</th>
                            <th className="w-[9%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-10 lg:px-2 py-4">Type</th>
                            <th className="w-[9%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-10 lg:px-2 py-4">Vendor</th>
                            <th className="w-[9%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-10 lg:px-2 py-4">Status</th>
                            <th className="w-[9%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-10 lg:px-2 py-4">Price</th>
                            <th className="w-[9%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-10 lg:px-2 py-4">Date</th>
                            <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-10 lg:px-2 py-4">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            quotes.map((element, i) => {
                                return (
                                    element?.type === "photos" || element?.type === "specifications" ?
                                        // <RowsQuotes
                                        //     key={i}
                                        //     enableSearch={enableSearch}
                                        //     enableCancel={enableCancel}
                                        //     quote={element}
                                        //     onImage={onImage}
                                        //     onDetail={onDetail}
                                        //     onCancel={onCancel}
                                        //     onAddProduct={onAddProduct}
                                        //     onCompatible={onCompatible}
                                        //     onFeatures={onFeatures}
                                        // />
                                        <></>
                                        :
                                        <RowsQuotesPWRWT
                                            key={i}
                                            enableSearch={enableSearch}
                                            enableCancel={enableCancel}
                                            quote={element}
                                            onImage={onImage}
                                            onDetail={onDetail}
                                            onCancel={onCancel}
                                            onAddProduct={onAddProduct}
                                        />
                                        
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="p-5 m-auto">
                <Pagination
                    currentPage={currentPage}
                    totalCount={pagesData.records}
                    pageSize={recordsPerPage}
                    onPageChange={page => onPage(page)}
                />
            </div>
        </div>
    )
}

export default TableQuotesPWRWT