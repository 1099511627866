import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getOrder } from "../../../../services/Orders";
import { FiChevronLeft } from "react-icons/fi";
import { alertOptions, formatCurrency } from "../../../../utils/Utilities";
import { saveReturn } from "../../../../services/Returns";
import { toast } from "react-toastify";
import { searchProductEspecification } from "../../../../services/Product";
import { PermitsContext } from "../../../../services/Permits";
import Loader from "../../../../components/loader/Loader";
import HeaderDetails from "./HeaderDetails";
import TableDetails from "./TableDetails";
import ModalInput from "../../../../components/modal/ModalInput";
import ModalCompatible from "../../products/ModalCompatible";
import MyCartFeatures from "../../../wholesaler/mycart/MyCartFeatures";

const DetailsOrders = () => {
  const params = useParams();
  const { dataSession } = useContext(PermitsContext);
  const [loader, setLoader] = useState(true);
  const [showReason, setShowReason] = useState({ status: false, data: "" });
  const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
  const [showFeatures, setShowFeatures] = useState({ status: false, element: null });
  const [order, setOrder] = useState({
    _id: "",
    client: "",
    date: "",
    employee: "",
    orderType: "",
    payType: "",
    productsOrder: [],
    status: "",
    subTotal: "",
    total: "",
    warehouse: "",
    ticketClover: "",
    po: ""
  });

  const getData = async () => {
    await getOrder(params.id).then(async res => {
      if (res.status === 200) {
        let newProductsOrders = [];
        if (res.data?.quote) {
          let productsRes =  await searchProductEspecification({ nag: res.data.quote.selectProduct.nag });
          newProductsOrders.push({...res.data.quote,
            compatible_vehicles: productsRes.status === 200 ? productsRes.data : []
          });
        } else if (res.data?.anotherQuote) {
          newProductsOrders.push({...res.data.anotherQuote, compatible_vehicles: []});
        } else {
          for (let i = 0; i < res.data.productsOrder.length; i++) {
            let productsRes =  await searchProductEspecification({ nag: res.data.productsOrder[i].productInventory?.product?.nags, excludePriceZero: false });
            newProductsOrders.push({...res.data.productsOrder[i],
              features: res.data.productsOrder[i].productInventory.product.features,
              compatible_vehicles: productsRes.status === 200 ? productsRes.data : []
            });
          }
        }

        setOrder({...order,
          _id: res.data?._id,
          client: res.data.wholesalerUser ? res.data.wholesalerUser : res.data.retailUser,
          date: res.data.createdAt,
          employee: res.data.employee,
          orderType: res.data.orderType,
          payType: res.data?.payment ? res.data?.payment : res.data?.payType,
          productsOrder: newProductsOrders,
          status: res.data.status,
          subTotal: res.data.subTotal,
          total: res.data.total,
          warehouse: res.data?.warehouse,
          ticketClover: res.data?.orderIdClover ? res.data?.orderIdClover : "",
          po: res.data?.purchase?.po ? res.data?.purchase?.po : "- - - - -"
        });
      }
      setLoader(false);
    });
  };

  const handleReturn = (item, reasonTxt) => {
    setShowReason({...showReason, status: false, data: ""});
    const returnModel = {
      order: { _id: order._id },
      warehouse: { _id: order.warehouse?._id },
      productOrder: { _id: item?._id },
      requestedBy: { _id: dataSession._id },
      wholesaler: { _id: order.client?._id },
      quantity: item?.quantity ? item?.quantity : 1,
      price: item?.price ? item?.price : item?.selectProduct?.price,
      total: item?.price ? Number(item?.price) * Number(item?.quantity) : item?.selectProduct?.total,
      reason: reasonTxt,
      photos: []
    };

    // if (order?.client?.userType === "WHOLESALER_USER") {
    //     returnModel.wholesaler = { _id: order.client?._id };
    // } else {
    //     returnModel.retail = { _id: order.client?._id };
    // }

    setLoader(true);
    saveReturn(returnModel).then(res => {
      if (res.status === 200) {
        toast.success("Order returned successfully", alertOptions);
        getData();
      } else {
        toast.warning(res.response.data.message ? res.response.data.message : "There was a problem delivering the order", alertOptions);
      }
      setLoader(false);
    }).catch((error) => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  useEffect(() => {
    if (params && params.id) {
      getData();
    }
  }, []);

  return (
    <>
      { loader && <Loader /> }

      <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
        <div className="grid grid-cols-12 px-0 md:px-5">
          <div>
            <Link to="/orders">
              <button className="p-1 rounded-md" type="button">
                <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
              </button>
            </Link>
          </div>
          <div className="flex justify-center items-center col-span-11 text-center">
            <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">Orders details</h1>
          </div>
        </div>
        <div className="pt-3">
          <HeaderDetails type="detail" orderData={order} />
        </div>
        <div className="text-center pt-3">
          <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Line items</h1>
        </div>
        <div className="overflow-x-auto">
          <TableDetails
            items={order.productsOrder}
            status={order.status}
            typeClient={order.client?.user?.userType}
            order={order}
            onReturn={(itemReq) => setShowReason({...showReason, status: true, data: itemReq})}
            onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
            onFeatures={(ftSel) => setShowFeatures({...showFeatures, status: true, element: ftSel})}
          />
        </div>
        {
          (order?.orderType !== "POWER_WINDOW_REPAIR" && order?.orderType !== "WINDOW_TINT") && order.productsOrder.length > 0 && order.productsOrder[0].retailUser ?
            <div className="md:flex md:justify-between md:items-start py-2 space-y-5 md:space-y-0">
              <div className="w-full md:w-[48%] xl:w-[40%] pt-2 space-y-1">
                <div className="bg-ag-secondary-table border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-8 py-4">
                  <span className="text-xs text-ag-secondary-letter">
                                        Extra services:
                  </span>
                </div>
                <div className="pt-3 px-2">
                  {
                    order.productsOrder.length > 0 && order.productsOrder[0]?.ceramicFull90 ?
                      <p className="text-xs text-ag-secondary-letter">
                                                Ceramic Full Windshield Tint 90% Heat Rejection $250
                      </p>
                      : null
                  }
                  {
                    order.productsOrder.length > 0 && order.productsOrder[0]?.ceramicFull70 ?
                      <p className="text-xs text-ag-secondary-letter">
                                                Ceramic Full Windshield Tint 70% Heat Rejection $200
                      </p>
                      : null
                  }
                  {
                    order.productsOrder.length > 0 && order.productsOrder[0]?.waterTreatment ?
                      <p className="text-xs text-ag-secondary-letter">
                                                Windshield Water Treatment $50
                      </p>
                      : null
                  }
                  {
                    order.productsOrder.length > 0 && order.productsOrder[0]?.windshieldVisorStrip ?
                      <p className="text-xs text-ag-secondary-letter">
                                                Windshield Visor Strip $50
                      </p>
                      : null
                  }
                  {
                    Number(
                      Number(order.productsOrder.length > 0 && order.productsOrder[0]?.ceramicFull90 ? 250 : 0) +
                                            Number(order.productsOrder.length > 0 && order.productsOrder[0]?.ceramicFull70 ? 200 : 0) +
                                            Number(order.productsOrder.length > 0 && order.productsOrder[0]?.waterTreatment ? 50 : 0) +
                                            Number(order.productsOrder.length > 0 && order.productsOrder[0]?.windshieldVisorStrip ? 50 : 0)
                    ) < Number(order.productsOrder.length > 0 && order.productsOrder[0]?.selectProduct?.additionalServices) ?
                      <p className="text-xs text-ag-secondary-letter">
                                                Calibration $200
                      </p>
                      : null
                  }
                </div>
              </div>
              <div className="w-full md:w-[48%] xl:w-[40%] space-y-1">
                <div className="flex justify-center items-center space-x-3 px-4 py-1 border border-ag-secondary rounded-full">
                  <span className="text-[12px] font-semibold">Subtotal:</span>
                  <span className="text-[12px]">
                    { formatCurrency(
                      (order.productsOrder.length > 0 && order.productsOrder[0]?.selectProduct?.price) +
                                            (order.productsOrder.length > 0 && order.productsOrder[0]?.selectProduct?.additionalServices)
                    )}
                  </span>
                </div>
                <div className="flex justify-center items-center space-x-3 px-4 py-1 border border-ag-secondary rounded-full">
                  <span className="text-[12px] font-semibold">Tax:</span>
                  <span className="text-[12px]">
                    { formatCurrency(order.productsOrder.length > 0 && order.productsOrder[0]?.selectProduct?.tax) }
                  </span>
                </div>
                <div className="flex justify-center items-center space-x-3 px-4 py-1 border border-ag-secondary rounded-full">
                  <span className="text-[12px] font-semibold">Total:</span>
                  <span className="text-[12px]">
                    { formatCurrency(
                      (order.productsOrder.length > 0 && order.productsOrder[0]?.selectProduct?.price) +
                                            (order.productsOrder.length > 0 && order.productsOrder[0]?.selectProduct?.additionalServices) +
                                            (order.productsOrder.length > 0 && order.productsOrder[0]?.selectProduct?.tax)
                    )}
                  </span>
                </div>
              </div>
            </div>
            : null
        }
      </div>

      {
        showReason.status ?
          <ModalInput
            title="Reason:"
            forDelivery={false}
            type="text"
            onResponse={(txtReason) => handleReturn(showReason.data, txtReason)}
            onClose={() => setShowReason({...showReason, status: false, data: ""})}
            onBack={() => setShowReason({...showReason, status: false, data: ""})}
          />
          : null
      }

      {
        showCompatible.status ?
          <ModalCompatible
            products={showCompatible.element}
            onClose={() => setShowCompatible({...showCompatible, status: false, element: null})}
          />
          : null
      }

      {
        showFeatures.status ?
          <MyCartFeatures
            features={showFeatures.element}
            onClose={() => setShowFeatures({...showFeatures, status: false, element: null})}
          />
          : null
      }
    </>
  );
};

export default DetailsOrders;