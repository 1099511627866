import { useContext, useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import { canceledInventoryTransfer, getInventoryTransfer } from "../../../../services/Transfer";
import { alertOptions, removeAccents } from "../../../../utils/Utilities";
import { PermitsContext } from "../../../../services/Permits";
import { toast } from "react-toastify";
import Filters from "./Filters";
import TableTransfer from "./TableTransfer";
import Loader from "../../../../components/loader/Loader";
import Modal from "../../../../components/modal/Modal";
import { getWarehouses } from "../../../../services/Warehouse";

const Transfer = () => {
  const [loader, setLoader] = useState(true);
  const { dataSession } = useContext(PermitsContext);
  const [showModal, setShowModal] = useState({ status: false, element: "" });
  const [transfer, setTransfer] = useState([]);
  const [transferUniv, setTransferUniv] = useState([]);
  const [warehouseOptions, setWarehousesOptions] = useState([]);

  const handleFilters = (filters) => {
    let transferFound = transferUniv;

    if (filters.search !== "") {
      let keywords = removeAccents(filters.search).split(/\s+/).filter(Boolean);

      transferFound = transferFound.filter(element => {
        let searchableText = [
          `T-${String(element._id).substring(String(element._id).length - 5).toLocaleUpperCase()}`,
          element.status,
          element.totalQuantity,
          element.subTotal,
          element?.lastUpdateBy?.names,
          element?.cratedBy?.names,
          element?.cratedBy?.lastNames,
          element?.fromWarehouse?.name,
          element?.toWarehouse?.name
        ].map(removeAccents).join(" ");

        return keywords.every(keyword => new RegExp(`${keyword}.*`, "i").test(searchableText));
      });
    }

    if (filters.transfer.value !== 'all') {
      transferFound = transferFound.filter(element => (`${element.status}` === filters.transfer.value));
    }

    if (filters.warehouse.length) {
      const warehouseIds = filters.warehouse.map(warehouse => warehouse.value);

      transferFound = transferFound.filter(element => warehouseIds.includes(element.fromWarehouse._id));
    }

    if (filters.createdAt !== '') {
      const date = new Date(filters.createdAt).toISOString().substring(0, 10);
      transferFound = transferFound.filter(element => element.createdAt.substring(0, 10) === date);
    }

    setTransfer(transferFound);
  };

  const canceledTransfer = () => {
    setLoader(true);
    const newModel = showModal.element;
    const transferModel = { _id: newModel, updateBy: { _id: dataSession._id }};
    setShowModal({...showModal, status: false, element: ""});
    canceledInventoryTransfer(newModel, transferModel).then(res => {
      if (res.status === 200) {
        getInventoryTransfer().then(resGet => {
          if (resGet.status === 200 && resGet.data.length > 0) {
            let transferWH = [];
            if (dataSession.userType === "ADMIN") {
              transferWH = resGet.data.filter(element => (!element?.fromWarehouse.deleted && !element?.toWarehouse.deleted));
            } else {
              transferWH = resGet.data.filter(element =>
                (
                  (element?.fromWarehouse?._id === dataSession.warehouse ||
                                    (element.status !== "draft" && element?.toWarehouse?._id === dataSession.warehouse)) &&
                                    (!element?.fromWarehouse.deleted && !element?.toWarehouse.deleted)
                )
              );
            }

            const transferSort = transferWH.sort(function(a,b){
              return new Date(b.createdAt) - new Date(a.createdAt);
            });

            setTransfer(transferSort);
            setTransferUniv(transferSort);
            setLoader(false);
          }

          toast.success("Transfer canceled successfully", alertOptions);
        });
      } else {
        setLoader(false);
        toast.warning(res.response.data.message, alertOptions);
      }
    }).catch(error => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  const getOptions = (array_warehouses) => {
    let warehouses = [];
    for (let i = 0; i < array_warehouses.length; i++) {
      const warehousesFound = warehouses.find(element => element.value === array_warehouses[i]._id);

      if (!warehousesFound) {
        warehouses.push({ label: array_warehouses[i].name, value: array_warehouses[i]._id, info: array_warehouses[i] });
      }
    }
    setWarehousesOptions(warehouses);
  };

  useEffect(() => {
    setLoader(true);
    getInventoryTransfer().then(res => {
      if (res.status === 200 && res.data.length > 0) {
        let transferWH = [];
        if (dataSession.userType === "ADMIN") {
          transferWH = res.data.filter(element => (!element?.fromWarehouse.deleted && !element?.toWarehouse.deleted)).map(elemWH => ({...elemWH, canEdit: true}));
        } else {
          transferWH = res.data.filter(element =>
            (
              ( dataSession.allWarehouse.some(item => item._id === element?.fromWarehouse?._id) || (element?.status !== "draft" && dataSession.allWarehouse.some(item => item._id === element?.toWarehouse?._id))) &&
                            // (element?.fromWarehouse?._id === dataSession.warehouse || (element?.status !== "draft" && element?.toWarehouse?._id === dataSession.warehouse)) &&
                            (!element?.fromWarehouse.deleted && !element?.toWarehouse.deleted)
            )
          ).map(elemWH => {
            const warehouseFound = dataSession.allWarehouse.filter(elemDWH =>
              (elemDWH?._id === elemWH?.toWarehouse?._id && elemWH?.status === "inprocess")
            );
            if (warehouseFound.length > 0) return {...elemWH, canEdit: true};
            return {...elemWH, canEdit: false };
          });
        }
        const transferSort = transferWH.sort(function(a,b){
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setTransfer(transferSort);
        setTransferUniv(transferSort);
      }
      setLoader(false);
    });

    getWarehouses().then(res => {
      if (res.status === 200 && res.data.length > 0) {
        if (dataSession.userType === "ADMIN") {
          const warehousesCurrent = res.data.filter(element => !element.deleted);
          getOptions(warehousesCurrent);
        } else {
          const warehousesCurrent = res.data.filter(element => {
            const foundWH = dataSession.allWarehouse.find(elem => element?._id === elem._id);
            if (!element?.deleted && foundWH) return element;
          });
          getOptions(warehousesCurrent);
        }
        setLoader(false);
      } else {
        getOptions([]);
        setLoader(false);
      }
    });
  }, [dataSession]);

  return (
    <div>
      { loader && <Loader /> }

      <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
        <div className="grid grid-cols-12 px-0 md:px-5">
          <div>
            <Link to="/inventory">
              <button className="p-1 rounded-md" type="button">
                <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
              </button>
            </Link>
          </div>

          <div className="flex justify-center items-center col-span-11 text-center">
            <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
              Transfers
            </h1>
          </div>
        </div>

        <div>
          <Filters
            onClose={() => {}}
            onFilters={handleFilters}
            warehouseOptions={warehouseOptions}
          />
        </div>

        <div>
          <TableTransfer
            transfers={transfer}
            onCanceled={(idTransfer) => setShowModal({...showModal, status: true, element: idTransfer})}
          />
        </div>
      </div>

      { showModal.status ?
        <Modal
          title={`¿Are you sure you want to cancel the transfer?`}
          onClose={() => {setShowModal({...showModal, status: false, element: ""}); }}
          onResponse={canceledTransfer}
        />
        :
        null
      }
    </div>
  );
};

export default Transfer;