import { IoMdImage } from "react-icons/io";
import { alertOptions, formatAMPM, formatCurrency, formatDateEng, parseTextTypeGlass } from "../../../../utils/Utilities";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

const arrStatus = {
    ATTEND: { text: "Finished" },
    CANCELED: { text: "Canceled" },
    INPROCESS: { text: "In process" }
}

const arrType = {
    REPLACEMENT: { text: "Replacement" },
    REPAIR: { text: "Repair" },
}

const arrShipping = {
    HOMESERVICE: { text: "Home service" },
    WAREHOUSESERVICE: { text: "Warehouse service" }
}

const paymentQuote = {
    PAY_ON_MY_WON: "Pay on my own",
    CAR_INSURANCE: "Pay with Insurance"
}

const typeOpts = {
    "specifications": "Order",
    "photos": "By Photo",
    "POWER_WINDOW_REPAIR": "Power Window Repair",
    "WINDOW_TINT": "Window Tint"
};

const RowsAppointments = ({ appointment, onImage, onAttend, onCancel }) => {
    const [addressStr, setAddressStr] = useState("");

    const handleTicket = () => {
        if (appointment?.orderIdClover && appointment?.orderIdClover !== "") {
            window.open(`https://sandbox.dev.clover.com/r/${appointment?.orderIdClover}`, "_blank", "noreferrer");
        } else {
            toast.warning("There is no ticket to show", alertOptions);
        }
    }

    useEffect(() => {
        if (appointment !== null) {
            if (appointment?.installationType === "HOMESERVICE") {
                setAddressStr(`${appointment?.payment?.userAddress?.street}, ${appointment?.payment?.userAddress?.suburb} ${appointment?.payment?.userAddress?.city} ${appointment?.payment?.userAddress?.postalCode}`);
            } else {
                setAddressStr(`${appointment?.payment?.warehouseAddress?.street}, ${appointment?.payment?.warehouseAddress?.locality} ${appointment?.payment?.warehouseAddress?.postalCode}`);
                // setAddressStr(`${appointment?.payment?.warehouseAddress?.street}, ${appointment?.payment?.warehouseAddress?.suburb} ${appointment?.payment?.warehouseAddress?.locality} ${appointment?.payment?.warehouseAddress?.postalCode}`);
            }
        }
    }, [appointment]);

    return (
        <tr>
            <td className="w-[21%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <div className="flex space-x-3">
                    <div className="flex justify-center items-center">
                        <button type="button" onClick={() => onImage(appointment?.quote?.photos)}>
                            <span className="text-3xl">
                                {
                                    appointment?.quote?.photos[0] ?
                                        <div className="w-[35px] h-[35px] flex justify-center items-center border border-ag-secondary-light rounded-lg p-1">
                                            <img className="w-full h-full object-contain" src={appointment?.quote?.photos[0]} />
                                        </div>
                                    :
                                        <IoMdImage />
                                }
                            </span>
                        </button>
                    </div>
                    {
                        appointment?.quote?.selectProduct ?
                            <div className="space-y-3">
                                <div className="text-left">
                                    <div>
                                        <p className="text-ag-secondary-letter text-xs truncate w-60">
                                            { appointment?.quote?.brand }{' '}
                                            { appointment?.quote?.model }
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-ag-secondary-letter text-xs truncate w-60">
                                            {'('}{ appointment?.quote?.year }{') '}
                                            { appointment?.quote?.vin }{' '}
                                            { parseTextTypeGlass(appointment?.quote?.typeGlass) }
                                            { appointment?.quote?.typeService ? ` (${arrType[appointment?.quote?.typeService].text})` : null }
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center space-x-3">
                                        <span className="text-[10px] font-semibold text-ag-secondary-letter">
                                            Shipping method:
                                        </span>
                                        <span className="text-[10px] text-ag-secondary-letter">
                                            { arrShipping[appointment?.installationType].text }
                                        </span>
                                    </div>
                                    <div className="text-left">
                                        <p className="w-60 truncate text-[10px] text-ag-secondary-letter" title={addressStr}>
                                            { addressStr }
                                        </p>
                                    </div>
                                </div>
                                <div className="w-full space-y-1 text-left">
                                    <div className="">
                                        <span className="text-[10px] font-semibold text-ag-secondary-letter">
                                            Extra services:
                                        </span>
                                    </div>
                                    <div>
                                        {
                                            appointment?.quote?.ceramicFull90 ?
                                                <p className="text-[10px] text-ag-secondary-letter">
                                                    Ceramic Full Windshield Tint 90% Heat Rejection
                                                </p>
                                            : null
                                        } 
                                        {
                                            appointment?.quote?.ceramicFull70 ? 
                                                <p className="text-[10px] text-ag-secondary-letter">
                                                    Ceramic Full Windshield Tint 70% Heat Rejection
                                                </p>
                                            : null
                                        }
                                        {
                                            appointment?.quote?.waterTreatment ?
                                                <p className="text-[10px] text-ag-secondary-letter">
                                                    Windshield Water Treatment
                                                </p>
                                            : null
                                        }
                                        {
                                            appointment?.quote?.windshieldVisorStrip ?
                                                <p className="text-[10px] text-ag-secondary-letter">
                                                    Windshield Visor Strip
                                                </p>
                                            : null
                                        }
                                        {
                                            Number(
                                                Number(appointment?.quote?.ceramicFull90 ? 250 : 0) + 
                                                Number(appointment?.quote?.ceramicFull70 ? 200 : 0) + 
                                                Number(appointment?.quote?.waterTreatment ? 50 : 0) + 
                                                Number(appointment?.quote?.windshieldVisorStrip ? 50 : 0)
                                            ) < Number(appointment?.quote?.selectProduct?.additionalServices) ?
                                                <p className="text-[10px] text-ag-secondary-letter">
                                                    Calibration
                                                </p>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                        :
                        <div className="space-y-1">
                            <div className="text-left">
                                {
                                    appointment?.quote?.searchType === "photos" ?
                                        <div className="text-ag-secondary-letter text-xs">
                                            <span>Quote by photo...</span>
                                        </div>
                                    :
                                        <>
                                            <div>
                                                <p className="text-ag-secondary-letter text-xs truncate w-60">
                                                    { appointment?.quote ? appointment?.quote?.brand : appointment?.anotherQuote?.brand }{' '}
                                                    { appointment?.quote ? appointment?.quote?.model : appointment?.anotherQuote?.model }
                                                </p>
                                            </div>
                                            <div>
                                                <p className="text-ag-secondary-letter text-xs truncate w-60">
                                                    {'('}{ appointment?.quote ? appointment?.quote?.year : appointment?.anotherQuote?.year }{') '}
                                                    { appointment?.quote ? appointment?.quote?.vin : appointment?.anotherQuote?.vin }{' '}
                                                    { appointment?.quote ? parseTextTypeGlass(appointment?.quote?.typeGlass) : parseTextTypeGlass(appointment?.anotherQuote?.typeGlass) }
                                                    { appointment?.quote?.typeService ? ` (${arrType[appointment?.quote?.typeService].text})` : null }
                                                </p>
                                            </div>
                                            {
                                                appointment?.anotherQuote ?
                                                    <div className="pt-3">
                                                        <div className="flex justify-start items-center space-x-3">
                                                            <span className="text-[10px] font-semibold text-ag-secondary-letter">
                                                                Shipping method:
                                                            </span>
                                                            <span className="text-[10px] text-ag-secondary-letter">
                                                                { arrShipping[appointment?.installationType].text }
                                                            </span>
                                                        </div>
                                                        <div className="text-left">
                                                            <p className="w-60 truncate text-[10px] text-ag-secondary-letter" title={addressStr}>
                                                                { addressStr }
                                                            </p>
                                                        </div>
                                                    </div>
                                                : null
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    }
                </div>
            </td>
            <td className="w-[11%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-28 m-auto">{ appointment?.retailUser ? `${ appointment?.retailUser?.names } ${appointment?.retailUser?.lastNames ? appointment?.retailUser?.lastNames : ""}` : 'External user' }</p>
            </td>
            <td className="w-[9%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-28 m-auto">{ appointment?.quote ? typeOpts[appointment?.quote?.searchType] : typeOpts[appointment?.type] }</p>
            </td>
            <td className="w-[9%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-28 m-auto">{ paymentQuote[appointment?.payment?.paymentMethod] }</p>
            </td>
            <td className="w-[9%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-28 m-auto" title={appointment?.payment?.paymentMethod === "CAR_INSURANCE" ? appointment?.payment?.insurance?.numberOfInsurance : ""}>
                    { appointment?.payment?.paymentMethod === "CAR_INSURANCE" ? appointment?.payment?.insurance?.numberOfInsurance : appointment?.payment?.paymentMethod === "CREDIT_CARD" ? `**** **** **** ${String(appointment?.payment?.creditCard?.cardNumber).substring(12, 16)}` : "---" }
                </p>
                <p className="truncate w-28 m-auto" title={appointment?.payment?.paymentMethod === "CAR_INSURANCE" ? appointment?.payment?.insurance?.companyInsurance : ""}>
                    { appointment?.payment?.paymentMethod === "CAR_INSURANCE" ? appointment?.payment?.insurance?.companyInsurance : appointment?.payment?.paymentMethod === "CREDIT_CARD" ? appointment?.payment?.creditCard?.nameofOwner : "" }
                </p>
                <p className="truncate w-28 m-auto" title={appointment?.payment?.paymentMethod === "CAR_INSURANCE" ? appointment?.payment?.phoneNumber : ""}>
                    { appointment?.payment?.paymentMethod === "CAR_INSURANCE" ? appointment?.payment?.phoneNumber : "" }
                </p>
                <p className="truncate w-28 m-auto" title={appointment?.payment?.paymentMethod === "CAR_INSURANCE" ? formatDateEng(appointment?.payment?.insurance?.dateInsurance) : ""}>
                    { appointment?.payment?.paymentMethod === "CAR_INSURANCE" ? formatDateEng(appointment?.payment?.insurance?.dateInsurance) : "" }
                </p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-24 m-auto">{ formatDateEng(appointment?.date) }</p>
                <p className="truncate w-24 m-auto">{ formatAMPM(appointment?.date) }</p>
            </td>
            <td className="w-[9%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-24 m-auto">{ appointment?.warehouse ? appointment?.warehouse?.name : "-----" }</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-24 m-auto">{ appointment?.status ? arrStatus[appointment?.status]?.text : "" }</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <p className="truncate w-24 m-auto">{ appointment?.total ? formatCurrency(appointment?.total) : "-----" }</p>
            </td>
            <td className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
                <div className="space-y-1 w-24">
                    { appointment?.status === "CANCELED" ? "-----" : null }
                    {
                        appointment?.status === "ATTEND" ?
                            <div>
                                <button
                                    className="w-full bg-blue-900 text-white p-2 rounded-full disabled:bg-zinc-200"
                                    type="button"
                                    onClick={handleTicket}
                                >
                                    View ticket
                                </button>
                            </div>
                        : null
                    }
                    {
                        appointment?.status === "INPROCESS" ?
                            <div>
                                <button
                                    className="w-full bg-green-700 text-white p-2 rounded-full disabled:bg-zinc-200"
                                    type="button"
                                    onClick={() => onAttend(appointment._id, appointment?.order?.status, appointment?.order?._id)}
                                >
                                    Attend
                                </button>
                            </div>
                        : null
                    }
                    {
                        appointment?.status === "INPROCESS" ?
                            <div>
                                <button
                                    className="w-full bg-ag-primary-light text-white p-2 rounded-full disabled:bg-zinc-200"
                                    type="button"
                                    onClick={() => onCancel(appointment?.retailUser?._id, appointment?._id)}
                                >
                                    Cancel
                                </button>
                            </div>
                        : null
                    }
                </div>
            </td>
        </tr>
    );
}

export default RowsAppointments;