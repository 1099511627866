import { alertOptions, formatCurrency } from "../../../../utils/Utilities";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { IoMdImage } from "react-icons/io";
import IconMegaphone from "../../../../assets/icons/icon_megaphone.svg";

const ProductListCard = ({ product, isParamsSearch, onCart, onPurcharse, onAddWishList, onCompatible }) => {
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);

  const handleChange = (evt) => {
    const pattern = new RegExp(/^[0-9\s]+$/g);
    if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
      setQuantity(evt.target.value);
    }
  };

  const handlePurcharse = () => {
    if (quantity > 0) {
      if (quantity <= product?.inventoryData?.totalQuantity) {
        const objCart = {
          warehouse: { _id: product?.inventoryData?.warehouse?._id },
          productInventory: { _id: product?.inventoryData?.productInventory?._id },
          quantity: Number(quantity),
          year: product.searchValueYear,
          price: product?.inventoryData?.productInventory?.cost
        };
        onPurcharse(objCart);
      } else {
        toast.warning("The quantity entered exceeds the available stock", alertOptions);
      }
    } else {
      toast.warning("The quantity to buy must be greater than 0", alertOptions);
    }
  };

  const handleSubmit = () => {
    if (quantity > 0) {
      if (quantity <= product?.inventoryData?.totalQuantity) {
        const objCart = {
          warehouse: { _id: product?.inventoryData?.warehouse?._id },
          productInventory: { _id: product?.inventoryData?.productInventory?._id },
          quantity: Number(quantity),
          year: product.searchValueYear,
          price:product?.inventoryData?.productInventory?.cost
        };
        onCart(objCart);
        setQuantity(1);
      } else {
        toast.warning("The quantity entered exceeds the available stock", alertOptions);
      }
    } else {
      toast.warning("The quantity to buy must be greater than 0", alertOptions);
    }
  };

  return (
    <div className="border border-ag-secondary rounded-xl">
      <div className="lg:flex lg:justify-between lg:items-center lg:space-x-5 p-3">
        <div className={`flex justify-center items-center ${product?.inventoryData?.productInventory?.product?.productImages.length > 0 ? "bg-black" : ""}`}>
          {
            product?.inventoryData && product?.inventoryData?.productInventory?.product?.productImages.length > 0 ?
              <div className="w-[144px] h-[144px] flex justify-center items-center m-auto">
                <img className="w-full h-full object-contain" src={product?.inventoryData?.productInventory?.product?.productImages[0]} />
              </div>
              :
              <span className="px-8 text-[80px] text-ag-secondary-letter">
                <IoMdImage />
              </span>
          }
        </div>

        <div className="w-full">
          <div className="text-center lg:text-left">
            <span className="text-ag-secondary-letter font-semibold cursor-pointer" onClick={() => navigate(`/wholesaler/search/${product?.available ? product?.inventoryData?.productInventory?._id : product?._id}`, { state: { qty: quantity, searchValueYear: product?.searchValueYear } })}>
              {product?.nag}
            </span>
          </div>

          {
            isParamsSearch ?
              <div className="flex justify-center lg:justify-start items-center space-x-3">
                <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter font-semibold">Year:</span>

                <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter cursor-pointer" onClick={() => navigate(`/wholesaler/search/${product?.available ? product?.inventoryData?.productInventory?._id : product?._id}`, { state: { qty: quantity, searchValueYear: product?.searchValueYear } })}>
                  {`${String(product.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(product.year).replace(/\s+/g, '').split(',').sort()[Number(String(product.year).replace(/\s+/g, '').split(',').length - 1)]}`}
                </span>
              </div>
              :
              <div className="flex justify-center lg:justify-start items-center space-x-3">
                <div className="inline-flex items-center space-x-2">
                  <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter font-semibold">Year:</span>

                  <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter cursor-pointer">{product?.year}</span>
                </div>
              </div>
          }

          {
            isParamsSearch ?
              <div className="flex justify-center lg:justify-start items-center space-x-3">
                <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter font-semibold">Make:</span>

                <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter cursor-pointer" onClick={() => navigate(`/wholesaler/search/${product?.available ? product?.inventoryData?.productInventory?._id : product?._id}`, { state: { qty: quantity, searchValueYear: product?.searchValueYear } })}>
                  {product?.brand}
                </span>
              </div>
              :
              <div className="flex justify-center lg:justify-start items-center space-x-3">
                <div className="inline-flex items-center space-x-2">
                  <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter font-semibold">Make:</span>

                  <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter cursor-pointer">{product?.brand }</span>
                </div>
              </div>
          }

          {
            isParamsSearch ?
              <div className="flex justify-center lg:justify-start items-center space-x-3">
                <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter font-semibold">Model:</span>

                <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter cursor-pointer" onClick={() => navigate(`/wholesaler/search/${product?.available ? product?.inventoryData?.productInventory?._id : product?._id}`, { state: { qty: quantity, searchValueYear: product?.searchValueYear } })}>
                  {product?.model}
                </span>
              </div>
              : null
          }

          {
            isParamsSearch ?
              <></>
              :
              <div className="flex justify-center lg:justify-start items-center space-x-3">
                <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter font-semibold">Model:</span>

                <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter cursor-pointer" onClick={() => navigate(`/wholesaler/search/${product?.available ? product?.inventoryData?.productInventory?._id : product?._id}`, { state: { qty: quantity, searchValueYear: product?.searchValueYear } })}>
                  {product?.model}
                </span>
              </div>
          }

          <div className="flex justify-center lg:justify-start items-center space-x-3">
            <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter font-semibold">Warehouse:</span>

            <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter cursor-pointer" onClick={() => navigate(`/wholesaler/search/${product?.available ? product?.inventoryData?.productInventory?._id : product?._id}`, { state: { qty: quantity, searchValueYear: product?.searchValueYear } })}>
              {product?.inventoryData !== null ? product?.inventoryData?.warehouse?.name : "---"}
            </span>
          </div>
        </div>

        <div className="w-full">
          <div className="flex justify-center lg:justify-start items-center space-x-5 pb-2">
            <div className={`w-5 h-5 ${product?.available && product?.inventoryData?.totalQuantity > 0 ? "bg-green-500" : "bg-red-600"} rounded-full`}></div>

            <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter">{product?.available && product?.inventoryData?.totalQuantity > 0 ? "Available" : "No available"}</span>
          </div>

          <div className="flex justify-center lg:justify-start items-center space-x-3">
            <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter font-semibold">Style:</span>

            <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter cursor-pointer" onClick={() => navigate(`/wholesaler/search/${product?.available ? product?.inventoryData?.productInventory?._id : product?._id}`, { state: { qty: quantity, searchValueYear: product?.searchValueYear } })}>
              {product?.type_car}
            </span>
          </div>

          <div className="flex justify-center lg:justify-start items-center space-x-3">
            <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter font-semibold">Type of glass:</span>

            <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter cursor-pointer" onClick={() => navigate(`/wholesaler/search/${product?.available ? product?.inventoryData?.productInventory?._id : product?._id}`, { state: { qty: quantity, searchValueYear: product?.searchValueYear } })}>
              {product?.type_glass}
            </span>
          </div>

          <div className="flex justify-center lg:justify-start items-center space-x-3">
            {
              product?.inventoryData?.productInventory?.product?.compatible_vehicles.length >= 1 ?
                <p className="truncate w-48 text-ag-secondary-letter font-semibold cursor-pointer" onClick={() => onCompatible(product?.inventoryData?.productInventory?.product)}>
                  See compatible vehicles +
                </p>
                : null
            }
          </div>

          <div className="flex justify-center lg:justify-start items-center space-x-3">
            <div className="inline-flex items-center space-x-2">
              <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter font-semibold">H.R:</span>

              <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter">{product?.labor_time}</span>
            </div>
          </div>
        </div>

        <div className="w-full space-y-3">
          {
            product?.wishList || !product?.available || product?.inventoryData?.totalQuantity === 0 ?
              <div className={`${!product?.available || product?.inventoryData?.totalQuantity === 0 ? "lg:pb-24" : ""}`}>
                <div className="flex justify-end items-start space-x-3">
                  <img
                    className={`${product?.wishList ? "opacity-50" : "cursor-pointer"} pr-2`}
                    src={IconMegaphone}
                    onClick={() => product?.wishList ? null : onAddWishList(product)}
                  />
                </div>

                <p
                  className={`${product?.wishList ? "opacity-50" : "cursor-pointer"} text-xs text-ag-secondary-letter text-right`}
                  onClick={() => product?.wishList ? null : onAddWishList(product)}
                >
                  Request
                </p>
              </div>
              : null
          }

          {
            product?.available && product?.inventoryData?.totalQuantity > 0 ?
              <div className="flex justify-center lg:justify-start items-center space-x-3">
                <span className="text-base lg:text-sm xl:text-xl text-ag-secondary-letter font-semibold">Sales Price:</span>

                <span className="text-base lg:text-sm xl:text-xl text-ag-secondary-letter font-semibold">{formatCurrency(product?.inventoryData?.productInventory?.cost)}</span>
              </div>
              : null
          }

          {
            product?.available && product?.inventoryData?.totalQuantity > 0 ?
              <div className="flex justify-center lg:justify-start items-center space-x-3">
                <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter font-semibold">Nags Price:</span>

                <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter">{formatCurrency(product?.inventoryData?.productInventory?.product?.price)}</span>
              </div>
              : null
          }

          {
            product?.available && product?.inventoryData?.totalQuantity > 0 ?
              <div className="flex justify-center lg:justify-start">
                <input
                  className="w-32 px-3 py-1 text-center text-ag-secondary-letter border border-ag-secondary-letter rounded-xl"
                  type="text"
                  name="quantity"
                  onChange={handleChange}
                  value={quantity}
                />
              </div>
              : null
          }

          {
            product?.available && product?.inventoryData?.totalQuantity > 0 ?
              <div className="flex lg:block xl:flex justify-center lg:justify-start items-center space-x-3 lg:space-x-0 xl:space-x-3 space-y-0 lg:space-y-1 xl:space-y-0">
                <button className="w-auto lg:w-full xl:w-auto px-5 py-2 bg-ag-primary-light text-white text-xs text-center rounded-xl" type="button" onClick={() => handleSubmit()}>
                  Add to cart
                </button>
                <button className="w-auto lg:w-full xl:w-auto px-5 py-2 bg-ag-secondary text-white text-xs text-center rounded-xl" type="button" onClick={() => handlePurcharse()}>
                  Quick purchase
                </button>
              </div>
              : null
          }
        </div>
      </div>

      <div className="px-5 text-center lg:text-left">
        <div className="inline-flex items-center space-x-2 mb-[10px]">
          <span className="text-base lg:text-sm xl:text-base text-ag-secondary-letter font-semibold">Features:</span>

          <span className="text-ag-secondary-letter italic text-xs">{product?.allFeatures}</span>

          {product.category.map((catElement, j) => (
            catElement.status ? (
              <img key={j} title={catElement.label} src={catElement.icont} className="inline-block" />
            ) : null
          ))}
        </div>
      </div>

    </div>
  );
};

export default ProductListCard;