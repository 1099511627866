import React from 'react'

const RearDoorRight = ({ isSelected, isAvailable, onClick }) => {
  return (
    <svg className="cursor-pointer outline-none" onClick={onClick} data-tooltip-id="car" data-tooltip-content="Rear Door (right)">
      <path d="M270.32 322.88V294.28H303.12V329.88L270.32 322.88Z" fill={isSelected ? "#F41A28" :isAvailable ? "#1D8F43" : "#D3D3D4"}/>
    </svg>
  )
}

export default RearDoorRight