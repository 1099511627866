import { useEffect, useState } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { IoMdCloseCircle } from "react-icons/io";

const PaymentConfirm = ({ carts, onClose, onAccept }) => {
    const [listProducts, setListProducts] = useState([]);

    useEffect(() => {
        let products = [];
        for (let i = 0; i < carts.length; i++) {
            for (let j = 0; j < carts[i]?.products.length; j++) {
                products.push(`
                    ${carts[i].products[j]?.compatible_vehicles[0]?.brand}
                    ${carts[i].products[j]?.compatible_vehicles[0]?.model}
                    ${carts[i].products[j]?.compatible_vehicles[0]?.type_car}
                    ${String(carts[i].products[j]?.compatible_vehicles[0]?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(carts[i].products[j]?.compatible_vehicles[0]?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(carts[i].products[j]?.compatible_vehicles[0]?.year).replace(/\s+/g, '').split(',').sort()[Number(String(carts[i].products[j]?.compatible_vehicles[0]?.year).replace(/\s+/g, '').split(',').length-1)]}` : carts[i].products[j]?.compatible_vehicles[0]?.year}
                `);
            }
        }
        setListProducts(products);
    }, [carts]);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[500px]">
                        <div className="px-5 pt-5 pb-3 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%] text-center font-semibold text-ag-secondary-letter">
                                    <h3 className="text-xl pl-5">Purchase confirmation</h3>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="relative p-3 flex-auto">
                                <div className="space-y-3">
                                    <div className="flex justify-center pt-5">
                                        <span className="text-[100px] text-green-700"><AiFillCheckCircle /></span>
                                    </div>
                                    <div className="text-center text-ag-secondary pb-5">
                                        <p>Your purchase of the product</p>
                                        <div className="py-2">
                                            { listProducts.map((elem, i) => 
                                                (<p 
                                                    key={i}
                                                    className="font-semibold"
                                                >
                                                    { elem }
                                                </p>)
                                            )}
                                        </div>
                                        <p>has been done successfully</p>
                                    </div>
                                    <div className="flex justify-center items-center space-x-3">
                                        <button
                                            type="button"
                                            className="w-full px-10 py-2 bg-ag-primary-light text-white text-sm rounded-xl"
                                            onClick={() => onClose(false)}
                                        >
                                            Close
                                        </button>
                                        <button
                                            type="button"
                                            className="w-full px-10 py-2 bg-green-700 text-white text-sm rounded-xl"
                                            onClick={() => onAccept(true)}
                                        >
                                            See my orders
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

export default PaymentConfirm;