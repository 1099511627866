import { useEffect, useState } from "react";
import Pagination from "../../../../components/pagination/Pagination";
import RowsTransfer from "./RowsTransfer";

const TableTransfer = ({ transfers, onCanceled }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 15;

  const indexOfLastRecord = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstRecord = indexOfLastRecord - ITEMS_PER_PAGE;
  const currentRecords = transfers.slice(indexOfFirstRecord, indexOfLastRecord);

  useEffect(() => {
    setCurrentPage(1);
  }, [transfers]);

  return (
    <div>
      <div className="overflow-auto min-h-[38vh] lg:min-h-[47vh] 3xl:min-h-[55vh]">
        <table className="w-full text-center text-ag-secondary">
          <thead>
            <tr className="bg-gray-300">
              <th className="w-[7%] bg-ag-primary-dark text-white border-[0.5px] border-white text-xs font-normal px-8 md:px-6 lg:px-2 py-2">Status</th>
              <th className="w-[8%] bg-ag-primary-dark text-white border-[0.5px] border-white text-xs font-normal px-8 md:px-10 lg:px-2 py-2">Created</th>
              <th className="w-[12%] bg-ag-primary-dark text-white border-[0.5px] border-white text-xs font-normal px-14 md:px-12 lg:px-2 py-2">Created by</th>
              <th className="w-[8%] bg-ag-primary-dark text-white border-[0.5px] border-white text-xs font-normal px-4 md:px-4 lg:px-2 py-2">Transfer #</th>
              <th className="w-[19%] bg-ag-primary-dark text-white border-[0.5px] border-white text-xs font-normal px-24 md:px-20 lg:px-2 py-2">Warehouses</th>
              <th className="w-[8%] bg-ag-primary-dark text-white border-[0.5px] border-white text-xs font-normal px-8 md:px-6 lg:px-2 py-2">Quantity</th>
              <th className="w-[8%] bg-ag-primary-dark text-white border-[0.5px] border-white text-xs font-normal px-8 md:px-6 lg:px-2 py-2">Subtotal</th>
              <th className="w-[8%] bg-ag-primary-dark text-white border-[0.5px] border-white text-xs font-normal px-8 md:px-10 lg:px-2 py-2">Last Updated</th>
              <th className="w-[12%] bg-ag-primary-dark text-white border-[0.5px] border-white text-xs font-normal px-16 md:px-2 lg:px-2 py-2">Last Updated by</th>
              <th className="w-[10%] bg-ag-primary-dark text-white border-[0.5px] border-white text-xs font-normal px-10 md:px-2 lg:px-2 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              currentRecords.map((element, i) => {
                return (
                  <RowsTransfer
                    key={i}
                    transfer={element}
                    onCanceled={onCanceled}
                  />
                );
              })
            }
          </tbody>
        </table>
      </div>
      <div className="p-5 m-auto">
        <Pagination
          currentPage={currentPage}
          totalCount={transfers.length}
          pageSize={ITEMS_PER_PAGE}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>
    </div>
  );
};

export default TableTransfer;