import { useEffect, useState } from "react";
import { IoMdImage } from "react-icons/io";

function ProductCard({ product, onAction, actionIcon, actionText, onCompatible }) {
  const [vendorsStr, setVendorsStr] = useState("");
  const backgroundButton = actionText === 'Add to Transfer' ? '#1d4ed8' : '#b91c1c';

  const {
    productInventory: {
      product: {
        vendors = [],
        productImages = [],
        compatible_vehicles = [],
        nags = '',
        name = '',
        description = '',
      } = {},
    } = {},
    rack = {},
  } = product || {};

  useEffect(() => {
    if (vendors.length > 0) {
      const vendorNames = vendors.map(vendor => vendor?.name).filter(Boolean);
      setVendorsStr(vendorNames.join(', '));
    }
  }, [vendors]);

  const renderVehicleInfo = (vehicle) => {
    const years = String(vehicle?.year).replace(/\s+/g, '').split(',');
    const yearRange = years.length > 1 ? `${years.sort()[0]} - ${years.sort()[years.length - 1]}` : vehicle?.year;

    return `${yearRange} ${vehicle?.brand} ${vehicle?.model} ${vehicle?.type_car}`;
  };

  const renderCompatibleVehicles = () => {
    if (compatible_vehicles.length === 0) return null;

    const firstVehicle = compatible_vehicles[0];
    return (
      <>
        <p title={renderVehicleInfo(firstVehicle)}>
          {renderVehicleInfo(firstVehicle)}
        </p>
        {compatible_vehicles.length > 1 && (
          <p
            className="font-semibold cursor-pointer text-gray-900"
            onClick={() => onCompatible(product?.productInventory?.product)}
          >
            See compatible vehicles +
          </p>
        )}
      </>
    );
  };

  return (
    <div style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }} className="w-full mb-4 border-[#b3adad8c] border-[1px] rounded-lg">
      <div className="p-4">
        <div className="flex items-start space-x-4">
          {productImages[0] ? (
            <div className="w-[96px] flex justify-center items-center">
              <img className="w-full h-full object-contain rounded-lg" src={productImages[0]} alt="Product" />
            </div>
          ) : (
            <IoMdImage className="text-8xl text-gray-500"/>
          )}

          <div className="flex-1 min-w-0">
            <h3 className="text-sm font-medium text-gray-900 break-words">
              {nags} {name}
            </h3>

            {renderCompatibleVehicles()}

            <p className="text-sm text-gray-500">
              Vendor: {vendorsStr}
            </p>

            <p className="text-sm text-gray-500">
              Rack: {rack?.name}
            </p>
          </div>
        </div>

        <p className="mt-2 text-sm text-gray-700">{description}</p>
      </div>

      <div className="p-4 pt-0">
        <button
          type="button"
          onClick={() => onAction(product._id_rackAndProductRelation)}
          className={`w-full flex items-center justify-center gap-[10px] text-white p-[10px] rounded-md`}
          style={{ backgroundColor: backgroundButton }}
        >
          {actionIcon}
          {actionText}
        </button>
      </div>
    </div>
  );
}

export default ProductCard;
