import { useState, useEffect } from "react";
import { IoMdCloseCircle, IoMdImage } from "react-icons/io";
import { alertOptions } from "../../../utils/Utilities";
import { toast } from "react-toastify";
import { selectProductQuotePWRWT } from "../../../services/Quotes";
import TableQuotesOther from "./TableQuoteOther";
import { PatternFormat } from "react-number-format";
import iconRadio from '../../../assets/icons/icon_radio_button_unchecked.svg'
import { sendQuotePowerWindowRepair } from "../../../services/WindowTintCatalog";


const arrTypeGlass = {
    FRONT_DOOR_LEFT: { text: "Door / Front / Left" },
    FRONT_DOOR_RIGHT: { text: "Door / Front / Right" },
    REAR_DOOR_LEFT: { text: "Door / Rear / Left" },
    REAR_DOOR_RIGHT: { text: "Door / Rear / Right" },
    REAR_QUARTER_LEFT: { text: "Quarter / Left" },
    REAR_QUARTER_RIGHT: { text: "Quarter / Right" },
    BACK_GLASS_SLIDER: { text: "Back Window" },
    SUNROOF: { text: "Roof" }
}

const QuoteOtherDetail = ({ quote, onLoader, onRefresh, onClose }) => {
    const [powerWindowRepairQuote, setPowerWindowRepairQuote] = useState([])
    const [selectedPWR, setSelectedPWR] = useState(false)


    // const [data, setData] = useState({
    //     service: "",
    //     hours: "00",
    //     minutes: "00",
    //     price: ""
    // });



    // const handleSend = () => {
    //     if (data.service && (data.hours !== "00" || data.minutes !== "00") && data.price && String(data.service).trim().length > 0 && String(data.price).trim().length > 0) {
    //         if (data.price > 0) {
    //             onLoader(true);
    //             selectProductQuotePWRWT(quote?._id, data).then(res => {
    //                 if (res.status === 200) {
    //                     onRefresh(true);
    //                 } else {
    //                     onLoader(false);
    //                     toast.warning("There was a problem selecting the product", alertOptions);
    //                 }
    //             }).catch((error) => {
    //                 onLoader(false);
    //                 toast.error(error.response?.data?.message, alertOptions);
    //             });
    //         } else {
    //             toast.info("The quote price must be greater than 0", alertOptions);
    //         }
    //     } else {
    //         toast.info("Please fill in all the fields of the form", alertOptions);
    //     }
    // }


    const handleSelectPWR = (index) => {
        const updatedData = [...powerWindowRepairQuote]
        updatedData[index].selected = !updatedData[index].selected;
        setPowerWindowRepairQuote(updatedData);
    }

    const handleRowChange = (e, column, index) => {
        const value = e.target.value;
        const updatedData = [...powerWindowRepairQuote];
        updatedData[index][column] = value;
        setPowerWindowRepairQuote(updatedData)

    }

    const handleSave = () => {
        console.log(powerWindowRepairQuote);
        for (let i = 0; i < powerWindowRepairQuote.length; i++) {
            const row = powerWindowRepairQuote[i];
            if (row.selected && (!row.description || !row.price)) {
                toast.warning(`Please fill the options in the row ${i + 1}`, alertOptions);
                return;
            }
            else {
                console.log(powerWindowRepairQuote)
                //sendData
                const dataPost = powerWindowRepairQuote.filter((x)=> x.selected=== true)
                console.log(dataPost)
                sendQuotePowerWindowRepair(quote._id, dataPost).then(res => {
                    if (res.status === 200) {
                        toast.success("The quote has been sent successfully!", alertOptions);
                    }
                }).catch((error) => {
                    toast.warning(error.response.data.message, alertOptions);
                });
            }
        }
    }

    const load = () => {
        const optionsGlasses = quote?.selectedOptionsPWR.map((item) => ({
            selected: false,
            windowName: item,
            description: '',
            price: null
        }))
        setPowerWindowRepairQuote(optionsGlasses)
    }

    useEffect(() => {
        load()
        console.log(quote)
    }, [])

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-20 outline-none focus:outline-none">
                        <div className="w-[320px] md:w-[700px] lg:w-[745px]">
                            <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="w-full flex items-center justify-center rounded text-center">
                                    <div className="w-[95%]">
                                        <span className="text-xl text-ag-secondary font-bold pl-[5%]">
                                            Quote Details
                                        </span>
                                    </div>
                                    <div className="w-[5%]">
                                        <button
                                            className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                            onClick={() => onClose(false)}
                                        >
                                            <IoMdCloseCircle />
                                        </button>
                                    </div>
                                </div>
                                <div className="relative px-5 pb-2 pt-2 flex-auto">
                                    <div className="w-full m-auto grid pb-5 md:grid-cols-3">
                                        <div className="w-full">
                                            <div className="text-left">
                                                <div className="mb-2">
                                                    <span className="text-ag-secondary-letter text-xs font-semibold">
                                                        Power Window Repair Quote
                                                    </span>
                                                </div>

                                                <div>
                                                    <div className="flex justify-start items-center space-x-1 pt-2">
                                                        <span className="text-[#000] text-xs font-bold">Retail Customer Information:</span>
                                                    </div>
                                                    <div className="flex justify-start items-center space-x-1">
                                                        <span className="text-ag-secondary-letter text-xs font-semibold">Name:</span>
                                                        <span className="text-ag-secondary-letter text-xs">{quote?.retailUser?.names + ' ' + quote?.retailUser?.lastNames}</span>
                                                    </div>
                                                    <div className="flex justify-start items-center space-x-1">
                                                        <span className="text-ag-secondary-letter text-xs font-semibold">Mail:</span>
                                                        <a className="text-xs text-blue-500 italic underline cursor-pointer" href={`mailto: ${quote?.retailUser?.user?.email}`}>{quote?.retailUser?.user?.email}</a>
                                                    </div>
                                                    <div className="transparent flex justify-start items-center space-x-1">
                                                        <span className="text-ag-secondary-letter text-xs font-semibold">Phone:</span>
                                                        <PatternFormat
                                                            value={quote?.retailUser?.phoneNumber}
                                                            format="(###)###-####"
                                                            displayType="text"
                                                            className="text-ag-secondary-letter text-xs"
                                                        />
                                                        {/* <span className="text-ag-secondary-letter text-xs">{quote?.retailUser?.phoneNumber}</span> */}
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="flex justify-start items-center space-x-1 pt-2">
                                                        <span className="text-[#000] text-xs font-bold">Vehicle Information:</span>
                                                    </div>
                                                    <div className="flex justify-start items-center space-x-1">
                                                        <span className="text-ag-secondary-letter text-xs font-semibold">Year:</span>
                                                        <span className="text-ag-secondary-letter text-xs">{quote?.year}</span>
                                                    </div>
                                                    <div className="flex justify-start items-center space-x-1">
                                                        <span className="text-ag-secondary-letter text-xs font-semibold">Make:</span>
                                                        <span className="text-ag-secondary-letter text-xs">{quote?.brand}</span>
                                                    </div>
                                                    <div className="flex justify-start items-center space-x-1">
                                                        <span className="text-ag-secondary-letter text-xs font-semibold">Model:</span>
                                                        <span className="text-ag-secondary-letter text-xs">{quote?.model}</span>
                                                    </div>
                                                    <div className="flex justify-start items-center space-x-1">
                                                        <span className="text-ag-secondary-letter text-xs font-semibold">Style:</span>
                                                        <span className="text-ag-secondary-letter text-xs">{quote?.style}</span>
                                                    </div>
                                                    {
                                                        quote?.vin ?
                                                            <div className="flex justify-start items-center space-x-1">
                                                                <span className="text-ag-secondary-letter text-xs font-semibold">VIN:</span>
                                                                <span className="text-ag-secondary-letter text-xs">{quote?.vin}</span>
                                                            </div>
                                                            : null
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full">
                                            <span className="text-ag-secondary-letter text-[100px] text-center m-auto">
                                                <div className="w-[100px] m-auto">
                                                    <IoMdImage />
                                                </div>
                                            </span>
                                        </div>
                                        <div className="w-full space-y-1 pl-2 py-2 border border-ag-secondary-light rounded-xl">
                                            <div className="text-center">
                                                <p className="text-ag-secondary-letter text-base font-semibold pb-1">Payment Method</p>
                                            </div>
                                            <div>
                                                <p className="text-center text-ag-secondary-letter text-xs font-semibold">Pay on my own</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full space-y-1 p-2 border border-ag-secondary-light rounded-xl mb-3">
                                        <div className={`${quote?.description && quote?.description.length > 680 ? 'overflow-y-scroll' : 'overflow-y-hidden'} max-h-24`}>
                                            <p className="pr-2 text-ag-secondary-letter text-xs whitespace-normal break-all" title={quote?.description && quote?.description !== "" ? quote?.description : "---"}>
                                                <span className="font-semibold mr-5" >Description:</span>  {quote?.description && quote?.description !== "" ? quote?.description : "---"}
                                            </p>
                                        </div>
                                    </div>

                                    <div>
                                        <p className="text-ag-secondary text-[13px] font-medium leading-[15.73px]">Below are the windows indicated by the retail user who wishes to quote his vehicle for power window repair. If you wish to send a quote for any of these glasses, you must fill out all the data in the table (Select at least 1 glass indicated by the retail):</p>
                                    </div>

                                    <div className="pt-5">
                                        {/* <TableQuotesOther
                                            stsQuote={quote?.status === "approved" ? true : false}
                                            svsQuote={quote?.service}
                                            prcQuote={quote?.price}
                                            onProduct={setData}
                                        /> */}
                                        {
                                            <div className="max-w-full border-solid border-[#D7D7D7] border">
                                                <table className="min-w-full border-collapse ">
                                                    <thead className="bg-ag-secondary-table h-[57px] text-center">
                                                        <tr>
                                                            <th className="w-[10%] border-[0.5px] border-white text-xs font-normal px-28 lg:px-2 py-4">
                                                                <div className="flex align-center justify-center">
                                                                    <img src={iconRadio}></img>
                                                                </div>
                                                            </th>
                                                            <th className="w-[30%] border-[0.5px] border-white text-xs font-normal px-28 lg:px-2 py-4 text-ag-secondary-letter">Window name</th>
                                                            <th className="w-[30%] border-[0.5px] border-white text-xs font-normal px-10 lg:px-2 py-4 text-ag-secondary-letter">Description</th>
                                                            <th className="w-[30%] border-[0.5px] border-white text-xs font-normal px-10 lg:px-2 py-4 text-ag-secondary-letter">Price</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                                <div className="overflow-y-auto h-[200px]">
                                                    <table className="min-w-full border-collapse table-quote">
                                                        <tbody>
                                                            {quote?.selectedOptionsPWR.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td className="w-[10%] text-ag-secondary-letter border border-white text-[10px] font-normal p-2">
                                                                            <div className="flex justify-center items-center">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name={item.selected}
                                                                                    onChange={() => handleSelectPWR(index)}
                                                                                    className=""
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                        <td className="w-[30%] text-ag-secondary-letter border border-white text-[10px] font-normal p-2">
                                                                            <input
                                                                                type="text"
                                                                                name="windowName"
                                                                                className="w-full h-12 text-center border border-ag-secondary-light px-2 py-2 rounded-xl disabled:bg-zinc-200"
                                                                                value={arrTypeGlass[item].text}
                                                                                disabled={true}
                                                                            />
                                                                        </td>
                                                                        <td className="w-[30%] text-ag-secondary-letter border border-white text-[10px] font-normal p-2">
                                                                            <input
                                                                                type="text"
                                                                                name="description"
                                                                                value={item.description}
                                                                                onChange={(e) => handleRowChange(e, "description", index)}
                                                                                className="w-full h-12 text-center border border-ag-secondary-light px-2 py-2 rounded-xl disabled:bg-zinc-200"
                                                                            />
                                                                        </td>
                                                                        <td className="w-[30%] text-ag-secondary-letter border border-white text-[10px] font-normal p-2">
                                                                            <input
                                                                                type="text"
                                                                                name="price"
                                                                                value={item.price}
                                                                                onChange={(e) => handleRowChange(e, "price", index)}
                                                                                className="w-full h-12 text-center border border-ag-secondary-light px-2 py-2 rounded-xl disabled:bg-zinc-200"
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {/* <div className="flex justify-end items-center space-x-5 pt-5">
                                        <button
                                            type="button"
                                            className="px-4 py-3 bg-red-600 text-white text-xs rounded-xl"
                                            onClick={() => onClose(false)}
                                        >
                                            Cancel
                                        </button>
                                        {
                                            quote?.status === "inprocess" ?
                                                <button
                                                    type="button"
                                                    className="px-4 py-3 bg-green-600 text-white text-xs rounded-xl"
                                                    onClick={() => handleSave()}
                                                >
                                                    Send Quote
                                                </button>
                                                : null
                                        }
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-50 fixed inset-0 z-10 bg-black"></div>
        </>
    );
}

export default QuoteOtherDetail;