import { searchProductEspecification } from "../../../../services/Product";

const RowsProductEspecification = ({ product, onSelect, onCompatible }) => {
    const handleCompatible = () => {
        searchProductEspecification({ nag: product.nag, excludePriceZero: false}).then(res => {
            if (res.status === 200) {
                onCompatible({...product, compatible_vehicles: res.data});
            } else {
                onCompatible({...product, compatible_vehicles: []});
            }
        }).catch(() => {
            onCompatible({...product, compatible_vehicles: []});
        });
    }

    return (
        <tr className="hover:bg-zinc-200 hover:cursor-pointer">
            <td className="w-[6%] border-[0.5px] border-ag-secondary-light text-[10px] font-normal px-1 py-2" onClick={() => onSelect(true)}>{ product.nag }</td>
            <td className="w-[94%] border-[0.5px] border-ag-secondary-light text-[10px] font-normal px-1 py-2">
                <p className="truncate m-auto" onClick={() => onSelect(true)} title={
                    `${String(product?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(product?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(product?.year).replace(/\s+/g, '').split(',').sort()[Number(String(product?.year).replace(/\s+/g, '').split(',').length-1)]}` : product?.year} ` +
                    `${product?.brand} ` +
                    `${product?.model} ` +
                    `${product?.type_car}`
                }>
                    { String(product?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(product?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(product?.year).replace(/\s+/g, '').split(',').sort()[Number(String(product?.year).replace(/\s+/g, '').split(',').length-1)]}` : product?.year }{' '}
                    { product?.brand }{' '}
                    { product?.model }{' '}
                    { product?.type_car }{' | '}
                    <b>Color:</b> { product?.color }{' | '}
                    <b>Width:</b> { product?.width }{' | '}
                    <b>Height:</b> { product?.height }{' | '}
                    <b>Graphic:</b> { product?.graphic_id ? product?.graphic_id : "---" }{' | '}
                    <b>Encapsulated: </b>{ product?.encapsulated }{' | '}
                    <b>HUD:</b> { product?.hud }{' | '}
                    <b>Heated:</b> { product?.heated }{' | '}
                    <b>Slider:</b> { product?.slider }{' | '}
                    <b>Solar:</b> { product?.solar }{' '}
                </p>
                <p className="truncate font-semibold m-auto cursor-pointer" onClick={() => handleCompatible()}>
                    See compatible vehicles +
                </p>
            </td>
        </tr>
    );
}

export default RowsProductEspecification;