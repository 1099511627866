import { _delete, _get, _post, _put } from "./http";

export const getPurcharse = (idWholesaler, idPurcharse) => _get(`/wholesaleUser/${idWholesaler}/purchases/${idPurcharse}`);

export const getPurcharses = (idWholesaler) => _get(`/wholesaleUser/${idWholesaler}/purchases`);

export const cancelPurcharse = (idWholesaler, idPurcharse, purcharseObject) => _put(`/wholesaleUser/${idWholesaler}/purchases/${idPurcharse}/cancel`, purcharseObject);

export const finishPurcharse = (idWholesaler, idPurcharse, purcharseObject) => _put(`/wholesaleUser/${idWholesaler}/purchases/${idPurcharse}/finish`, purcharseObject);

export const sendNote = (idWholesaler, idPurcharse, purcharseObject) => _put(`/wholesaleUser/${idWholesaler}/purchases/${idPurcharse}/sendNote`, purcharseObject);

export const deletePurcharse = (idWholesaler, idPurcharse) => _delete(`/wholesaleUser/${idWholesaler}/purchases/${idPurcharse}/delete`);

export const shippingOptionCartWholesaler = (idWholesaler, shippingObject) => _post(`/wholesaleUser/${idWholesaler}/purchases/shipping`, shippingObject);

export const paymentOptionCartWholesaler = (idWholesaler, shippingObject) => _post(`/wholesaleUser/${idWholesaler}/purchases/payment`, shippingObject);

export const savePaymentCartWholesaler = (idWholesaler, shippingObject) => _post(`/wholesaleUser/${idWholesaler}/purchases/savePayment`, shippingObject);
