/* eslint-disable no-trailing-spaces */
import { useContext, useEffect, useState } from "react";
import { getUsers, saveUser, deleteUser, updateUser } from "../../../services/Administrator";
import { getRoles } from "../../../services/Role";
import { alertOptions, removeAccents } from "../../../utils/Utilities";
import { PermitsContext } from "../../../services/Permits";
import { toast } from "react-toastify";
import { getWarehouses } from "../../../services/Warehouse";
import Modal from "../../../components/modal/Modal";
import Filters from "./Filters";
import FormAdmin from "./FormAdmin";
import TableAdmin from "./TableAdmin";
import Loader from "../../../components/loader/Loader";

const Administrators = () => {
  const { permits, dataSession, resetPermits } = useContext(PermitsContext);

  const [loader, setLoader] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showModal, setShowModal] = useState({ status: false, element: null });

  const [adminData, setAdminData] = useState(null);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [nameOptions, setNameOptions] = useState([]);
  const [rolesOptions, setRolesOptions] = useState([]);

  const [users, setUsers] = useState([]);
  const [usersUniv, setUsersUniv] = useState([]);
  const [clearFilters, setClearFilters] = useState(false);

  const handleFilters = (value, option) => {
    if (option === "filters") {
      handleSearchFilter(value);
    } else {
      handleSpecificFilters(value);
    }
  };

  const handleSearchFilter = (searchValue) => {
    const palabra = new RegExp(`${removeAccents(String(searchValue).replace("+", ""))}.*`, "i");

    const usersFound = usersUniv.filter(element => (
      palabra.test(`${removeAccents(element.names)} ${removeAccents(element.lastNames)}`) ||
      palabra.test(removeAccents(element.warehouse)) ||
      palabra.test(removeAccents(String(element.user.email).replace("+", ""))) ||
      palabra.test(removeAccents(element.role?.name || "")) ||
      palabra.test(element.phoneNumber)
    ));

    setUsers(usersFound);
  };

  const handleSpecificFilters = (filters) => {
    const isEmptyFilter = Object.values(filters).every(value => value === "");

    if (isEmptyFilter) {
      setUsers(usersUniv);
      return;
    }

    let filteredUsers = [ ...usersUniv ];

    if (filters.names !== '') {
      filteredUsers = filteredUsers.filter(element =>
        (`${element.names} ${element.lastNames}` === filters.names)
      );
    }

    if (filters.role !== '') {
      filteredUsers = filteredUsers.filter(element =>
        element.role?.name === filters.role
      );
    }

    if (filters.warehouse.length) {
      const warehouseIds = filters.warehouse.map(w => w.value);
    
      filteredUsers = filteredUsers.filter(element => {
        const matchingWarehouses = element.warehouse.filter(warehouse =>
          warehouseIds.includes(warehouse._id)
        );
        return matchingWarehouses.length > 0;
      });
    }

    setUsers(filteredUsers);
  };


  const getData = () => {
    getUsers().then(resGet => {
      const adminsCurrent = resGet.data.filter(element => !element.deleted && element?.names !== "Super Admin");

      const array_sort = adminsCurrent.sort(function(a, b) {
        if (a.names.toUpperCase() < b.names.toUpperCase()) return -1;
        if (a.names.toUpperCase() > b.names.toUpperCase()) return 1;
        return 0;
      });

      setUsers(array_sort);
      setUsersUniv(array_sort);
      getOptions(array_sort, []);
      setShowForm(false);
    });
  };

  const addUser = (userObject) => {
    setLoader(true);
    saveUser(userObject).then(res => {
      if (res.status === 200) {
        getData();
        toast.success("User added successfully", alertOptions);
      } else {
        toast.warning(res.response.data.message, alertOptions);
      }
      setLoader(false);
    }).catch(error => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  const editUser = (id, userObject) => {
    setClearFilters(true);
    setLoader(true);
    updateUser(id, userObject).then((res) => {
      if (res.status === 200) {
        if (dataSession._id === id) resetPermits();
        getData();
        toast.success("User updated successfully", alertOptions);
      } else {
        toast.warning(res.response.data.message, alertOptions);
      }
      setLoader(false);
    }).catch(error => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  const removeUser = (sts) => {
    setLoader(true);
    if (sts) {
      if (showModal.element._id === dataSession._id) {
        toast.warning("You can't delete the administrator you're logged", alertOptions);
      } else {
        deleteUser(showModal.element._id).then((res) => {
          if (res.status === 200) {
            getData();
            toast.success("User deleted successfully", alertOptions);
          } else {
            toast.warning(res.response.data.message, alertOptions);
          }
        }).catch(error => {
          toast.warning(error.response.data.message, alertOptions);
        });
      }
    }
    setShowModal({...showModal, status: false, element: null});
    setLoader(false);
  };

  const getOptions = (array_users, array_roles) => {
    let names = [];
    for (let i = 0; i < array_users.length; i++) {
      const nameFound = names.find(element => element.value === `${array_users[i].names} ${array_users[i].lastNames}`);

      if (!nameFound) {
        names.push({
          label: `${array_users[i].names} ${array_users[i].lastNames}`,
          value: `${array_users[i].names} ${array_users[i].lastNames}`
        });
      }
    }
    setNameOptions(names);

    if (array_roles.length > 0) {
      let roles = [];
      for (let j = 0; j < array_roles.length; j++) {
        roles.push({ label: array_roles[j]?.name, value: array_roles[j]._id });
      }
      setRolesOptions(roles);
    }
  };

  useEffect(() => {
    setLoader(true);
    getWarehouses().then(res => {
      if (res.status === 200 && res.data.length > 0) {
        const warehousesCurrent = res.data.filter(element => !element.deleted);
        setWarehouseOptions(warehousesCurrent);
      }
    });

    getUsers().then(res => {
      if (res.status && res.data.length > 0) {
        const adminsCurrent = res.data.filter(element => !element.deleted && element?.names !== "Super Admin");

        // Sort by name
        const array_sort = adminsCurrent.sort(function(a, b) {
          if (a.names.toUpperCase() < b.names.toUpperCase()) return -1;
          if (a.names.toUpperCase() > b.names.toUpperCase()) return 1;
          return 0;
        });

        setUsers(array_sort);
        setUsersUniv(array_sort);

        getRoles().then((resRoles) => {
          getOptions(array_sort, resRoles.data);
        });

        setLoader(false);
      } else {
        getRoles().then((resRoles) => {
          getOptions([], resRoles.data);
        });

        setLoader(false);
      }
    }).catch(() => {
      getRoles().then((resRoles) => {
        getOptions([], resRoles.data);
        setLoader(false);
      });
    });
  }, []);

  return (
    <>
      { loader && <Loader /> }

      <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
        <div className="text-center">
          <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">
            Administrators
          </h1>
        </div>

        <div className="pt-3">
          <Filters
            enableFilters={permits['ADMINISTRATORS']['FILTER']}
            enableAdd={permits['ADMINISTRATORS']['ADD']}
            enableRoles={permits['ADMINISTRATORS']['ROLE']}
            warehouseOptions={warehouseOptions}
            namesOptions={nameOptions}
            roleOptions={rolesOptions}
            onFilters={handleFilters}
            onAdd={() => {
              setAdminData(null);
              setShowForm(true);
            }}
            clearFilters={clearFilters}
          />
        </div>

        <div className="pt-8 md:pt-0">
          <TableAdmin
            enableDelete={permits['ADMINISTRATORS']['DELETE']}
            arrAdmins={users}
            onDelete={(userElement) => {
              setShowModal({...showModal, status: true, element: userElement});
            }}
            onEdit={(adminInfo) => {
              setAdminData(adminInfo);
              setShowForm(true);
            }}
          />
        </div>
      </div>

      { showModal.status &&
        <Modal
          title="¿Are you sure you want to delete the user?"
          onClose={() => setShowModal({...showModal, status: false, element: null})}
          onResponse={removeUser}
        />
      }

      { showForm &&
        <FormAdmin
          adminInfo={adminData}
          warehouseOptions={warehouseOptions}
          rolOptions={rolesOptions}
          onSave={addUser}
          onEdit={editUser}
          onClose={() => {
            setShowForm(false);
            setAdminData(null);
          }}
        />
      }
    </>
  );
};

export default Administrators;