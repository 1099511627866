import { useEffect, useState } from "react";
import { getAllFeaturesWC } from "../../utils/Utilities";

const Features = ({ element, color, fontSize, seeTitle }) => {
    const customStyle = {
        color: color,
        fontSize: fontSize
    };
    
    const [features, setFeatures] = useState([]);

    useEffect(() => {
        if (element !== null) {
            const featuresAux = getAllFeaturesWC();
            let distinctFeatures = [];

            for (let i = 0; i < featuresAux.length; i++) {
                const regex = new RegExp(featuresAux[i].value.toLowerCase(), 'i')
                if (
                    (element.compatible_vehicles.some(item => regex.test(item.ad_info_a.toLowerCase())) === true) ||
                    (String(element.features).toLowerCase().includes(String(featuresAux[i].value).toLowerCase()) === true)
                ) {
                    const foundFeatures = distinctFeatures.find(distFeatElem => distFeatElem === featuresAux[i].value);
                    if (!foundFeatures) distinctFeatures.push(featuresAux[i].value);
                }
            }
            if (element.compatible_vehicles !== undefined) {
                if (element.compatible_vehicles.some(item => item.antena === "Y")) {
                    const foundFeatures = distinctFeatures.find(feature => feature === "Antenna");
                    if (!foundFeatures) distinctFeatures.push("Antenna");
                }
                if (element.compatible_vehicles.some(item => item.encapsulated === "Y")) {
                    const foundFeatures = distinctFeatures.find(feature => feature === "Encapsulated");
                    if (!foundFeatures) distinctFeatures.push("Encapsulated");
                }
                if (element.compatible_vehicles.some(item => item.hud === "Y")) {
                    const foundFeatures = distinctFeatures.find(feature => feature === "HUD");
                    if (!foundFeatures) distinctFeatures.push("HUD");
                }
                if (element.compatible_vehicles.some(item => item.slider === "Y")) {
                    const foundFeatures = distinctFeatures.find(feature => feature === "Slider");
                    if (!foundFeatures) distinctFeatures.push("Slider");
                }
                if (element.compatible_vehicles.some(item => item.solar === "Y")) {
                    const foundFeatures = distinctFeatures.find(feature => feature === "Solar");
                    if (!foundFeatures) distinctFeatures.push("Solar");
                }
                if (element.compatible_vehicles.some(item => item.heated === "Y")) {
                    const foundFeatures = distinctFeatures.find(feature => feature === "Heated");
                    if (!foundFeatures) distinctFeatures.push("Heated");
                }
            } else if (element.compatible_vehicles === undefined) { 
                if (element.encapsulated === "Y") {
                    const foundFeatures = distinctFeatures.find(feature => feature === "Encapsulated");
                    if (!foundFeatures) distinctFeatures.push("Encapsulated");
                }
                if (element.hud === "Y") {
                    const foundFeatures = distinctFeatures.find(feature => feature === "HUD");
                    if (!foundFeatures) distinctFeatures.push("HUD");
                }
                if (element.slider === "Y") {
                    const foundFeatures = distinctFeatures.find(feature => feature === "Slider");
                    if (!foundFeatures) distinctFeatures.push("Slider");
                }
                if (element.solar === "Y") {
                    const foundFeatures = distinctFeatures.find(feature => feature === "Solar");
                    if (!foundFeatures) distinctFeatures.push("Solar");
                }
                if (element.solar === "Y") {
                    const foundFeatures = distinctFeatures.find(feature => feature === "Solar");
                    if (!foundFeatures) distinctFeatures.push("Solar");
                }
                if (element.heated === "Y") {
                    const foundFeatures = distinctFeatures.find(feature => feature === "Heated");
                    if (!foundFeatures) distinctFeatures.push("Heated");
                }
            }
            setFeatures(distinctFeatures);
        }
    }, [element]);

  return (
    <>
           <div>   
                <ul>
                   {
                        seeTitle ?
                        <p style={customStyle} className="inline font-semibold">Features: </p>
                        :
                    <></>
                   }
                    {
                        features.length > 0 ? features.map((elem, i) => (
                            <p style={customStyle} className="inline" key={i}>{elem}{i < features.length - 1 && ", "}</p>
                        )) : <p style={customStyle} className="italic inline">Features not found...</p>
                    }
                </ul>
            </div>
    </>
  )
}

export default Features