import { useEffect, useState } from "react";
import Pagination from "../../../components/pagination/Pagination";
import RowsHistory from "./RowsHistory";

const TableHistory = ({ product, movements }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(3);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = movements.slice(indexOfFirstRecord, indexOfLastRecord);
    
    useEffect(() => {
        setCurrentPage(1);
    }, [movements]);

    return (
        <div>
            <div className="overflow-x-auto">
                <table className="w-full text-center text-ag-secondary">
                    <thead>
                        <tr className="bg-ag-secondary-table">
                            <th className="w-[11%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-2 py-2 md:py-4">Product</th>
                            <th className="w-[17%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-2 py-2 md:py-4">Rack</th>
                            <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-2 py-2 md:py-4">Type</th>
                            <th className="w-[16%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-2 py-2 md:py-4">Warehouse</th>
                            <th className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-2 py-2 md:py-4">Qty changed</th>
                            <th className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-2 py-2 md:py-4">Qty in stock</th>
                            <th className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-2 py-2 md:py-4">Update date</th>
                            <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-2 py-2 md:py-4">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentRecords.map((element, i) => {
                                return (
                                    <RowsHistory
                                        key={i}
                                        nag={product.nags}
                                        movement={element}
                                    />
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="px-5 pt-5 pb-3 m-auto">
                <Pagination
                    currentPage={currentPage}
                    totalCount={movements.length}
                    pageSize={recordsPerPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
        </div>
    );
}

export default TableHistory;