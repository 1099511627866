
import { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";

const RowsAdmin = ({ indexUser, enableEdit, enableDelete, administrators, onEdit, onDelete }) => {
  const [warehousesAv, setWarehousesAv] = useState([]);

  useEffect(() => {
    if (administrators?.warehouse?.length > 0) {
      const warehousesAvFound = administrators?.warehouse?.filter(elem => !elem?.deleted);
      setWarehousesAv(warehousesAvFound);
    }
  }, [administrators]);

  return (
    <tr>
      <td className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">{ indexUser }</td>
      <td className="w-[18%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
        <p className="truncate w-52 m-auto">{ `${administrators.names} ${administrators.lastNames}` }</p>
      </td>
      <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
        <p className="truncate w-44 m-auto flex flex-col">
          {
            warehousesAv.length > 1 ? warehousesAv.map((elem, i) => {
              return (
                <span key={i}>
                  {elem.name}
                </span>
              );
            }) : warehousesAv.length === 1 ? warehousesAv[0]?.name : "---"
          }
        </p>
      </td>
      <td className="w-[18%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
        <p className="truncate w-52 m-auto">{ administrators.user.email }</p>
      </td>
      <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
        <p className="truncate w-28 m-auto">{ administrators.role?.name }</p>
      </td>
      <td className="w-[6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
        <button
          className="text-2xl disabled:bg-zinc-200"
          type="button"
          onClick={() => { onEdit(true); }}
          disabled={!enableEdit}
        >
          <MdEdit />
        </button>
      </td>
      <td className="w-[6%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-3">
        <button
          className="text-2xl disabled:bg-zinc-200"
          type="button"
          onClick={() => onDelete(true) }
          disabled={!enableDelete}
        >
          <MdDelete />
        </button>
      </td>
    </tr>
  );
};

export default RowsAdmin;