import { _delete, _get, _post, _put } from "./http";

export const getPaymentMethod = (idWholesaler, idPaymentMethod) => _get(`/wholesaleUser/${idWholesaler}/paymentMethods/${idPaymentMethod}`);

export const getPaymentMethods = (idWholesaler) => _get(`/wholesaleUser/${idWholesaler}/paymentMethods`);

export const savePaymentMethod = (idWholesaler, paymentMethodObject) => _post(`/wholesaleUser/${idWholesaler}/paymentMethods`, paymentMethodObject);

export const updatePaymentMethod = (idWholesaler, idPaymentMethod, paymentMethodObject) => _put(`/wholesaleUser/${idWholesaler}/paymentMethods/${idPaymentMethod}`, paymentMethodObject);

export const deletePaymentMethod = (idWholesaler, idPaymentMethod) => _delete(`/wholesaleUser/${idWholesaler}/paymentMethods/${idPaymentMethod}`);
