import React from 'react'

const MiddleDoorRight = ({ isSelected, isAvailable, onClick }) => {
  return (
    <svg className="cursor-pointer outline-none" onClick={onClick} data-tooltip-id="car" data-tooltip-content="Middle Door (right)">
      <path d="M270.328 285.086L303.128 285.086L303.128 242.086L270.328 242.086L270.328 285.086Z" fill={isSelected ? "#F41A28" :isAvailable ? "#1D8F43" : "#D3D3D4"}/>
    </svg>
  )
}

export default MiddleDoorRight