import { useContext, useEffect, useState } from "react";
import { deleteRetailUser, saveRetailUser, updateRetailUser } from "../../../services/Retail";
import { deleteWholesalerUser, saveWholesalerUser, updateWholesalerUser } from "../../../services/Wholesaler";
import { getAllUsers } from "../../../services/User";
import { alertOptions, formatReverseDate } from "../../../utils/Utilities";
import { PermitsContext } from "../../../services/Permits";
import { toast } from "react-toastify";
import Modal from "../../../components/modal/Modal";
import Filters from "./Filters";
import FormUser from "./FormUser";
import TableUser from "./TableUser";
import Pagination from "../../../components/pagination/Pagination";

const Users = () => {
  const { permits } = useContext(PermitsContext);

  const [showForm, setShowForm] = useState(false);
  const [showModal, setShowModal] = useState({ status: false, element: null });
  const [cleanFilters, setCleanFilters] = useState(false);
  const [userData, setUserData] = useState(null);
  const [users, setUsers] = useState([]);
  const [yearsOptions, setYearsOptions] = useState([]);
  const [typeOptions] = useState([
    { label: "Retail client", value: "RETAIL_USER" },
    { label: "Wholeseller", value: "WHOLESALER_USER" }
  ]);

  const [monthsOptions] = useState([
    { label: "January", value: 0 },
    { label: "February", value: 1 },
    { label: "March", value: 2 },
    { label: "April", value: 3 },
    { label: "May", value: 4 },
    { label: "June", value: 5 },
    { label: "July", value: 6 },
    { label: "August", value: 7 },
    { label: "September", value: 8 },
    { label: "October", value: 9 },
    { label: "November", value: 10 },
    { label: "December", value: 11 }
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(7);
  const [usersCount, setUsersCount] = useState(0);
  const [filters, setFilters] = useState({});
  const [loadingUsers, setLoadingUsers] = useState(false);

  const onPageChange = async (page) => {
    setLoadingUsers(true);
    setCurrentPage(page);
    const response = await getAllUsers({ limit: 7, page: page, ...filters });
    const users = response.data.result;
    const countUsers = response.data.count;
    setUsersCount(countUsers);
    setUsers(users);
    setLoadingUsers(false);
  };

  const searchUsers = async (params) => {
    setLoadingUsers(true);

    try {
      const response = await getAllUsers({ limit: 7, page: 1, ...params });
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      setLoadingUsers(false);
    }
  };

  const getData = async () => {
    const response = await searchUsers();
    const allUsers = response.result;
    const countUsers = response.count;

    setCleanFilters(true);
    setUsersCount(countUsers);
    setUsers(allUsers);
    getOptions(allUsers);
  };

  const handleFilters = async (wordFilter, value) => {
    const isEmptyFilter = Object.values({ wordFilter, ...value }).every(val => val === "");

    if (isEmptyFilter) {
      await getData();
      return;
    }

    if ((value.startDate && !value.endDate) || (!value.startDate && value.endDate)) {
      toast.info("Please enter both dates to perform the filter", alertOptions);
      return;
    }

    if (value.startDate && value.endDate && formatReverseDate(value.startDate) > formatReverseDate(value.endDate)) {
      toast.info("The start date is greater than the end date", alertOptions);
      return;
    }

    const filterObject = createFilterObject(value);
    setFilters(filterObject);
    const response = await searchUsers(filterObject);
    const allUsers = response.result;
    const countUsers = response.count;
    //const filteredUsers = filterUsers(allUsers, wordFilter, value);

    setUsersCount(countUsers);
    setUsers(allUsers);
  };

  const createFilterObject = (value) => {
    const filterObject = {};

    for (const key in value) {
      if (value[key]) {
        filterObject[key] = value[key];
      }
    }

    return filterObject;
  };

  const addUser = async (userObject, type) => {
    try {
      const saveUser = type === "wsu" ? saveWholesalerUser : saveRetailUser;
      const res = await saveUser(userObject);
      if (res.status === 200) {
        setShowForm(false);
        toast.success("User added successfully", alertOptions);
        await getData();
      } else {
        toast.warning(res.response.data.message, alertOptions);
      }
    } catch (error) {
      toast.warning(error.response.data.message, alertOptions);
    }
  };

  const editUser = async (id, userObject, type) => {
    try {
      const updateUser = type === "wsu" ? updateWholesalerUser : updateRetailUser;
      const res = await updateUser(id, userObject);
      if (res.status === 200) {
        setShowForm(false);
        toast.success("User updated successfully", alertOptions);
        await getData();
      } else {
        toast.warning(res.response.data.message, alertOptions);
      }
    } catch (error) {
      toast.warning(error.response.data.message, alertOptions);
    }
  };

  const removeUser = async (sts) => {
    if (sts) {
      try {
        const deleteUser = showModal.element.user.userType === "WHOLESALER_USER" ? deleteWholesalerUser : deleteRetailUser;
        const res = await deleteUser(showModal.element._id);
        if (res.status === 200) {
          toast.success("User deleted successfully", alertOptions);
          await getData();
        } else {
          toast.warning(res.response.data.message, alertOptions);
        }
      } catch (error) {
        toast.warning(error.response.data.message, alertOptions);
      }
    }
    setShowModal({ status: false, element: null });
  };

  const getOptions = (array_users) => {
    const array_years = array_users.reduce((acc, user) => {
      const userDate = user.user.createdAt;
      if (userDate) {
        const year = new Date(userDate).getFullYear();
        if (!acc.some(y => y.value === year)) {
          acc.push({ label: year, value: year });
        }
      }
      return acc;
    }, []);
    setYearsOptions(array_years);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="py-5 md:px-8 md:py-8">
        <div className="text-center">
          <h1 className="mb-[10px] text-[26px] md:text-xl lg:text-[26px] font-bold text-ag-secondary">Users</h1>
        </div>
        <div>
          <Filters
            enableFilters={permits['USERS']['FILTER']}
            typeOptions={typeOptions}
            yearsOptions={yearsOptions}
            monthsOptions={monthsOptions}
            isClean={cleanFilters}
            onClean={(valClean) => setCleanFilters(valClean)}
            onFilters={handleFilters}
          />
        </div>

        <div className="mt-[15px]">
          <TableUser
            loadingUsers={loadingUsers}
            enableApprove={permits['USERS']['APPROVE_DISPPROVE']}
            enableEdit={permits['USERS']['EDIT']}
            enableDelete={permits['USERS']['DELETE']}
            users={users}
            onDelete={(userElement) => {
              setShowModal({...showModal, status: true, element: userElement});
            }}
            onEdit={(userData) => {
              setUserData(userData);
              setShowForm(true);
            }}
            onStatus={(userInfo, sts) => {
              let newUserModel = {};
              if (userInfo.user.userType === "WHOLESALER_USER") {
                newUserModel = {
                  _id: userInfo._id,
                  names: userInfo.names,
                  lastNames: userInfo.lastNames,
                  email: userInfo.user.email,
                  phoneNumber: userInfo.phoneNumber,
                  zipCode: userInfo.zipCode,
                  workshopName: userInfo.workshopName,
                  invoice: userInfo.invoice,
                  invoiceFile: userInfo.invoiceFile,
                  approve: sts
                };
                editUser(userInfo._id, newUserModel, "wsu");
              } else {
                newUserModel = {
                  _id: userInfo._id,
                  names: userInfo.names,
                  lastNames: userInfo.lastNames,
                  email: userInfo.user.email,
                  phoneNumber: userInfo.phoneNumber,
                  approve: sts
                };
                editUser(userInfo._id, newUserModel, "ru");
              }
            }}
            onAdd={() => {
              setShowForm(true);
            }}
            enableAddRetail={permits['USERS']['ADD_RETAILER']}
            enableAddWholesaler={permits['USERS']['ADD_WOLESALER']}
          />

          <div className="p-5 m-auto">
            <Pagination
              currentPage={currentPage}
              totalCount={usersCount}
              pageSize={recordsPerPage}
              onPageChange={page => onPageChange(page)}
            />
          </div>
        </div>
      </div>

      {showModal.status && (
        <Modal
          title="¿Are you sure you want to delete the user?"
          onClose={() => {setShowModal({...showModal, status: false, element: null}); }}
          onResponse={removeUser}
        />
      )}

      {showForm && (
        <FormUser
          enableAddRetail={permits['USERS']['ADD_RETAILER']}
          enableAddWholesaler={permits['USERS']['ADD_WOLESALER']}
          userType={userData && userData.user.userType === "WHOLESALER_USER" ? "wholesaler" : "retailer"}
          userData={userData}
          onSave={addUser}
          onEdit={(id, user, type) => { editUser(id, user, type); }}
          onClose={() => {
            setShowForm(false);
            setUserData(null);
          }}
        />
      )}
    </>
  );
};

export default Users;
