import { useEffect, useState } from "react";
import Pagination from "../../../components/pagination/Pagination";
import RowsVendors from "./RowsVendors";

const TableVendor = ({ enableEdit, enableDelete, arrVendors, onDelete, onEdit }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(7);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = arrVendors.slice(indexOfFirstRecord, indexOfLastRecord);

    useEffect(() => {
        setCurrentPage(1);
    }, [arrVendors]);

    return (
        <div>
            <div className="overflow-auto min-h-[57vh] 2xl:min-h-[55vh]">
                <table className="w-full text-center text-ag-secondary">
                    <thead>
                        <tr className="bg-ag-secondary-table">
                            <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">Number</th>
                            <th className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-16 lg:px-2 py-3">Name</th>
                            <th className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-16 lg:px-2 px-2 py-3">Email</th>
                            <th className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-16 lg:px-2 px-2 py-3">Address</th>
                            <th className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-10 lg:px-2 px-2 py-3">Phone</th>
                            <th className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">Edit</th>
                            <th className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentRecords.map((element, i) => {
                                return (
                                    <RowsVendors
                                        key={i}
                                        indexVendor={i}
                                        enableEdit={enableEdit}
                                        enableDelete={enableDelete}
                                        vendors={element}
                                        onDelete={() => { onDelete(element) }}
                                        onEdit={() => { onEdit(element) }}
                                    />
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="p-5 m-auto">
                <Pagination
                    currentPage={currentPage}
                    totalCount={arrVendors.length}
                    pageSize={recordsPerPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
        </div>
    );
}

export default TableVendor;