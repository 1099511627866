import { useEffect, useState } from "react";
import Pagination from "../../../../components/pagination/Pagination";
import RowsGraphics from "./RowsGraphics";

const TableGraphics = ({ products, onPrint, onCompatible }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(3);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = products.slice(indexOfFirstRecord, indexOfLastRecord);

    useEffect(() => {
        if (onPrint) {
            setCurrentPage(1);
            setRecordsPerPage(products.length);
        } else {
            setCurrentPage(1);
            setRecordsPerPage(3);
        }
    }, [onPrint]);

    useEffect(() => {
        setCurrentPage(1);
    }, [products]);

    return (
        <div>
            <table className="w-full text-center text-ag-secondary">
                <thead>
                    <tr className="bg-ag-secondary-table">
                        <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-3 lg:px-2 py-3">Nags</th>
                        <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-3 lg:px-2 py-3">Name</th>
                        <th className="w-[18%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-3 lg:px-2 py-3">Compatible Vehicles</th>
                        <th className="w-[18%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-3 lg:px-2 py-3">Features</th>
                        <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-3 lg:px-2 py-3">Description</th>
                        <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-3 lg:px-2 py-3">Supplier</th>
                        <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-3 lg:px-2 py-3">Nags Price</th>
                        <th className="w-[8%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 md:px-3 lg:px-2 py-3">Quantity</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        currentRecords.map((element, i) => {
                            return (
                                <RowsGraphics key={i} graphic={element} onPrint={onPrint} onCompatible={onCompatible} />
                            )
                        })
                    }
                </tbody>
            </table>
            <div className="p-5 m-auto">
                <Pagination
                    currentPage={currentPage}
                    totalCount={products.length}
                    pageSize={recordsPerPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
        </div>
    );
}

export default TableGraphics;