import React from 'react'

const FrontDoorRight = ({ isSelected, isAvailable, onClick }) => {
  return (
    <svg className="cursor-pointer outline-none" onClick={onClick} data-tooltip-id="car" data-tooltip-content="Front Door (right)">
      <path d="M270.32 207.39V232.88H303.12V194.79L270.32 207.39Z" fill={isSelected ? "#F41A28" :isAvailable ? "#1D8F43" : "#D3D3D4"}/>
    </svg>
  )
}

export default FrontDoorRight