import { useState } from "react";

const Filters = ({ nameOptions, onShowForm, onFilters }) => {
    const [focusClass, setFocusClass] = useState({
        search: { placeholder: "Search", status: false },
        name: { placeholder: "Name", status: false }
    });

    const [options, setOptions] = useState({
        names: ""
    });

    const handleChange = (evt) => {
        if (evt.currentTarget.name === "search") {
            onFilters(evt.currentTarget.value, "filters");
        } else {
            if (evt.currentTarget.value === "default") {
                setOptions({...options, [evt.currentTarget.name]: ""});
            } else {
                setOptions({...options, [evt.currentTarget.name]: evt.currentTarget.value});
            }
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        onFilters(options, "options");
    }
    
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="grid gap-3 xl:gap-6 md:grid-cols-4">
                    <div className="md:col-span-3 space-y-3">
                        <div>
                            <input
                                className={`w-full text-ag-secondary-letter text-sm lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.search.status ? "text-left" : "text-center"}`}
                                type="text"
                                name="search"
                                placeholder={focusClass.search.placeholder}
                                onChange={handleChange}
                                onFocus={() => setFocusClass({...focusClass, search: { placeholder: "", status: true }})}
                                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, search: { placeholder: "Search", status: false }}) : null}
                                maxLength={60}
                            />
                        </div>
                        <div className="grid gap-3 xl:gap-6 md:grid-cols-4">
                            <div>
                                <select
                                    className={`w-full text-ag-secondary-letter text-xs lg:text-sm border border-gray-500 px-2 py-1 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.name.status ? "text-left" : "text-center"}`}
                                    name="name"
                                    defaultValue="default"
                                    onChange={handleChange}
                                    onFocus={() => setFocusClass({...focusClass, name: { placeholder: "", status: true }})}
                                    onBlur={(evt) => evt.target.value === "default" ? setFocusClass({...focusClass, name: { placeholder: "Name", status: false }}) : null}
                                >
                                    <option value="default">Name</option>
                                    {
                                        nameOptions.map((element, index) => {
                                            return <option key={index} value={element.label}>{element.label}</option>;
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                <button
                                    className="w-full text-center text-ag-secondary-letter text-xs lg:text-sm border border-gray-500 px-[7.53px] py-[3.5px] rounded-xl disabled:bg-zinc-200"
                                    type="submit"
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="space-y-3">
                        <div>
                            <button
                                className="w-full bg-ag-primary-light text-white text-sm lg:text-base p-2 rounded-xl disabled:bg-zinc-200"
                                type="button"
                                onClick={() => onShowForm(true)}
                            >
                                Create Insurer
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Filters;