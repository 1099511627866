import { formatCurrency, formatDateEng } from "../../../utils/Utilities";
import { IoMdCar } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import IconCash from "../../../assets/icons/icon_cash_black.svg";

const InformationQuote = ({ quote, onContinue, disabledBtn }) => {
    const navigate = useNavigate();
    
    return (
        <div className="w-full px-4 md:px-6 py-3 border border-ag-secondary-light rounded-xl">
            <div className="relative py-3 border-b border-ag-secondary-light">
                <span className="font-bold">Product Details</span>
            </div>
            <div className="relative py-3 border-b border-ag-secondary-light">
                <ul>
                    <li className="flex space-x-3">
                        <span className="font-bold">Make:</span>
                        <span>{ quote?.brand ? quote.brand : "Quote per photo..." }</span>
                    </li>
                    <li className="flex space-x-3">
                        <span className="font-bold">Year:</span>
                        <span>{ quote?.year ? quote.year : "Quote per photo..." }</span>
                    </li>
                    <li className="flex space-x-3">
                            <span className="font-bold">Total:</span>
                            <span>{ formatCurrency(quote?.price) }</span>
                        </li>
                    {
                        quote?.day ?
                            <li className="flex space-x-3 pt-5">
                                <span className="font-bold">Day:</span>
                                <span>{ quote?.day ? formatDateEng(quote.day) : "- - - -" }</span>
                            </li>
                        : null
                    }
                    {
                        quote?.hour ?
                            <li className="flex space-x-3">
                                <span className="font-bold">Hour:</span>
                                <span>{ quote?.hour ? quote.hour : "- - - -" }</span>
                            </li>
                        : null
                    }
                </ul>
            </div>
            {
                quote?.shippingMethod ?
                    <>
                        <div className="relative py-3">
                            <span className="font-bold">{ quote?.shippingMethod === "SHIP" ? "Mobile Service" : "In Shop" }</span>
                        </div>
                        <div className="pb-3 border-b border-ag-secondary-light">
                            <ul>
                                <li className="flex space-x-3">
                                    {
                                        quote?.shippingMethod === "SHIP" ?
                                            <p className="w-full truncate">
                                                { quote?.address?.street }, { quote?.address?.city }<br/>
                                                { quote?.address?.suburb }, { quote?.address?.postalCode }<br />
                                                { quote?.address?.references }
                                            </p>
                                        :
                                            <p className="w-full truncate">
                                                { quote?.address?.street },{' '}{ quote?.address?.locality },{' '}
                                                { quote?.address?.state } { quote?.address?.postalCode }
                                            </p>
                                    }
                                </li>
                            </ul>
                        </div>
                    </>
                : null
            }
            {
                quote?.paymentMethod ?
                    <>
                        <div className="relative py-3">
                            <span className="font-bold">Payment Details</span>
                        </div>
                        <div className="pb-3 border-b border-ag-secondary-light">
                            <ul>
                                <li className="flex space-x-3">
                                    {
                                        quote?.paymentMethod === "PAY_ON_MY_WON" ?
                                            <div className="flex items-center space-x-8">
                                                <img className="w-6 h-6" src={IconCash} />
                                                <span>Cash</span>
                                            </div>
                                        :
                                            <div className="flex items-center space-x-8">
                                                <span className="text-2xl"><IoMdCar /></span>
                                                <span>Car Insurance</span>
                                            </div>
                                    }
                                </li>
                            </ul>
                        </div>
                    </>
                : null
            }
            <div className="pt-6">
                <button disabled={disabledBtn} className="w-full bg-ag-primary-light px-3 py-2 text-white text-sm rounded-xl" type="button" onClick={() => onContinue(true)}>
                    Continue appointment
                </button>
            </div>
        </div>
    );
}

export default InformationQuote;