const { formatDate, formatCurrency } = require("./Utilities");

const headers = [
  { label: "Warehouse", key: "warehouse" },
  { label: "Rol", key: "role" },
  { label: "Name", key: "name" },
  { label: "Sale number", key: "saleNumber" },
  { label: "Order number", key: "orderNumber" },
  { label: "Date", key: "date" },
  { label: "Total", key: "total" },
  { label: "Quantity", key: "quantity" },
  { label: "Product", key: "product" }
];

const headersProducts = [
  { label: "Nags", key: "nags" },
  { label: "Name", key: "name" },
  { label: "Compatible Vehicles", key: "compatible_vehicles" },
  { label: "Description", key: "description" },
  { label: "Supplier", key: "supplier" },
  { label: "Price", key: "price" },
  { label: "Quantity", key: "quantity" },
  { label: "Month", key: "month" },
  { label: "Type", key: "type" }
];

const getRowsCSV = (rows, type) => {
  if (type === "order") {
    const newRows = rows.map(row => {
      let quantityAux = 0;
      let productsAux = "";

      for (let i = 0; i < row?.productsOrder.length; i++) {
        const element = row?.productsOrder[i];
        quantityAux += Number(element.quantity);

        if (i === 0) productsAux += `${element?.productInventory?.product?.nags}`;
        else productsAux += `, ${element?.productInventory?.product?.nags}`;
      }

      return {
        warehouse: row?.warehouse?.name,
        role: row?.employee ? row?.employee?.role?.name : "-----",
        name: row?.wholesalerUser ? row?.wholesalerUser?.names : row?.retailUser ? `${row?.retailUser?.names} ${row?.retailUser?.lastNames}` : "-----",
        saleNumber: row?.sale ? `SN-${String(row?.sale?._id).substring(String(row?.sale?._id).length - 5).toLocaleUpperCase()}` : "-----",
        orderNumber: `ON-${String(row?._id).substring(String(row?._id).length - 5).toLocaleUpperCase()}`,
        date: formatDate(row?.createdAt),
        total: formatCurrency(row?.total),
        quantity: quantityAux,
        product: productsAux
      };
    });

    return newRows;
  }
  const newRows = rows.map(row => {
    return {
      warehouse: row?.warehouse?.name,
      role: row?.employee ? row?.employee?.role?.name : "-----",
      name: row?.wholesaler ? row?.wholesaler?.names : "-----",
      saleNumber: row?.sale ? `SN-${String(row?.sale?._id).substring(String(row?.sale?._id).length - 5).toLocaleUpperCase()}` : "-----",
      orderNumber: `ON-${String(row?._id).substring(String(row?._id).length - 5).toLocaleUpperCase()}`,
      date: formatDate(row?.createdAt),
      total: formatCurrency(row?.total),
      quantity: row?.quantity,
      product: row?.productOrder?.productInventory?.product?.nags
    };
  });

  return newRows;

};

const getRowsGVCSV = (rows) => {
  let newRows = [];
  for (let i = 0; i < rows.length; i++) {
    for (let j = 0; j < rows[i]?.compatible_vehicles.length; j++) {
      const valCompVeh = rows[i]?.compatible_vehicles[j];
      const yearStr = String(valCompVeh?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(valCompVeh?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(valCompVeh?.year).replace(/\s+/g, '').split(',').sort()[Number(String(valCompVeh?.year).replace(/\s+/g, '').split(',').length-1)]}` : valCompVeh?.year;
      const comp_veh_str = `${ yearStr } ${ valCompVeh?.brand } ${ valCompVeh?.model } ${ valCompVeh?.type_car }`;
      newRows.push({
        nags: rows[i]?.nags,
        name: rows[i]?.name,
        compatible_vehicles: comp_veh_str,
        description: rows[i]?.description,
        supplier: "-----",
        price: formatCurrency(rows[i]?.price),
        quantity: rows[i]?.qty,
        month: rows[i]?.month,
        type: rows[i]?.indicator
      });
    }
  }
  return newRows;
};

const getDataChart = (data, params) => {
  let products = [];
  let monthsData = [];
  if (params.year && !params.month && (params.bestSeller || params.regularSale || params.minorSold)) {
    monthsData = [
      { month: "", quantity: 0 },
      { month: "", quantity: 0 },
      { month: "", quantity: 0 },
      { month: "", quantity: 0 },
      { month: params.year, quantity: 0 },
      { month: "", quantity: 0 },
      { month: "", quantity: 0 },
      { month: "", quantity: 0 },
      { month: "", quantity: 0 }
    ];
  } else {
    monthsData = [
      { month: "Jan", quantity: 0 },
      { month: "Feb", quantity: 0 },
      { month: "Mar", quantity: 0 },
      { month: "Apr", quantity: 0 },
      { month: "May", quantity: 0 },
      { month: "Jun", quantity: 0 },
      { month: "Jul", quantity: 0 },
      { month: "Aug", quantity: 0 },
      { month: "Sep", quantity: 0 },
      { month: "Oct", quantity: 0 },
      { month: "Nov", quantity: 0 },
      { month: "Dec", quantity: 0 }
    ];
  }

  for (let i = 0; i < data.length; i++) {
    const currentMonth = new Date(data[i].months.endDate).getMonth();
    let currentSales = 0;
    for (let j = 0; j < data[i].result.length; j++) {
      currentSales += data[i].result[j].totalOrders;
      products.push({...data[i].result[j].product,
        qty: data[i].result[j].quantitySold,
        indicator: data[i].result[j].salesIndicator ? data[i].result[j].salesIndicator : "",
        month: monthsData[currentMonth]?.month
      });
    }

    if (params.year && !params.month && (params.bestSeller || params.regularSale || params.minorSold)) {
      monthsData[4].quantity = currentSales;
    } else {
      monthsData[currentMonth].quantity = currentSales;
    }
  }

  const productsSort = products.sort((a, b) => b.nags.localeCompare(a.nags, undefined, { numeric: true, sensitivity: 'base'}));
  return { graphicData: monthsData, productsData: productsSort };
};

export {
  headers,
  headersProducts,
  getRowsCSV,
  getRowsGVCSV,
  getDataChart
};