import { useState } from "react";
import NavbarClients from "../../components/navbar/NavbarClients";
import SidebarClients from "../../components/sidebar/SidebarClients";
import SearchHome from "./search_result/SearchHome";
import PopUpDiscount from "../../components/discounts/PopUpDiscount";

const Wholesaler = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  return (
    <>
      <div>
        <div className="flex flex-row">
          <div className="w-full bg-no-repeat bg-cover fixed z-10">
            <NavbarClients type="wholesaler" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          </div>
        </div>
        <div className="flex h-screen overflow-hidden">
          <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
            <SidebarClients type="wholesaler" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          </div>
          <div className="w-full overflow-auto flex-grow mt-[65px] md:mt-[100px]">
            {/* <PopUpDiscount /> */}
            <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
              {/* <div className="text-center">
                                <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">
                                    Year, make, model and style search
                                </h1>
                            </div> */}
              <div className="pt-3">
                <SearchHome />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wholesaler;