import { AiFillStar } from "react-icons/ai";
import { FaHome } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";

const AdressesCard = ({ address, onEdit, onDelete, onSelected}) => {
    return (
        <div className="w-full flex justify-between border-y border-l border-ag-secondary-light rounded-xl m-auto">
            <div className="w-full flex justify-between items-center px-5 py-2">
                <div className="md:pr-3">
                    <span className="text-ag-secondary-letter text-4xl"><FaHome /></span>
                </div>
                <div>
                    <p className="w-44 md:w-48 lg:w-60 text-xs lg:text-sm text-ag-secondary-letter truncate m-auto">Street:  {`${address?.street}`}</p>
                    <p className="w-44 md:w-48 lg:w-60 text-xs lg:text-sm text-ag-secondary-letter truncate m-auto">City/Town:   {`${address?.city}`}</p>
                    <p className="w-44 md:w-48 lg:w-60 text-xs lg:text-sm text-ag-secondary-letter truncate m-auto">State/Province/Region:	{`${address?.suburb}`}</p>
                    <p className="w-44 md:w-48 lg:w-60 text-xs lg:text-sm text-ag-secondary-letter truncate m-auto">Zip/Postal Code:	{`${address?.postalCode}`}</p>
                </div>
                <div>
                    <span className={`text-4xl cursor-pointer ${ address?.selected ? "text-red-700" : "text-ag-secondary-light" }`} onClick={() => onSelected(address._id, !address?.selected)}>
                        <AiFillStar />
                    </span>
                </div>
            </div>
            <div className="bg-ag-secondary rounded-r-xl space-y-2 p-2 text-center m-auto">
                <div>
                    <button className="w-full" type="button" onClick={() => onDelete(address?._id)}>
                        <span className="text-3xl text-white"><MdDelete /></span>
                    </button>
                </div>
                <div>
                    <button className="w-full" type="button" onClick={() => onEdit(address) }>
                        <span className="text-3xl text-white"><MdEdit /></span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AdressesCard;