import { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { getProductEspecificationsBrands, getProductEspecificationsModel, getProductEspecificationsTypec, getProductEspecificationsTypeg, getProductEspecificationsYears } from "../../../../services/Product";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";

const SearchFilters = ({ defaultParams, onFilters }) => {
    const [filters, setFilters] = useState({
        brand: "",
        model: "",
        year: "",
        style: "",
        type: ""
    });

    const [yearOpts, setYearOpts] = useState([]);
    const [makeOpts, setMakeOpts] = useState([]);
    const [modelOpts, setModelOpts] = useState([]);
    const [styleOpts, setStyleOpts] = useState([]);
    const [typeOpts, setTypeOpts] = useState([]);

    const [typexOptions] = useState([
        { show: false, selected: false, value: "Windshield" },
        { show: false, selected: false, value: "Door / Front / Left" },
        { show: false, selected: false, value: "Vent / Front / Left" },
        { show: false, selected: false, value: "Door / Rear / Left" },
        { show: false, selected: false, value: "Vent / Rear / Left" },
        { show: false, selected: false, value: "Quarter / Left" },
        { show: false, selected: false, value: "Door / Front / Right" },
        { show: false, selected: false, value: "Vent / Front / Right" },
        { show: false, selected: false, value: "Door / Rear / Right" },
        { show: false, selected: false, value: "Vent / Rear / Right" },
        { show: false, selected: false, value: "Quarter / Right" },
        { show: false, selected: false, value: "Back Window" }
    ]);

    const getYears = () => {
        setMakeOpts([]);
        setModelOpts([]);
        setStyleOpts([]);
        setTypeOpts([]);
        getProductEspecificationsYears().then(res => {
            if (res.status === 200) setYearOpts(res.data);
        });
    }

    const getBrands = (yearParam) => {
        setModelOpts([]);
        setStyleOpts([]);
        setTypeOpts([]);
        getProductEspecificationsBrands(yearParam).then(res => {
            if (res.status === 200) setMakeOpts(res.data);
        });
    }

    const getModels = (yearParam, brandParam) => {
        setStyleOpts([]);
        setTypeOpts([]);
        getProductEspecificationsModel(yearParam, brandParam).then(res => {
            if (res.status === 200) setModelOpts(res.data);
        });
    }

    const getTypec = (yearParam, brandParam, modelParam) => {
        setTypeOpts([]);
        getProductEspecificationsTypec(yearParam, brandParam, modelParam).then(res => {
            if (res.status === 200) setStyleOpts(res.data);
        });
    }

    const getTypeg = (yearParam, brandParam, modelParam, typecParam) => {
        getProductEspecificationsTypeg(yearParam, brandParam, modelParam, typecParam).then(res => {
            if (res.status === 200) {
                const arrTypeGlass = res.data;
                const newTypeXOptions = typexOptions.map(element => {
                    const sonExist = arrTypeGlass.find(elemSon => elemSon.type_glass === element.value);
                    if (sonExist) return {...element, show: true }
                    return element;
                });
                setTypeOpts(newTypeXOptions);
            }
        });
    }

    const handleChange = (evt) => {
        if (evt.target.name === "year") {
            setFilters({...filters, year: evt.target.value, brand: "", model: "", style: "", type: ""});
            getBrands(evt.target.value);
        } else if (evt.target.name === "brand") {
            setFilters({...filters, brand: evt.target.value, model: "", style: "", type: ""});
            getModels(filters.year, evt.target.value);
        } else if (evt.target.name === "model") {
            setFilters({...filters, model: evt.target.value, style: "", type: ""});
            getTypec(filters.year, filters.brand, evt.target.value);
        } else if (evt.target.name === "style") {
            setFilters({...filters, style: evt.target.value, type: ""});
            getTypeg(filters.year, filters.brand, filters.model, evt.target.value);
        } else {
            setFilters({...filters, [evt.target.name]: evt.target.value});
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (filters.year !== "" && filters.brand !== "" && filters.model !== "" && filters.style !== "" && filters.type !== "") {
            onFilters(filters);
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    useEffect(() => {
        if (defaultParams !== null) {
            getYears();
            getBrands(defaultParams.year);
            getModels(defaultParams.year, defaultParams.brand);
            getTypec(defaultParams.year, defaultParams.brand, defaultParams.model);
            getTypeg(defaultParams.year, defaultParams.brand, defaultParams.model, defaultParams.style);
            
            setFilters({...filters,
                year: defaultParams.year,
                brand: defaultParams.brand,
                model: defaultParams.model,
                style: defaultParams.style,
                type: defaultParams.type
            });
        } else {
            getYears();
        }
    }, [defaultParams]);

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="md:flex md:justify-between md:items-center md:space-x-5 space-y-3 md:space-y-0">
                    <div className="w-full">
                        <select
                            className="w-full p-[9px] text-center text-ag-secondary-letter border border-ag-secondary rounded-xl"
                            name="year"
                            onChange={handleChange}
                            value={filters.year}
                        >
                            <option value="">Year</option>
                            {
                                yearOpts.map((element, index) => {
                                    return <option key={index} value={element.year}>{element.year}</option>;
                                })
                            }
                        </select>
                    </div>
                    <div className="w-full">
                        <select
                            className="w-full p-[9px] text-center text-ag-secondary-letter border border-ag-secondary rounded-xl"
                            name="brand"
                            onChange={handleChange}
                            value={filters.brand}
                        >
                            <option value="">Make</option>
                            {
                                makeOpts.map((element, index) => {
                                    return <option key={index} value={element.brand}>{element.brand}</option>;
                                })
                            }
                        </select>
                    </div>
                    <div className="w-full">
                        <select
                            className="w-full p-[9px] text-center text-ag-secondary-letter border border-ag-secondary rounded-xl"
                            name="model"
                            onChange={handleChange}
                            value={filters.model}
                        >
                            <option value="">Model</option>
                            {
                                modelOpts.map((element, index) => {
                                    return <option key={index} value={element.model}>{element.model}</option>;
                                })
                            }
                        </select>
                    </div>
                    <div className="w-full">
                        <select
                            className="w-full p-[9px] text-center text-ag-secondary-letter border border-ag-secondary rounded-xl"
                            name="style"
                            onChange={handleChange}
                            value={filters.style}
                        >
                            <option value="">Style</option>
                            {
                                styleOpts.map((element, index) => {
                                    return <option key={index} value={element.type_car}>{element.type_car}</option>;
                                })
                            }
                        </select>
                    </div>
                    <div className="w-full">
                        <select
                            className="w-full p-[9px] text-center text-ag-secondary-letter border border-ag-secondary rounded-xl"
                            name="type"
                            onChange={handleChange}
                            value={filters.type}
                        >
                            <option value="">Type of glass</option>
                            {
                                typeOpts.map((element, index) => {
                                    return <option key={index} value={element.value}>{element.value === "Back Window" ? "Backglass" : element.value === "Quarter / Left" ? "Quarter / Rear / Left" : element.value === "Quarter / Right" ? "Quarter / Rear / Right" :  element.value }</option>;
                                })
                            }
                        </select>
                    </div>
                    <div>
                        <button className="w-full flex justify-center px-5 py-2 bg-ag-primary text-white text-xs lg:text-base rounded-xl" type="submit">
                            <span className="text-[18px] lg:text-[25px]"><BiSearch /></span>
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default SearchFilters;