import { useContext, useEffect, useState } from "react";
import { PermitsContext } from "../../../services/Permits";
import { savePaymentMethod, deletePaymentMethod, getPaymentMethods, updatePaymentMethod } from "../../../services/WholesalerPaymentMethod";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { Link } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import Loader from "../../../components/loader/Loader";
import NavbarClients from "../../../components/navbar/NavbarClients";
import SidebarClients from "../../../components/sidebar/SidebarClients";
import Modal from "../../../components/modal/Modal";
import MethodsForm from "../../fragments/paymentMethod/MethodsForm";
import MethodsList from "../../fragments/paymentMethod/MethodsList";

const Payment = () => {
  const { dataSession } = useContext(PermitsContext);
  const [loader, setLoader] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [showForm, setShowForm] = useState({ status: false, element: null});
  const [showModal, setShowModal] = useState({ status: false, type: "", title: "", element: null });
  const [payments, setPayments] = useState([]);

  const getData = () => {
    getPaymentMethods(dataSession._id).then(res => {
      if (res.status === 200) setPayments(res.data);
      setLoader(false);
    }).catch(() => setLoader(false));
  };

  const handleSelected = (idPayment, sts) => {
    setLoader(true);
    if (sts) {
      const paymentToFalse = payments.find(element => element.selected);
      if (paymentToFalse) {
        updatePaymentMethod(dataSession._id, paymentToFalse._id, {...paymentToFalse, selected: false}).then(() => {});
      }
    }

    const paymentFound = payments.find(element => element._id === idPayment);
    if (paymentFound) handleEditPayment({...paymentFound, selected: sts});
  };

  const handleAddPayment = (paymentModel) => {
    const foundCard = payments.filter(element => element?.cardNumber === paymentModel?.cardNumber);
    if (foundCard.length === 0) {
      savePaymentMethod(dataSession._id, paymentModel).then(res => {
        if (res.status === 200) {
          setShowForm({...showForm, status: false, element: null});
          toast.success("Payment method added successfully", alertOptions);
          getData();
        } else {
          toast.warning(res.response.data.message, alertOptions);
        }
        setLoader(false);
      }).catch(error => {
        toast.warning(error.response.data.message, alertOptions);
        setLoader(false);
      });
    } else {
      setLoader(false);
      toast.warning("The entered card number is already in the system", alertOptions);
    }
  };

  const handleEditPayment = (paymentModel) => {
    const foundCard = payments.filter(element => (element?._id !== paymentModel?._id && element?.cardNumber === paymentModel?.cardNumber));
    if (foundCard.length === 0) {
      updatePaymentMethod(dataSession._id, paymentModel._id, paymentModel).then(res => {
        if (res.status === 200) {
          setShowForm({...showForm, status: false, element: null});
          toast.success("Payment method updated successfully", alertOptions);
          getData();
        } else {
          toast.warning(res.response.data.message, alertOptions);
        }
        setLoader(false);
      }).catch(error => {
        toast.warning(error.response.data.message, alertOptions);
        setLoader(false);
      });
    } else {
      setLoader(false);
      toast.warning("The entered card number is already in the system", alertOptions);
    }
  };

  const handleDelete = () => {
    setLoader(true);
    deletePaymentMethod(dataSession._id, showModal.element).then(res => {
      if (res.status === 200) {
        getData();
        setShowModal({...showModal, status: false, type: "", title: "", element: null});
        toast.success("Payment method deleted successfully", alertOptions);
      } else {
        setLoader(false);
        toast.warning(res.response.data.message, alertOptions);
      }
    }).catch(error => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  useEffect(() => {
    setLoader(true);
    getData();
  }, [dataSession]);

  return (
    <>
      { loader ? <Loader /> : null }

      {
        showForm.status ?
          <MethodsForm
            methodData={showForm.element}
            onClose={() => setShowForm({...showForm, status: false, element: null})}
            onLoader={(val) => setLoader(val)}
            onAdd={handleAddPayment}
            onEdit={handleEditPayment}
          />
          : null
      }

      { showModal.status ?
        <Modal
          title={showModal.title}
          onClose={() => setShowModal({...showModal, status: false, type: "", title: "", element: null})}
          onResponse={handleDelete}
        />
        :
        null
      }

      <div>
        <div className="flex flex-row">
          <div className="w-full bg-no-repeat bg-cover fixed z-10">
            <NavbarClients type="wholesaler" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          </div>
        </div>
        <div className="flex h-screen overflow-hidden">
          <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
            <SidebarClients type="wholesaler" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          </div>
          <div className="w-full overflow-auto flex-grow mt-[100px]">
            <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
              <div className="grid grid-cols-12 px-0 md:px-5">
                <div>
                  <Link to="/wholesaler/profile">
                    <button className="p-1 rounded-md" type="button">
                      <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
                    </button>
                  </Link>
                </div>
                <div className="flex justify-center items-center col-span-11 text-center">
                  <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary mr-[8%]">Payment methods</h1>
                </div>
              </div>
              <div className="pt-10 md:pt-20">
                <MethodsList
                  methods={payments}
                  onShow={() => setShowForm({...showForm, status: true, element: null})}
                  onEdit={(paymentModel) => setShowForm({...showForm, status: true, element: paymentModel})}
                  onDelete={(idPayment) => setShowModal({...showModal,
                    status: true,
                    type: "delete",
                    title: "Are you sure you want to delete the method payment?",
                    element: idPayment
                  })}
                  onSelected={handleSelected}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;