import { _delete, _get, _post, _put } from "./http";

export const getProductCart = (id, idCart) => _get(`/wholesaleUser/${id}/mycart/${idCart}`);

export const getProductsCart = (idWholesaler) => _get(`/wholesaleUser/${idWholesaler}/mycart`);

export const saveProductCart = (idWholesaler, myCartObject) => _post(`/wholesaleUser/${idWholesaler}/mycart`, myCartObject);

export const updateProductCart = (idWholesaler, idCart, myCartObject) => _put(`/wholesaleUser/${idWholesaler}/mycart/${idCart}`, myCartObject);

export const deleteProductCart = (idWholesaler, idCart) => _delete(`/wholesaleUser/${idWholesaler}/mycart/${idCart}`);

export const emptyProductCart = (idWholesaler) => _delete(`/wholesaleUser/${idWholesaler}/mycart/emptyCart`);
