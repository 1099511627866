import { useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { savePaymentMethod as savePaymentMethodWU, getPaymentMethods } from "../../services/WholesalerPaymentMethod";
import { alertOptions } from "../../utils/Utilities";
import { validateCreditCard } from "../../utils/Validators";

const NewPaymentMethod = ({ idUser, typeUser, onClose, onLoader, onResult }) => {
    const [paymentMethod, setPaymentMethod] = useState({
        cardType: "",
        cardNumber: "",
        nameofOwner: "",
        validity: "",
        cvv: ""
    });

    const handleChange = (evt) => {
        if ((evt.target.name === "cardNumber" || evt.target.name === "validity" || evt.target.name === "cvv") && isNaN(evt.nativeEvent.data)) {
            return 0;
        }

        if (evt.target.name === "validity") {
            if (evt.target.defaultValue !== "" && String(evt.target.value).length === 2 && evt.nativeEvent.data !== null) {
                setPaymentMethod({...paymentMethod, validity: `${String(evt.target.value)}/` });
            } else if (String(evt.target.value).length === 5) {
                setPaymentMethod({...paymentMethod,
                    validity:`${String(evt.target.value).replace("/", "").substring(0,2)}/${String(evt.target.value).substring(3,5)}`
                });
            } else if (String(evt.target.value).replace("/", "").length < 2) {
                setPaymentMethod({...paymentMethod, validity: `${String(evt.target.value).replace("/", "")}` });
            } else if (String(evt.target.value).replace("/", "").length === 4) {
                setPaymentMethod({...paymentMethod,
                    validity:`${String(evt.target.value).replace("/", "").substring(0,2)}/${String(evt.target.value).substring(3,5)}`
                });
            } else {
                setPaymentMethod({...paymentMethod, validity: evt.target.value });
            }
        } else {
            setPaymentMethod({...paymentMethod, [evt.target.name]: evt.target.value});
        }
    }

    const creditCardFormat = (value) => {
        const v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "").substr(0, 16);
        const parts = [];
      
        for (let i = 0; i < v.length; i += 4) parts.push(v.substr(i, 4));
        return parts.length > 1 ? parts.join(" ") : value;
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        if (paymentMethod.cardType !== "" && paymentMethod.cardNumber !== "" && paymentMethod.nameofOwner !== "" && paymentMethod.validity !== "" && paymentMethod.cvv !== "") {
            onLoader(true);
            let newModel = paymentMethod;
            newModel.cardNumber = String(paymentMethod.cardNumber).replace(/\s+/g, '');
            const result = validateCreditCard(newModel);
            if (result.status) {
                const dateValidity = new Date(`${String(paymentMethod.validity).substring(0,2)}-01-${String(paymentMethod.validity).substring(3,5)}`);
                if (new Date(`${new Date().getMonth()+1}-01-${new Date().getFullYear()}`) <= dateValidity) {
                    
                    const resPMU = await getPaymentMethods(idUser);
                    const arrPMU = (resPMU.status === 200 ? resPMU.data : []).filter(element => element?.cardNumber === paymentMethod?.cardNumber);
                    if (arrPMU.length === 0) {
                        await savePaymentMethodWU(idUser, newModel).then(res => {
                            if (res.status === 200) {
                                onResult(true);
                            } else {
                                toast.warning(res.response.data.message, alertOptions);
                                onLoader(false);
                            }
                        }).catch(error => {
                            toast.warning(error.response.data.message, alertOptions);
                            onLoader(false);
                        });
                    } else {
                        onLoader(false);
                        toast.warning("The entered card number is already in the system", alertOptions);
                    }
                } else {
                    toast.warning("The card validation date cannot be less than the current date", alertOptions);
                    onLoader(false);
                }
            } else {
                onLoader(false);
                toast.warning(result.msg, alertOptions);
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[600px]">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="text-xl text-ag-secondary font-bold ml-[5%]">
                                        New payment method
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="px-2 md:px-5 pb-0 pt-5">
                                <form className="space-y-3" onSubmit={handleSubmit}>
                                    <div>
                                        <input
                                            className="w-full text-center text-ag-secondary-letter text-sm border border-gray-500 p-2 rounded-xl"
                                            type="text"
                                            name="cardNumber"
                                            placeholder="Card Number"
                                            maxLength={19}
                                            onChange={handleChange}
                                            value={creditCardFormat(paymentMethod.cardNumber)}
                                        />
                                    </div>
                                    <div>
                                        <input
                                            className="w-full text-center text-ag-secondary-letter text-sm border border-gray-500 p-2 rounded-xl"
                                            type="text"
                                            name="nameofOwner"
                                            placeholder="Name of owner"
                                            onChange={handleChange}
                                            value={paymentMethod.nameofOwner}
                                        />
                                    </div>
                                    <div className="grid gap-x-3 md:gap-x-6 gap-y-2 md:grid-cols-3">
                                        <div>
                                            <input
                                                className="w-full text-center text-ag-secondary-letter text-sm border border-gray-500 p-2 rounded-xl"
                                                type="text"
                                                name="validity"
                                                placeholder="Validity"
                                                maxLength={5}
                                                onChange={handleChange}
                                                value={paymentMethod.validity}
                                            />
                                        </div>
                                        <div>
                                            <input
                                                className="w-full text-center text-ag-secondary-letter text-sm border border-gray-500 p-2 rounded-xl"
                                                type="text"
                                                name="cvv"
                                                placeholder="CVV"
                                                maxLength={4}
                                                onChange={handleChange}
                                                value={paymentMethod.cvv}
                                            />
                                        </div>
                                        <div>
                                            <select
                                                className="w-full text-center text-ag-secondary-letter text-sm border border-gray-500 px-2 py-[9px] rounded-xl truncate"
                                                name="cardType"
                                                onChange={handleChange}
                                                defaultValue="default"
                                                value={paymentMethod.cardType}
                                            >
                                                <option value="default">Type</option>
                                                <option value="CREDIT">Credit</option>
                                                <option value="DEBIT">Debit</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="w-full flex justify-center">
                                        <button
                                            className="w-full md:w-[40%] bg-ag-primary-light text-center text-sm text-white p-2 rounded-xl disabled:bg-zinc-200 m-auto"
                                            type="submit"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
        </>
    );
}

export default NewPaymentMethod;