import { MdDelete, MdEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../../utils/Utilities";
import IconPDF from "../../../assets/icons/pdf-icon.png";

const UserIdentifier = ({ identifier }) => (
  <td className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
    {identifier || "-"}
  </td>
);

const UserName = ({ userId, name }) => (
  <td className="w-[17%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
    <Link to={`/users/history/${userId}`}>
      <p className="truncate w-44 m-auto">{name}</p>
    </Link>
  </td>
);

const UserEmail = ({ email }) => (
  <td className="w-[17%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
    <p className="truncate w-44 m-auto">{email}</p>
  </td>
);

const UserInvoice = ({ userType, invoiceFile }) => (
  <td className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
    {userType === "WHOLESALER_USER" ? (
      <a href={invoiceFile} target="_blank" rel="noopener noreferrer">
        <img className="w-[35px] m-auto" src={IconPDF} alt="Invoice" />
      </a>
    ) : "NA"}
  </td>
);

const UserType = ({ userType }) => (
  <td className="w-[11%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
    {userType === "WHOLESALER_USER" ? "Wholeseller" : "Retail client"}
  </td>
);

const UserPhone = ({ phoneNumber }) => (
  <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
    {phoneNumber}
  </td>
);

const UserCredit = ({ userType, availableCredit }) => (
  <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
    {userType === "WHOLESALER_USER" ? formatCurrency(availableCredit) : "NA"}
  </td>
);

const UserTotalPurchased = ({ totalPurchased }) => (
  <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
    {formatCurrency(totalPurchased)}
  </td>
);

const EditButton = ({ onEdit, enableEdit }) => (
  <td className="w-[5%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-3">
    <button className="text-3xl disabled:bg-zinc-200" type="button" onClick={() => onEdit(true)} disabled={!enableEdit}>
      <MdEdit />
    </button>
  </td>
);

const DeleteButton = ({ onDelete, enableDelete }) => (
  <td className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
    <button className="text-3xl disabled:bg-zinc-200" type="button" onClick={() => onDelete(true)} disabled={!enableDelete}>
      <MdDelete />
    </button>
  </td>
);

const ApproveToggle = ({ userType, approve, onStatus, enableApprove }) => (
  <td className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-3">
    {userType === "WHOLESALER_USER" ? (
      <label className={`${!enableApprove ? 'opacity-50' : '' } inline-flex relative items-center cursor-pointer`}>
        <input
          className="sr-only peer disabled:bg-zinc-200"
          type="checkbox"
          checked={approve}
          onChange={onStatus}
          disabled={!enableApprove}
        />
        <div className="w-10 h-5 bg-ag-secondary rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-ag-active z-[-1]"></div>
      </label>
    ) : "NA"}
  </td>
);

const RowsUsers = ({ enableApprove, enableEdit, enableDelete, userData, onEdit, onDelete, onStatus }) => {
  return (
    <tr>
      <UserIdentifier identifier={userData?.user?.identifier} />
      <UserName userId={userData?._id} name={`${userData?.names} ${userData?.lastNames || ""}`} />
      <UserEmail email={userData?.user?.email} />
      <UserInvoice userType={userData?.user?.userType} invoiceFile={userData?.invoiceFile} />
      <UserType userType={userData?.user?.userType} />
      <UserPhone phoneNumber={userData?.phoneNumber} />
      <UserCredit userType={userData?.user?.userType} availableCredit={userData?.availableCredit} />
      <UserTotalPurchased totalPurchased={userData?.totalPurchased} />
      <EditButton onEdit={onEdit} enableEdit={enableEdit} />
      <DeleteButton onDelete={onDelete} enableDelete={enableDelete} />
      <ApproveToggle userType={userData?.user?.userType} approve={userData?.approve} onStatus={onStatus} enableApprove={enableApprove} />
    </tr>
  );
};

export default RowsUsers;
