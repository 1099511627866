import { useEffect, useState } from "react";
import { formatCurrency } from "../../../utils/Utilities";
import { IoMdCar } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { RiBankCard2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import IconCash from "../../../assets/icons/icon_cash_black.svg";
import IconVisa from "../../../assets/images/visa_image.png";
import IconMasterCard from "../../../assets/images/master_card_icon.png";
import IconAmericanExpress from "../../../assets/images/american_express_icon.png";
import IconDiscover from "../../../assets/images/discover_icon.png";

const creditCards = [null, null, null, IconAmericanExpress, IconVisa, IconMasterCard, IconDiscover];

const MyCartInformation = ({ cart, onContinue }) => {
    const navigate = useNavigate();
    const [values, setValues] = useState({
        subtotal: 0,
        pickup: 0,
        discount: 0,
        total: 0
    });

    useEffect(() => {
        if (cart && cart.length > 0) {
            let valSubtotal = 0;
            let valPickup = 0;
            for (let i = 0; i < cart.length; i++) {
                valPickup += Number(cart[i]?.costShipping ? cart[i]?.costShipping : 0);
                for (let j = 0; j < cart[i].products.length; j++) {
                    valSubtotal += Number(cart[i].products[j].quantity * cart[i].products[j].price);
                }
            }
            setValues({...values,
                subtotal: valSubtotal,
                pickup: valPickup,
                discount: 0,
                total: Number(valSubtotal) + Number(valPickup)
            });
        }
    }, [cart])

    return (
        <div className="w-full px-4 md:px-6 py-3 border border-ag-secondary-light rounded-xl">
            <div className="relative py-3 border-b border-ag-secondary-light">
                <span className="font-bold">Order Summary</span>
            </div>
            <div className="relative py-3 border-b border-ag-secondary-light">
                <ul>
                    <li className="flex justify-between">
                        <div className="flex justify-center space-x-3">
                            <span className="font-bold">Subtotal:</span>
                            <span>{ formatCurrency(values.subtotal) }</span>
                        </div>
                        <span
                            className="absolute right-0 text-2xl cursor-pointer"
                            onClick={() => navigate(`/wholesaler/mycart/shipping`, { state: cart })}
                        >
                            <MdEdit />
                        </span>
                    </li>
                    <li className="flex space-x-3">
                        <span className="font-bold">Delivery:</span>
                        <span>{ formatCurrency(values.pickup) }</span>
                    </li>
                    <li className="flex space-x-3">
                        <span className="font-bold">Discount:</span>
                        <span>{ formatCurrency(values.discount) }</span>
                    </li>
                    <li className="flex space-x-3 pt-5">
                        <span className="font-bold">Total:</span>
                        <span>{ formatCurrency(values.total) }</span>
                    </li>
                </ul>
            </div>
            {
                cart && cart.length > 0 && cart[0]?.shippingMethod ?
                    <>
                        <div className="relative py-3">
                            <span className="font-bold">Shipping method - { cart[0]?.shippingMethod === "SHIP" ? "Delivery" : "Will Call" }</span>
                        </div>
                        <div className="pb-3 border-b border-ag-secondary-light">
                            <ul>
                                { cart[0]?.shippingMethod === "SHIP" ?
                                    <li className="flex space-x-3">
                                        <p className="w-full truncate">
                                            { cart[0]?.address?.street }, { cart[0]?.address?.city }<br/>
                                            { cart[0]?.address?.suburb }, { cart[0]?.address?.postalCode }<br />
                                            { cart[0]?.address?.references }
                                        </p>
                                    </li>
                                :
                                    <li>
                                        { cart.map((cartElem, ice) => (
                                            <p key={ice} className="w-full truncate">
                                                { cartElem?.address?.street },{' '}{ cartElem?.address?.locality },{' '}
                                                { cartElem?.address?.state } { cartElem?.address?.postalCode }
                                            </p>
                                        ))}
                                    </li>
                                }
                            </ul>
                        </div>
                    </>
                : null
            }
            {
                cart && cart.length > 0 && cart[0]?.paymentMethod ?
                    <>
                        <div className="relative py-3">
                            <span className="font-bold">Payment details</span>
                        </div>
                        <div className="pb-3 border-b border-ag-secondary-light">
                            <ul>
                                <li className="flex space-x-3">
                                    {
                                        cart[0]?.paymentMethod === "CREDIT_CARD" ?
                                            cart[0]?.creditCard ?
                                                <div className="w-full relative flex justify-start items-center space-x-3 md:space-x-5 mr-0 md:mr-14">
                                                    <div className="w-8 md:w-14 h-6 md:h-10 flex justify-center items-center border border-ag-secondary-light rounded-lg p-1 md:p-2">
                                                        <img className="w-full h-full" src={cart[0]?.creditCard.number ? creditCards[String(cart[0]?.creditCard.number).substring(0, 1)] : null} />
                                                    </div>
                                                    <div className="text-xs">
                                                        <ul>
                                                            <li>Credit card</li>
                                                            <li>**** **** **** { String(cart[0]?.creditCard.number).substring(12, 16) }</li>
                                                            <li><p className="w-72 md:w-72 lg:w-32 xl:w-52 truncate">{ cart[0]?.creditCard.name }</p></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            :
                                                <div className="flex items-center space-x-8">
                                                    <span className="text-2xl"><RiBankCard2Line /></span>
                                                    <span>Credit Card</span>
                                                </div>
                                        : cart[0]?.paymentMethod === "CASH" ?
                                            <div className="flex items-center space-x-8">
                                                <img className="w-6 h-6" src={IconCash} />
                                                <span>Cash</span>
                                            </div>
                                        :
                                            <div className="flex items-center space-x-8">
                                                <span className="text-2xl"><IoMdCar /></span>
                                                <span>In Store Credit</span>
                                            </div>
                                    }
                                </li>
                            </ul>
                        </div>
                    </>
                : null
            }
            <div className="pt-6">
                <button className="w-full bg-ag-primary-light px-3 py-2 text-white text-sm rounded-xl" type="button" onClick={() => onContinue(true)}>
                    { cart?.paymentMethod && cart?.shippingMethod ? "Finish with the payment" : "Continue with the payment" }
                </button>
            </div>
        </div>
    );
}

export default MyCartInformation;