import React, { useState, useEffect, useRef } from 'react';
import { BiChevronUp, BiChevronDown } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

const Dropdown = ({ options, enableActions, enableFilters }) => {
  const [openDropDown, setOpenDropDown] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setOpenDropDown(false);
      }
  };

  useEffect(() => {
      if (openDropDown) {
          document.addEventListener('mousedown', handleClickOutside);
      } else {
          document.removeEventListener('mousedown', handleClickOutside);
      }

      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, [openDropDown]);

  return (
      <div className="lg:relative" ref={dropdownRef}>
          <button
              className="w-full bg-ag-primary-light flex justify-center items-center text-white text-xs lg:text-base p-2 rounded-xl disabled:bg-zinc-200"
              type="button"
              onClick={() => setOpenDropDown(!openDropDown)}
              disabled={!enableActions}
          >
              <span>Actions</span>
              <span className="text-lg">
                  {openDropDown ? <BiChevronUp /> : <BiChevronDown />}
              </span>
          </button>

          {openDropDown && (
              <div className="w-full lg:absolute bg-[#939191] mt-[0.5px] text-center border border-[#FF5F52] z-10 rounded-b-xl">
                  <ul className="py-2">
                      {options.map((option, index) => (
                          <li
                              key={index}
                              className={`p-3 text-white text-base md:text-xs lg:text-base ${!enableFilters ? 'opacity-50' : ''} cursor-pointer hover:bg-zinc-500`}
                              onClick={() => {
                                  if (enableActions) {
                                      navigate(option.path);
                                      setOpenDropDown(false); // Close dropdown on option click
                                  }
                              }}
                          >
                              {option.label}
                          </li>
                      ))}
                  </ul>
              </div>
          )}
      </div>
  );
};

export default Dropdown;
