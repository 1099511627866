import { useContext, useEffect, useState } from "react";
import { deleteWarehouse, deleteWarehouseRack, getWarehouse, getWarehouses, saveWarehouse, saveWarehouseRack, updateWarehouse, updateWarehouseRack, updateWarehouseRack_V2 } from "../../../services/Warehouse";
import { alertOptions, removeAccents } from "../../../utils/Utilities";
import { PermitsContext } from "../../../services/Permits";
import { toast } from "react-toastify";
import Filters from "./Filters";
import FormWarehouse from "./FormWarehouse";
import TableWarehouse from "./TableWarehouse";
import FormRack from "./FormRack";
import FormProducts from "./FormProducts";
import Modal from "../../../components/modal/Modal";
import Loader from "../../../components/loader/Loader";
import ModalCompatible from "../products/ModalCompatible";

const Warehouse = () => {
  const { dataSession, permits, resetPermits } = useContext(PermitsContext);

  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState({ status: false, element: null });
  const [showForm, setShowForm] = useState({ status: false, element: null });
  const [showFormRack, setShowFormRack] = useState({ status: false, element: null });
  const [showDelete, setShowDelete] = useState({ status: false, type: "", title: "", element: null});
  const [showFormProducts, setShowFormProducts] = useState({ status: false, element: null });
  const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
  const [warehousesOptions, setWarehousesOptions] = useState([]);
  const [racksOptions, setRacksOptions] = useState([]);
  const [currentWarehouse, setCurrentWarehouse] = useState(null);
  const [currentRacks, setCurrentRacks] = useState([]);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);

  const handleFilters = (value, option) => {
    if (option === "filters") {
      let palabra = new RegExp(`${removeAccents(value)}.*`, "i");
      const warehouseFound = racksOptions.filter(element => {
        // Busqueda en productos
        let productsFound = [];
        if (element?.productsRack) {
          productsFound = element?.productsRack.filter(elementAux => {
            const foundVehicles = elementAux?.productInventory?.product?.compatible_vehicles.filter(elemFound => (
              palabra.test(elemFound?.nag) ||
                            palabra.test(elemFound?.year) ||
                            palabra.test(removeAccents(elemFound?.model)) ||
                            palabra.test(removeAccents(elemFound?.brand)) ||
                            palabra.test(removeAccents(elemFound?.type_car)) ||
                            palabra.test(removeAccents(elemFound?.type_glass)) ||
                            palabra.test(removeAccents(
                              `${elemFound?.nag} - ` +
                                `${String(elemFound?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(elemFound?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(elemFound?.year).replace(/\s+/g, '').split(',').sort()[Number(String(elemFound?.year).replace(/\s+/g, '').split(',').length-1)]}` : elemFound?.year}` +
                                ` ${elemFound?.brand} ${elemFound?.model} ${elemFound?.type_car}`
                            ))
            ));

            if (foundVehicles.length > 0) {
              return elementAux;
            }
          });
        }

        if (palabra.test(`${removeAccents(element.name)}`) || productsFound.length > 0) return element;
      });
      setCurrentRacks(warehouseFound);
    } else {
      if (value.warehouse === "") {
        setCurrentWarehouse(null);
        setCurrentRacks([]);
      } else {
        const warehouseFound = warehousesOptions.find(element => element.value === value.warehouse);
        getRacks(warehouseFound.value);
        setCurrentWarehouse(warehouseFound.info);
      }
    }
  };

  const getOptions = (array_warehouses) => {
    let warehouses = [];
    for (let i = 0; i < array_warehouses.length; i++) {
      const warehousesFound = warehouses.find(element => element.value === array_warehouses[i]._id);

      if (!warehousesFound) {
        warehouses.push({ label: array_warehouses[i].name, value: array_warehouses[i]._id, info: array_warehouses[i] });
      }
    }
    setWarehousesOptions(warehouses);
  };

  const getDataWarehouse = () => {
    getWarehouses().then(res => {
      if (res.status === 200 && res.data.length > 0) {
        if (dataSession.userType === "ADMIN") {
          const warehousesCurrent = res.data.filter(element => !element.deleted);
          getOptions(warehousesCurrent);
        } else {
          const warehousesCurrent = res.data.filter(element => {
            const foundWH = dataSession.allWarehouse.find(elem => element?._id === elem._id);
            if (!element?.deleted && foundWH) return element;
          });
          getOptions(warehousesCurrent);
        }
        setLoader(false);
      } else {
        getOptions([]);
        setLoader(false);
      }
    });
  };

  const handleAddWarehouse = (warehouseObject) => {
    setLoader(true);
    saveWarehouse(warehouseObject).then(res => {
      if (res.status === 200) {
        getDataWarehouse();
        setShowForm({...showForm, status: false, element: null});
        toast.success("Warehouse added successfully", alertOptions);
      } else {
        setLoader(false);
        toast.warning(res.response.data.message, alertOptions);
      }
    }).catch(error => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  const handleEditWarehouse = (warehouseID, warehouseObject) => {
    setLoader(true);
    updateWarehouse(warehouseID, warehouseObject).then(res => {
      if (res.status === 200) {
        setCurrentWarehouse(res.data);
        getDataWarehouse();
        setShowForm({...showForm, status: false, element: null});
        toast.success("Warehouse updated successfully", alertOptions);
      } else {
        setLoader(false);
        toast.warning(res.response.data.message, alertOptions);
      }
    }).catch(error => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  const handleDeleteWarehouse = () => {
    setLoader(true);
    setShowDelete({...showDelete, status: false, type: "", title: "", element: null});
    deleteWarehouse(currentWarehouse._id).then(res => {
      if (res.status === 200) {
        getDataWarehouse();
        setCurrentWarehouse(null);
        setShowForm({...showForm, status: false, element: null});
        toast.success("Warehouse deleted successfully", alertOptions);
      } else {
        setLoader(false);
        toast.warning(res.response.data.message, alertOptions);
      }
    }).catch(error => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  const getModal = (productElement, type) => {
    if (type !== "delete") {
      setShowFormProducts({...showFormProducts, status: true, element: productElement});
    } else {
      setShowDelete({...showDelete,
        status: true,
        type: "product",
        title: "¿Are you sure you want to delete the product?",
        element: productElement
      });
    }
  };

  const handleModal = () => {
    if (showDelete.type === "warehouse") {
      handleDeleteWarehouse();
    } else if (showDelete.type === "rack") {
      handleDeleteRack(showDelete.element);
    } else {
      handleDeleteProduct();
    }
  };

  const getRacks = (idWarehouse) => {
    getWarehouse(idWarehouse).then(res => {
      if (res.status === 200 && res.data.racks.length > 0) {
        setCurrentRacks(res.data.racks);
        setRacksOptions(res.data.racks);
      } else {
        setCurrentRacks([]);
        setRacksOptions([]);
      }
    });
  };

  const handleAddRack = (rackObject) => {
    setLoader(true);
    saveWarehouseRack(currentWarehouse._id, rackObject).then(res => {
      if (res.status === 200) {
        getDataWarehouse();
        getRacks(currentWarehouse._id);
        setShowFormRack({...showFormRack, status: false, element: null});
        setProducts([]);
        toast.success("Rack added successfully", alertOptions);
      } else {
        setLoader(false);
        toast.warning(res.response.data.message, alertOptions);
      }
    }).catch(error => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  const handleEditRack = async (rackID, rackObject, productID, quantity) => {
    setLoader(true);

    let response;
    try {
      if (productID) {
        const params = {
          productID,
          quantity,
          validateMaxMin: false,
        };
        response = await updateWarehouseRack_V2(currentWarehouse._id, rackID, params);
      } else {
        const params = { ...rackObject, validateMaxMin: false };
        response = await updateWarehouseRack(currentWarehouse._id, rackID, params);
      }

      if (response.status === 200) {
        getDataWarehouse();
        getRacks(currentWarehouse._id);
        setShowFormRack({ ...showFormRack, status: false, element: null });
        setProducts([]);
        toast.success("Rack updated successfully", alertOptions);
      } else {
        setLoader(false);
        toast.warning(response.response.data.message, alertOptions);
      }
    } catch (error) {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    }
  };

  const handleDeleteRack = (idRack) => {
    setLoader(true);
    setShowDelete({...showDelete, status: false, type: "", title: "", element: null});
    deleteWarehouseRack(currentWarehouse._id, idRack).then(res => {
      if (res.status === 200) {
        getDataWarehouse();
        getRacks(currentWarehouse._id);
        setShowFormRack({...showFormRack, status: false, element: null});
        setProducts([]);
        setPage(1);
        toast.success("Rack deleted successfully", alertOptions);
      } else {
        setLoader(false);
        toast.warning(res.response.data.message, alertOptions);
      }
    }).catch(error => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  const handleProducts = (resp, type) => {
    if (showFormRack.element === null && showFormProducts.element !== null && type === true) {
      const foundRack = currentRacks.find(element => element.productsRack.find(elemAux => elemAux._id === resp._idRack));
      const foundProducts = foundRack?.productsRack.map(element => {
        if (element.productInventory._id === resp._id) return {...element, quantity: Number(resp.quantity)};
        return element;
      });
      const bodyRack = { _id: foundRack?._id, name: foundRack?.name, productsRack: foundProducts };
      handleEditRack(foundRack?._id, bodyRack, resp._id, resp.quantity);
    } else {
      if (resp._idRack === "") {
        const foundProduct = products.find(element => element?.productInventory?._id === resp._id);
        if (foundProduct) {
          toast.warning("The product you selected is already in the list", alertOptions);
          return 0;
        }

        const newProducts = products.map(element => element);
        newProducts.push(resp);
        setProducts(newProducts);
      } else {
        const newProducts = products.map(element => {
          if (element._id === resp._idRack) return {...element, quantity: Number(resp.quantity)};
          return element;
        });
        setProducts(newProducts);
      }
    }
    setShowFormProducts({...showFormProducts, status: false, element: null});
  };

  const handleDeleteProduct = () => {
    const newProducts = products.filter(element => element._id !== showDelete.element._id);
    setProducts(newProducts);
    setShowDelete({...showDelete, status: false, type: "", title: "", element: null});
  };

  const handleDeleteProductByTable = () => {
    const foundRack = currentRacks.find(element => element._id === showModal.element.idRack);
    const foundProducts = foundRack.productsRack.filter(element => element._id !== showModal.element.idProd);
    const bodyRack = { _id: foundRack._id, name: foundRack.name, productsRack: foundProducts };
    setShowModal({...showModal, status: false, element: null});
    handleEditRack(foundRack._id, bodyRack);
  };

  useEffect(() => {
    setLoader(true);
    getDataWarehouse();
  }, [dataSession]);

  return (
    <>
      <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
        <div className="text-center">
          <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Warehouse</h1>
        </div>
        <div className="pt-3">
          <Filters
            enableFilters={permits['WAREHOUSE']['FILTER']}
            enableAdd={permits['WAREHOUSE']['ADD']}
            warehouseOptions={warehousesOptions}
            onFilters={handleFilters}
            onClose={() => setShowForm({...showForm, status: true, element: null})}
          />
        </div>
        <div>
          {
            currentWarehouse ?
              <TableWarehouse
                enableCreateR={permits['WAREHOUSE']['CREATE_RACK']}
                enableEditR={permits['WAREHOUSE']['EDIT_RACK']}
                // enableDeleteR={permits['WAREHOUSE']['DELET_RACK']}
                enableDeleteR={permits['WAREHOUSE']['DELETE_RACK']}
                warehouse={currentWarehouse}
                racks={currentRacks}
                currntPg={page}
                onPage={(pg) => setPage(pg)}
                onRack={() => {
                  setShowFormRack({...showFormRack, status: true, element: null});
                  setProducts([]);
                }}
                onEditRack={(rckElem) => {
                  setShowFormRack({...showFormRack, status: true, element: rckElem});
                  setProducts(rckElem.productsRack);
                }}
                onEditWarehouse={() => setShowForm({...showForm, status: true, element: currentWarehouse})}
                onDeleteProduct={(idRack, idProd) => setShowModal({...showModal, status: true, element: { idRack: idRack, idProd: idProd }})}
                onEditProduct={(productElement) => setShowFormProducts({...showFormProducts, status: true, element: productElement})}
                onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
              />
              : null
          }
        </div>
      </div>

      { loader ? <Loader /> : null }

      { showModal.status ?
        <Modal
          title="¿Are you sure you want to delete the product?"
          onClose={() => setShowModal({...showModal, status: false, element: null})}
          onResponse={handleDeleteProductByTable}
        />
        :
        null
      }

      { showDelete.status ?
        <Modal
          title={showDelete.title}
          onClose={() => setShowDelete({...showDelete, status: false, type: "", title: "", element: null})}
          onResponse={handleModal}
        />
        :
        null
      }

      { showForm.status ?
        <FormWarehouse
          enableEdit={permits['WAREHOUSE']['EDIT']}
          enableRemove={permits['WAREHOUSE']['REMOVE']}
          warehouseInfo={showForm.element}
          onSave={handleAddWarehouse}
          onEdit={handleEditWarehouse}
          onDelete={() => {
            setShowDelete({...showDelete, status: true, type: "warehouse", title: "¿Are you sure you want to delete the warehouse?", element: null});
            setShowForm({...showForm, status: false, element: null});
          }}
          onClose={() => setShowForm({...showForm, status: false, element: null})}
        />
        :
        null
      }

      { showFormRack.status ?
        <FormRack
          enableEditR={permits['WAREHOUSE']['EDIT_RACK']}
          // enableDeleteR={permits['WAREHOUSE']['DELET_RACK']}
          enableDeleteR={permits['WAREHOUSE']['DELETE_RACK']}
          rackInfo={showFormRack.element}
          products={products}
          onSave={handleAddRack}
          onEdit={handleEditRack}
          onDelete={(idRack) => {
            setShowDelete({...showDelete, status: true, type: "rack", title: "¿Are you sure you want to delete the rack?", element: idRack});
            setShowFormRack({...showFormRack, status: false, element: null});
          }}
          onModal={getModal}
          onClose={() => {
            setShowFormRack({...showFormRack, status: false, element: null});
            setProducts([]);
          }}
          onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
        />
        :
        null
      }

      { showFormProducts.status ?
        <FormProducts
          idWarehouse={currentWarehouse ? currentWarehouse._id : ""}
          nameWarehouse={currentWarehouse ? currentWarehouse.name : ""}
          productSelected={showFormProducts.element}
          productsCurrent={products}
          onLoader={(sts) => setLoader(sts)}
          onProduct={(prd, type) => handleProducts(prd, type)}
          onClose={() => setShowFormProducts({...showFormProducts, status: false, element: null})}
          onCompatible={(prdSel) => setShowCompatible({...showCompatible, status: true, element: prdSel})}
        />
        :
        null
      }

      {
        showCompatible.status ?
          <ModalCompatible
            products={showCompatible.element}
            onClose={() => setShowCompatible({...showCompatible, status: false, element: null})}
          />
          : null
      }
    </>
  );
};

export default Warehouse;