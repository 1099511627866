import { AiFillStar } from "react-icons/ai";
import { MdDelete, MdEdit } from "react-icons/md";
import IconVisa from "../../../assets/images/visa_image.png";
import IconMasterCard from "../../../assets/images/master_card_icon.png";
import IconAmericanExpress from "../../../assets/images/american_express_icon.png";
import IconDiscover from "../../../assets/images/discover_icon.png";

const creditCards = [null, null, null, IconAmericanExpress, IconVisa, IconMasterCard, IconDiscover];

const MethodsCard = ({ method, onEdit, onDelete, onSelected }) => {
    return (
        <div className="w-full flex justify-between border border-ag-secondary-light rounded-xl m-auto">
            <div className="w-full flex justify-between items-center px-5 py-2">
                <div className="w-8 md:w-14 h-6 md:h-10 flex justify-center items-center border border-ag-secondary-light text-ag-secondary-letter rounded-lg p-1 md:p-2">
                    <img className="w-auto h-auto" src={method.cardNumber ? creditCards[String(method.cardNumber).substring(0, 1)] : null} />
                </div>
                <div className="text-ag-secondary-letter">
                    <ul>
                        <li className="text-center text-base md:text-xl font-light"><p className="w-56 truncate m-auto">{ method.nameofOwner }</p></li>
                        <li className="text-center text-sm md:text-lg">**** **** **** { String(method.cardNumber).substring(12, 16) }</li>
                    </ul>
                </div>
                <div>
                    <span className={`text-4xl cursor-pointer ${ method?.selected ? "text-red-700" : "text-ag-secondary-light" }`} onClick={() => onSelected(method._id, !method?.selected)}>
                        <AiFillStar />
                    </span>
                </div>
            </div>
            <div className="bg-ag-secondary rounded-r-xl space-y-2 p-2 text-center m-auto">
                <div>
                    <button className="w-full" type="button" onClick={() => onDelete(method?._id)}>
                        <span className="text-3xl text-white"><MdDelete /></span>
                    </button>
                </div>
                <div>
                    <button className="w-full" type="button" onClick={() => onEdit(method) }>
                        <span className="text-3xl text-white"><MdEdit /></span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default MethodsCard;