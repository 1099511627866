import { RiErrorWarningLine } from "react-icons/ri";
import MethodsCard from "./MethodsCard";

const MethodsList = ({ methods, onShow, onEdit, onDelete, onSelected }) => {
    return (
        <>
            <div className="space-y-3">
                {
                    methods.length > 0 ?
                        methods.map((element, i) => (
                            <div className="w-full md:w-[60%] xl:w-[35%] m-auto" key={i}>
                                <MethodsCard method={element} onEdit={onEdit} onDelete={onDelete} onSelected={onSelected} />
                            </div>
                        ))
                    :
                        <div className="w-full flex justify-center items-center space-x-3 pb-[5%] text-center text-ag-secondary-letter">
                            <div>
                                <span className="flex justify-center pb-3 text-2xl"><RiErrorWarningLine /></span>
                                <span className="text-xs italic lg:text-base">
                                    You currently do not have any methods payment in the system...
                                </span>
                            </div>
                        </div>
                }
                <div className="w-full md:w-[60%] xl:w-[35%] m-auto">
                    <button className="w-full bg-ag-primary-light text-white text-xs lg:text-base p-3 rounded-xl" type="button" onClick={() => onShow(true)}>
                        Add new payment method
                    </button>
                </div>
            </div>
        </>
    );
}

export default MethodsList;