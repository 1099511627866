import { IoMdImage } from "react-icons/io";
import { useEffect, useState } from "react";
import { getAllFeaturesWC } from "../../../utils/Utilities";

const WishListCard = ({ element, onAttend, onCompatible, onRequestedBy }) => {
    const [features, setFeatures] = useState([]);
    const renderCompatibleVehicles = () => {
        return element.compatible_vehicles.slice(0, 2).map((prodComp, i) => {
            const years = String(prodComp?.year).replace(/\s+/g, '').split(',').sort();
            const yearRange = years.length > 1 ? `${years[0]} - ${years[years.length - 1]}` : prodComp?.year;
            const vehicleInfo = `${yearRange} ${prodComp?.brand} ${prodComp?.model} ${prodComp?.type_car}`;
            
            return (
                <p 
                    className="text-ag-secondary-letter text-sm truncate whitespace-pre-wrap" 
                    key={i} 
                    title={vehicleInfo}
                >
                    {vehicleInfo}
                </p>
            );
        });
    };


    const renderProductInfo = () => {
        return element.compatible_vehicles.slice(0, 1).map((prodComp, i) => {
            const years = String(prodComp?.year).replace(/\s+/g, '').split(',').sort();
            const yearRange = years.length > 1 ? `${years[0]} - ${years[years.length - 1]}` : prodComp?.year;
            const vehicleInfo = `${yearRange} ${prodComp?.brand} ${prodComp?.model} ${prodComp?.type_car}`;
            
            return (
                <p 
                    className="text-ag-secondary-letter text-sm truncate whitespace-pre-wrap" 
                    key={i} 
                    title={vehicleInfo}
                >
                    {vehicleInfo}
                </p>
            );
        });
    };

    const renderProductDescription = () => {
        return element.compatible_vehicles.slice(0, 1).map((prodComp, i) => {
            const color =  prodComp.color
            const characteristics =  `${'('} ${prodComp.height} ${'x'} ${prodComp.width} ${')'}`
            const vehicleInfo = `${color} ${characteristics}`;
            
            return (
                <p 
                    className="text-ag-secondary-letter text-sm truncate whitespace-pre-wrap" 
                    key={i} 
                    title={vehicleInfo}
                >
                    {vehicleInfo}
                </p>
            );
        });
    };
    
    useEffect(() => {
        if (element !== null) {
            const featuresAux = getAllFeaturesWC();
            let distinctFeatures = [];

            for (let i = 0; i < featuresAux.length; i++) {
                const regex = new RegExp(featuresAux[i].value.toLowerCase(), 'i')
                if (
                    (element.compatible_vehicles.some(item => regex.test(item.ad_info_a.toLowerCase())) === true) ||
                    (String(element.features).toLowerCase().includes(String(featuresAux[i].value).toLowerCase()) === true)
                ) {
                    const foundFeatures = distinctFeatures.find(distFeatElem => distFeatElem === featuresAux[i].value);
                    if (!foundFeatures) distinctFeatures.push(featuresAux[i].value);
                }
            }
            if (element.compatible_vehicles.some(item => item.antena === "Y")) {
                const foundFeatures = distinctFeatures.find(feature => feature === "Antenna");
                if (!foundFeatures) distinctFeatures.push("Antenna");
            }
            if (element.compatible_vehicles.some(item => item.encapsulated === "Y")) {
                const foundFeatures = distinctFeatures.find(feature => feature === "Encapsulated");
                if (!foundFeatures) distinctFeatures.push("Encapsulated");
            }
            if (element.compatible_vehicles.some(item => item.hud === "Y")) {
                const foundFeatures = distinctFeatures.find(feature => feature === "HUD");
                if (!foundFeatures) distinctFeatures.push("HUD");
            }
            if (element.compatible_vehicles.some(item => item.slider === "Y")) {
                const foundFeatures = distinctFeatures.find(feature => feature === "Slider");
                if (!foundFeatures) distinctFeatures.push("Slider");
            }
            if (element.compatible_vehicles.some(item => item.solar === "Y")) {
                const foundFeatures = distinctFeatures.find(feature => feature === "Solar");
                if (!foundFeatures) distinctFeatures.push("Solar");
            }
            if (element.compatible_vehicles.some(item => item.heated === "Y")) {
                const foundFeatures = distinctFeatures.find(feature => feature === "Heated");
                if (!foundFeatures) distinctFeatures.push("Heated");
            }
            setFeatures(distinctFeatures);
        }
    }, [element]);

    return (
          <div className="px-5 py-2 border border-zinc-400 rounded-xl">
            <div className="flex-wrap md:flex-nowrap flex justify-between items-center space-x-3">
                <div className="md:w-[100%] md:flex md:justify-between">
                    <div className="h-[auto] h-md:[250px] w-[100%] md:w-[45%] ">
                       <div className="flex mb-3">
                            <IoMdImage className="text-ag-secondary-letter text-[50px]"/>
                            <div className="ml-4">
                                <p className="text-ag-secondary-letter text-sm">{element.nag}</p>
                                { renderProductInfo() }
                            </div>
                       </div>
                       <div className="mb-3">
                            <p className="text-ag-secondary-letter text-sm font-semibold">Description</p>
                            { renderProductDescription() }
                       </div>
                       <div>
                            <p className="text-ag-secondary-letter text-sm font-semibold">Features</p>
                            <ul>
                                {
                                    features.length > 0 ? features.map((elem, i) => (
                                        <p className="text-ag-secondary-letter text-sm inline" key={i}>{ elem}   {i < features.length - 1 && ", "}</p>
                                    )) : <p className="text-ag-secondary-letter text-sm italic">Features not found...</p>
                                }
                        </ul>
                       </div>
                    </div>

                    <div className="h-[auto] h-md:[250px] w-[100%] md:w-[45%]">
                        <div className="mb-8">
                            <p className="text-ag-secondary-letter text-sm font-semibold">Compatible vehicles</p>
                            { renderCompatibleVehicles() }
                            {element.compatible_vehicles.length > 2 && (
                                <p className="truncate text-ag-secondary-letter font-bold cursor-pointer" 
                                onClick={() => onCompatible(element)}>
                                    See compatible vehicles +
                                </p>
                            )}
                       </div>
                       <div className="mb-3">
                            <p className="text-ag-secondary-letter text-sm font-semibold">Requested by: </p>
                            <p className="text-ag-secondary-letter text-sm">
                            {
                                element.customers.slice(0, 1).map((item, index) => (
                                <p key={index}>{item.names + ' ' + item.lastNames}</p>
                            ))
                            }
                            </p>
                            {
                                element.customers.length > 1 ?
                                <p className="truncate text-ag-secondary-letter font-bold cursor-pointer" 
                                onClick={() => onRequestedBy(element)}>
                                    See more wholesaler +
                                </p>
                                :
                                <></>
                            }
                       </div>
                    </div>

                    <div className="h-[auto] h-md:[250px] w-[100%] md:w-[10%] flex justify-center items-center">
                       <div>
                            <button 
                                className="px-5 py-2 bg-green-600 text-white text-xs rounded-xl" 
                                type="button" 
                                onClick={() => onAttend(element.nag)}
                            >
                                Attend
                            </button>

                            <div className="pt-2 flex space-x-3 mt-3">
                                <p className="text-ag-secondary-letter text-xs font-semibold inline">Times:</p>
                                <p className="text-ag-secondary-letter text-xs inline">{element.count}</p>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WishListCard;
