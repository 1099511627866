import { useEffect, useState } from "react";
import { formatCurrency } from "../../../../../utils/Utilities";
import RowsReceivedProducts from "./RowsReceivedProducts";

const TableReceivedProducts = ({ products, totalExpectedQuantity, totalRealQuantity, totalDiscrepancy, subTotal, total, onProduct, onOrganize, onlyRead, onCompatible }) => {
  const [data, setData] = useState([]);
  const [readView, setReadView] = useState(false);

  useEffect(() => {
    setData(products);
  }, [products]);

  useEffect(() => {
    setReadView(onlyRead);
  }, [onlyRead]);

  return (
    <div>
      <table className="w-full text-center text-ag-secondary">
        <thead>
          <tr className="bg-gray-300">
            <th className="w-[40%] bg-black text-white border-[0.5px] border-white text-xs font-normal px-20 lg:px-8 xl:px-2 py-2">Products</th>
            <th className="w-[8%] bg-black text-white border-[0.5px] border-white text-xs font-normal px-20 lg:px-8 xl:px-8 py-2">Incoming rack</th>
            <th className="w-[8%] bg-black text-white border-[0.5px] border-white text-xs font-normal px-10 lg:px-8 xl:px-2 py-2">Expected Quantity</th>
            <th className="w-[8%] bg-black text-white border-[0.5px] border-white text-xs font-normal px-10 lg:px-8 xl:px-2 py-2">Real Quantity</th>
            <th className="w-[8%] bg-black text-white border-[0.5px] border-white text-xs font-normal px-10 lg:px-8 xl:px-2 py-2">Discrepancy</th>
            <th className="w-[12%] bg-black text-white border-[0.5px] border-white text-xs font-normal px-10 lg:px-8 xl:px-2 py-2">Notes</th>
            <th className="w-[8%] bg-black text-white border-[0.5px] border-white text-xs font-normal px-10 lg:px-8 xl:px-2 py-2">Unit Cost</th>
            <th className="w-[8%] bg-black text-white border-[0.5px] border-white text-xs font-normal px-10 lg:px-8 xl:px-2 py-2">Total</th>
          </tr>
        </thead>
        <tbody>
          {
            data.map((element, i) => {
              return (
                <RowsReceivedProducts
                  key={i}
                  product={element}
                  onProduct={onProduct}
                  onOrganize={onOrganize}
                  onlyRead={readView}
                  onCompatible={onCompatible}
                />
              );
            })
          }
          <tr>
            <td className="w-[84%] border-[0.5px] border-ag-secondary-light text-right text-xs font-normal px-4 py-2" colSpan="6">TOTAL EXPECTED QUANTITY</td>
            <td className="w-[16%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2" colSpan="2">{ totalExpectedQuantity }</td>
          </tr>
          <tr>
            <td className="w-[84%] border-[0.5px] border-ag-secondary-light text-right text-xs font-normal px-4 py-2" colSpan="6">TOTAL REAL QUANTITY</td>
            <td className="w-[16%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2" colSpan="2">{ totalRealQuantity }</td>
          </tr>
          <tr>
            <td className="w-[84%] border-[0.5px] border-ag-secondary-light text-right text-xs font-normal px-4 py-2" colSpan="6">TOTAL DISCREPANCY</td>
            <td className="w-[16%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2" colSpan="2">{ totalDiscrepancy }</td>
          </tr>
          <tr>
            <td className="w-[84%] border-[0.5px] border-ag-secondary-light text-right text-xs font-normal px-4 py-2" colSpan="6">SUBTOTAL</td>
            <td className="w-[16%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2" colSpan="2">{ formatCurrency(subTotal) }</td>
          </tr>
          <tr>
            <td className="w-[84%] border-[0.5px] border-ag-secondary-light text-right text-xs font-normal px-4 py-2" colSpan="6">TOTAL</td>
            <td className="w-[16%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2" colSpan="2">{ formatCurrency(total) }</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TableReceivedProducts;