import { FaPlus } from "react-icons/fa";
import RowsUsers from "./RowsUsers";
import { useEffect, useState } from "react";

const TableUser = ({ enableApprove, enableEdit, enableDelete, users, onEdit, onDelete, onStatus, loadingUsers, onAdd, enableAddRetail, enableAddWholesaler, }) => {
  const [enableAdd, setEnableAdd] = useState(true);

  useEffect(() => {
    if (enableAddRetail || enableAddWholesaler) {
      setEnableAdd(false);
    }
  }, [enableAddRetail, enableAddWholesaler]);

  if (loadingUsers) {
    return (
      <div className="flex items-center justify-center min-h-[55vh] 2xl:min-h-[49vh]">
        <div className="flex items-center gap-[15px] p-5 border-0 rounded-lg shadow-lg bg-[#00000099] outline-none focus:outline-none">
          <span className="loader"></span>
          <span className="text-white text-base md:text-xl">Loading, please wait...</span>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex flex-col md:flex-row-reverse mb-[20px]">
        <div>
          <button
            className="font-bold w-full bg-ag-primary-light hover:bg-[#f74738] text-white text-sm lg:text-base px-[24px] py-[8px] rounded-[5px] disabled:bg-zinc-200"
            type="button"
            onClick={() => { onAdd(true); }}
            disabled={enableAdd}
          >
            <div className="flex justify-center items-center gap-[15px]">
              <span>
                Add User
              </span>

              <FaPlus />
            </div>
          </button>
        </div>
      </div>

      <div className="overflow-auto min-h-[55vh] 2xl:min-h-[49vh]">
        <table className="w-full text-center text-ag-secondary">
          <thead>
            <tr className="bg-ag-secondary-table">
              <th className="w-[5%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-3">User Number</th>
              <th className="w-[17%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 md:px-20 lg:px-2 py-3">Name</th>
              <th className="w-[17%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 md:px-20 lg:px-2 py-3">Email</th>
              <th className="w-[5%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 md:px-4 lg:px-2 py-3">Docs</th>
              <th className="w-[11%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 md:px-8 lg:px-2 py-3">Type</th>
              <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 md:px-8 lg:px-2 py-3">Phone</th>
              <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 md:px-4 lg:px-2 py-3">Credit</th>
              <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 md:px-10 lg:px-2 py-3">Total year</th>
              <th className="w-[5%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-3">Edit</th>
              <th className="w-[5%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-3">Delete</th>
              <th className="w-[5%] border-[0.5px] border-ag-secondary-light text-xs font-normal px-2 py-3">Approve / Disapprove</th>
            </tr>
          </thead>
          <tbody>
            {
              users.map((userData, i) => {
                return (
                  <RowsUsers
                    key={i}
                    enableApprove={enableApprove}
                    enableEdit={enableEdit}
                    enableDelete={enableDelete}
                    userData={userData}
                    onDelete={() => { onDelete(userData); }}
                    onEdit={() => { onEdit(userData); }}
                    onStatus={(evtSts) => { onStatus(userData, evtSts.target.checked); }}
                  />
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableUser;