import { MdEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import Icon_Dup from "../../../../assets/icons/admin-dup.svg";
import Icon_Del from "../../../../assets/icons/admin-del.svg";

const CardRoles = ({ id, name, users, onDuplicate, onDelete }) => {
    return (
        <div className="w-full flex justify-between border border-ag-secondary rounded-xl m-auto">
            <div className="w-full space-y-5 p-2">
                <div className="flex justify-start items-center space-x-2">
                    <h1 className="text-lg font-bold">{ name }</h1>
                    <Link to={`/administrators/roles/element/${id}`}>
                        <button className="text-3xl text-ag-secondary" type="button">
                            <MdEdit />
                        </button>
                    </Link>
                </div>
                <div>
                    <span className="text-lg text-ag-secondary">Assigned to </span>
                    <span className="text-lg font-semibold text-ag-primary">{ users } Users</span>
                </div>
            </div>
            <div className="w-[50px] bg-[#D9D9D9] rounded-r-xl space-y-4 p-3 text-center m-auto">
                <div>
                    <button type="w-full button" onClick={() => onDuplicate(true)}>
                        <img className="w-full m-auto" src={Icon_Dup} />
                    </button>
                </div>
                <div>
                    <button type="w-full button" onClick={() => onDelete(id, name) }>
                        <img className="w-full m-auto" src={Icon_Del} />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CardRoles;