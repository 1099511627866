import { _delete, _get, _postFree, _put } from "./http";

export const getRetailUser = (id) => _get(`/retailUser/${id}`);

export const getRetailUsers = () => _get(`/retailUser`);

export const saveRetailUser = (userObject) => _postFree(`/retailUser`, userObject);

export const updateRetailUser = (id, userObject) => _put(`/retailUser/${id}`, userObject);

export const deleteRetailUser = (id) => _delete(`/retailUser/${id}`);
