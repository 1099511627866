import { _delete, _get, _post, _put } from "./http";

export const getAddress = (idWholesaler, idAddress) => _get(`/wholesaleUser/${idWholesaler}/myAddresses/${idAddress}`);

export const getAddresses = (idWholesaler) => _get(`/wholesaleUser/${idWholesaler}/myAddresses`);

export const saveAddress = (idWholesaler, addressObject) => _post(`/wholesaleUser/${idWholesaler}/myAddresses`, addressObject);

export const updateAddress = (idWholesaler, idAddress, addressObject) => _put(`/wholesaleUser/${idWholesaler}/myAddresses/${idAddress}`, addressObject);

export const deleteAddress = (idWholesaler, idAddress) => _delete(`/wholesaleUser/${idWholesaler}/myAddresses/${idAddress}`);