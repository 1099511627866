const RowsInterchanges = ({ interchange }) => {
    let year = interchange.year;
    const yearsString = String(interchange.year).replace(/\s+/g, '');
    const yearsArray = yearsString.split(',');

    if (yearsArray.length > 1) {
        yearsArray.sort();
        const firstYear = yearsArray[0];
        const lastYear = yearsArray[yearsArray.length - 1];
        year = `${firstYear} - ${lastYear}`;
    }

    return (
        <tr>
            <td
                className="w-[16.6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-2"
                rowSpan={1}
            >
                <p className="truncate w-36 m-auto">{ interchange.nag }</p>
            </td>
            <td className="w-[16.6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-2">
                <p className="truncate w-36 m-auto">
                    { year }
                </p>
            </td>
            <td className="w-[16.6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-2">
                <p className="truncate w-36 m-auto" title={interchange.brand}>
                    { interchange.brand }
                </p>
            </td>
            <td className="w-[16.6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-2">
                <p className="truncate w-36 m-auto" title={interchange.model}>
                    { interchange.model }
                </p>
            </td>
            <td className="w-[16.6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-2">
                <p className="truncate w-36 m-auto">
                    { interchange.type_glass }
                </p>
            </td>
            <td className="w-[16.6%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-2 py-2">
                <p className="truncate w-36 m-auto">
                    { interchange.type_car }
                </p>
            </td>
        </tr>
    );
}

export default RowsInterchanges;