import React from 'react'

const RearQuarterLeft = ({ isSelected, isAvailable, onClick }) => {
  return (
    <svg className="cursor-pointer outline-none" onClick={onClick} data-tooltip-id="car" data-tooltip-content="Rear Quarter (left)">
      <path d="M85.46 352.68L65.46 402.01C65.46 402.01 56.13 396.41 56.93 377.34C68.39 367.62 85.46 352.68 85.46 352.68Z" fill={isSelected ? "#F41A28" :isAvailable ? "#1D8F43" : "#D3D3D4"}/>
    </svg>
  )
}

export default RearQuarterLeft