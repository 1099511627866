
import { useEffect, useMemo, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { getProductEspecificationsBrands, getProductEspecificationsModel, getProductEspecificationsTypec, getProductEspecificationsTypeg, getProductEspecificationsYears } from "../../../services/Product";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { useNavigate } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import Loader from "../../../components/loader/Loader";
import BackGlass from "../../../components/car/BackGlass";
import BaseCar from "../../../components/car/BaseCar";
import FrontQuarterLeft from "../../../components/car/FrontQuarterLeft";
import FrontVentLeft from "../../../components/car/FrontVentLeft";
import FrontDoorLeft from "../../../components/car/FrontDoorLeft";
import MiddleDoorLeft from "../../../components/car/MiddleDoorLeft";
import FrontQuarterRight from "../../../components/car/FrontQuarterRight";
import FrontVentRight from "../../../components/car/FrontVentRight";
import FrontDoorRight from "../../../components/car/FrontDoorRight";
import RearDoorLeft from "../../../components/car/RearDoorLeft";
import RearVentLeft from "../../../components/car/RearVentLeft";
import RearQuarterLeft from "../../../components/car/RearQuarterLeft";
import MiddleDoorRight from "../../../components/car/MiddleDoorRight";
import RearDoorRight from "../../../components/car/RearDoorRight";
import RearVentRight from "../../../components/car/RearVentRight";
import RearQuarterRight from "../../../components/car/RearQuarterRight";
import Roof from "../../../components/car/Roof";
import Windshield from "../../../components/car/Windshield";

const SearchHome = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [searchData, setSearchData] = useState({ year: "", brand: "", model: "", style: "", typeGlass: "" });
    const [wordData, setWordData] = useState("");
    const [yearOpts, setYearOpts] = useState([]);
    const [makeOpts, setMakeOpts] = useState([]);
    const [modelOpts, setModelOpts] = useState([]);
    const [styleOpts, setStyleOpts] = useState([]);
    const [typeOpts, setTypeOpts] = useState([]);

    const [typexOptions] = useState([
        { show: false, selected: false, value: "Windshield" },
        { show: false, selected: false, value: "Door / Front / Left" },
        { show: false, selected: false, value: "Vent / Front / Left" },
        { show: false, selected: false, value: "Door / Rear / Left" },
        { show: false, selected: false, value: "Vent / Rear / Left" },
        { show: false, selected: false, value: "Quarter / Left" },
        { show: false, selected: false, value: "Door / Front / Right" },
        { show: false, selected: false, value: "Vent / Front / Right" },
        { show: false, selected: false, value: "Door / Rear / Right" },
        { show: false, selected: false, value: "Vent / Rear / Right" },
        { show: false, selected: false, value: "Quarter / Right" },
        { show: false, selected: false, value: "Back Window" }
    ]);

    const { availableCarGlasses, availableCarGlassesObjects} = useMemo(() => {
        return typexOptions.reduce((availableGlasses, glass) => {

            availableGlasses.availableCarGlasses.push(glass.value)
            availableGlasses.availableCarGlassesObjects[glass.value] = glass

            return availableGlasses
        },{availableCarGlasses: [], availableCarGlassesObjects: {}})
    },[typeOpts])

    const getYears = () => {
        setMakeOpts([]);
        setModelOpts([]);
        setStyleOpts([]);
        setTypeOpts([]);
        getProductEspecificationsYears().then(res => {
            if (res.status === 200) setYearOpts(res.data);
            setLoader(false);
        });
    }

    const getBrands = (yearParam) => {
        setModelOpts([]);
        setStyleOpts([]);
        setTypeOpts([]);
        getProductEspecificationsBrands(yearParam).then(res => {
            if (res.status === 200) setMakeOpts(res.data);
        });
    }

    const getModels = (yearParam, brandParam) => {
        setStyleOpts([]);
        setTypeOpts([]);
        getProductEspecificationsModel(yearParam, brandParam).then(res => {
            if (res.status === 200) setModelOpts(res.data);
        });
    }

    const getTypec = (yearParam, brandParam, modelParam) => {
        setTypeOpts([]);
        getProductEspecificationsTypec(yearParam, brandParam, modelParam).then(res => {
            if (res.status === 200) setStyleOpts(res.data);
        });
    }

    const getTypeg = (yearParam, brandParam, modelParam, typecParam) => {
        getProductEspecificationsTypeg(yearParam, brandParam, modelParam, typecParam).then(res => {
            if (res.status === 200) {
                const arrTypeGlass = res.data;
                const newTypeXOptions = typexOptions.map(element => {
                    const sonExist = arrTypeGlass.find(elemSon => elemSon.type_glass === element.value);
                    if (sonExist) return {...element, show: true }
                    return element;
                });
                setTypeOpts(newTypeXOptions);
            }
        });
    }

    const handleClean = () => {
        setMakeOpts([]);
        setModelOpts([]);
        setStyleOpts([]);
        setTypeOpts([]);
        setSearchData({...searchData, year: "", brand: "", model: "", style: "", typeGlass: ""});
    }

    const handleChange = (name, value) => {
        if (name === "year") {
            setSearchData({...searchData, year: value, brand: "", model: "", style: "", typeGlass: ""});
            getBrands(value);
        } else if (name === "brand") {
            setSearchData({...searchData, brand: value, model: "", style: "", typeGlass: ""});
            getModels(searchData.year, value);
        } else if (name === "model") {
            setSearchData({...searchData, model: value, style: "", typeGlass: ""});
            getTypec(searchData.year, searchData.brand, value);
        } else if (name === "style") {
            setSearchData({...searchData, style: value, typeGlass: ""});
            getTypeg(searchData.year, searchData.brand, searchData.model, value);
        } else {
            setSearchData({...searchData, typeGlass: value});
        }
    }

    const handleChangeSearch = (evt) => {
        let valStr = /^[a-z0-9]*$/i;
        if (!(evt.target.value).match(valStr)) return;

        setWordData(evt.target.value);
    }

    const handleGlassCarChange = (value) => {
        setSearchData({...searchData, typeGlass: value});
    }

    const handleSubmitAux = () => {
        if (String(wordData).length > 0) {
            navigate(`/wholesaler/home/search/`, { state: {  nag: wordData }});
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    const handleSubmit = () => {
        if (searchData.year !== "" && searchData.brand !== "" && searchData.model !== "" && searchData.style !== "" && searchData.typeGlass !== "") {
            navigate(`/wholesaler/home/search/`, {
                state: { 
                    year: searchData.year,
                    brand: searchData.brand,
                    model: searchData.model,
                    style: searchData.style,
                    type: searchData.typeGlass
                }
            });
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    useEffect(() => {
        setLoader(true);
        getYears();
    }, []);

    return (
        <>
            { loader ? <Loader /> : null }

            <div className="space-y-10 md:space-y-14">
                <div className="border-b-2 border-ag-primary-light py-3 md:py-5">
                    <div className="w-full flex justify-center items-center">
                        <div className="w-full">
                            <input
                                className="w-full text-ag-secondary-letter text-center text-xs lg:text-base border border-gray-500 py-2 px-4 rounded-l-xl truncate"
                                type="text"
                                placeholder="Part# or keyword search..."
                                value={wordData}
                                maxLength={10}
                                onChange={handleChangeSearch}
                            />
                        </div>
                        <div>
                            <button className="w-full px-5 py-2 bg-ag-primary text-white text-xs lg:text-base rounded-r-xl" type="button" onClick={() => handleSubmitAux()}>
                                <span className="text-[18px] lg:text-[25px]"><BiSearch /></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="text-center pb-5 md:pb-8">
                        <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">
                            Year, make, model and style search
                        </h1>
                    </div>
                    <div className="flex flex-col xl:flex-row justify-center gap-8">
                    <div className="w-full xl:w-3/4 lg:flex lg:justify-between lg:items-start lg:space-x-5 space-y-3 lg:space-y-0">
                        <div className="w-full space-y-5">
                            <div>
                                <div className="bg-zinc-200 px-5 py-2 text-center border border-ag-secondary rounded-t-xl">
                                    <span className="text-ag-secondary-letter text-sm">Year</span>
                                </div>
                                <div className="px-5 py-2 text-center border border-ag-secondary rounded-b-xl">
                                    <span className="text-ag-secondary-letter text-sm">
                                        { searchData.year !== "" ? `Selected ${searchData.year}` : "None selected" }
                                    </span>
                                </div>
                            </div>
                            {
                                yearOpts.length > 0 ?
                                    <div className="h-[205px] overflow-y-auto border border-ag-secondary rounded-xl">
                                        <ul>
                                            { yearOpts.map((element, i) => (
                                                <li key={i} className={`p-2 text-center border-b border-ag-secondary cursor-pointer hover:bg-ag-primary hover:text-white ${searchData.year === element.year ? "bg-ag-primary-light text-white" : "text-ag-secondary-letter"} `} onClick={() => handleChange("year", element.year)}>
                                                    { element.year }
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                :
                                    <div className="h-[44px] overflow-y-auto border border-ag-secondary rounded-xl">
                                        <ul>
                                            <li className="pt-3 pb-2 px-2 text-center text-xs text-ag-secondary-letter">
                                                Please select a year...
                                            </li>
                                        </ul>
                                    </div>
                            }
                        </div>
                        <div className="w-full space-y-5">
                            <div>
                                <div className="bg-zinc-200 px-5 py-2 text-center border border-ag-secondary rounded-t-xl">
                                    <span className="text-ag-secondary-letter text-sm">Make</span>
                                </div>
                                <div className="px-5 py-2 text-center border border-ag-secondary rounded-b-xl">
                                    <span className="text-ag-secondary-letter text-sm">
                                        { searchData.brand !== "" ? `Selected ${searchData.brand}` : "None selected" }
                                    </span>
                                </div>
                            </div>
                            {
                                makeOpts.length > 0 ?
                                    <div className="h-[205px] overflow-y-auto border border-ag-secondary rounded-xl">
                                        <ul>
                                            { makeOpts.map((element, i) => (
                                                <li key={i} className={`p-2 text-center border-b border-ag-secondary cursor-pointer hover:bg-ag-primary hover:text-white ${searchData.brand === element.brand ? "bg-ag-primary-light text-white" : "text-ag-secondary-letter"}`} onClick={() => handleChange("brand", element.brand)}>
                                                    { element.brand }
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                :
                                    <div className="h-[44px] overflow-y-auto border border-ag-secondary rounded-xl">
                                        <ul>
                                            <li className="pt-3 pb-2 px-2 text-center text-xs text-ag-secondary-letter">
                                                Please select a year...
                                            </li>
                                        </ul>
                                    </div>
                            }
                        </div>
                        <div className="w-full space-y-5">
                            <div>
                                <div className="bg-zinc-200 px-5 py-2 text-center border border-ag-secondary rounded-t-xl">
                                    <span className="text-ag-secondary-letter text-sm">Model</span>
                                </div>
                                <div className="px-5 py-2 text-center border border-ag-secondary rounded-b-xl">
                                    <span className="text-ag-secondary-letter text-sm">
                                        { searchData.model !== "" ? `Selected ${searchData.model}` : "None selected" }
                                    </span>
                                </div>
                            </div>
                            {
                                modelOpts.length > 0 ?
                                    <div className="h-[205px] overflow-y-auto border border-ag-secondary rounded-xl">
                                        <ul>
                                            { modelOpts.map((element, i) => (
                                                <li key={i} className={`p-2 text-center border-b border-ag-secondary cursor-pointer hover:bg-ag-primary hover:text-white ${searchData.model === element.model ? "bg-ag-primary-light text-white" : "text-ag-secondary-letter"}`} onClick={() => handleChange("model", element.model)}>
                                                    { element.model }
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                :
                                    <div className="h-[44px] overflow-y-auto border border-ag-secondary rounded-xl">
                                        <ul>
                                            <li className="pt-3 pb-2 px-2 text-center text-xs text-ag-secondary-letter">
                                                Please select a make...
                                            </li>
                                        </ul>
                                    </div>
                            }
                        </div>
                        <div className="w-full space-y-5">
                            <div>
                                <div className="bg-zinc-200 px-5 py-2 text-center border border-ag-secondary rounded-t-xl">
                                    <span className="text-ag-secondary-letter text-sm">Style</span>
                                </div>
                                <div className="px-5 py-2 text-center border border-ag-secondary rounded-b-xl">
                                    <span className="text-ag-secondary-letter text-sm">
                                        { searchData.style !== "" ? `Selected ${searchData.style}` : "None selected" }
                                    </span>
                                </div>
                            </div>
                            {
                                styleOpts.length > 0 ?
                                    <div className="h-[205px] overflow-y-auto border border-ag-secondary rounded-xl">
                                        <ul>
                                            { styleOpts.map((element, i) => (
                                                <li key={i} className={`p-2 text-center border-b border-ag-secondary cursor-pointer hover:bg-ag-primary hover:text-white ${searchData.style === element.type_car ? "bg-ag-primary-light text-white" : "text-ag-secondary-letter"}`} onClick={() => handleChange("style", element.type_car)}>
                                                    { element.type_car }
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                :
                                    <div className="h-[44px] overflow-y-auto border border-ag-secondary rounded-xl">
                                        <ul>
                                            <li className="pt-3 pb-2 px-2 text-center text-xs text-ag-secondary-letter">
                                                Please select a model...
                                            </li>
                                        </ul>
                                    </div>
                            }
                        </div>
                        <div className="w-full space-y-5">
                            <div>
                                <div className="bg-zinc-200 px-5 py-2 text-center border border-ag-secondary rounded-t-xl">
                                    <span className="text-ag-secondary-letter text-sm">Type of Glass</span>
                                </div>
                                <div className="px-5 py-2 text-center border border-ag-secondary rounded-b-xl">
                                    <span className="text-ag-secondary-letter text-sm">
                                        { searchData.typeGlass !== "" ? `Selected ${searchData.typeGlass === "Back Window" ? "Backglass" : searchData.typeGlass === "Quarter / Left" ? "Quarter / Rear / Left" : searchData.typeGlass === "Quarter / Right" ? "Quarter / Rear / Right" :  searchData.typeGlass }` : "None selected" }
                                    </span>
                                </div>
                            </div>
                            {
                                typeOpts.length > 0 ?
                                    <div className="h-[205px] overflow-y-auto border border-ag-secondary rounded-xl">
                                        <ul>
                                            { typeOpts.map((element, i) => (
                                                <li key={i} className={`p-2 text-center border-b border-ag-secondary cursor-pointer hover:bg-ag-primary hover:text-white ${searchData.typeGlass === element.value ? "bg-ag-primary-light text-white" : "text-ag-secondary-letter"}`} onClick={() => handleChange("typeGlass", element.value)}>
                                                    {element.value === "Back Window" ? "Backglass" : element.value === "Quarter / Left" ? "Quarter / Rear / Left" : element.value === "Quarter / Right" ? "Quarter / Rear / Right" :  element.value }
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                :
                                    <div className="h-[44px] overflow-y-auto border border-ag-secondary rounded-xl">
                                        <ul>
                                            <li className="pt-3 pb-2 px-2 text-center text-xs text-ag-secondary-letter">
                                                Please select a style...
                                            </li>
                                        </ul>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="w-full xl:w-1/4 flex justify-center">
                        <BaseCar>
                            <Windshield 
                            isSelected={searchData.typeGlass === "Windshield"} 
                            isAvailable={availableCarGlasses.includes("Windshield") && searchData.style !== ""}
                            onClick={searchData.style === "" ? () =>{} : () => handleGlassCarChange("Windshield")}
                            />

                            <FrontQuarterLeft 
                            />
                            <FrontVentLeft 
                            isSelected={searchData.typeGlass === "Vent / Front / Left"} 
                            isAvailable={availableCarGlasses.includes("Vent / Front / Left") && searchData.style !== ""}
                            onClick={searchData.style === "" ? () =>{} : () => handleGlassCarChange("Vent / Front / Left")}
                            />
                            <FrontDoorLeft 
                            isSelected={searchData.typeGlass === "Door / Front / Left"}
                            isAvailable={availableCarGlasses.includes("Door / Front / Left") && searchData.style !== ""}
                            onClick={searchData.style === "" ? () =>{} : () => handleGlassCarChange("Door / Front / Left")}
                            />
                            <MiddleDoorLeft 
                            />
                            <RearDoorLeft 
                            isSelected={searchData.typeGlass === "Door / Rear / Left"} 
                            isAvailable={availableCarGlasses.includes("Door / Rear / Left") && searchData.style !== ""}
                            onClick={searchData.style === "" ? () =>{} : () => handleGlassCarChange("Door / Rear / Left")}
                            />
                            <RearVentLeft 
                            isSelected={searchData.typeGlass === "Vent / Rear / Left"} 
                            isAvailable={availableCarGlasses.includes("Vent / Rear / Left") && searchData.style !== ""}
                            onClick={searchData.style === "" ? () =>{} : () => handleGlassCarChange("Vent / Rear / Left")}
                            />
                            <RearQuarterLeft 
                            isSelected={searchData.typeGlass === "Quarter / Left"}
                            isAvailable={availableCarGlasses.includes("Quarter / Left") && searchData.style !== ""}
                            onClick={searchData.style === "" ? () =>{} : () => handleGlassCarChange("Quarter / Left")}
                            />

                            <Roof />

                            <FrontQuarterRight 
                            />

                            <FrontVentRight 
                            isSelected={searchData.typeGlass === "Vent / Front / Right"} 
                            isAvailable={availableCarGlasses.includes("Vent / Front / Right") && searchData.style !== ""}
                            onClick={searchData.style === "" ? () =>{} : () => handleGlassCarChange("Vent / Front / Right")}
                            />
                            <FrontDoorRight 
                            isSelected={searchData.typeGlass === "Door / Front / Right"} 
                            isAvailable={availableCarGlasses.includes("Door / Front / Right") && searchData.style !== ""}
                            onClick={searchData.style === "" ? () =>{} : () => handleGlassCarChange("Door / Front / Right")}
                            />
                            <MiddleDoorRight 
                            />
                            <RearDoorRight 
                            isSelected={searchData.typeGlass === "Door / Rear / Right"} 
                            isAvailable={availableCarGlasses.includes("Door / Rear / Right") && searchData.style !== ""}
                            onClick={searchData.style === "" ? () =>{} : () => handleGlassCarChange("Door / Rear / Right")}
                            />
                            <RearVentRight 
                            isSelected={searchData.typeGlass === "Vent / Rear / Right"} 
                            isAvailable={availableCarGlasses.includes("Vent / Rear / Right") && searchData.style !== ""}
                            onClick={searchData.style === "" ? () =>{} : () => handleGlassCarChange("Vent / Rear / Right")}
                            />
                            <RearQuarterRight 
                            isSelected={searchData.typeGlass === "Quarter / Right"} 
                            isAvailable={availableCarGlasses.includes("Quarter / Right") && searchData.style !== ""}
                            onClick={searchData.style === "" ? () =>{} : () => handleGlassCarChange("Quarter / Right")}
                            />

                            <BackGlass 
                            isSelected={searchData.typeGlass === "Back Window"} 
                            isAvailable={availableCarGlasses.includes("Back Window") && searchData.style !== ""}
                            onClick={searchData.style === "" ? () =>{} : () => handleGlassCarChange("Back Window")}
                            />
                        </BaseCar>
                    </div>
                    </div>
                    <div className="flex justify-end items-center space-x-3 pt-10">
                        <button className="bg-ag-primary-light flex justify-between items-center space-x-3 px-5 py-2 text-white rounded-xl" type="button" onClick={() => handleClean()}>
                            <span>Clear</span>
                            <span className="text-lg"><AiOutlineClose /></span>
                        </button>
                        <button className="bg-ag-primary flex justify-between items-center space-x-3 px-5 py-2 text-white rounded-xl" type="button" onClick={() => handleSubmit()}>
                            <span>Search</span>
                            <span><BsSearch /></span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SearchHome;