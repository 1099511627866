import { useEffect, useState } from "react";
import { formatCurrency } from "../../../../../utils/Utilities";
import RowsSentProducts from "./RowsSentProducts";

const TableSentProducts = ({ products, quantity, subtotal, total, onProduct, onDelete, onCompatible }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(products);
  }, [products]);

  return (
    <div className="overflow-x-auto">
      <table className="w-full text-center text-ag-secondary">
        <thead>
          <tr className="bg-gray-300">
            <th className="w-[40%] bg-black text-white border-[0.5px] border-white text-xs font-normal px-20 md:px-2 py-2">Products</th>
            <th className="w-[15%] bg-black text-white border-[0.5px] border-white text-xs font-normal px-20 md:px-2 py-2">Rack</th>
            <th className="w-[15%] bg-black text-white border-[0.5px] border-white text-xs font-normal px-10 md:px-2 py-2">Quantity</th>
            <th className="w-[15%] bg-black text-white border-[0.5px] border-white text-xs font-normal px-10 md:px-2 py-2">Default Unit Cost</th>
            <th className="w-[15%] bg-black text-white border-[0.5px] border-white text-xs font-normal px-10 md:px-2 py-2">Est. Total Cost</th>
          </tr>
        </thead>
        <tbody>
          {
            data.map((element, i) => {
              return (
                <RowsSentProducts
                  key={i}
                  product={element}
                  onProduct={onProduct}
                  onDelete={onDelete}
                  onCompatible={onCompatible}
                />
              );
            })
          }
          <tr>
            <td className="w-[85%] border-[0.5px] border-ag-secondary-light text-right text-xs font-normal px-4 py-2" colSpan="4">TOTAL QUANTITY</td>
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">{ quantity }</td>
          </tr>
          <tr>
            <td className="w-[85%] border-[0.5px] border-ag-secondary-light text-right text-xs font-normal px-4 py-2" colSpan="4">ESTIMATED SUBTOTAL</td>
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">{ formatCurrency(subtotal) }</td>
          </tr>
          <tr>
            <td className="w-[85%] border-[0.5px] border-ag-secondary-light text-right text-xs font-normal px-4 py-2" colSpan="4">ESTIMATED TOTAL</td>
            <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-xs font-normal p-2">{ formatCurrency(total) }</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TableSentProducts;