import { useContext, useEffect, useState } from "react";
import Inventory from "./Inventory";
import InventoryAccessory from "./InventoryAccessory";
import { Link, useParams } from "react-router-dom";

const InventoryTabs = () => {

  let { option } = useParams();
  const [activeTab, setActiveTab] = useState(option ? option : 'tableInventory');

  return (
    <>
      <div className="pb-5 md:pb-8 bg-[#f3f4f6]">
        <div className="px-3 md:px-8">
          <h1 className="text-3xl font-semibold mb-6 pt-6">
            Inventory
          </h1>
        </div>
        <div className="flex w-[100%] h-[50px] mb-6 px-3 md:px-8">
          <button onClick={() => setActiveTab('tableInventory')} className={activeTab === 'tableInventory' ? "w-[50%] bg-[#fff] rounded-l text-ag-secondary text-xs lg:text-base text-[#FF5F52] border-b-[3px] border-[#FF5F52] rounded-bl-none rounded-tl" : "w-[50%] bg-[#fff] rounded-l text-ag-secondary text-xs lg:text-base border-b-[3px] border-[#fff]"}>Inventory NAGS</button>
          <button onClick={() => setActiveTab('tableInventoryAccessories')} className={activeTab === 'tableInventoryAccessories' ? "w-[50%] bg-[#fff] text-ag-secondary text-xs lg:text-base text-[#FF5F52] border-b-[3px] border-[#FF5F52] rounded-br-none rounded-tr" : "w-[50%] bg-[#fff] rounded-r text-ag-secondary text-xs lg:text-base border-b-[3px] border-[#fff]"}>Inventory Accessories</button>
        </div>

        {
          activeTab === 'tableInventory' ?
            <>
              <Inventory />
            </>
            :
            <></>
        }

        {
          activeTab === 'tableInventoryAccessories' ?
            <>
              <InventoryAccessory />
            </>
            :
            <></>
        }

      </div>

    </>
  );
};

export default InventoryTabs;