import { useEffect, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { toast } from "react-toastify";
import { alertOptions, formatHours, formatReverseDate, getArrayDaysOfMonth, getNameOfDay } from "../../../../utils/Utilities";
import { getHoursNotAvailable } from "../../../../services/Quotes";

const ScheduleQuote = ({ shippingMethod, warehouseID, dayValue, onDay, onHour, onSunday }) => {
    const [arrayDays, setArrayDays] = useState([]);
    const [monthStr, setMonthStr] = useState({ name: "", value: 0, past: 0 });
    const [yearStr, setYearStr] = useState(0);
    const [newHours, setNewHours] = useState([]);

    const [hours] = useState([
        { name: "08:00", value: "08:00", status: false },
        { name: "09:00", value: "09:00", status: false },
        { name: "10:00", value: "10:00", status: false },
        { name: "11:00", value: "11:00", status: false },
        { name: "12:00", value: "12:00", status: false },
        { name: "13:00", value: "13:00", status: false },
        { name: "14:00", value: "14:00", status: false },
        { name: "15:00", value: "15:00", status: false },
        { name: "16:00", value: "16:00", status: false },
        { name: "17:00", value: "17:00", status: false },
        { name: "18:00", value: "18:00", status: false }
    ]);

    const [months, setMonths] = useState([
        { name: "January", value: 0, status: false },
        { name: "February", value: 1, status: false },
        { name: "March", value: 2, status: false },
        { name: "April", value: 3, status: false },
        { name: "May", value: 4, status: false },
        { name: "June", value: 5, status: false },
        { name: "July", value: 6, status: false },
        { name: "August", value: 7, status: false },
        { name: "September", value: 8, status: false },
        { name: "October", value: 9, status: false },
        { name: "November", value: 10, status: false },
        { name: "December", value: 11, status: false },
    ]);

    const getHoursNA = async (filterParams) => {
        return await getHoursNotAvailable(filterParams).then(res => {
            if (res.status === 200) {
                const newHoursNA = res.data.map(elem => (formatHours(elem)));
                return newHoursNA;
            }
        }).catch(() => ([]));
    }

    const handleDay = (daySelected) => {
        new Promise((resolve, rejecect) => {
            setNewHours([]);
            resolve();
        }).then(() => {
            let dateSelected = new Date(`${yearStr}-${monthStr.value+1}-${daySelected}`);
            dateSelected.setMinutes(dateSelected.getMinutes() + dateSelected.getTimezoneOffset());

            let dateNow = new Date(`${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate()}`);
            dateNow.setMinutes(dateNow.getMinutes() + dateNow.getTimezoneOffset());

            if ((shippingMethod === "SHIP" && dateNow <= dateSelected) || (shippingMethod === "PICK_UP" && dateNow <= dateSelected)) {
                const newArrayDays = arrayDays.map((dayElement => {
                    if (dayElement.day === daySelected) return {...dayElement, status: true}
                    return {...dayElement, status: false}
                }));
                setArrayDays(newArrayDays);
                handleHours(`${yearStr}-${monthStr.value+1}-${daySelected}`);
            } else {
                toast.warning("The selected date is not available", alertOptions);
            }
        });
    }

    const handleHours = async (daySelected) => {
        const nameOfDay = getNameOfDay(daySelected);
        const hoursNATD = await getHoursNA({ warehouse: warehouseID, day: formatReverseDate(daySelected) });
        if (shippingMethod === "PICK_UP") {
            if (nameOfDay === "Saturday") {
                const newHours = hours.filter(elem => {
                    if (formatReverseDate(daySelected) === formatReverseDate(new Date())) {
                        if (elem.value !== "18:00" && Number(String(elem.value).replace(":00", "")) > new Date().getHours()) return elem;
                    } else {
                        if (elem.value !== "18:00") return elem;
                    }
                });
                setNewHours(newHours);
                onSunday(false, daySelected);
            } else if (nameOfDay === "Sunday") {
                const newHours = hours.filter(elem =>{
                    if (formatReverseDate(daySelected) === formatReverseDate(new Date())) {
                        if ((elem.value !== "08:00" && elem.value !== "15:00" && elem.value !== "16:00" && elem.value !== "17:00" && elem.value !== "18:00") && Number(String(elem.value).replace(":00", "")) > new Date().getHours()) return elem;
                    } else {
                        if (elem.value !== "08:00" && elem.value !== "15:00" && elem.value !== "16:00" && elem.value !== "17:00" && elem.value !== "18:00") return elem;
                    }
                });
                setNewHours(newHours);
                onSunday(true, daySelected);
            } else {
                const newHours = hours.filter(elem => {
                    if (formatReverseDate(daySelected) === formatReverseDate(new Date())) {
                        if (Number(String(elem.value).replace(":00", "")) > new Date().getHours()) return elem;
                    } else {
                        return elem;
                    }
                });
                setNewHours(newHours);
                onSunday(false, daySelected);
            }
        } else {
            onDay(daySelected);
            if (nameOfDay === "Saturday") {
                const newHours = hours.filter(elem => {
                    if (formatReverseDate(daySelected) === formatReverseDate(new Date())) {
                        if ((elem.value !== "16:00" && elem.value !== "17:00" && elem.value !== "18:00") && Number(String(elem.value).replace(":00", "")) > new Date().getHours()) return elem;
                    } else {
                        if (elem.value !== "16:00" && elem.value !== "17:00" && elem.value !== "18:00") return elem;
                    }
                });
                const newHoursAux = newHours.filter(elem => {
                    const foundHour = hoursNATD.filter(elemAux => elemAux === elem.value);
                    if (foundHour.length === 0) return elem;
                });
                setNewHours(newHoursAux);
            } else if (nameOfDay === "Sunday") {
                const newDaysSelected = arrayDays.map(elem => {
                    const dayValueFormat = new Date(dayValue);
                    dayValueFormat.setMinutes(dayValueFormat.getMinutes() + dayValueFormat.getTimezoneOffset());
                    if (dayValueFormat.getDate() === elem.day) return {...elem, status: true};
                    return {...elem, status: false};
                });
                setArrayDays(newDaysSelected);
                toast.info("Mobile service is not available on Sunday", alertOptions);
                setNewHours([]);
                onHour(null);
            } else {
                const newHours = hours.filter(elem => {
                    if (formatReverseDate(daySelected) === formatReverseDate(new Date())) {
                        if ((elem.value !== "17:00" && elem.value !== "18:00") && Number(String(elem.value).replace(":00", "")) > new Date().getHours()) return elem;
                    } else {
                        if ((elem.value !== "17:00" && elem.value !== "18:00")) return elem;
                    }
                });
                const newHoursAux = newHours.filter(elem => {
                    const foundHour = hoursNATD.filter(elemAux => elemAux === elem.value);
                    if (foundHour.length === 0) return elem;
                });
                setNewHours(newHoursAux);
            }
        }
    }

    const changeMonth = (type) => {
        const indexCurrentMonth = months.findIndex(element => element.status);
        const dayValueFormat = new Date(dayValue);
        dayValueFormat.setMinutes(dayValueFormat.getMinutes() + dayValueFormat.getTimezoneOffset());

        const newMonths = months.map((element, i) => {
            let pastMonth = 0;
            let currentYear = 0;
            let statusCurrent = false;

            if (type === "next" && indexCurrentMonth+1 === i) {
                pastMonth = i === 0 ? 11 : i-1;
                currentYear = i === 0 && pastMonth === 11 ? yearStr+1 : yearStr;
                statusCurrent = true;
            } else if (type === "next" && monthStr.past === 10 && monthStr.value === 11 && i === 0) {
                pastMonth = 11;
                currentYear = yearStr+1;
                statusCurrent = true;
            } else if (type === "back" && indexCurrentMonth-1 === i) {
                pastMonth = i === 11 ? 0 : i+1;
                currentYear = i === 11 && pastMonth === 0 ? yearStr-1 : yearStr;
                statusCurrent = true;
            } else if (type === "back" && ((monthStr.past === 1 || monthStr.past === 11) && monthStr.value === 0) && i === 11) {
                pastMonth = 0;
                currentYear = yearStr-1;
                statusCurrent = true;
            } else {
                statusCurrent = false;
            }
            let daysSelected = getArrayDaysOfMonth(element.value, currentYear);

            if (statusCurrent) {
                if (element.value !== dayValueFormat.getMonth()) {
                    setArrayDays(daysSelected);
                } else {
                    const newDaysSelected = daysSelected.map(elem => {
                        if (dayValueFormat.getDate() === elem.day) return {...elem, status: true};
                        return {...elem, status: false};
                    });
                    setArrayDays(newDaysSelected);
                }
    
                setYearStr(currentYear);
                setMonthStr({...monthStr, name: `${element.name} ${currentYear}`, value: element.value, past: pastMonth});
            }
            return {...element, status: statusCurrent};
        });
        setMonths(newMonths);
    }
    
    useEffect(() => {
        const currentMonth = new Date().getMonth();
        setArrayDays(getArrayDaysOfMonth(currentMonth, new Date().getFullYear()));
        const newMonths = months.map((element, i) => {
            if (currentMonth === i) {
                setMonthStr({...monthStr, name: `${element.name} ${new Date().getFullYear()}`, value: element.value, past: currentMonth === 0 ? 11 : currentMonth-1});
                return {...element, status: true }
            } 
            return element;
        });
        setYearStr(new Date().getFullYear());
        setMonths(newMonths);
    }, []);

    useEffect(() => {
        if (dayValue === null) {
            setArrayDays(getArrayDaysOfMonth(monthStr.value, new Date().getFullYear()));
            setNewHours([]);
        }
    }, [dayValue]);

    return (
        <div>
            <div className="w-full grid md:grid-cols-2 py-4 space-y-3 md:space-y-0 border border-ag-secondary-light rounded-xl">
                <div className="space-y-3 px-4 md:px-8 py-2 border-r border-ag-secondary-light">
                    <div className="flex justify-between items-center">
                        <div>
                            <span className="font-bold">{ monthStr.name }</span>
                        </div>
                        <div className="flex justify-center items-center space-x-1">
                            <button className="text-base" type="button" onClick={() => changeMonth("back")}><FiChevronLeft /></button>
                            <button className="text-base" type="button" onClick={() => changeMonth("next")}><FiChevronRight /></button>
                        </div>
                    </div>
                    <div>
                        <div className="grid grid-cols-7">
                            <div className="flex justify-center items-center text-ag-secondary-letter font-semibold">
                                <span className="p-2 text-[9px]">Su</span>
                            </div>
                            <div className="flex justify-center items-center text-ag-secondary-letter font-semibold">
                                <span className="p-2 text-[9px]">Mo</span>
                            </div>
                            <div className="flex justify-center items-center text-ag-secondary-letter font-semibold">
                                <span className="p-2 text-[9px]">Tu</span>
                            </div>
                            <div className="flex justify-center items-center text-ag-secondary-letter font-semibold">
                                <span className="p-2 text-[9px]">We</span>
                            </div>
                            <div className="flex justify-center items-center text-ag-secondary-letter font-semibold">
                                <span className="p-2 text-[9px]">Th</span>
                            </div>
                            <div className="flex justify-center items-center text-ag-secondary-letter font-semibold">
                                <span className="p-2 text-[9px]">Fr</span>
                            </div>
                            <div className="flex justify-center items-center text-ag-secondary-letter font-semibold">
                                <span className="p-2 text-[9px]">Sa</span>
                            </div>
                            {
                                arrayDays.map((element, i) => (
                                    <div
                                        className={`flex justify-center items-center border-[0.5px] border-ag-secondary cursor-pointer ${element.day === 0 ? "" : "hover:bg-ag-primary hover:text-white"} ${element.status ? "bg-ag-primary text-white" : "text-ag-secondary-letter"}`}
                                        key={i}
                                        onClick={() => handleDay(element.day)}
                                    >
                                        <span className={`p-2 text-[9px] ${element.day === 0 ? "opacity-0" : "opacity-100"}`}>{ element.day }</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="space-y-5 px-4 md:px-8 py-2">
                    <div>
                        <span className="font-bold">Available schedules</span>
                    </div>
                    <div className="overflow-y-auto h-52">
                        {
                            newHours.length > 0 ?
                                <ul className="space-y-3">
                                    {
                                        newHours.map((element, i) => (
                                            <li className="flex items-center space-x-3" key={i}>
                                                <span className="text-ag-secondary-letter text-base">{ element.name }</span>
                                                <input
                                                    className="w-4 h-4 accent-ag-primary cursor-pointer"
                                                    type="radio"
                                                    name="shedules_quotes"
                                                    value={element.value}
                                                    onClick={() => onHour(element.value)}
                                                />
                                            </li>
                                        ))
                                    }
                                </ul>
                            :
                                <span className="text-xs text-ag-secondary-letter italic">Please select the installation day first...</span>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ScheduleQuote;