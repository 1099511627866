import RowsInventory from "../inventory/RowsInventory";
import InfiniteScroll from "react-infinite-scroll-component";
import RowsInventoryAccessory from "./RowsInventoryAccessory";
import { useContext, useEffect, useState } from "react";

const TableInventoryAccessory = ({ enableEdit, enableRecord, enableOrderQuery, arrInventory, onPage, onHistory, onStatus, onImage, onPrice, onOrder, onCompatible, finalMessage, showFilters, warehousesAccess, onMovementHistory }) => {
  const divStyleWithFilters = {
    height: `calc(100vh - 480px)`,
  };

  const divStyleWithOutFilters = {
    height: `calc(100vh - 370px)`,
  };  

  return (
    <div>
      <div style={showFilters ? divStyleWithFilters : divStyleWithOutFilters}>
        <div
          id="scrollableDiv"
          style={{
            height: "100%",
            overflow: "auto",
          }}
        >
          <InfiniteScroll
            dataLength={arrInventory ? arrInventory.length : 0}
            next={onPage}
            hasMore={finalMessage}
            loader={
              arrInventory.length > 0 ? (
                <div className="h-[100px] text-center flex items-center justify-center space-x-5">
                  <span className="loaderTwo"></span>
                  <span className="text-[#00000099] text-base md:text-xl">
                    Loading, please wait...
                  </span>
                </div>
              ) : (
                <></>
              )
            }
            scrollableTarget="scrollableDiv"
            endMessage={
              <p className="text-center text-ag-secondary-letter text-[20px]">
                No more items to display.
              </p>
            }
          >
            <table className="w-full text-center text-ag-secondary">
              <thead>
                <tr className="bg-ag-secondary-table">
                  <th className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-32 md:px-40 xl:px-2 py-4">
                    Accessory name
                  </th>
                  <th className="w-[30%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">
                    Description
                  </th>
                  <th className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">
                    Warehouse
                  </th>
                  <th className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">
                    Stock
                  </th>
                  <th className="w-[5%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">
                    Sales price
                  </th>
                  <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-10 md:px-10 xl:px-2 py-4">
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                {arrInventory.length === 0 ? (
                  <></>
                ) : (
                  arrInventory.map((element, i) => {
                    return (
                      <RowsInventoryAccessory
                        key={i}
                        enableEdit={enableEdit}
                        enableRecord={enableRecord}
                        enableOrderQuery={enableOrderQuery}
                        inventory={element}
                        onHistory={onHistory}
                        onStatus={onStatus}
                        onImage={onImage}
                        onPrice={onPrice}
                        onOrder={onOrder}
                        onCompatible={onCompatible}
                        warehousesAccess={warehousesAccess}
                        onMovementHistory={onMovementHistory}
                      />
                    );
                  })
                )}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default TableInventoryAccessory;
